import React from "react";
const PathShadowHolder = ({svgElements})=>{
    return (
        <div className="PathShadowHolder">
            {
                svgElements.map((ele,i)=>{
                    return(
                        <div key={i} className={`PathHolderElement line_hidden_svg_${i}`}>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 1000 700"
                                 xmlnsXlink="http://www.w3.org/1999/xlink">
                                <path
                                    className="svg-path"
                                    d={ele.d}
                                    // lineuniqueid = {ele.}
                                    // playeruniqueId={obj?obj.uniqueId:"123"}
                                    strokeWidth={60}
                                    id={ele.id}
                                    transform="translate(168.2 96) translate(-167.703125, -95.5) "
                                    strokeLinecap="round"/>
                            </svg>
                        </div>
                    )
                })
            }
        </div>
    )
}
export default PathShadowHolder;
