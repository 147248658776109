const initialState = {
    copiedObject: null, // Initialize with null or any default value you prefer
};

const clipBoard = (state = initialState, action) => {
    switch (action.type) {
        case "COPY_TO_CLIPBOARD":
            // Copy the Fabric.js object to the clipboard by assigning it to the 'copiedObject' property.
            return {
                ...state,
                copiedObject: action.payload,
            };

        case "CLEAR_CLIPBOARD":
            // Clear the clipboard by setting 'copiedObject' to null or any other default value.
            return {
                ...state,
                copiedObject: null,
            };

        default:
            return state;
    }
};

export default clipBoard;