import React, {useEffect, useState} from "react";
import {InputNumber} from "antd";
import {useSelector, useDispatch} from "react-redux";
import {setEditingMode, setObjSpeed} from "../../actions";
import {DelayIcon} from "../../assets/icons";
import {defaultTime} from "../../utils/utils";

const SpeedDropDown = ({frControlVisibility}) => {
    const dispatch = useDispatch();
    const objectStates = useSelector(state => state.canvasObjectStates);
    const canvas = useSelector(state => state.canvas);
    const objSpeed = useSelector(state => state.speed);
    const [playback, setPlayback] = useState(objSpeed / 1000);


    useEffect(() => {
        let obj = canvas.getActiveObject();
        if (obj) {
            setPlayback(obj.time / 1000)
        }
        return () => {
            dispatch(setEditingMode(false));
        }
    }, [objectStates])

    const setPlaybackSpeed = (speed) => {
        let obj = canvas.getActiveObject();
        if (obj.type === 'activeSelection') {
            const objs = obj._objects;
            if (objs.length) {
                objs.forEach(ob => {
                    setSpeed(speed, ob, true)
                })
                dispatch(setObjSpeed(speed * 1000))
            }
        } else {
            setSpeed(speed, obj)
        }

    }
    const setSpeed = (speed, obj, isMultiple = false) => {
        if (obj && obj.time && speed) {
            obj.time = speed * 1000
            if (!isMultiple) dispatch(setObjSpeed(obj.time))
            setPlayback(speed)
            // getLongestObject();
        }
    }


    return (
        <div className='time-container'>

                    <InputNumber className={frControlVisibility ? 'speed-bar' : 'speed-bar disabled_speed-bar'} min={0.1}
                                 max={10} defaultValue={defaultTime / 1000} value={playback}
                                 step={0.5}
                                 onChange={(e) => setPlaybackSpeed(e)}
                                 onFocus={() => {
                                     dispatch(setEditingMode(true))
                                 }}
                                 onBlur={() => {
                                     dispatch(setEditingMode(false))
                                 }}

                    />
            <DelayIcon fill={'#000000'}/>
        </div>
    )
}
export default SpeedDropDown;