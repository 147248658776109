import {sendBackShapes} from "../utils/shapes/shapeUtils";
import {notification} from "antd"
export const useRemoveFrame = ()=>{
    const handleRemoveFrame =async (props)=>{
        let {
            setFrameObjects,
            shadowFrames,
            setSvgPathElementss,
            svgPathElements,
            setFrames,
            allFrames,
            activeFrameVal,
            frames,stopEvents,canvas,frame,setActiveFrame,
            setActiveFrameTime,buildCanvas,addShadowObjects,
            reInitArrowLines,updateRemovedProps,intl
        } = props;
        const { formatMessage } = intl;
        let tempFrames = [...allFrames];
        let tmpShadowFrames = [...shadowFrames];
        let tmpPaths = [...svgPathElements];

        console.log('current frame', frame);
        console.log('shadowFrames', shadowFrames);
        console.log('allFrames', allFrames);
        console.log('svgPathElements', svgPathElements);
        console.log('activeFrameVal', activeFrameVal);

        if (frames.length > 1 || allFrames.length > 1) {
            stopEvents();

            if(activeFrameVal.data_num === frames.length-1){
                // if last frame is removed

                // remove its svg path elements
                tmpPaths = tmpPaths.filter(o => !o.id.startsWith(`line-svg:${activeFrameVal.data_num}:`));
                updateRemovedProps(false, {svgPathElements:tmpPaths},false);
                setSvgPathElementss([...tmpPaths]);

                // updating shadow objects
                let indexNo = activeFrameVal.data_num;
                tmpShadowFrames[indexNo].objects = tmpShadowFrames[indexNo].objects.map(o => {
                    o.d = ''
                    return o;
                })
                tmpShadowFrames = tmpShadowFrames.filter(f=>f.frame !== activeFrameVal.name);
                updateRemovedProps(false,false, {shadowFrames:tmpShadowFrames});
                setFrameObjects([...tmpShadowFrames])
            }
            else if(activeFrameVal.data_num === 0){
                // if first frame is removed

                // remove its svg path elements
                tmpPaths = tmpPaths.filter(o => !o.id.startsWith(`line-svg:${activeFrameVal.data_num+1}:`));
                tmpPaths = tmpPaths.map(mp=>{
                    return {
                        ...mp,
                        id: mp.id.replace(/line-svg:(\d+):/, (match, p1) => `line-svg:${parseInt(p1) - 1}:`)
                    };
                })
                updateRemovedProps(false, {svgPathElements:tmpPaths},false);
                setSvgPathElementss([...tmpPaths]);


                // updating shadow objects
                let indexNo = activeFrameVal.data_num;
                tmpShadowFrames[indexNo].objects = tmpShadowFrames[indexNo].objects.map(o => {
                    o.d = ''
                    return o;
                })
                tmpShadowFrames = tmpShadowFrames.filter(f=>f.frame !== activeFrameVal.name);
                updateRemovedProps(false,false, {shadowFrames:tmpShadowFrames});
                setFrameObjects([...tmpShadowFrames])

            }
            else{
                // if frame is removed somewhere from the middle

                // remove its svg path elements
                let prevPath = tmpPaths.filter(o => o.id.startsWith(`line-svg:${activeFrameVal.data_num}:`));
                tmpPaths = tmpPaths.filter(o => !o.id.startsWith(`line-svg:${activeFrameVal.data_num}:`));
                for (let i = activeFrameVal.data_num; i < tempFrames.length; i++) {
                    tmpPaths = tmpPaths.map(el => {
                                            if (el.id.startsWith(`line-svg:${tempFrames[i].data_num + 1}:`)) {
                                                el.id = el.id.replace(`line-svg:${tempFrames[i].data_num + 1}:`, `line-svg:${tempFrames[i].data_num}:`)
                                                // for D
                                                let d = el.d.split(" ");
                                                let prevElInd = -1;
                                                if(i === 1){
                                                    prevElInd = prevPath.findIndex(o => o.id.endsWith(el.id.split(":")[2]));
                                                    if (prevElInd > -1) {
                                                        const prevElD = prevPath[prevElInd].d.split(" ");
                                                        d[1] = prevElD[1];
                                                        d[2] = prevElD[2];
                                                        d[4] = prevElD[1];
                                                        d[5] = prevElD[2];
                                                        el.d = d.join(' ');
                                                    }
                                                }
                                                else{
                                                    prevElInd = tmpPaths.findIndex(o => o.id.startsWith(`line-svg:${tempFrames[i - 1].data_num}:`) && o.id.endsWith(el.id.split(":")[2]));
                                                    if (prevElInd > -1) {
                                                        const prevElD = tmpPaths[prevElInd].d.split(" ");
                                                        d[1] = prevElD[6];
                                                        d[2] = prevElD[7];
                                                        d[4] = prevElD[6];
                                                        d[5] = prevElD[7];
                                                        el.d = d.join(' ');
                                                    }
                                                }
                                            }
                                            return el;
                                        })
                                    }
                updateRemovedProps(false, {svgPathElements:tmpPaths},false);
                setSvgPathElementss([...tmpPaths]);

                // updating shadow objects
                let indexNo = activeFrameVal.data_num;
                tmpShadowFrames[indexNo+1].objects = tmpShadowFrames[indexNo+1].objects.map(o => {
                                        let prevFrObjs = tmpShadowFrames[indexNo - 1].objects;
                                        const ind = prevFrObjs.findIndex(ob => ob.ref_id === o.ref_id)
                                        if (ind > -1) {
                                            if(o.d === '' || !o.d || !o.d.length){
                                                o.d = ['M',o.left.toString(),o.top.toString(),'Q',o.left.toString(),o.top.toString(),o.left.toString(),o.top.toString()]
                                            }
                                            o.d[1] = prevFrObjs[ind].left.toString();
                                            o.d[2] = prevFrObjs[ind].top.toString();
                                            o.d[4] = prevFrObjs[ind].left.toString();
                                            o.d[5] = prevFrObjs[ind].top.toString();
                                        }
                                        return o;
                                    });
                tmpShadowFrames = tmpShadowFrames.filter(f=>f.frame !== activeFrameVal.name);
                updateRemovedProps(false,false, {shadowFrames:tmpShadowFrames});
                setFrameObjects([...tmpShadowFrames])
            }

            tempFrames = tempFrames.filter(f=>f.name !== activeFrameVal.name);
            tempFrames = tempFrames.map((mp, i) => {
                return { ...mp, data_num: i };
            });


            //Setting active frameVal and number of frames
            setFrames(tempFrames);
            allFrames = tempFrames;
            setActiveFrame(tempFrames[tempFrames.length - 1]);
            activeFrameVal = tempFrames[tempFrames.length - 1]
            activeFrameVal.data_num = tempFrames.indexOf(tempFrames[tempFrames.length - 1]);
            updateRemovedProps({allFrames,activeFrameVal});

            await buildCanvas(canvas, tempFrames[tempFrames.length - 1].json, false, tempFrames, activeFrameVal);
                await addShadowObjects(activeFrameVal);
                reInitArrowLines();
                setTimeout(() => {
                    sendBackShapes(canvas)
                }, 100)


        } else {
                    notification.error({
                        message: formatMessage({ id: "general.cantDeleteLastFrame" }),
                    });
        }
    }
    return {handleRemoveFrame}
}