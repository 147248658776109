import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import '../tools.scss';
import {FormattedMessage} from 'react-intl';
import {PlusOutlined} from '@ant-design/icons';
import {clearActiveTab, clearClipBoard, setActiveTab} from "../../../actions";
import {getMobileView} from "../../helpers";
import {tacticsBoardCustomImg} from "../../../utils/utils";

const getSurName = (playerName) => {
    let fullName = [];
    let hebrewCheck = /[\u0590-\u05FF]/
    let nameArr = playerName.split(' ').filter(f => f !== '');
    if (nameArr.length <= 1) {
        return {name: nameArr[0], isHebrew: hebrewCheck.test(nameArr[0])}
    } else {
        for (let i = 0; i < nameArr.length - 1; i++) {
            fullName.push(nameArr[i][0]);
        }
        fullName.push(nameArr[nameArr.length - 1])
        return {name: fullName.join('.'), isHebrew: hebrewCheck.test(playerName)}
    }
}

const CustomPlayers = (props) => {
    const playerSizesDel = [
        {size: 'small', span: 6, fontSize: 8, imgSize: '24', shirtWidth: '15px', shirtfLeft: '16px'},
        {size: 'medium', span: 12, fontSize: 8, imgSize: '28', shirtWidth: '18px', shirtfLeft: '10px'},
        {size: 'large', span: 6, fontSize: 8, imgSize: '34', shirtWidth: '20px', shirtfLeft: '16px'}
    ];
    const dispatch = useDispatch();


    const [playerImages, setPlayerImages] = useState([]);
    const iTags = playerImages.map(() => React.createRef());
    const [sizee, setSizee] = useState({
        size: 'small',
        span: 6,
        fontSize: 8,
        imgSize: '24',
        shirtWidth: '15px',
        shirtfLeft: '16px'
    })
    const selectedColor = useSelector(state => state.color);
    const customSquads = useSelector(state => state.easyTacticsBoard);
    const activeTab = useSelector((state)=>state.activeTab).tab;
    const canvas = useSelector(state => state.canvas);
    const {playerSizes, circleImg, team, showPlayerNumbers} = useSelector(state => state.player);

    useEffect(() => {
        if (customSquads.customPlayerImagesJson) {
            let parsedImages = JSON.parse(customSquads.customPlayerImagesJson)
            let filteredImages = parsedImages.map(mp=>{
                return {
                    ...mp, imageUrl:(mp.imageUrl?.length) ?  extractUrlParameter(mp.imageUrl): mp.imageUrl
                }
            })
            console.log(filteredImages)
            setPlayerImages(filteredImages);
        }
    }, [customSquads.customPlayerImagesJson]);
    useEffect(() => {
        let newSize = playerSizesDel.find(f => f.size === playerSizes.playerSquad);
        if (newSize) setSizee({...newSize});
    }, [playerSizes.playerSquad]);
    const setTab = (id,e)=>{
        if(activeTab === id){
            e.stopPropagation();
            canvas.selection = true;
            canvas.defaultCursor = 'default';
            dispatch(clearActiveTab());
            dispatch(clearClipBoard());
            return;
        }
        dispatch(setActiveTab(id));
    }
    function extractUrlParameter(url) {
        const urlObj = new URL(url);
        const params = new URLSearchParams(urlObj.search);
        const paramUrl = params.get('url');

        // Return an empty string if the 'url' parameter is present but empty, otherwise return the parameter value or original URL
        if (paramUrl === null) {
            return url.includes('GetImage')?'':url;  // Return the original URL if 'url' parameter is not present
        }
        return paramUrl === '' ? '' : decodeURIComponent(paramUrl);
    }

    const ItemsList  = ({team, showPlayerNumbers})=>{
        const isMobileView = getMobileView();
        const colSpan = isMobileView?6:12;
        return(
        <>
            {
                playerImages.map((player, i) => {
                    return (
                        <Col key={i} span={colSpan}
                             onClick={(e)=>{
                                 setTab(`${sizee.size}-${player.name}-${player.shirtNo}-${(player.imageUrl && circleImg) ? 'image' : 'circle'}`,e)
                                 iTags[i].current.click();
                             }}
                        >
                            <div className={`player-custom-image  ${getMobileView()?'tacticsboard__feature_icon-sq':'tacticsboard__feature_icon'} ${
                                activeTab===`${sizee.size}-${player.name}-${player.shirtNo}-${(player.imageUrl && circleImg) ? 'image' : 'circle'}`?'prim':''
                            } ${team} ${showPlayerNumbers?.playerSquad}`}
                                 id={`${sizee.size}-${player.name}-${player.shirtNo}-${(player.imageUrl && circleImg) ? 'image' : 'circle'}`}
                                 datasrc={player.imageUrl} data-playercustom="true">
                                                    <span
                                                        className="player-custom-image"
                                                        ref={iTags[i]}
                                                        id={`${sizee.size}-${player.name}-${player.shirtNo}-${(player.imageUrl && circleImg) ? 'image' : 'circle'}`}
                                                        datasrc={player.imageUrl}>
                                                        <img
                                                            className="player-custom-image"
                                                            src={(player.imageUrl && circleImg) ? player.imageUrl : tacticsBoardCustomImg('', selectedColor.playerSquad)}
                                                            height={getMobileView()?'22':sizee.imgSize}
                                                            width={getMobileView()?'22':sizee.imgSize}
                                                        />
                                                    </span>
                                <div
                                    className={getSurName(player.name).isHebrew ? 'shirt_no_right' : 'shirt_no_left'}>
                                                        <span className="custom_player_nameTag rtl" aria-disabled={true}
                                                              style={{
                                                                  fontSize: getMobileView()?'4px':sizee.fontSize,
                                                              }}>{getSurName(player.name).name}</span>
                                </div>
                            </div>
                        </Col>
                    )
                })
            }
            </>)
    }

    return (
        <div className="tacticsboard__feature_container" onClick={props.props}>
            {
                getMobileView()?
                    <ItemsList/>:
                    <Row gutter={[7, 7]} className="tacticsboard__feature_icons">
                        <ItemsList team={team} showPlayerNumbers={showPlayerNumbers} />
                    </Row>
            }

        </div>
    )
}

export default CustomPlayers;