const activeTab = (state = {tab:''}, action) => {
    if(action.type === "SET_ACTIVE_TAB"){
        console.log('active tab is ',action.payload)
        state.tab = action.payload
    }
    else if(action.type === "CLEAR_ACTIVE_TAB"){
        state.tab = ""
    }
    return state

};

export default activeTab;