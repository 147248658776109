const selectedColor = (state = "#003E60", action)=>{
    if(action.type === "SET_SELECTED_COLOR"){
        state = action.state
    }
    if(action.type === "RESET_CANVAS"){
        state = "#003E60";
    }

    return state;
}

export default selectedColor;