import React, {useEffect} from 'react';
import {FormattedMessage} from "react-intl";
import {Button} from "antd";
import {SvgIcon} from "@mui/material";
import {
    CirclePlayerIcon,
    LargeSizeIcon,
    MediumSizeIcon,
    PlayerShirtIcon, PlayersIcon,
    ShapeIconEditor, SmallShirtIcon,
    SmallSizeIcon
} from "../../assets/icons";
import {panelKeys} from "./index";
import {setPlayerSizes, setPlayerType} from "../../actions";
import {useDispatch, useSelector} from "react-redux";
import {getMobileView} from "../helpers";

const PlayerType = () => {
    const {playerType} = useSelector(state => state.player);
    const dispatch = useDispatch();
    const changePlayerType = (type) => {
        dispatch(setPlayerType(type));
    }
    return (
        <div className={`size_container`}>
            <span className="player_type_title"> <FormattedMessage id="Type"/></span>
            <div className="expandable_sizes size-buttons">
                <Button onClick={() => changePlayerType('shirt')} icon={<SvgIcon><SmallShirtIcon
                    fill={playerType === 'shirt' ? 'blue' : getMobileView() ? '#000000' : 'white'}/></SvgIcon>}/>
                <Button onClick={() => changePlayerType('circle')} icon={<SvgIcon><CirclePlayerIcon
                    fill={playerType === 'circle' ? 'blue' : getMobileView() ? '#000000' : 'white'}/></SvgIcon>}/>
                <Button onClick={() => changePlayerType('char')} icon={<SvgIcon><PlayersIcon
                    fill={playerType === 'char' ? 'blue' : getMobileView() ? '#000000' : 'white'}/></SvgIcon>}/>
            </div>
        </div>
    )
}
export default PlayerType;