import React from 'react';
import {Button} from "antd";
import {setSpriteIndex, setSrc} from "../../actions";
import {SvgIcon} from "@mui/material";
import {RotateIcon, RotateLeftIcon, RotateRightIcon} from "../../assets/icons";
import {useDispatch, useSelector} from "react-redux";
import {getMobileView} from "../helpers";
import {BASE_URL_ASSETS} from "../../utils/utils";
const DirectionsTab = ()=>{
    const {spriteIndex, sideSrc} = useSelector(state => state.player);
    const dispatch = useDispatch();
    let downArrow = `${BASE_URL_ASSETS}/assets/icons/rotateBottom.png`;
    let rightArrow = `${BASE_URL_ASSETS}/assets/icons/rotateRight.png`;
    let upArrow = `${BASE_URL_ASSETS}/assets/icons/rotateTop.png`;
    let leftArrow = `${BASE_URL_ASSETS}/assets/icons/rotateLeft.png`;

    const setDirection = (side, sideIndex) => {
        if (side === 'forward') {
            if (sideIndex === 0 && sideIndex < 1) {
                dispatch(setSrc(downArrow))
            } else if (sideIndex === 1 && sideIndex < 3) {
                dispatch(setSrc(rightArrow))

            } else if (sideIndex === 3 && sideIndex < 5) {
                dispatch(setSrc(upArrow)

                )            } else if (sideIndex === 5) {
                dispatch(setSrc(leftArrow))
            } else if (sideIndex === 7) {
                dispatch(setSrc(downArrow))
            }
        } else {
            if (sideIndex === 1 || sideIndex === 2) {
                dispatch(setSrc(rightArrow))
            } else if (sideIndex === 4 || sideIndex === 3) {
                dispatch(setSrc(upArrow)
                )           } else if (sideIndex === 6 || sideIndex === 5) {
                dispatch(setSrc(leftArrow))
            } else if (sideIndex === 7 || sideIndex === 0) {
                dispatch(setSrc(downArrow))
            }
        }

    }
    return(
        <div className="sprites-container_rotation">
            <Button style={{height: '17px', width: '17px'}} onClick={() => {
                if (spriteIndex === 0) {
                    dispatch(setSpriteIndex(7))
                    setDirection('backward', 7)
                } else {
                    dispatch(setSpriteIndex(spriteIndex - 1))
                    setDirection('backward', spriteIndex - 1)

                }
            }} icon={<SvgIcon><RotateLeftIcon fill={getMobileView()?'#000000':'#FFFFFF'}/></SvgIcon>}/>
            {getMobileView()?<RotateIcon/>:
                <img
                    src={sideSrc}
                    height="25"
                    width="25"
                    style={{cursor: 'pointer'}}/>
            }

            <Button style={{height: '17px', width: '17px'}} onClick={() => {
                if (spriteIndex === 7) {
                    dispatch(setSpriteIndex(0))
                    setDirection('forward', 0);

                } else {
                    dispatch(setSpriteIndex(spriteIndex + 1))
                    setDirection('forward', spriteIndex + 1);

                }
            }} icon={<SvgIcon><RotateRightIcon fill={getMobileView()?'#000000':'#FFFFFF'}/></SvgIcon>}/>
        </div>
    )
}
export default DirectionsTab;