import {setColorPopup, setLoader} from "../actions";
import {addAllObjs, startAnimation} from "../utils/animations";
import {sendBackShapes} from "../utils/shapes/shapeUtils";
import {useDispatch, useSelector} from "react-redux";
let hasAnimationStopped;

export const usePlayAnimation = (props)=>{

    let {activeFrameVal,convertToJson,animationState,stopEvents,setPlay,canvasBackgroundClass,frames,shadowFrames,setFrControlVisibility,hasAnimationStopped,deleteShadowObjects,allFrames,canvas,isAnimating,stopCurvedAnimation,reCreateAnimationProps} = props
    const dispatch = useDispatch();
    const checkAnimationStatus = () => {
        if (hasAnimationStopped) {
            isAnimating = false;
            stopCurvedAnimation(true)
            return true
        } else {
            return false
        }
    }
    const handlePlayAnimation =async ()=>{
        stopEvents()
        dispatch(setLoader(true));
        dispatch(setColorPopup(false))
        setFrControlVisibility(false);
        hasAnimationStopped = false;
        if (canvas.getActiveObject()) canvas.discardActiveObject();
        await deleteShadowObjects()
            allFrames[activeFrameVal.data_num].json = convertToJson(canvas);
            reCreateAnimationProps({allFrames,hasAnimationStopped})
            canvas.clear()
        await addAllObjs(allFrames, canvas);
        sendBackShapes(canvas);
            canvas.hasAnimationStarted = true;
            isAnimating = true;
            reCreateAnimationProps(false,{isAnimating})
            dispatch(setLoader(false));
            setPlay(true);
           await startAnimation(0, animationState, canvas, frames, shadowFrames, checkAnimationStatus, stopCurvedAnimation)
    }

    return {handlePlayAnimation}

}