export const setBrushType = (canvas,drawLineStates) => {
    let endps = canvas._objects.filter(f => f.name === 'line-end-point_shadow-object');
    for (let j = 0; j < endps.length; j++) {
        for (let i = 0; i < drawLineStates.length; i++) {
            if (endps[j].ref_id === drawLineStates[i].ref_id) {
                endps[j].set({brushType: drawLineStates[i].brushType, opacity: 0, evented: false});
            }
        }
    }
    canvas.renderAll();
}