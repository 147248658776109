import React from 'react';
import { Progress, Spin } from 'antd';
import { useSelector } from "react-redux";
import './loading.scss';

export const Loading = ({ downloadingProgress, uploadingState }) => {
    const animationState = useSelector(state => state.animation)
    return (
        <div className={animationState ? 'loading-animation' : 'loading'} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            {
                uploadingState ?
                    <>
                        <Progress
                            width={60}
                            type='circle'
                            percent={uploadingState.percentage}
                            strokeColor={{ '100%': "#6E6DD3", '0%': "#6E6DD3" }}
                        />
                        <div className='easy-tacticsboard-loader-label' >{uploadingState.message}</div>
                    </>
                    :
                    <>
                        <Spin />
                        {
                            animationState ?
                                <div style={{ marginTop: '20px' }}>{downloadingProgress}</div>
                                :
                                <div style={{ marginTop: '20px' }}>Processing file, Please wait...</div>
                        }
                    </>
            }
        </div>
    );
};
export const FormationLoading = () => {
    return (
        <div className={'loading'}
             style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Spin/>
        </div>
    );
};

