import {fabric} from "fabric";
const getEquipmentName = (activeObject)=>{
    return activeObject.imageFileName.split(':')[1].split('-')[0]
}
export const isTri = (obj)=>{
    if(obj) return !!(obj.hasOwnProperty('polygon') && obj.polygon);
    else return false
}
export const handleObjectUpdate = (object,canvas,updateInputText,updateObjColorState,isMobile,activeFrameVal)=>{
    let obj;
    let controls = {
        tl: false, //top-left
        mt: false, // middle-top
        tr: false, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: false, // bottom-left
        mb: false, //middle-bottom
        br: false, //bottom-right
        mtr: false, // rotate icon
        editctr: false, // edit text
        leftAction: false, //left Action
        rightAction: false, //Right Action
        flipAction: false, //Flip Action
        rotateAction:false,//angle rotate action
    }
    if (window.innerWidth < 1100 || isMobile()) {
        fabric.Object.prototype.setControlsVisibility(controls)
    }
    else {
        if (['rect','ellipse','circle'].includes(object.type)) {
            object.setControlsVisibility({ ...controls,tl: true, mt: true, tr: true, ml: true, mr: true, bl: true, mb: true, br: true, mtr: false,
        })
        }
        if (object.name === 'player_custom_image' || object.objecttype === 'disabled_borders') {
            fabric.Object.prototype.setControlsVisibility({ ...controls, editctr: false, br:false, rotateAction:  object._objects[2]?.direction })
        }
    }
    if(object.type === 'activeSelection'){
        object.setControlsVisibility({ ...controls, editctr: false, leftAction: object.multiSelectAction(), rightAction: object.multiSelectAction() })
    }
    if (object.objecttype === 'sprite-image') {
        object.setControlsVisibility({ ...controls, editctr: false })
    }
    if(object.name === 'player' || object.name === 'player_custom_image'){
        const finalDirection = !isTri(object)?object._objects[2]?.direction:false;
        console.log('isTri '+isTri(object)+' isSvg '+object.isSvg)
        fabric.Object.prototype.setControlsVisibility({ ...controls, editctr: false, br:false, rotateAction:finalDirection || false ,mtr:!!((isTri(object)))})
    }
    if (object.name === "image" ){
        if(object.objecttype === 'sprite-image'){
            object.setControlsVisibility({ ...controls, editctr: false, leftAction: true, rightAction: true, flipAction:true })
        }

        else{
            object.setControlsVisibility({ ...controls, editctr: false, br:false, rotateAction:false})
        }
    }
    if((object.imageFileName && getEquipmentName(object) === 'SG' ) || (object.imageFileName && getEquipmentName(object) === 'DummyStand')  || (object.imageFileName && getEquipmentName(object) === 'AngleStand')){
        object.setControlsVisibility({ ...controls, editctr: false, leftAction: true, rightAction: true,  flipAction:false })
    }
    if(object.imageFileName && (object.imageFileName.includes('Gate'))){
        object.setControlsVisibility({ ...controls, editctr: false, tl:true,mt:true, mtr:true,tr:true,ml:true,mr:true,bl:true,br:true,mb:true,leftAction: true, rightAction: true, rotateAction:false})
    }
    if(object.imageFileName && object.imageFileName.includes('Equipments:Stand-')){
        object.setControlsVisibility({ ...controls, editctr: false ,leftAction: true, rightAction: true, rotateAction:false})
    }
    updateObjColorState(object)

    if (object.name !== "clear") {
        if (object.name === "pX" && object.objecttype === "shadow_curve_point_active") {
            return;
        }
        canvas._objects.forEach((o, i) => {
            if (o.name === "pX" && o.objecttype === "shadow_curve_point_active") {
                canvas._objects[i].opacity = 0;
                canvas._objects[i].evented = false;
            }
        });
        let refId = object.ref_id;
        if (object?.name === "pX") refId = refId.split("::")[0];
        const middlePointActInd = canvas._objects.findIndex((o) => o.name === "pX" && o.ref_id?.includes(object.ref_id));
        const lineInd = canvas._objects.findIndex((o) => (o.name === "shadow-line" || o.name === "line") && o.ref_id.includes(refId));
        if (middlePointActInd > -1 && lineInd > -1) {
            canvas._objects[middlePointActInd].opacity = 0.5;
            canvas._objects[middlePointActInd].evented = true;
            canvas._objects[middlePointActInd].bringForward()
            // canvas._objects[lineInd].sendToBack();
        }
    }

    if (object.objecttype === "arrow_line" || object.objecttype === "simple_line" || object.objecttype === "curve_line") {
        obj = {
            objectActive: true,
            line: true,
            text: false,
            triangle:false,
            object: false,
            sprite:false,
            player: false,
            draw: false,
            shape: false,
            playerImage: false,
            reverse: false,
            activeSelection: false,
            time: false,
            direction:false
        }
    } else if (object.name === "text") {
        const text = object.text
        updateInputText(text)

        obj = {
            objectActive: true,
            line: false,
            text: true,
            object: false,
            triangle:false,
            sprite:false,
            player: false,
            draw: false,
            shape: false,
            playerImage: false,
            reverse: false,
            activeSelection: false,
            time: false,
            direction:false
        }
    } else if (object.name === "player") {
        const text = object._objects[object._objects.length - 1].text
        updateInputText(text)
        let dir = object._objects[2]?.hasOwnProperty('direction') ? object._objects[2].direction : false;
        const finalDirection = (isTri(object) || dir)

        obj = {
            objectActive: true,
            line: false,
            text: false,
            svg:object.isSvg,
            object: false,
            sprite:false,
            player: true,
            draw: false,
            shape: false,
            triangle:isTri(object),
            playerImage: false,
            reverse: true,
            activeSelection: false,
            time: activeFrameVal.data_num > 0,
            direction:finalDirection || false
        }
    } else if (object.name === "clear") {
        obj = {
            objectActive: false,
            line: false,
            text: false,
            object: false,
            triangle:false,
            svg:false,
            sprite:false,
            player: false,
            draw: false,
            shape: false,
            playerImage: false,
            reverse: false,
            activeSelection: false,
            time: false,
            direction:false
        }
        const middlePointActInd = canvas._objects.findIndex((o) => o.ref_id?.includes(obj.ref_id) && o.name === "pX");
        if (middlePointActInd > -1) {
            canvas._objects[middlePointActInd].opacity = .5;
            canvas._objects[middlePointActInd].selectable = true;
            canvas._objects[middlePointActInd].bringForward()
        }
        for (let i = 0; i < canvas._objects.length; i++) {
            if (canvas._objects[i].name === "pX" && (canvas._objects[i].objecttype === "shadow_curve_point_active" || !canvas._objects[i].ref_id.includes("shadow"))) {
                canvas._objects[i].opacity = 0;
                canvas._objects[i].evented = false;
            }
        }
    } else if (object.name === "drawLine") {
        obj = {
            objectActive: true,
            line: true,
            text: false,
            object: false,
            player: false,
            sprite:false,
            svg:false,
            triangle:false,
            draw: false,
            shape: false,
            playerImage: false,
            reverse: false,
            activeSelection: false,
            time: false,
            direction:false
        }
    } else if (object.name === "image") {
        obj = {
            objectActive: true,
            line: false,
            triangle:false,
            svg:false,
            text: object.objecttype === 'sprite-image',
            object: true,
            sprite:object.objecttype === 'sprite-image',
            player: false,
            draw: false,
            shape: false,
            playerImage: false,
            reverse: !!object.is_animation,
            activeSelection: false,
            time: !!(activeFrameVal.data_num > 0 && object.is_animation),
            direction:false
        }
    } else if (object.name === "Line Arrow") {
        obj = {
            objectActive: true,
            line: true,
            triangle:false,
            svg:false,
            text: false,
            object: false,
            sprite:false,
            player: false,
            draw: false,
            shape: false,
            playerImage: false,
            reverse: false,
            activeSelection: false,
            time: false,
            direction:false
        }
    }
    else if (object.name === "player_custom_image") {
        obj = {
            objectActive: true,
            line: false,
            text: true,
            triangle:false,
            svg:false,
            object: false,
            sprite:false,
            player: false,
            draw: false,
            shape: false,
            playerImage: true,
            reverse: true,
            activeSelection: false,
            time: activeFrameVal.data_num > 0,
            direction:object._objects[2].hasOwnProperty('direction') ? object._objects[2].direction : false
        }
    }
    else if (object.name === "free-shape" || object.name === "shape") {
        obj = {
            objectActive: true,
            line: false,
            text: false,
            triangle:false,
            svg:false,
            object: false,
            sprite:false,
            player: false,
            draw: false,
            shape: true,
            playerImage: false,
            reverse: false,
            activeSelection: false,
            time: false,
            direction:false
        }
    }
    else if (object.type === 'activeSelection') {
        obj = {
            objectActive: true,
            line: false,
            text: false,
            object: false,
            sprite:false,
            triangle:false,
            svg:false,
            player: false,
            draw: false,
            shape: false,
            playerImage: false,
            reverse: false,
            activeSelection: true,
            time: activeFrameVal.data_num>0 && multiDirectionState(object),
            direction:multiDirectionState(object)
        }
    }
    else if (object.name === 'shadow-line') {
        obj = {
            objectActive: false,
            line: false,
            text: false,
            object: false,
            sprite:false,
            triangle:false,
            svg:false,
            player: false,
            draw: false,
            shape: false,
            playerImage: false,
            reverse: false,
            activeSelection: false,
            time: false,
            direction:false
        }
    }
    else if(object.name === 'custom_image_name'){
        obj = {
            objectActive: false,
            line: false,
            text: false,
            object: false,
            sprite:false,
            triangle:false,
            svg:false,
            player: false,
            draw: false,
            shape: false,
            playerImage: false,
            reverse: false,
            activeSelection: false,
            time: false,
            direction:false
        }
    }
    else {
        obj = {
            objectActive: true,
            line: false,
            text: false,
            object: false,
            sprite:false,
            triangle:false,
            svg:false,
            player: false,
            draw: false,
            shape: false,
            playerImage: false,
            reverse: false,
            activeSelection: false,
            time: false,
            direction:false
        }
    }
    return obj;
}

const multiDirectionState = (obj)=>{
    if(obj.type !== 'activeSelection') return;
    let playerDirectionCount = obj.getObjects().filter(fInd=>(fInd.name === 'player' || fInd.name === 'player_custom_image') && fInd._objects[2] && fInd._objects[2].direction);
    if(playerDirectionCount.length) return true
   return false;

}