export const useUpdateObjects = ()=>{
    const handleFrameObjs = (props)=>{
     let {
         type,objj,updateAllFrames,setFrameObjects,shadowFrames,setSvgPathElementss,svgPathElements,activeFrameVal,animationState,canvas,updateRemovedProps
     } = props;

        let refId;
        if (objj) {
            refId = objj.ref_id;
            if (objj?.name === "pX") refId = refId.split("::")[0];
        }
        switch (type) {
            case "add":
                let indd = 0;
                if (activeFrameVal) {
                    indd = activeFrameVal.data_num;
                }
                let frInd = shadowFrames[indd].objects.findIndex(
                    (o) => o.ref_id === refId
                );
                if (!shadowFrames[indd].objects || frInd === -1) {
                    if (
                        objj.hasOwnProperty("is_animation") &&
                        objj.is_animation &&
                        !refId?.endsWith("shadow-object")
                    ) {
                        const {
                            name,
                            ref_id,
                            left,
                            height,
                            width,
                            top,
                            scaleX,
                            scaleY,
                        } = objj;
                        let objProps = {
                            name,
                            ref_id,
                            left,
                            top,
                            height,
                            width,
                            scaleX,
                            scaleY,
                            d: "",
                        };
                        if (
                            objj.name === "player_custom_image" ||
                            objj.objecttype === "sprite-image" ||
                            objj.name === "player"
                        ) {
                            const { nameText, shirtNo } = objj;
                            objProps = { ...objProps, nameText, shirtNo };
                        }
                        let indd = 0;
                        if (activeFrameVal) {
                            indd = activeFrameVal.data_num;
                        }
                        objj.set("animationProps", { frameIndex: indd, objProps });
                        shadowFrames[indd].objects = [
                            ...shadowFrames[indd].objects,
                            objProps,
                        ];
                        updateRemovedProps(false,false,{shadowFrames});
                        setFrameObjects([...shadowFrames]);
                        updateAllFrames(indd, objProps, objj);
                    }
                }
                break;
            case "add-all":
                let animateableObjects = [];
                let arrowLines = [];

                canvas._objects.forEach((obj) => {
                    if (
                        obj.hasOwnProperty("is_animation") &&
                        obj.is_animation &&
                        !obj.ref_id?.endsWith("shadow-object")
                    ) {
                        const {
                            name,
                            ref_id,
                            left,
                            top,
                            height,
                            width,
                            scaleX,
                            scaleY,
                        } = obj;
                        if (
                            !animateableObjects.find(
                                (o) => o.name === obj.name && o.ref_id === obj.ref_id
                            )
                        ) {
                            let actObj = svgPathElements.find(
                                (el) =>
                                    el.id ===
                                    `line-svg:${activeFrameVal.data_num}:${obj.ref_id}__shadow-object`
                            );
                            let d = actObj ? actObj.d.split(" ") : "";
                            if (
                                obj.name === "player_custom_image" ||
                                obj.objecttype === "sprite-image" ||
                                obj.name === "player"
                            ) {
                                const { nameText, shirtNo } = obj;
                                animateableObjects.push({
                                    name,
                                    ref_id,
                                    left,
                                    top,
                                    height,
                                    width,
                                    scaleX,
                                    scaleY,
                                    nameText,
                                    shirtNo,
                                    d,
                                });
                                activeFrameVal &&
                                obj.set("animationProps", {
                                    frameIndex: activeFrameVal.data_num,
                                    objProps: {
                                        name,
                                        ref_id,
                                        left,
                                        top,
                                        height,
                                        width,
                                        scaleX,
                                        scaleY,
                                        nameText,
                                        shirtNo,
                                        d,
                                    },
                                });
                            } else {
                                animateableObjects.push({
                                    name,
                                    ref_id,
                                    left,
                                    top,
                                    height,
                                    width,
                                    scaleX,
                                    scaleY,
                                    d,
                                });
                                activeFrameVal &&
                                obj.set("animationProps", {
                                    frameIndex: activeFrameVal.data_num,
                                    objProps: {
                                        name,
                                        ref_id,
                                        left,
                                        top,
                                        height,
                                        width,
                                        scaleX,
                                        scaleY,
                                        d,
                                    },
                                });
                            }
                        }
                    }

                    if (
                        obj.name === "line" ||
                        obj.name === "arrow_line" ||
                        obj.name === "arrow" ||
                        obj.name === "pa" ||
                        obj.name === "Line Arrow"
                    ) {
                        arrowLines.push(obj);
                    }
                });
                if (arrowLines.length) {
                    let ind = 0;
                    if (activeFrameVal) {
                        ind = activeFrameVal.data_num;
                    }
                    shadowFrames[ind] = { ...shadowFrames[ind], arrowLines: arrowLines };
                    setFrameObjects([...shadowFrames]);
                }
                if (animateableObjects.length) {
                    let ind = 0;
                    if (activeFrameVal) {
                        ind = activeFrameVal.data_num;
                    }
                    shadowFrames[ind] = {
                        ...shadowFrames[ind],
                        objects: animateableObjects,
                    };
                    setFrameObjects([...shadowFrames]);
                } else {
                    setFrameObjects([...shadowFrames]);
                }
                updateRemovedProps(false,false,{shadowFrames});
                break;
            case "update":
                let tempFrameObj = { ...shadowFrames[activeFrameVal.data_num] };
                // if(svgPathElements[0] === undefined)return
                let actObj = svgPathElements.find((el) =>
                    el.id.includes(
                        `line-svg:${activeFrameVal.data_num}:${
                            refId?.includes("shadow-object")
                                ? refId
                                : refId + "__shadow-object"
                        }`
                    )
                );
                let d = actObj ? actObj.d.split(" ") : "";
                if (tempFrameObj.objects.length && animationState) {
                    if (objj.objecttype === "shadow_curve_point_active") {
                        const ind = tempFrameObj.objects.findIndex((o) =>
                            refId.includes(o.ref_id)
                        );
                        let currObjInd = canvas._objects.findIndex(
                            (f) =>
                                f.ref_id ===
                                shadowFrames[activeFrameVal.data_num].objects[ind].ref_id &&
                                f.name !== "custom_image_name" &&
                                f.name !== "custom_image_shirtno"
                        );
                        if (ind > -1) {
                            shadowFrames[activeFrameVal.data_num].objects[ind].d = d;
                            if (currObjInd > -1 && canvas._objects[currObjInd].animationProps)
                                canvas._objects[currObjInd].animationProps.objProps.d = d;
                            updateRemovedProps(false,false,{shadowFrames});
                            setFrameObjects([...shadowFrames]);
                        }
                    } else {
                        if (objj.name.startsWith("custom_image_")) {
                            let imgInd = canvas._objects.findIndex(
                                (f) =>
                                    (f.name === "player_custom_image" ||
                                        f.objecttype === "sprite-image" ||
                                        f.name === "player") &&
                                    f.ref_id === objj.ref_id
                            );
                            if (imgInd > -1) {
                                objj = canvas._objects[imgInd];
                            }
                        }
                        const objIndex = tempFrameObj.objects.findIndex(
                            (o) => o.ref_id === objj.ref_id
                        );
                        if (objIndex > -1) {
                            shadowFrames[activeFrameVal.data_num].objects[objIndex].left =
                                objj.left;
                            shadowFrames[activeFrameVal.data_num].objects[objIndex].top =
                                objj.top;
                            shadowFrames[activeFrameVal.data_num].objects[objIndex].d = d;
                            if (objj.animationProps) {
                                objj.animationProps.objProps.left = objj.left;
                                objj.animationProps.objProps.top = objj.top;
                                objj.animationProps.objProps.d = d;
                            }
                            updateRemovedProps(false,false,{shadowFrames});
                            setFrameObjects([...shadowFrames]);
                        }
                    }
                }
                break;
            case "delete":
                if (shadowFrames.length) {
                    let tempFrameObjj = { ...shadowFrames[activeFrameVal.data_num] };
                    let svgInd = svgPathElements.findIndex(
                        (el) =>
                            el.id ===
                            `line-svg:${activeFrameVal.data_num}:${objj.ref_id}__shadow-object`
                    );
                    let svgIndNext = svgPathElements.findIndex(
                        (el) =>
                            el.id ===
                            `line-svg:${activeFrameVal.data_num + 1}:${
                                objj.ref_id
                            }__shadow-object`
                    );
                    if (svgInd > -1) {
                        svgPathElements.splice(svgInd, 1);
                        if (svgIndNext > -1) {
                            svgPathElements.splice(svgIndNext, 1);
                        }
                        updateRemovedProps(false,false,{shadowFrames});
                        setSvgPathElementss([...svgPathElements]);
                    }
                    if (tempFrameObjj.objects.length) {
                        const ind = tempFrameObjj.objects.findIndex(
                            (o) => o.ref_id === objj.ref_id
                        );
                        tempFrameObjj.objects.splice(ind, 1);
                        shadowFrames[activeFrameVal.data_num].objects = tempFrameObjj.objects;
                        updateRemovedProps(false,false,{shadowFrames});
                        setFrameObjects([...shadowFrames]);
                    }
                }
                break;
        }
    }
    return {handleFrameObjs}
}