import {fabric} from "fabric";
import {setLayers} from "../layring";
import {sendBackShapes} from "../shapes/shapeUtils";
import {copyToClipboard} from "../../actions";

export  const addLine = (props, customProps = false,setObjectPadding,moveLine,updateUndoStates,setActiveObject,canvas,isDropped=false) => {
    let x1 = customProps ? props.x1 : 50;
    let y1 = customProps ? props.y1 : 50;
    let x2 = customProps ? props.x2 : -props.scaleProps.lineHeight;
    let y2 = customProps ? props.y2 : 48;
    const {dispatch} = props;
    var line = new fabric.Line([x1, y1, x2, y2], {
        stroke: props.color,
        strokeWidth: customProps ? props.strokeWidth : (props.scaleProps.strokeWidth) - 2,
        hasBorders: false,
        hasControls: false,
        originX: 'center',
        originY: 'center',
        lockScalingX: true,
        lockScalingY: true,
        left: props.left,
        top: props.top,
        name: "arrow_line",
        perPixelTargetFind: true,
        objecttype: "simple_line",
        widthFactor: customProps ? props.widthFactor : 'l0',
        opacity:customProps ? props.opacity  : 1,
        newOpacity:customProps ? props.newOpacity  : 1,
        lockMovementX:customProps?.lockMovementX || false,
        lockMovementY:customProps?.lockMovementY || false,
    });

    setObjectPadding(line, 20, 10)
    if (props.is_dashed) {
        line.set({ strokeDashArray: [5, 5] })
    }

    var centerX = (line.x1 + line.x2) / 2,
        centerY = (line.y1 + line.y2) / 2,
        deltaX = line.left - centerX,
        deltaY = line.top - centerY;

    var square1 = new fabric.Circle({
        left: line.get('x2') + deltaX,
        top: line.get('y2') + deltaY,
        radius: props.scaleProps.lineSelectorHeight * .75,
        fill: "#fdd835",
        padding: 10,
        strokeWidth: 1,
        originX: 'center',
        originY: 'center',
        hasBorders: false,
        hasControls: false,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        selectable: false,
        pointType: 'arrow_end',
        opacity: 0,
        name: 'square1',
        objecttype: "simple_line",
        hoverCursor: "default",
    });
    setObjectPadding(square1, 5, 2)

    var square2 = new fabric.Circle({
        left: line.get('x2') + deltaX,
        top: line.get('y2') + deltaY,
        radius: props.scaleProps.lineSelectorHeight * .75,
        fill: "#fdd835",
        padding: 10,
        strokeWidth: 1,
        originX: 'center',
        originY: 'center',
        hasBorders: false,
        hasControls: false,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        selectable: false,
        pointType: 'arrow_start',
        opacity: 0,
        name: 'square2',
        objecttype: "simple_line",
        hoverCursor: "default",
    });
    setObjectPadding(square2, 5, 2)

    line.ref_id = square1.ref_id = square2.ref_id = props.ref_id
    line.square1 = square2.square1 = square1;
    line.square2 = square1.square2 = square2;
    square1.line = square2.line = line;
    if(!isDropped){
        if(!isDropped) {
            canvas.add(line, square1, square2);
            line.set('fadeFlag', 'no-fade-out');
            canvas.sendToBack(square2);
            canvas.sendToBack(square1);
            canvas.sendToBack(line);
            sendBackShapes(canvas);
            moveLine(line, "simple")
            if (!customProps) setActiveObject(line)
            updateUndoStates(line)
        }
    }
    else {
        dispatch(copyToClipboard(line));
        canvas.discardActiveObject();
        canvas.defaultCursor = 'crosshair';
        canvas.selection = false;
    }

}