import {showShadowLines} from "../../utils/HandleShadowObjs/showShadowLines";
import {addContinuePoint} from "../../utils/lines/FreeDraw/setFreeDrawControls";
import {fabric} from "fabric";
import {moveLine} from "../../utils/lines/moveLine";

export const useMovedObject = ()=>{
    const movedHelper = async (props)=>{
        let {
            e,canvas,updateArrowLinesArray,prevX,prevY,continueDraw,animationState,updateSelectedObjsLine,updateDrawControls,prevArX, prevArY,reinitpath,
            setCenterCurvePoint,reAddActivePoint,svgPathElements,setSvgPathElementss,updateArrowObject,shadowLineUpdate,updateRemovedProps,activeFrameVal
        } = props;

        if (
            [
                "p0",
                "p2",
                "arrow_line",
                "line",
                "square1",
                "square2",
                "arrow",
            ].includes(e.target.name) ||
            (e.target.name === "p1" && e.target.objecttype !== "shadow_curve_point")
        ) {
            updateArrowLinesArray();
        }
        if (e.target.name === 'drawLine') {
            let endPInd = canvas._objects.findIndex(f => f.name === 'line-end-point_shadow-object' && f.ref_id === e.target.ref_id);
            if (endPInd > -1) {
                let px = prevX.find(f => f.ref_id === e.target.ref_id);
                let py = prevY.find(f => f.ref_id === e.target.ref_id);
                const leftDiff = px?.x - e.target.left;
                const topDiff = py?.y - e.target.top;
                canvas._objects[endPInd].left = canvas._objects[endPInd].left - leftDiff;
                canvas._objects[endPInd].top = canvas._objects[endPInd].top - topDiff;

                let newEndPoint = continueDraw(1, 2, canvas._objects[endPInd].left, canvas._objects[endPInd].top, canvas._objects[endPInd].brushType, 'none', e.target.ref_id);
                newEndPoint.set('widthFactor', canvas._objects[endPInd].widthFactor)
                canvas.remove(canvas._objects[endPInd]);
                canvas.add(newEndPoint);

                canvas.renderAll();
                let prevXInd = prevX.findIndex(g => g.ref_id === e.target.ref_id);
                let prevYInd = prevY.findIndex(g => g.ref_id === e.target.ref_id);
                if (prevXInd > -1) {
                    prevX[prevXInd].x = e.target.left;
                    prevY[prevYInd].y = e.target.top;
                    updateDrawControls({prevArX,prevArY,prevX,prevY});
                }
            }
        }
        if (e.target.type === 'activeSelection') {
            var objs = e.target._objects;
            var lines = [];
            var arrowLines = [];
            canvas.discardActiveObject();
            for(let ind=0; ind<objs.length; ind++){
                let obj = objs[ind];
                if (animationState) {
                    updateSelectedObjsLine(obj)
                    showShadowLines(obj,canvas);
                }
                if (obj.name === 'arrow_line') {
                    arrowLines.push(obj);
                    if (obj.arrow) {
                        obj.arrow.opacity = 1;
                    }
                }
                if (obj.name === 'line') {
                    reinitpath({ target: obj, state: true }, (line) => {
                        const middlePointInd = canvas._objects.findIndex((o) => o.ref_id.includes(line.ref_id) && o.name === "p1");
                        if (middlePointInd > -1) {
                            setCenterCurvePoint(canvas._objects[middlePointInd],false);
                            reAddActivePoint(canvas._objects[middlePointInd]);
                        }
                        lines.push(line);
                    })
                }
            }

            objs.forEach(o => {
                addContinuePoint(objs, o,canvas, continueDraw, prevArX, prevArY, prevX, prevY, updateDrawControls)
            })


            if (arrowLines.length) {
                for (let i = 0; i < arrowLines.length; i++) {
                    moveLine(arrowLines[i]);
                }
            }
            if (lines.length) {
                objs = objs.filter(o => !lines.find(ob => ob.ref_id === o.ref_id));
                for (let i = 0; i < lines.length; i++) {
                    objs.push(lines[i])
                }
            }

            var sel = new fabric.ActiveSelection(objs, {
                canvas: canvas,
            });
            canvas.setActiveObject(sel);
        } else {
            e.target.opacity = .5
            let refId = e.target.ref_id.split('::')[0];
            if (e.target.name === "pX") refId = e.target.ref_id.split('::')[0];
            if (e.target.name === "pX" && e.target.objecttype === "shadow_curve_point_active") {
                let actObjInd = svgPathElements.findIndex((el) => el.id === `line-svg:${activeFrameVal.data_num}:${refId}`)
                if (actObjInd > -1) {
                    canvas.renderAll();
                    let actObj = svgPathElements[actObjInd];
                    let d = actObj.d.split(" ");
                    d[4] = e.target.line2.path[1][1].toString();
                    d[5] = e.target.line2.path[1][2].toString();
                    actObj.d = d.join(' ');
                    //For undo mid points
                    svgPathElements[actObjInd] = actObj;
                    updateRemovedProps(false,{svgPathElements});
                    setSvgPathElementss([...svgPathElements]);
                }
                return;
            }
            updateArrowObject(e.target, "moving");
            shadowLineUpdate(e.target,false);
            const middlePointInd = canvas._objects.findIndex((o) => o.ref_id.includes(e.target.ref_id) && o.name === "p1");
            if (middlePointInd > -1) setCenterCurvePoint(canvas._objects[middlePointInd],false);
            reAddActivePoint();
            if (e.target?.type === "LineAnnotateArrow") e.target.addCanvasListeners();
        }

    }
    return {movedHelper}
}