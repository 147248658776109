import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ArrowUpOutlined from "@ant-design/icons/lib/icons/ArrowUpOutlined";
import ArrowLeftOutlined from "@ant-design/icons/lib/icons/ArrowLeftOutlined";
import ArrowDownOutlined from "@ant-design/icons/lib/icons/ArrowDownOutlined";
import ArrowRightOutlined from "@ant-design/icons/lib/icons/ArrowRightOutlined";
import {setDisableSides, setTeam} from "../../actions";
import {panelKeys} from "./index";

let canvasWrapper, pitch;
const PlayerSide = () => {
    const [isVertical, setVertical] = useState(false);
    const canvas = useSelector(state => state.canvas);
    const {team, disableSides} = useSelector(state => state.player);
    const activeKey = useSelector(state => state.activePanel);

    console.log("Team is: ", typeof (team));

    const dispatch = useDispatch();
    useEffect(() => {
        canvasWrapper = canvas?.wrapperEl.classList[1];
        if (canvasWrapper) {
            console.log(canvasWrapper)
            pitch = canvasWrapper.slice(-2);
            let horizontalPitches = ['p7', 'p4', 'p8', 'p11', 'p12', 'p13'];
            if (canvasWrapper.includes('futsal')) pitch = canvasWrapper.slice(-3);
            if (canvasWrapper.includes('basketBall')) horizontalPitches = [...horizontalPitches, 'p1']
            setVertical(!horizontalPitches.includes(pitch));
        }
    }, [canvas])

    const toggleSides = () => {
        let newDisableSides = {...disableSides}
        newDisableSides = {...newDisableSides, [panelKeys[activeKey]]: !disableSides[panelKeys[activeKey]]};
        dispatch(setDisableSides(newDisableSides));
    }


    return (
        <div dir={"ltr"} className={`btn-wrp ${activeKey !== "7" ? "btn-wrp-gap" : ""}`}
             style={{justifyContent: !disableSides[panelKeys[activeKey]] ? "space-between" : "center"}}>

            <button className={`${team === 'left' ? 'prim' : 'default'} frm-btn`}
                    onClick={() => dispatch(setTeam('left'))} >
                {isVertical ? <ArrowUpOutlined/> : <ArrowLeftOutlined/>}
            </button>

            {
                activeKey !== "7" &&
                    <button
                        className={`frm-btn ${team === 'none' ? 'prim' : 'default'}`}
                        onClick={() => dispatch(setTeam('none'))}
                    >
                         <span role="img" className="anticon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" fill="none" width="15"
                             height="15" >
  <path d="M90 36L36 92" stroke={team === 'none' ? "#ffffff": "#1F1F1F"} stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/>
  <circle cx="64" cy="64" r="41" stroke={team === 'none' ? "#ffffff": "#1F1F1F"} stroke-width="10" fill="none"/>
</svg>
                    </span>
                    </button>

            }

            <button className={`${team === 'right' ? 'prim' : 'default'} frm-btn`}
                    onClick={() => dispatch(setTeam('right'))} >
                {isVertical ? <ArrowDownOutlined/> : <ArrowRightOutlined/>}
            </button>

        </div>
    )

}
export default PlayerSide;
