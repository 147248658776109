import {clearActiveTab, clearClipBoard} from "../../actions";
import {calcArrowAngle} from "../lines/calcArrowAngle";
import {fabric} from 'fabric';

export const dropObj = (props)=>{
    const {canvas, e, drawQuadratic, drawQuadraticArrow,dispatch} = props;
    let {cb} = props;
    dispatch(clearActiveTab());
    if(['curve_line'].includes(cb.objecttype)){
        let {is_dashed,ref_id,color,scaleProps,isArrow} = cb;
        const {p0, p1, p2 ,pX,pa} = getPoints(e);
        const {path} = getPath(e);
        let newProps = {left:e.absolutePointer.x,top:e.absolutePointer.y,is_dashed,ref_id,color,scaleProps,p0,p1,p2,pa,pX,path};
        if(isArrow){
            drawQuadraticArrow(newProps,true);
        }
        else{
            drawQuadratic(newProps,true);
        }

        let activeObj = canvas.getActiveObject();
        activeObj.isInitialized = false
        cb = activeObj
        cb.set('selectable',true);
    }
    else{
        cb.left = e.absolutePointer.x;
        cb.top = e.absolutePointer.y;
        cb.x1 = cb.x2 = e.absolutePointer.x;
        cb.y1 = cb.y2 = e.absolutePointer.y;
        cb.set('selectable',true);
        if(['disabled_borders','sprite-image'].includes(cb.objecttype)){
            canvas.add(cb.nameInstance);
            cb.setTextPosition(cb.nameInstance,canvas)
        }
        else if(['player_image'].includes(cb.objecttype)){
            canvas.add(cb.nameInstance);
            canvas.add(cb.numInstance);
            cb.setTextPosition(cb.nameInstance,canvas);
        }
        canvas.add(cb);
        //If shape is added then send it to back
        if(cb?.name === "shape" || cb?.name === "free-shape"){
            canvas.sendToBack(cb)
        }
    }
 if(['simple_line','arrow_line'].includes(cb.objecttype)){
        addSimpleLine(cb,canvas);
    }
    else if(['Line Arrow'].includes(cb.objecttype)){
        let coords = new fabric.Point(e.absolutePointer.x, e.absolutePointer.y);
        cb.width = 10;
        cb.set({x1:coords.x+10,y1:coords.y})
        cb.set({x2:coords.x,y2:coords.y})

    }
    canvas.defaultCursor = 'default';
    canvas.setActiveObject(cb);
    if(cb.name === 'text') cb.enterEditing();
}
export const dropMove = ({cb,canvas,e,setCenterCurvePoint})=>{
    let obj = canvas.getActiveObject();
    if(obj && cb){
        if(!['simple_line','curve_line','Line Arrow'].includes(cb.objecttype)) obj.set({left:e.absolutePointer.x,top:e.absolutePointer.y});
        if(['disabled_borders','sprite-image','player_image'].includes(cb.objecttype)){
            cb.setTextPosition(cb.nameInstance,canvas)
        }
        else if(['simple_line','arrow_line'].includes(cb.objecttype)){
            moveSimpleLine(cb,e);
        }
        else if(['curve_line'].includes(cb.objecttype)){
            let tempPath = [...obj.path]
            tempPath[0][1] = e.absolutePointer.x;
            tempPath[0][2] = e.absolutePointer.y;
            obj.set({path:tempPath})
            obj.p0.left = e.absolutePointer.x;
            obj.p0.top = e.absolutePointer.y;
            if(obj.pa){
                obj.pa.left = e.absolutePointer.x;
                obj.pa.top = e.absolutePointer.y;
                obj.pa.set({angle:calcArrowAngle(obj.p1.left,obj.p1.top,obj.p0.left,obj.p0.top) + 90})
                obj.pa.setCoords();
            }
            setCenterCurvePoint(obj.p1,false);
            obj.p0.setCoords();
            obj.setCoords();
        }
        else if(['Line Arrow'].includes(cb.objecttype)){
            obj.onCanvasMouseDown(e);
        }

        canvas.renderAll();
        obj.setCoords();
    }
}
const addSimpleLine = (cb,canvas) => {
    cb.set({x1:cb.left+10,y1:cb.top,x2:cb.left,y2:cb.top});
    cb.square1.set({left:cb.left,top:cb.top});
    cb.square2.set({left:cb.left,top:cb.top});
    cb.square1.setCoords();
    cb.square2.setCoords();
    cb.moveLine();
    if(cb.arrow){
        cb.arrow.set({left:cb.left+15,top:cb.top});
        cb.arrow.setCoords();
        cb.arrow.set({angle:calcArrowAngle(cb.left,cb.top,cb.left,cb.top) + 90})
        canvas.add(cb.arrow);
    }
    cb.sendToBack();
    canvas.add(cb.square1,cb.square2);
}
const moveSimpleLine = (cb,e) =>{
    cb.set({x1:e.absolutePointer.x,y1:e.absolutePointer.y})
    cb.square2.set({left:e.absolutePointer.x,top:e.absolutePointer.y});
    cb.square2.setCoords();
    if(cb.arrow){
        cb.arrow.set({left:e.absolutePointer.x,top:e.absolutePointer.y});
        cb.arrow.setCoords();
        cb.arrow.set({angle:calcArrowAngle(cb.x1,cb.y1,cb.x2,cb.y2) - 90})
    }
}

export const dropUp = (cb,{canvas,dispatch,clipBoard})=>{
    if(cb && clipBoard){
        let obj = canvas.getActiveObject();
        if(obj && obj.objecttype === 'curve_line'){
            canvas.fire('object:modified',{target:obj,isDropped:true})
        }
        cb = null;
        canvas.selection = true;
        dispatch(clearClipBoard());
    }
}
function isFabricObject(obj) {
    return obj instanceof Object && obj.constructor && obj.constructor.fabric === fabric.Object;
}
const getPoints  = (e) =>{
    let p0 = {left:e.absolutePointer.x,top:e.absolutePointer.y};
    let pa = {left:e.absolutePointer.x,top:e.absolutePointer.y, angle:360+90};
    let p1 = {left:e.absolutePointer.x,top:e.absolutePointer.y};
    let p2 = {left:e.absolutePointer.x,top:e.absolutePointer.y};
    let pX = {left:e.absolutePointer.x,top:e.absolutePointer.y};
    return {p0,p1,p2,pX,pa}
}
const getPath = (e) =>{
    let x = e.absolutePointer.x;
    let y = e.absolutePointer.y;
    const path = `M ${x} ${y} Q ${x} ${y} ${x} ${y}`;
    return {path}
}

export const checkEventedObjects = (props)=>{
 const {copiedObject,canvas,setEventedObjs,eventedObjs} = props;
    if(copiedObject){
        let objs = canvas?._objects || [];
        let tempOjs = [...eventedObjs];
        for(let index=0; index<objs.length;index++){
            let currentOb  = objs[index];
            if(currentOb.evented){
                currentOb.set({evented:false})
                tempOjs.push(currentOb)
            }
        }
        setEventedObjs([...tempOjs])
    }
    else{
        if(eventedObjs.length){
            for(let index=0;index<eventedObjs.length;index++){
                let currentObj = eventedObjs[index];
                currentObj.set({evented:true});
            }
            setEventedObjs([])
        }
    }
}