import React, {useEffect, useState} from 'react';
import './index.css';
import {useDispatch, useSelector} from "react-redux";
import {
    highlightBackground,
    setBackgroundOpacity,
    setColorPopup,
    setSelectedColor,
    textBackgroundToggler
} from '../../../actions';
import {fabric} from "fabric";
import {BASE_URL_ASSETS, colorName, returnObjectOfObject} from "../../../utils/utils";
import { Switch } from 'antd';
import {FormattedMessage} from "react-intl";
import {changeShirtColors} from "../../../utils/addPlayer";
import {getFabricImageFromURL} from "../../../utils/addImage";


let COLORS = [] ;
let TEXTCOL = [];

const ColorPopup = ({modifiedObject}) => {
    const dispatch = useDispatch();
    const canvas = useSelector(state => state.canvas);
    const isColorPopup = useSelector(state => state.colorPopup);
    const showHighlight = useSelector(state => state.highlightBg)
    const objectStates = useSelector(state => state.canvasObjectStates)

    const [isGoali,setGoali] = useState(false);
    TEXTCOL = ["#003E60", "#35ACDD", "#F7CF00", "#C40E12", "#fff", "#23803C","#003E60",null]

    if(objectStates.object && !(isColorPopup.type && (isColorPopup.type.includes('_solid') || isColorPopup.type.includes('free_shape')))){
        COLORS = ["#003E60", "#35ACDD", "#F7CF00", "#C40E12", "#fff", "#23803C"]
    }
    else if(isColorPopup.type && (isColorPopup.type.includes('Stand') || isColorPopup.type.includes('Cones') || isColorPopup.type === 'SG')){
        COLORS = ["#003E60", "#35ACDD", "#F7CF00", "#C40E12", "#fff", "#23803C"]
    }
    else if(objectStates.activeSelection){
        if(canvas.getActiveObject()){
            let spriteColor = canvas.getActiveObject()._objects.find(e=>e.objecttype==='sprite-image' || e.objecttype === "equipments")
            if(spriteColor)
                COLORS = ["#003E60", "#35ACDD", "#F7CF00", "#C40E12", "#fff", "#23803C"];
        }
    }
    else{
        COLORS = ["#003E60", "#35ACDD", "#F7CF00", "#C40E12", "#fff", "#23803C", "#000000"];
    }

    useEffect(() => {
        if(objectStates.object){
            let obj = canvas.getActiveObject()
            if(obj.objecttype === 'sprite-image'){
                if(obj.imageFileName.includes("Goali")){
                    setGoali(true)
                }else{
                    setGoali(false)
                }
            }
        }
    }, [])

    const importAndAddImage =(obj,fileName,nameCol,newPoints=null)=>{
        let src = `${BASE_URL_ASSETS}/assets/Attacking/${nameCol}/${fileName.split('_')[1]}.png`;
        getFabricImageFromURL(src,(fabImage)=>{
            let newSrc = fabImage.getSrc();
            let newFilename = `Players:${nameCol}_${fileName.split('_')[1]}`;
            obj.imageFileName = newFilename;
            obj.setSrc(newSrc, function(){
                canvas.requestRenderAll();
            },{crossOrigin:'anonymous'});
        })
    }

    const replaceImage = (obj, color,newPoints = null) => {
        if (!obj || !obj.imageFileName) return;
        const nameCol = colorName[color] || 'White';
        let fileName = obj.imageFileName.split(':')[1];
        if (obj.imageFileName.split(':')[0] === "Players") {
            if (!fileName || fileName.split('_')[0] === 'Goali') return;
            importAndAddImage(obj,fileName,nameCol,newPoints);
        }
        if (obj.imageFileName.split(':')[0] === "Equipments") {
            const eles = fileName.split('-');
            const file = eles[1];
            let nums = file.replace(/[^0-9]/g, '');
            const name = file.replace(nums, '');

            let src = `${BASE_URL_ASSETS}/assets/images/${eles[0]}/${name.includes('_') ? name.split('_')[0] + '_' : ''}${nameCol}${nums ? nums : ''}.png`;
            getFabricImageFromURL(src,(fabImage)=>{
                let newSrc = fabImage.getSrc();
                obj.setSrc(newSrc, function(){
                    canvas.requestRenderAll();
                },{crossOrigin:'anonymous'});
            })
        }
        if (obj.imageFileName.split(':')[0] === "Gate") {
            const eles = fileName.split('-');
            const size = eles[0];
            const numss = eles[2];

            let src = `${BASE_URL_ASSETS}/assets/images/Gate/${size}-${nameCol}-${numss}.png`;
            getFabricImageFromURL(src,(fabImage)=>{
                let newSrc = fabImage.getSrc();
                obj.setSrc(newSrc, function(){
                    canvas.requestRenderAll();
                },{crossOrigin:'anonymous'});
            })
        }
    }

    const returnObject2 = (array, ref_id, name) => {
        for (var i = 0; i < array._objects.length; i++) {
            var obj = array._objects[i]
            if (obj.ref_id === ref_id && obj.name === name) {
                return obj
            }
        }
    }
    function hexToRgbA(hex,alpha){
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            let tempHexArr = hex.split('').filter(o=> o!=='#');
            hex = tempHexArr.join('');
            if (hex.length === 3) {
                hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
            }

            var r = parseInt(hex.substring(0,2), 16),
                g = parseInt(hex.substring(2,4), 16),
                b = parseInt(hex.substring(4,6), 16);

            /* Backward compatibility for whole number based opacity values. */


            return 'rgba('+r+','+g+','+b+','+alpha+')';
        }
        else{
            hex = rgba2hex(hex,alpha);
            let tempHexArr = hex.split('').filter(o=> o!=='#');
            hex = tempHexArr.join('');
            if (hex.length === 3) {
                hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
            }

            var r = parseInt(hex.substring(0,2), 16),
                g = parseInt(hex.substring(2,4), 16),
                b = parseInt(hex.substring(4,6), 16);

            /* Backward compatibility for whole number based opacity values. */
            return 'rgba('+r+','+g+','+b+','+alpha+')';

        }
    }
    function rgba2hex(rgba) {
        rgba = rgba.match(
            /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
        );
        let newVal = rgba && rgba.length === 4
            ? "#" +
            ("0" + parseInt(rgba[1], 10).toString(16)).slice(-2) +
            ("0" + parseInt(rgba[2], 10).toString(16)).slice(-2) +
            ("0" + parseInt(rgba[3], 10).toString(16)).slice(-2)
            : "";
        return newVal
    }
    const returnObject = (ref_id, name) => {
        for(var i = 0;i < canvas._objects.length;i++){
            var obj = canvas._objects[i]
            if(obj.ref_id === ref_id && obj.name === name && !obj.ref_id.includes("shadow")){
                return obj
            }
        }
    }
    const getObjectByType =(actObj,color,type)=>{
        if (actObj.name === 'p0' || actObj.name === 'p1' || actObj.name === 'p2' || actObj.name === 'pa' || actObj.name === 'square1' || actObj.name === 'square2') {
            actObj = canvas._objects.find(o=>o.ref_id === actObj.ref_id && o.name.includes('line'))
        }
        switch (type) {
            case 'simple_solid':
                if (actObj.objecttype === "simple_line" && !actObj.strokeDashArray) actObj.set('stroke', color);
                break;
            case 'simple_dashed':
                if (actObj.objecttype === "simple_line" && actObj.strokeDashArray) actObj.set('stroke', color);
                break;
            case 'arrow_solid':
                if (actObj.objecttype === "arrow_line" && !actObj.strokeDashArray) {
                    actObj.set('stroke', color);
                    if (actObj.arrow) actObj.arrow.set('fill', color)
                }
                break;
            case 'arrow_dashed':
                if (actObj.objecttype === "arrow_line" && actObj.strokeDashArray) {
                    actObj.set('stroke', color);
                    if (actObj.arrow) actObj.arrow.set({ fill: color })
                }
                break;
            case 'curvy_solid':
                if (actObj.objecttype === "curve_line" && !actObj.strokeDashArray) actObj.set('stroke', color);
                break;
            case 'curvy_dashed':
                if (actObj.objecttype === "curve_line" && actObj.strokeDashArray) actObj.set('stroke', color);
                break;
            case 'curvyarrow_solid':
                if (actObj.objecttype === "curve_line" && !actObj.strokeDashArray && actObj.pa) {
                    if (actObj.pa) actObj.pa.set('fill', color)
                    actObj.set('stroke', color);
                }
                break;
            case 'curvyarrow_dashed':
                if (actObj.objecttype === "curve_line" && actObj.strokeDashArray && actObj.pa) {
                    if (actObj.pa) actObj.pa.set({ fill: color });
                    actObj.set('stroke', color);
                }
                break;
            case 'arrow_zigzag':
                updateZigzagColor(actObj,color);
            case 'text':
                if (actObj.name === 'text') {
                    actObj.set({ fill: color })
                }
                break;
            case 'free_shape_border':
                actObj.set({stroke:color});
                break;
            case 'free_shape_fill':
                color  = toRgba(color,actObj.bgOpacity);
                actObj.set({fill:color});
                break;
            case 'square_solid_fill':
                color  = toRgba(color,actObj.bgOpacity);
                actObj.set({fill:color});
                break;
            case 'square_solid_border':
                actObj.set({stroke:color});
                break;
            case 'ellipse_solid_fill':
                color  = toRgba(color,actObj.bgOpacity);
                actObj.set({fill:color});
                break;
            case 'ellipse_solid_border':
                actObj.set({stroke:color});
                break;
            case 'oval_solid_fill':
                color  = toRgba(color,actObj.bgOpacity);
                actObj.set({fill:color});
                break;
            case 'oval_solid_border':
                actObj.set({stroke:color});
                break;
            default:
                if (actObj.name === 'drawLine' && actObj.objecttype === type) {
                    actObj._objects.forEach(obj => {
                        if (obj.name === "drawElementArrow") {
                            obj.set({ fill: color })
                        } else if (obj.name === "drawElementBlock") {
                            obj.set({ fill: color })
                            obj.set({ stroke: color })
                        } else {
                            obj.set({ stroke: color })
                        }
                    })
                }
                if (actObj.type === "image"){
                    const typeName =actObj.imageFileName.split(':')[1]
                    if (typeName.split('-')[0] === type) {
                        replaceImage(actObj, color)
                    }
                    else if (type.startsWith(typeName.split('-')[0])){
                        replaceImage(actObj, color)
                    }
                }
                break;
        }
        canvas.renderAll()
        modifiedObject({ target: actObj, state: true })
    }
    const updateZigzagColor =(object,color)=>{
        let size = object.size;
        if (object.custom) object.custom.arrowA.color=color;
        const col = colorName[color] || 'White';
        import(`../../../assets/sinWave-${col}/sinWave-${size}-${col}.png`).then(zigzagRed=>{
            fabric.util.loadImage(zigzagRed.default,function(img){
                object.set('stroke', new fabric.Pattern({
                    source:img,
                    repeat:'repeat-x'
                }))
                object.set('color',col);
                canvas.renderAll();
            },{ crossOrigin: 'anonymous'});
        })
    }
    const setEachObjColor = (object,color,freeShapeFlag,bgTextColor,imageText,imageTextBg,showHighlightColor)=>{
        if(object.name === 'Line Arrow') updateZigzagColor(object,color);
        if (object.name === 'p0' || object.name === 'p1' || object.name === 'p2' || object.name === 'pa') {
            object.line.set({ stroke: color })

            if (object.pa) {
                object.pa.set({ fill: color })
            }
            if (object.name === "pa") {
                object.set({ fill: color })
            }
        }
        if(object.name === 'free-shape' || object.name === 'shape'){
            if(isColorPopup.freeShape && freeShapeFlag){
                object.set({stroke:color})
            }
            else{
                color = toRgba(color,object.bgOpacity);
                object.set({fill: color})
            }

        }
        if (object.name === 'line') {
            object.set({ stroke: color })

            if (object.pa) {
                object.pa.set({ fill: color })
            }
        }
        if (object.name === 'arrow_line') {
            object.set({ stroke: color })
            if (object.arrow) {
                object.arrow.set({ fill: color })
            }
        }
        if (object.name === 'square1' || object.name === 'square2') {
            object.line.set({ stroke: color })
            if (object.arrow) {
                object.arrow.set({ fill: color })
            }
        }
        if (object.name === 'player') {
            let frInd = canvas._objects.findIndex(o => o.ref_id === object.ref_id && o.name.includes('custom_image_name'));
            var player = returnObjectOfObject(object, object.ref_id, "circle");
            // if(object._objects[2]?.hasOwnProperty('direction')) object._objects[2].set({dirty:true,arrowFill:color})
            if(imageText){
                canvas._objects[frInd].set({fill: color})
            }else if(imageTextBg){
                if(!color){
                    canvas._objects[frInd].set({ dirty: true, hasBg:false})
                    return
                }
                canvas._objects[frInd].set({ dirty: true, hasBg:true})

                if(color === '#fff'){
                    color = '#ffffff'
                }
                canvas._objects[frInd].set({ dirty: true, fillStyle: color+95})
                if(color === '#ffffff' || color === '#f7cf00'){
                    canvas._objects[frInd].set({fill: '#000000'})
                }
            }else if(showHighlightColor){
                if(color === '#fff') color = '#FFFFFF'
                player && player.set({ dirty:true, highlightColor: color+65 })
            }else{
                if(object._objects[2]?.hasOwnProperty('direction')) object._objects[2].set({dirty:true,arrowFill:color})
                if(object.isSvg){
                    changeShirtColors(object, canvas, color);
                }
                else{
                    var player = returnObject2(object, object.ref_id, "circle")
                    player && player.set({ fill: color })
                }
            }
        }
        if (object.name === 'player_custom_image') {
            let frInd = canvas._objects.findIndex(o => o.ref_id === object.ref_id && o.name.includes('custom_image_name'));
            var player = returnObjectOfObject(object, object.ref_id, "circle")
            // if(object._objects[2]?.hasOwnProperty('direction')) object._objects[2].set({dirty:true,arrowFill:color})
            if(imageText){
                canvas._objects[frInd].set({fill: color})
            }
            else if(imageTextBg){
                if(!color){
                    canvas._objects[frInd].set({ dirty: true, hasBg:false})
                    return
                }
                canvas._objects[frInd].set({ dirty: true, hasBg:true})
                if(color === '#fff')color = '#ffffff'
                canvas._objects[frInd].set({ dirty: true, fillStyle: color+95})
                if(color === '#ffffff' || color === '#F7CF00'){
                    canvas._objects[frInd].set({fill: '#000000'})
                }
            }else if(showHighlightColor){
                if(color === '#fff')color = '#ffffff'
                player && player.set({ dirty:true, highlightColor: color+65 })
            }
            else{
                if(object._objects[2]?.hasOwnProperty('direction')) object._objects[2].set({dirty:true,arrowFill:color})
                var grpImg = returnObject(object.ref_id, "player_custom_image");
                grpImg &&  typeof grpImg._objects[0].fill !== 'object' &&  grpImg._objects[0].set({fill:color});
            }
        }
        var customImage = returnObject(object.ref_id, "player_custom_image")
        if (customImage){
            let desObj = returnObject(object.ref_id, object.name)
            desObj &&typeof customImage._objects[0].fill !== 'object' && desObj.set({ fill: color })
            if (object.name === 'custom_image_name') {
                // Added properties for shadow
                if (object.hasOwnProperty('nameText')){
                    object.nameText.fill=color;
                }
            }
            if (object.name === 'custom_image_shirtno') {
                if (object.hasOwnProperty('shirtNo')){
                    object.shirtNo.fill=color;
                }
            }

        }
        if (object.name === 'text') {
            if(bgTextColor){
                let newColor = hexToRgbA(color,object.bgOpacity);
                object.set({backgroundColor:newColor})
            }
            else{
                object.set({ fill: color })
            }
        }

        if (object.name === 'drawLine') {
            object._objects.forEach(obj => {
                if (obj.name === "drawElementArrow") {
                    obj.set({ fill: color })
                } else if (obj.name === "drawElementBlock") {
                    obj.set({ fill: color })
                    obj.set({ stroke: color })
                } else {
                    obj.set({ stroke: color })
                }
            })
        }
        if (object.name === 'image') {
            if (object.objecttype === 'sprite-image'){
                let frInd = canvas._objects.findIndex(o => o.ref_id === object.ref_id && o.name.includes('custom_image_name'));
                if(imageText){
                    canvas._objects[frInd].set({fill: color})
                }else if(imageTextBg){
                    if(!color){
                        canvas._objects[frInd].set({ dirty: true, hasBg:false})
                        return
                    }
                    canvas._objects[frInd].set({ dirty: true, hasBg:true})
                    if(color === '#fff'){
                        color = '#ffffff'
                    }
                    canvas._objects[frInd].set({ dirty: true, fillStyle: color+95})
                    if(color === '#ffffff' || color === '#F7CF00'){
                        canvas._objects[frInd].set({fill: '#000000'})
                    }
                }else{
                    replaceImage(object, color)
                }
            }
            else if(object.objecttype === 'equipments') {
                replaceImage(object, color)
            }
        }
    }
    const updateIndObjColor = (color,freeShapeFlag = false,bgTextColor=false,imageText = false,imageTextBg = false, showHighlightColor = false) => {
        canvas.freeDrawingBrush.color = color
        const object = canvas.getActiveObject()
        if (object) {
            if(object.type === 'activeSelection'){
                object._objects.forEach(e=>{
                    if(e.name!=='custom_image_name') {
                        if(e.objecttype === 'sprite-image'){
                            let matrix = object.calcTransformMatrix();
                            let newPoint = fabric.util.transformPoint({y:e.top,x:e.left},matrix);
                            replaceImage(e, color,newPoint)
                        }
                        else{
                            setEachObjColor(e, color, false,bgTextColor)
                        }
                    }
                })
            }
            else{
                setEachObjColor(object,color,freeShapeFlag,bgTextColor,imageText,imageTextBg,showHighlightColor)
            }
            canvas.renderAll()
            modifiedObject({ target: object, state: true })
        }

    }
    function toRgba(hex,opacity) {
        let hexRegx = /^#([A-Fa-f0-9]{3}){1,2}$/
        if (!hexRegx.test(hex)) {
            return hex
        }
        else{
            let tempHexArr = hex.split('').filter(o=> o!=='#');
            hex = tempHexArr.join('');
            if (hex.length === 3) {
                hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
            }

            var r = parseInt(hex.substring(0,2), 16),
                g = parseInt(hex.substring(2,4), 16),
                b = parseInt(hex.substring(4,6), 16);

            /* Backward compatibility for whole number based opacity values. */
            return 'rgba('+r+','+g+','+b+','+opacity+')';
        }
    }

    const updateColor = (color,freeShape = false,bgTextColor = false, imageText = false, imageTextBg = false,showHighlightColor= false) => {
        const type = isColorPopup.type;
        let actObj = canvas.getActiveObject();
        if(type !== 'ind_object') {
            if(type === "highlight" || type === undefined)updateIndObjColor(color,freeShape,bgTextColor,imageText,imageTextBg,showHighlightColor)
            else{
                dispatch(setSelectedColor(''))
                dispatch(setSelectedColor(color))
            }
        }
        else if (actObj){
            if (type === "ind_object") updateIndObjColor(color,freeShape,bgTextColor,imageText,imageTextBg,showHighlightColor)
            else getObjectByType(actObj,color,type)
            canvas.renderAll();
        }
    }
    const closePopUp=()=>{
        dispatch(setColorPopup(false))
    }
    const onChange = (checked) => {
        let obj = canvas.getActiveObject()
        if(checked){
            dispatch(setBackgroundOpacity(checked))
            obj.set({
                backgroundColor: hexToRgbA('#C40E12',0.6)
            })
        }else{
            dispatch(setBackgroundOpacity(checked))
            obj.set({
                backgroundColor:''
            })
        }
        canvas.renderAll()
    };
    const onChangeBg = (checked) => {
        let obj = canvas.getActiveObject()
        let frInd = canvas._objects.findIndex(o => o.ref_id === obj.ref_id && o.name.includes('custom_image_name'));
        let hasBgObj = canvas._objects[frInd]
        if(checked){
            dispatch(textBackgroundToggler(checked))
            hasBgObj.set({
                hasBg:true,
                dirty:true
            })
        }else{
            dispatch(textBackgroundToggler(checked))
            hasBgObj.set({
                hasBg:false,
                dirty:true
            })
        }
        canvas.renderAll()
    };
    const onChangeHighlight = (checked) => {
        let obj = canvas.getActiveObject()
        let player = returnObjectOfObject(obj, obj.ref_id, 'circle')
        if(checked){
            dispatch(highlightBackground(checked))
            player.set({
                showHighlight:true,
                dirty:true
            })
        }else{
            dispatch(highlightBackground(checked))
            player.set({
                showHighlight:false,
                dirty:true
            })
        }
        modifiedObject({target:obj,action:'highlighted'})
        canvas.renderAll()
    };
    return (
        <div className={`color-main-container ${isColorPopup.type === "highlight" && "color-popup-highlight"}`}>
            { (isColorPopup.type !== "highlight" && isColorPopup.type) &&
                <div className="d_flex_color">
                        {!isGoali &&
                            <div className="color_property">
                                <span>{isColorPopup.text ? 'Text Color' : (objectStates.player || objectStates.playerImage) ? <FormattedMessage id="general.colorFill" /> : <FormattedMessage id="general.color" />}</span>
                                <div className="colors_container">
                                    {COLORS.map(color => <div key={color}
                                                              data-color={color}
                                                              onClick={() => updateColor(color)}
                                                              className="colors_color black__border_light"
                                                              style={{backgroundColor: color}}/>)
                                    }
                                </div>
                            </div>
                        }
                        {
                            isColorPopup.imgWithText &&
                            <div className="d_flex_color">
                                <div className="color_property">
                                    <span><FormattedMessage id="general.nameColor" /></span>
                                    <div className="colors_container">
                                        {COLORS.map(color => <div key={color}
                                                                  data-color={color}
                                                                  onClick={() => updateColor(color, false, false, true, false)}
                                                                  className="colors_color black__border_light"
                                                                  style={{backgroundColor: color}}/>)}
                                    </div>
                                </div>
                                <div className="color_property">
                                    <div className="toggle_bg_Btn">
                                        <span><FormattedMessage id="general.nameBackground" /></span>
                                    </div>
                                    {
                                        <>
                                            <div className="colors_container">
                                                {TEXTCOL.map(color =>
                                                    (color!== null) ? (
                                                                          <div key={color}
                                                                          data-color={color}
                                                                          onClick={() => updateColor(color, false, false, false, true)}
                                                                          className="colors_color black__border_light"
                                                                          style={{backgroundColor: color}}/>
                                                    ):
                                                        (
                                                    <div key={color}
                                                    data-color={color}
                                                    onClick={() => updateColor(color, false, false, false, true)}
                                                    className="colors_color black__border_light"
                                                    style={{backgroundColor: 'transparent', color:'red'}}>
                                                        X
                                                    </div>
                                                        ))}
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        }
                </div>
            }
            {(isColorPopup.showHighlight || isColorPopup.showHighlight === undefined ) &&
                <>
                    {(objectStates.playerImage || objectStates.player) &&
                    <div>
                        <div className="toggle_bg_Btn">
                            <span><FormattedMessage id="general.showHighlight"/></span>
                            <Switch checked={showHighlight} onChange={onChangeHighlight} />
                        </div>
                        {
                            showHighlight&&
                            <>
                                <div className="colors_container">
                                    {COLORS.map(color => <div key={color}
                                      data-color={color}
                                      onClick={() => updateColor(color, false, false, false, false, true)}
                                      className="colors_color black__border_light"
                                      style={{backgroundColor: color}}/>)}
                                </div>
                            </>
                        }
                    </div>
                    }
                </>
            }
            {isColorPopup.freeShape &&
              <div>
                  <span>Border</span>
                    <div className="colors_container">
                        {COLORS.map(color => <div key={color}
                              data-color={color}
                              onClick={() => updateColor(color,true)}
                              className="colors_color black__border_light"
                              style={{ backgroundColor: color }}/>)}
                    </div>
                </div>
            }
            {isColorPopup.text &&
            <div>
                <div className="toggle_bg_Btn">
                    <span>Background</span>
                </div>
                {
                    <>
                        <div className="colors_container">
                            {COLORS.map(color => <div key={color}
                              data-color={color}
                              onClick={() => updateColor(color, false, true)}
                              className="colors_color black__border_light"
                              style={{backgroundColor: color}}/>)}
                        </div>
                    </>
                }
            </div>
            }
        </div>
    )
}

export default ColorPopup;