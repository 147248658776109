import {getMobileView} from "../components/helpers";
import {BASE_URL_ASSETS} from "../utils/utils";

const player = (state = {state: false, text: "0",spriteIndex:0, sideSrc:`${BASE_URL_ASSETS}/assets/icons/rotateBottom.png`, team:'none', playerType:'shirt',circleImg:true,
    playerSizes: {players: "medium",playerShirts:'medium', goalKeepers: "medium",playerPositions:getMobileView() ? "large" : "medium" , playerNo: getMobileView() ? "large" : "medium", playerSquad: getMobileView() ? "large" : "medium", playerSquadNumbers: getMobileView() ? "large" : "medium",
        isModalOpen:false, modalTop:'50%',modalLeft:'50%',playerFormation:"medium",textField:false
    }, showPlayerNumbers:{playerFormation:false, playerSquad:false}, disableSides: {players: false, playerPositions:false , playerNo: false,playerFormation:false, playerShirts:false
    },
    toolsToShow: {"players":true, "goalKeepers":true},
    layerOrder:0,
    showLayers:false,
    lang:{
        "id": "1",
        "name": "English",
        "short_name": "en"
    },
    defaultDelay:1.5,
}, action)=>{
    if(action.type === "SET_PLAYER_STATE"){
        state = {state: action.action.state, text: action.action.text, ...state}
    }
    if(action.type === "RESET_CANVAS"){
        state = {state: false, text: "0", ...state};
    }
    if(action.type === 'SET_SPRITE_INDEX'){
        state = {...state,spriteIndex:action.state}
    }
    if(action.type === 'SET_SIDE_SRC'){
        state = {...state,sideSrc:action.state}
    }
    if(action.type === 'SET_TEAM'){
        state = {...state,team:action.state}
    }
    if(action.type === 'SET_PLAYER_SIZE'){
        state = {...state, playerSizes:action.state}
    }
    if(action.type === 'SET_MODAL_LEFT'){
        state = {...state, modalLeft:action.state}
    }
    if(action.type === 'SET_MODAL_TOP'){
        state = {...state, modalTop:action.state}
    }
    if(action.type === 'TOGGLE_MODAL'){
        state = {...state,isModalOpen:!state.isModalOpen}
    }
    if(action.type === 'SET_TEXT_FIELD'){
        state = {...state,textField:action.state}
    }
    if(action.type === 'SET_PLAYER_TYPE'){
        state = {...state,playerType:action.state}
    }
    if(action.type === 'SET_CIRCLE_IMG'){
        state = {...state,circleImg:action.state}
    }
    if(action.type === 'SET_SHOW_PLAYER_NUMBERS'){
        state = {...state, showPlayerNumbers:action.state}
    }
    if(action.type === 'SET_DISABLE_SIDES'){
        state = {...state, disableSides:action.state}
    }
    if(action.type === 'SET_TOOLS_TO_SHOW'){
        state = {...state, toolsToShow:action.state}
    }
    if(action.type === 'SET_LANG'){
        state = {...state, lang:action.payload}
    }
    if(action.type === 'UPDATE_LAYER'){
        state = {...state, layerOrder: state.layerOrder+1}
    }
    if(action.type === 'SET_SHOW_LAYERS'){
        state = {...state, showLayers: !state.showLayers}
    }
    if(action.type === 'SET_DELAY'){
        state = {...state, defaultDelay:action.payload}
    }
    return state;
}

export default player;