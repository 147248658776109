import {fabric} from 'fabric';
import {getFabricImageFromURL} from "./addImage";
import {BASE_URL_ASSETS} from "./utils";
export class VideoGen{
    constructor(canvas,data = {}){
        this.data = data;
        this.canvas=canvas;
        this.bg = canvas.wrapperEl.classList[1];

    }

    /** Return image and video player json */
    async getVideoPlayerJson (){
        let base64Img = await this.generateImage();
        let playerData = {
            videoPlayerJson:this.data,
            image:base64Img
        }
        return playerData
    }

    /** Creates a dummy canvas and loads the background image.
     *  After loading image , gets the json of first frame from the given data.
     *  Loads the data on the canvas .
     *  Returns the screenshot.
     * */
    generateImage(){
        return new Promise(resolve => {
            let canvasWidth = this.data.canvasWidth;
            let canvasHeight = this.data.canvasHeight;
            const StaticCanvas = new fabric.StaticCanvas("canvas", {
                skipTargetFind: true,
                selection: false,
                objectCaching:true,
                preserveObjectStacking: true,
                width:canvasWidth,
                height:canvasHeight
            });

            StaticCanvas.loadFromJSON(this.data.frames[0].json,()=>{
                this.setCanvasBg(StaticCanvas,()=>{
                    this.setExportImageSize(StaticCanvas,StaticCanvas.width,StaticCanvas.height,3000);
                    resolve(StaticCanvas.toDataURL())
                })

            })
        })


    }
    generateImageStatic(isAnimation=false){
        return new Promise(resolve => {
            let canvasWidth = this.canvas.width;
            let canvasHeight = this.canvas.height;
            let frames = this.data.frames;
            let canvasJson = this.canvas.toJSON(['name']);
            const StaticCanvas = new fabric.StaticCanvas("canvas", {
                skipTargetFind: true,
                selection: false,
                objectCaching:true,
                preserveObjectStacking: true,
                width:canvasWidth,
                height:canvasHeight
            });
            if(isAnimation){
                if(frames?.[0]){
                    StaticCanvas.loadFromJSON(frames[0].json,()=>{
                        this.setCanvasBg(StaticCanvas,()=>{
                            this.setExportImageSize(StaticCanvas,StaticCanvas.width,StaticCanvas.height,3000);
                            resolve(StaticCanvas.toDataURL())
                        },true)
                    })
                }
                else{
                    this.setCanvasBg(this.canvas,()=>{
                        resolve(this.canvas.toDataURL());
                    },true)
                }

            }
            else{
                StaticCanvas.loadFromJSON(canvasJson,()=>{
                    this.setCanvasBg(StaticCanvas,()=>{
                        StaticCanvas._objects.forEach(e=>{
                            if(e.name.includes('shadow')){
                                e.visible = false;
                            }
                        })

                        this.setExportImageSize(StaticCanvas,StaticCanvas.width,StaticCanvas.height,3000);
                        resolve(StaticCanvas.toDataURL())
                    },true)
                })
            }


        })
    }

    /** Getting the background class from the given data.
     * Loads the background image on canvas.
     */

    setCanvasBg(canvasEl,callback,isStatic=false){
        let bgSource = this.data.bg || this.bg;
        let canvasBackgroundClass = bgSource;
        let pitchSrc = canvasBackgroundClass.includes('default')?`${BASE_URL_ASSETS}/assets/images/${canvasBackgroundClass}.png`:`https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/${canvasBackgroundClass}.jpg`;

        getFabricImageFromURL(pitchSrc,(fabricImage)=>{
            let newSrc = fabricImage.getSrc();
            fetch(newSrc)
                .then(result => result.blob())
                .then(blob => {
                    const url = URL.createObjectURL(blob);
                    fabric.Image.fromURL(url, function (img) {

                        canvasEl.setBackgroundImage(img, () => {
                            canvasEl.renderAll()
                            callback && callback();
                        }, {
                            scaleX: (canvasEl.width / canvasEl.getZoom()) / fabricImage.width,
                            scaleY: (canvasEl.height / canvasEl.getZoom()) / fabricImage.height,
                        });
                    })
                });

        })
    }


    /** Gets the original canvas width and height.
     *  Gets the max width to convert image to HD quality "1280".
     */

    setExportImageSize = (canvas,originalWidth, originalHeight, clientWidth) => {
        const ratio = originalHeight/originalWidth;
        let zoom = clientWidth/originalWidth ;
        canvas.setZoom(zoom);
        canvas.setWidth(clientWidth);
        canvas.setHeight(clientWidth*ratio);
    }
}