export const hideShadowLines = (obj = null,canvas) => {
    if (obj) {
        for (let i = 0; i < canvas._objects.length; i++) {
                if (canvas._objects[i].ref_id.indexOf(obj.ref_id) > -1 && canvas._objects[i].name === 'shadow-line') {
                    if (canvas._objects[i].opacity !== 0) {
                        canvas._objects[i].opacity = 0;
                    }
                }
        }
    }
}