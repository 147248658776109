import {fabric} from "fabric";


export const  propsArray = ['newOpacity', 'padding', 'nameText', 'shirtNo', 'selectionBackgroundColor', 'perPixelTargetFind', 'hasControls', 'hasBorders', 'evented', 'selectable', 'id', 'class', 'name', 'ref_id', 'objecttype', 'x1', 'x2', 'y1', 'y2', 'pointType', 'shapeType']
const reDrawFreeShape = (obj, id, activeSel = false,canvas,reinitFreeShape) => {
    let cObj = reinitFreeShape(obj);
    cObj.ref_id = id;
    cObj.left = obj.left + 3;
    cObj.top = obj.top + 10;
    canvas.add(cObj);
    if (!activeSel) {
        canvas.sendToBack(cObj);
        canvas.discardActiveObject();
        canvas.setActiveObject(cObj);
    }
}
const clonedCustomImage = (obj, newId,canvas) => {
    canvas.discardActiveObject();
    if (!obj) return;
    let imageInd = canvas._objects.findIndex(f => f.name === 'player_custom_image' && f.ref_id === obj.ref_id);
    if (imageInd === -1) return;
    let customImage = canvas._objects[imageInd];
    let refId = customImage.ref_id;
    let nameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === refId);
    let shirtInd = canvas._objects.findIndex(f => f.name === 'custom_image_shirtno' && f.ref_id === refId);
    customImage.clone(function (clonedImage) {
        clonedImage.set({
            left: clonedImage.left + 30,
            top: clonedImage.top + 10,
            evented: true,
            ref_id: newId,
        });
        clonedImage._objects[0].set({
            ref_id: newId,
        });
        clonedImage._objects[1].set({
            ref_id: newId,
        });
        if (customImage.crossOrigin) clonedImage.set({ crossOrigin: customImage.crossOrigin });
        if (customImage.is_animation) clonedImage.set({ is_animation: customImage.is_animation });
        if (customImage.nameText) {
            const nameTxt = { ...customImage.nameText }
            clonedImage.set({ nameText: nameTxt });
        }
        if (customImage.shirtNo) {
            const shirtNo = { ...customImage.shirtNo }
            clonedImage.set({ shirtNo });
        }
        if (nameInd > -1 && shirtInd > -1) {
            canvas._objects[nameInd].clone(function (clonedTextName) {
                clonedTextName.set({
                    left: clonedTextName.left + 30,
                    top: clonedTextName.top + 10,
                    evented: false,
                    ref_id: newId,
                });
                canvas._objects[shirtInd].clone(function (clonedShirtNo) {
                    clonedShirtNo._objects.forEach(f => f.ref_id = newId);
                    clonedShirtNo.set({
                        left: clonedShirtNo.left + 30,
                        top: clonedShirtNo.top + 10,
                        evented: false,
                        ref_id: newId,
                    });
                    canvas.add(clonedImage, clonedTextName, clonedShirtNo);
                    canvas.discardActiveObject();
                    canvas.setActiveObject(clonedImage);
                    canvas.renderAll();
                }, propsArray);
            }, propsArray);
        } else {
            canvas.add(clonedImage);
            canvas.discardActiveObject();
            canvas.setActiveObject(clonedImage);
            canvas.renderAll();
        }
    }, propsArray);
}
export const duplicateObject = ({canvas, reinitFreeShape, updateUndoStates,moveLine,moveEnd2, movingObject,reinitpath,mouseUp},dObj=null) => {
    const uuid = require("uuid");
    const id = uuid.v4();
    let obj = dObj || canvas.getActiveObject()
    if (obj) {
        if (obj.type === "activeSelection") {
            let objs = [];
            canvas.discardActiveObject();
            let promises = [];
            for (let i = 0; i < obj._objects.length; i++) {
                if (!obj._objects[i].name.startsWith('custom_image_')) {
                    promises[i] = new Promise((resolve, reject) => {
                        const idd = uuid.v4();
                        let actObj = obj._objects[i];
                        if (actObj.name === 'p0' || actObj.name === 'p1' || actObj.name === 'pX' || actObj.name === 'p2') {
                            resolve();
                            return;
                        }
                        if (actObj.name === 'line') {
                            actObj.clone(function (clonedLine) {
                                clonedLine.set({
                                    left: clonedLine.left + 30,
                                    top: clonedLine.top + 10,
                                    evented: true,
                                    ref_id: idd
                                });

                                actObj.p0.clone(function (clonedP0) {
                                    clonedP0.set({
                                        left: clonedP0.left + 30,
                                        top: clonedP0.top + 10,
                                        evented: true,
                                        ref_id: idd
                                    });

                                    actObj.p1.clone(function (clonedP1) {
                                        clonedP1.set({
                                            left: clonedP1.left + 30,
                                            top: clonedP1.top + 10,
                                            evented: true,
                                            ref_id: idd
                                        });

                                        actObj.p2.clone(function (clonedP2) {
                                            clonedP2.set({
                                                left: clonedP2.left + 30,
                                                top: clonedP2.top + 10,
                                                evented: true,
                                                ref_id: idd
                                            });

                                            if (actObj.pa) {
                                                actObj.pa.clone(function (clonedPa) {
                                                    clonedPa.set({
                                                        left: clonedPa.left + 30,
                                                        top: clonedPa.top + 10,
                                                        evented: true,
                                                        ref_id: idd
                                                    })
                                                    clonedLine.objecttype = clonedP0.objecttype = clonedP1.objecttype = clonedP2.objecttype = clonedPa.objecttype = "curve_line"
                                                    clonedLine.p0 = clonedP1.p0 = clonedP2.p0 = clonedPa.p0 = clonedP0;
                                                    clonedLine.p1 = clonedP0.p1 = clonedP2.p1 = clonedPa.p1 = clonedP1;
                                                    clonedLine.p2 = clonedP1.p2 = clonedP0.p2 = clonedPa.p2 = clonedP2;
                                                    clonedLine.pa = clonedP0.pa = clonedP1.pa = clonedP2.pa = clonedPa;
                                                    clonedP0.line = clonedP1.line = clonedP2.line = clonedPa.line = clonedLine;
                                                    //For pX (curve line active point)
                                                    let pX;
                                                    const curveLineInd = canvas._objects.findIndex((o) => o.ref_id.includes(actObj.ref_id) && o.name === "pX");
                                                    if (curveLineInd > -1) pX = canvas._objects[curveLineInd];
                                                    pX.clone(function (clonedPX) {
                                                        clonedPX.set({
                                                            left: clonedPX.left + 30,
                                                            top: clonedPX.top + 10,
                                                            evented: true,
                                                            ref_id: `${idd}::active`
                                                        })
                                                        canvas.add(clonedLine, clonedPa, clonedP0, clonedP1, clonedP2, clonedPX);
                                                        clonedLine.moveTo(0)
                                                        objs.push(clonedLine);
                                                        resolve(clonedLine);
                                                        updateUndoStates(clonedLine)
                                                    }, propsArray)
                                                }, propsArray);

                                            } else {
                                                clonedLine.objecttype = clonedP0.objecttype = clonedP1.objecttype = clonedP2.objecttype = "curve_line"
                                                clonedLine.p0 = clonedP1.p0 = clonedP2.p0 = clonedP0;
                                                clonedLine.p1 = clonedP0.p1 = clonedP2.p1 = clonedP1;
                                                clonedLine.p2 = clonedP1.p2 = clonedP0.p2 = clonedP2;
                                                clonedP0.line = clonedP1.line = clonedP2.line = clonedLine;

                                                let pX;
                                                const curveLineInd = canvas._objects.findIndex((o) => o.ref_id.includes(actObj.ref_id) && o.name === "pX");
                                                if (curveLineInd > -1) pX = canvas._objects[curveLineInd];
                                                pX.clone(function (clonedPX) {
                                                    clonedPX.set({
                                                        left: clonedPX.left + 30,
                                                        top: clonedPX.top + 10,
                                                        evented: true,
                                                        ref_id: `${idd}::active`
                                                    })
                                                    canvas.add(clonedLine, clonedP0, clonedP1, clonedP2, clonedPX);
                                                    clonedLine.moveTo(0)
                                                    objs.push(clonedLine);
                                                    resolve(clonedLine);
                                                    updateUndoStates(clonedLine)
                                                }, propsArray)
                                            }
                                        }, propsArray)

                                    }, propsArray);

                                }, propsArray);

                            }, propsArray);
                        } else if (actObj.name === 'arrow_line' || actObj.name === 'square1' || actObj.name === 'square2') {
                            if (actObj.name === 'square1' || actObj.name === 'square2') {
                                actObj = actObj.line
                            }

                            actObj.clone(function (clonedLine) {
                                clonedLine.set({
                                    left: clonedLine.left + 30,
                                    top: clonedLine.top + 10,
                                    evented: true,
                                    ref_id: idd
                                });

                                actObj.square1.clone(function (clonedSq1) {
                                    clonedSq1.set({
                                        left: clonedSq1.left + 30,
                                        top: clonedSq1.top + 10,
                                        evented: true,
                                        ref_id: idd
                                    });
                                    clonedSq1.line = clonedLine;

                                    actObj.square2.clone(function (clonedSq2) {
                                        clonedSq2.set({
                                            left: clonedSq2.left + 30,
                                            top: clonedSq2.top + 10,
                                            evented: true,
                                            ref_id: idd
                                        });
                                        clonedSq2.line = clonedLine;

                                        if (actObj.arrow) {
                                            actObj.arrow.clone(function (clonedArrow) {
                                                clonedArrow.set({
                                                    left: clonedArrow.left + 30,
                                                    top: clonedArrow.top + 10,
                                                    evented: true,
                                                    ref_id: idd
                                                });
                                                clonedArrow.line = clonedLine;

                                                clonedLine.customType = clonedArrow.customType = clonedSq1.customType = clonedSq2.customType = 'arrow';
                                                clonedLine.square1 = clonedArrow.square1 = clonedSq2.square1 = clonedSq1;
                                                clonedLine.square2 = clonedArrow.square2 = clonedSq1.square2 = clonedSq2;
                                                clonedLine.arrow = clonedSq1.arrow = clonedSq2.arrow = clonedArrow;

                                                canvas.add(clonedLine, clonedArrow, clonedSq1, clonedSq2);
                                                canvas.renderAll();
                                                moveLine(clonedLine, "simple")
                                                moveEnd2(clonedSq1);
                                                objs.push(clonedLine, clonedSq1, clonedSq2);
                                                resolve(clonedLine);
                                                updateUndoStates(clonedLine)
                                            }, propsArray)
                                        } else {
                                            clonedLine.customType = clonedSq1.customType = clonedSq2.customType = 'arrow';
                                            clonedLine.square1 = clonedSq2.square1 = clonedSq1;
                                            clonedLine.square2 = clonedSq1.square2 = clonedSq2;

                                            canvas.add(clonedLine, clonedSq1, clonedSq2);
                                            canvas.renderAll();
                                            moveLine(clonedLine, "simple")

                                            objs.push(clonedLine, clonedSq1, clonedSq2);
                                            resolve(clonedLine);
                                            updateUndoStates(clonedLine)
                                        }
                                    }, propsArray);

                                }, propsArray);

                            }, propsArray);
                        } else if (actObj.name === "player_custom_image") {
                            let customImage = actObj || canvas.getActiveObject();
                            let refId = customImage.ref_id;
                            let nameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === refId);
                            let shirtInd = canvas._objects.findIndex(f => f.name === 'custom_image_shirtno' && f.ref_id === refId);
                            customImage.clone(function (clonedImage) {
                                clonedImage.set({
                                    left: clonedImage.left + 30,
                                    top: clonedImage.top + 10,
                                    evented: true,
                                    ref_id: idd,
                                });
                                clonedImage._objects[0].set({
                                    ref_id: idd,
                                });
                                clonedImage._objects[1].set({
                                    ref_id: idd,
                                });
                                if (customImage.crossOrigin) clonedImage.set({ crossOrigin: customImage.crossOrigin });
                                if (customImage.is_animation) clonedImage.set({ is_animation: customImage.is_animation });
                                if (nameInd > -1 && shirtInd > -1) {
                                    canvas._objects[nameInd].clone(function (clonedTextName) {
                                        clonedTextName.set({
                                            left: clonedTextName.left + 30,
                                            top: clonedTextName.top + 10,
                                            evented: false,
                                            ref_id: idd,
                                        });
                                        canvas._objects[shirtInd].clone(function (clonedShirtNo) {
                                            clonedShirtNo._objects.forEach(e => e.ref_id = idd)
                                            clonedShirtNo.set({
                                                left: clonedShirtNo.left + 30,
                                                top: clonedShirtNo.top + 10,
                                                evented: false,
                                                ref_id: idd,
                                            });
                                            canvas.add(clonedImage, clonedTextName, clonedShirtNo);
                                            objs.push(clonedImage, clonedTextName, clonedShirtNo)
                                            resolve(true)
                                            canvas.renderAll();
                                        }, propsArray);
                                    }, propsArray);
                                } else {
                                    canvas.add(clonedImage);
                                    objs.push(clonedImage)
                                    resolve(true)
                                    canvas.renderAll();
                                }
                            }, propsArray);
                        } else if (actObj.name === 'free-shape') {
                            reDrawFreeShape(actObj, idd, true,canvas,reinitFreeShape);
                            objs.push(actObj);
                            resolve(actObj);
                        } else {
                            let formationObj = actObj.formation
                            actObj.clone(function (clonedObj) {
                                clonedObj.set({
                                    left: clonedObj.left + 30,
                                    top: clonedObj.top + 10,
                                    evented: true,
                                    ref_id: idd,
                                });
                                if (clonedObj.name === "player") {
                                    if (formationObj) {
                                        clonedObj.set({ formation: true })
                                    }
                                    clonedObj._objects.forEach(obj => {
                                        obj.ref_id = idd
                                    })
                                    let nameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === actObj.ref_id);
                                    canvas._objects[nameInd].clone(function (clonedTextName) {
                                        clonedTextName.set({
                                            left: clonedTextName.left + 30,
                                            top: clonedTextName.top + 10,
                                            name: 'custom_image_name',
                                            evented: true,
                                            hasControls: false,
                                            ref_id: clonedObj.ref_id,
                                        });
                                        canvas.add(clonedTextName);
                                        objs.push(clonedTextName);
                                    })
                                }
                                if (clonedObj.name === "image") {
                                    if (formationObj) {
                                        clonedObj.set({ formation: true })
                                    }
                                    if (actObj.imageFileName) {
                                        clonedObj.set({ imageFileName: actObj.imageFileName });
                                    }
                                    if (actObj.crossOrigin) {
                                        clonedObj.set({ crossOrigin: actObj.crossOrigin });
                                    }

                                }
                                if (actObj.is_animation) {
                                    clonedObj.set({ is_animation: actObj.is_animation });
                                }
                                if (actObj.objecttype === 'sprite-image') {
                                    let nameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === actObj.ref_id);
                                    canvas._objects[nameInd].clone(function (clonedTextName) {
                                        clonedTextName.set({
                                            left: clonedTextName.left + 30,
                                            top: clonedTextName.top + 10,
                                            name: 'custom_image_name',
                                            evented: true,
                                            hasControls: false,
                                            ref_id: clonedObj.ref_id,
                                        });
                                        canvas.add(clonedTextName);
                                        objs.push(clonedTextName);
                                    })
                                }
                                canvas.add(clonedObj);
                                objs.push(clonedObj)
                                resolve(clonedObj)
                                canvas.renderAll();
                            }, propsArray);
                        }
                    })
                }
            }
            Promise.allSettled(promises).then((values) => {
                if (objs.length) {
                    canvas.discardActiveObject();
                    var sel = new fabric.ActiveSelection(objs, {
                        canvas: canvas,
                    });
                    canvas.setActiveObject(sel);
                    canvas.renderAll();
                }
            })
        } else {
            if (obj.name === 'drawLine') {
                let endP = canvas._objects.find(f => f.name === 'line-end-point_shadow-object' && f.ref_id === obj.ref_id);
                obj.clone(function (clonedLine) {
                    clonedLine.set({
                        left: clonedLine.left + 30,
                        top: clonedLine.top + 10,
                        evented: true,
                        name: 'drawLine',
                        ref_id: id,
                        hasControls: false,
                        selectionBackgroundColor: 'transparent'
                    })
                    if (endP.brushType === "arrow" || endP.brushType === "arrow_dashed") {
                        clonedLine._objects[0].set('name', 'drawElementArrow');
                    } else if (endP.brushType === "block" || endP.brushType === "block_dashed") {
                        clonedLine._objects[0].set('name', 'drawElementBlock');
                    }
                    canvas.add(clonedLine);
                    canvas.discardActiveObject();
                    canvas.sendBackwards(clonedLine);
                    let grp = clonedLine;
                    let items = clonedLine._objects;
                    grp._restoreObjectsState();
                    canvas.remove(clonedLine);
                    const newGroup = new fabric.Group(items, {
                        originX: 'center',
                        originY: 'center',
                        name: "drawLine",
                        perPixelTargetFind: true,
                        hasBorders: false,
                        hasControls: false,
                        ref_id: clonedLine.ref_id,
                        objecttype: obj.objecttype
                    });
                    canvas.add(newGroup);
                })
                endP.clone(function (clonedPoint) {
                    clonedPoint.set({
                        left: clonedPoint.left + 30,
                        top: clonedPoint.top + 10,
                        name: 'line-end-point_shadow-object',
                        brushType: endP.brushType,
                        widthFactor: endP.widthFactor,
                        evented: true,
                        ref_id: id
                    })
                    canvas.add(clonedPoint);
                    canvas.bringForward(clonedPoint);
                    canvas.renderAll();

                })
            } else if (obj.name === 'free-shape') {
                reDrawFreeShape(obj, id,false,canvas,reinitFreeShape);
            } else if (obj.name === 'Line Arrow') {
                obj.clone(function (clonedLine) {
                    clonedLine.set({
                        left: clonedLine.left + 30,
                        top: clonedLine.top + 10,
                        ref_id: id,
                        name: obj.name,
                        size: obj.size,
                        type: obj.type,
                        color: obj.color,
                        stroke: obj.stroke,
                        selectionBackgroundColor: 'transparent',
                    });
                    canvas.add(clonedLine);
                    canvas.discardActiveObject();
                    canvas.setActiveObject(clonedLine);
                    canvas.renderAll();
                })
            } else if (obj.name === 'p0' || obj.name === 'p1' || obj.name === 'pX' || obj.name === 'p2' || obj.name === 'line') {
                if (obj.name === 'p0' || obj.name === 'p1' || obj.name === 'pX' || obj.name === 'p2') {
                    obj = obj.line
                } else if (obj.name === 'pX') {
                    let refId = obj.ref_id.split("::")[0];
                    const curveLineInd = canvas._objects.findIndex((o) => o.ref_id.includes(refId) && o.objecttype === "curve_line" && o.name === "line");
                    if (curveLineInd > -1) obj = canvas._objects[curveLineInd];
                }
                obj.clone(function (clonedLine) {
                    clonedLine.set({
                        left: clonedLine.left + 30,
                        top: clonedLine.top + 10,
                        widthFactor: obj.widthFactor,
                        evented: true,
                        ref_id: id
                    });

                    obj.p0.clone(function (clonedP0) {
                        clonedP0.set({
                            left: clonedP0.left + 30,
                            top: clonedP0.top + 10,
                            evented: true,
                            ref_id: id
                        });

                        obj.p1.clone(function (clonedP1) {
                            clonedP1.set({
                                left: clonedP1.left + 30,
                                top: clonedP1.top + 10,
                                evented: true,
                                ref_id: id
                            });

                            obj.p2.clone(function (clonedP2) {
                                clonedP2.set({
                                    left: clonedP2.left + 30,
                                    top: clonedP2.top + 10,
                                    evented: true,
                                    ref_id: id
                                });

                                if (obj.pa) {
                                    obj.pa.clone(function (clonedPa) {
                                        clonedPa.set({
                                            left: clonedPa.left + 30,
                                            top: clonedPa.top + 10,
                                            evented: true,
                                            ref_id: id
                                        })
                                        clonedLine.objecttype = clonedP0.objecttype = clonedP1.objecttype = clonedP2.objecttype = clonedPa.objecttype = "curve_line"
                                        clonedLine.p0 = clonedP1.p0 = clonedP2.p0 = clonedPa.p0 = clonedP0;
                                        clonedLine.p1 = clonedP0.p1 = clonedP2.p1 = clonedPa.p1 = clonedP1;
                                        clonedLine.p2 = clonedP1.p2 = clonedP0.p2 = clonedPa.p2 = clonedP2;
                                        clonedLine.pa = clonedP0.pa = clonedP1.pa = clonedP2.pa = clonedPa;
                                        clonedP0.line = clonedP1.line = clonedP2.line = clonedPa.line = clonedLine;

                                        //For pX (curve line active point)
                                        let pX;
                                        const curveLineInd = canvas._objects.findIndex((o) => o.ref_id.includes(obj.ref_id) && o.name === "pX");
                                        if (curveLineInd > -1) pX = canvas._objects[curveLineInd];
                                        pX.clone(function (clonedPX) {
                                            clonedPX.set({
                                                left: clonedPX.left + 30,
                                                top: clonedPX.top + 10,
                                                evented: true,
                                                ref_id: `${id}::active`
                                            })
                                            canvas.add(clonedLine, clonedPa, clonedP0, clonedP1, clonedP2, clonedPX);
                                            clonedLine.moveTo(0)
                                            canvas.discardActiveObject();
                                            movingObject({ target: clonedLine })
                                            mouseUp({ target: clonedLine })
                                            reinitpath({ target: clonedLine })
                                            updateUndoStates(clonedLine)
                                        }, propsArray)
                                    }, propsArray);

                                } else {
                                    clonedLine.objecttype = clonedP0.objecttype = clonedP1.objecttype = clonedP2.objecttype = "curve_line"
                                    clonedLine.p0 = clonedP1.p0 = clonedP2.p0 = clonedP0;
                                    clonedLine.p1 = clonedP0.p1 = clonedP2.p1 = clonedP1;
                                    clonedLine.p2 = clonedP1.p2 = clonedP0.p2 = clonedP2;
                                    clonedP0.line = clonedP1.line = clonedP2.line = clonedLine;
                                    //For pX (curve line active point)
                                    let pX;
                                    const curveLineInd = canvas._objects.findIndex((o) => o.ref_id.includes(obj.ref_id) && o.name === "pX");
                                    if (curveLineInd > -1) pX = canvas._objects[curveLineInd];
                                    pX.clone(function (clonedPX) {
                                        clonedPX.set({
                                            left: clonedPX.left + 30,
                                            top: clonedPX.top + 10,
                                            evented: true,
                                            ref_id: `${id}::active`
                                        })
                                        canvas.add(clonedLine, clonedP0, clonedP1, clonedP2, clonedPX);
                                        clonedLine.moveTo(0)
                                        canvas.discardActiveObject();
                                        movingObject({ target: clonedLine })
                                        mouseUp({ target: clonedLine })
                                        reinitpath({ target: clonedLine })
                                        updateUndoStates(clonedLine)
                                    }, propsArray)
                                }
                            }, propsArray)

                        }, propsArray);

                    }, propsArray);

                }, propsArray);
            } else if (obj.name === 'arrow_line' || obj.name === 'square1' || obj.name === 'square2') {
                if (obj.name === 'square1' || obj.name === 'square2') {
                    obj = obj.line
                }

                obj.clone(function (clonedLine) {
                    clonedLine.set({
                        left: clonedLine.left + 30,
                        top: clonedLine.top + 10,
                        widthFactor: obj.widthFactor,
                        evented: true,
                        ref_id: id
                    });

                    obj.square1.clone(function (clonedSq1) {
                        clonedSq1.set({
                            left: clonedSq1.left + 30,
                            top: clonedSq1.top + 10,
                            evented: true,
                            ref_id: id
                        });
                        clonedSq1.line = clonedLine;
                        obj.square2.clone(function (clonedSq2) {
                            clonedSq2.set({
                                left: clonedSq2.left + 30,
                                top: clonedSq2.top + 10,
                                evented: true,
                                ref_id: id
                            });
                            clonedSq2.line = clonedLine;

                            if (obj.arrow) {
                                obj.arrow.clone(function (clonedArrow) {
                                    clonedArrow.set({
                                        left: clonedArrow.left + 30,
                                        top: clonedArrow.top + 10,
                                        evented: true,
                                        ref_id: id
                                    });
                                    clonedArrow.line = clonedLine;
                                    clonedLine.customType = clonedArrow.customType = clonedSq1.customType = clonedSq2.customType = 'arrow';
                                    clonedLine.square1 = clonedArrow.square1 = clonedSq2.square1 = clonedSq1;
                                    clonedLine.square2 = clonedArrow.square2 = clonedSq1.square2 = clonedSq2;
                                    clonedLine.arrow = clonedSq1.arrow = clonedSq2.arrow = clonedArrow;

                                    canvas.add(clonedLine, clonedArrow, clonedSq1, clonedSq2);
                                    canvas.renderAll();
                                    moveLine(clonedLine, "simple")
                                    moveEnd2(clonedSq1)

                                    canvas.discardActiveObject();
                                    canvas.setActiveObject(clonedLine);
                                    updateUndoStates(clonedLine)
                                }, propsArray)
                            } else {
                                clonedLine.customType = clonedSq1.customType = clonedSq2.customType = 'arrow';
                                clonedLine.square1 = clonedSq2.square1 = clonedSq1;
                                clonedLine.square2 = clonedSq1.square2 = clonedSq2;
                                canvas.add(clonedLine, clonedSq1, clonedSq2);
                                canvas.renderAll();
                                moveLine(clonedLine, "simple")
                                canvas.discardActiveObject();
                                canvas.setActiveObject(clonedLine);
                                updateUndoStates(clonedLine)
                            }
                        }, propsArray);

                    }, propsArray);

                }, propsArray);
            } else if (obj.name.includes("custom_image")) {
                clonedCustomImage(obj, id,canvas)
            } else {
                (canvas.getActiveObject() || dObj).clone(function (clonedObj) {
                    let actObj = canvas.getActiveObject() || dObj;
                    let formationObj = actObj.formation
                    clonedObj.set({
                        left: clonedObj.left + 30,
                        top: clonedObj.top + 10,
                        evented: true,
                        ref_id: id,
                    });
                    if (clonedObj.name === "player") {
                        if (formationObj) {
                            clonedObj.set({ formation: true })
                        }
                        clonedObj._objects.forEach(obj => {
                            obj.ref_id = id
                        })
                        let nameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === actObj.ref_id);
                        canvas._objects[nameInd].clone(function (clonedTextName) {
                            clonedTextName.set({
                                left: clonedTextName.left + 30,
                                top: clonedTextName.top + 10,
                                name: 'custom_image_name',
                                evented: true,
                                hasControls: false,
                                ref_id: clonedObj.ref_id,
                            });
                            canvas.add(clonedTextName);
                        })
                    }
                    if (clonedObj.name === "image") {
                        if (formationObj) {
                            clonedObj.set({ formation: true })
                        }
                        if (actObj.imageFileName) {
                            clonedObj.set({ imageFileName: actObj.imageFileName });
                        }
                        if (actObj.crossOrigin) {
                            clonedObj.set({ crossOrigin: actObj.crossOrigin });
                        }
                        if ((canvas.getActiveObject() || dObj).imageFileName) {
                            clonedObj.set({ imageFileName: (canvas.getActiveObject() || dObj).imageFileName });
                        }
                        if ((canvas.getActiveObject() || dObj).crossOrigin) {
                            clonedObj.set({ crossOrigin: (canvas.getActiveObject() || dObj).crossOrigin });
                        }
                    }
                    if ((canvas.getActiveObject() || dObj).is_animation) {
                        clonedObj.set({ is_animation:(canvas.getActiveObject() || dObj).is_animation });
                    }
                    if (actObj.objecttype === 'sprite-image') {
                        let nameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === actObj.ref_id);
                        canvas._objects[nameInd].clone(function (clonedTextName) {
                            clonedTextName.set({
                                left: clonedTextName.left + 30,
                                top: clonedTextName.top + 10,
                                name: 'custom_image_name',
                                evented: true,
                                hasControls: false,
                                ref_id: clonedObj.ref_id,
                            });
                            canvas.add(clonedTextName)
                        })
                    }
                    canvas.add(clonedObj);
                    clonedObj.name === 'shape' && canvas.sendToBack(clonedObj);
                    canvas.discardActiveObject();
                    canvas.setActiveObject(clonedObj);
                    canvas.renderAll();
                }, propsArray);
            }
        }
    }
    canvas.renderAll();
}
