export const updatePaths = (redo=false,shadowObjs,canvas,showShadowObjects,reArrangeShadow,reinitCanvas,addedTextObjs,reinitFreeShape,reInitArrowLines,startEvents)=>{
    shadowObjs.forEach((ob) => {
        const objInd = canvas._objects.findIndex(o => o.ref_id === ob.ref_id);
        const shadowLineInd = canvas._objects.findIndex((o) => o.ref_id === `${ob.ref_id}__shadow-object` && o.name === "shadow-line");
        const shadowObjInd = canvas._objects.findIndex((o) => o.ref_id === `${ob.ref_id}__shadow-object` && o.name === "shadow-object");
        const p1Ind = canvas._objects.findIndex((o) => o.ref_id === `${ob.ref_id}__shadow-object` && o.name === "p1");
        if (shadowLineInd > -1) {
            let d = ob.d;
            if (d) {
                canvas._objects[shadowLineInd].path[0][1] = canvas._objects[shadowObjInd].left;
                canvas._objects[shadowLineInd].path[0][2] = canvas._objects[shadowObjInd].top;
                canvas._objects[shadowLineInd].path[1][1] = parseFloat(d[4]);
                canvas._objects[shadowLineInd].path[1][2] = parseFloat(d[5]);
                canvas._objects[shadowLineInd].path[1][3] = parseFloat(d[6]);
                canvas._objects[shadowLineInd].path[1][4] = parseFloat(d[7]);

                canvas._objects[p1Ind].left = parseFloat(d[4]);
                canvas._objects[p1Ind].top = parseFloat(d[5]);
                canvas._objects[p1Ind].evented = false;
                !redo && canvas._objects[p1Ind].sendBackwards();
                canvas._objects[objInd].bringForward();

            } else {
                canvas._objects[shadowLineInd].path[0][1] = canvas._objects[shadowObjInd].left;
                canvas._objects[shadowLineInd].path[0][2] = canvas._objects[shadowObjInd].top;
                canvas._objects[shadowLineInd].path[1][1] = (canvas._objects[shadowObjInd].left + canvas._objects[objInd].left) / 2;
                canvas._objects[shadowLineInd].path[1][2] = (canvas._objects[shadowObjInd].top + canvas._objects[objInd].top) / 2;
                canvas._objects[shadowLineInd].path[1][3] = canvas._objects[objInd].left;
                canvas._objects[shadowLineInd].path[1][4] = canvas._objects[objInd].top;
                canvas._objects[p1Ind].left = (canvas._objects[shadowObjInd].left + canvas._objects[objInd].left) / 2;
                canvas._objects[p1Ind].top = (canvas._objects[shadowObjInd].top + canvas._objects[objInd].top) / 2;
                canvas._objects[p1Ind].evented = false;
                !redo && canvas._objects[p1Ind].sendBackwards();
                canvas._objects[objInd].bringForward();
            }
        }
    })
    showShadowObjects();
    canvas.renderAll();
    reArrangeShadow();
    reinitCanvas()
    addedTextObjs();
    reInitArrowLines();
    reinitFreeShape();
    startEvents()
}
