import {fabric} from "fabric";

export const createCurvePoint =(left, top, line1, line2, line3, props,setObjectPadding,setLineToCircle)=>{
    var c = new fabric.Circle({
        left: left,
        top: top,
        radius: props.scaleProps.lineSelectorHeight * .75,
        fill: "#fdd835",
        padding: 2,
        originX: 'center',
        originY: 'center',
        opacity: 0,
        objecttype: "curve_line",
        hoverCursor: "default",
    });
    setObjectPadding(c, 5, 2)

    c.hasBorders = c.hasControls = c.selectable = false;
    setLineToCircle(c, line1, line2, line3)
    return c;
}

export const createCurveCircle = (left, top, line1, line2, line3, props,setObjectPadding)=>{
    var c = new fabric.Circle({
        left: left,
        top: top,
        radius: props.scaleProps.lineSelectorHeight * .75,
        fill: "#fdd835",
        padding: 2,
        originX: 'center',
        originY: 'center',
        opacity: 0,
        objecttype: "curve_line",
        hoverCursor: "default",
    });
    setObjectPadding(c, 5, 2)
    c.hasBorders = c.hasControls = c.selectable = false;
    c.line1 = line1;
    c.line2 = line2;
    c.line3 = line3;

    return c;
}