import enUS from "antd/lib/locale/en_US";
import heIL from "antd/lib/locale/he_IL";
import { generalEN, generalIL } from "./general";

const transform = (obj, prefix = "") => {
    const result = {};

    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === "object") {
            Object.assign(result, transform(obj[key], `${prefix}${key}.`));
        } else {
            result[`${prefix}${key}`] = obj[key];
        }
    });

    return result;
};

const en = {
    ...generalEN,
};

const il = {
    ...generalIL,
};

const transformEn = transform(en);
const transformIL = transform(il);

export const en_US = {
    ...transformEn,
    localeAntd: enUS,
};

export const he_IL = {
    ...transformIL,
    localeAntd: heIL,
};
