import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import TacticsBoardToolsIcon from "./icon";
import textOutlined from "../../assets/images/toolsIcons/textImg.svg";
import zigZag from "../../assets/images/toolsIcons/sin-wave.svg";
import simpleLine from "../../assets/images/toolsIcons/line.svg";
import dashedLine from "../../assets/images/toolsIcons/dashed-line.svg";
import simpleArrow from "../../assets/images/toolsIcons/K.svg";
import dashedArrow from "../../assets/images/toolsIcons/L.svg";
import simpleCurve from "../../assets/images/toolsIcons/C.svg";
import dashedCurve from "../../assets/images/toolsIcons/D.svg";
import simpleCurveArrow from "../../assets/images/toolsIcons/E.svg";
import dashedCurveArrow from "../../assets/images/toolsIcons/F.svg";

import iconDrawLine from "../../assets/images/toolsIcons/G.svg";
import iconDrawDashLine from "../../assets/images/toolsIcons/H.svg";
import iconDrawArrow from "../../assets/images/toolsIcons/I.svg";
import iconDrawDashArrow from "../../assets/images/toolsIcons/J.svg";
import iconDrawCutLine from "../../assets/images/toolsIcons/curve-line.svg";
import iconDrawDashCutLine from "../../assets/images/toolsIcons/draw_cut_dash_line.svg";

import "./tools.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  clearClipBoard,
  setActiveTab,
  setBrush,
  setColorPopup,
  setDrawState,
  setSelectedColor,
} from "../../actions";
import { Button } from "antd";
import { colorName, colorHex, drawsObjsWithColor } from "../../utils/utils";
import { getMobileView } from "../helpers";
import background from "../../reducers/background";

const getObjMapType = {
  simple_line: "simple_solid",
  simple_line_dashed: "simple_dashed",
  arrow_line: "arrow_solid",
  arrow_line_dashed: "arrow_dashed",
  curve_line: "curvy_solid",
  curve_line_dashed: "curvy_dashed",
  curve_arrow_line: "curvyarrow_solid",
  curve_arrow_line_dashed: "curvyarrow_dashed",
  simple: "draw_simple",
  simple_dashed: "simple_dashed",
  arrow: "draw_arrow",
  arrow_dashed: "arrow_dashed",
  block: "draw_block",
  block_dashed: "draw_block_dashed",
  "Line Arrow": "arrow_zigzag",
};

const TacticsBoardToolsDraw = (props) => {
  const dispatch = useDispatch();
  const selectedColor = useSelector((state) => state.color);
  const updatedObjectType = useSelector((state) => state.updatedObjectType);
  const canvas = useSelector((state) => state.canvas);
  const brush = useSelector((state) => state.brush);
  const ObjectDefaultColors = useSelector((state) => state.ObjectDefaultColors);

  const [objectColors, setObjectColors] = useState(
    drawsObjsWithColor(ObjectDefaultColors)
  );

  const lineTypes = [
    { name: "simple", src: iconDrawLine },
    { name: "simple_dashed", src: iconDrawDashLine },
    { name: "arrow", src: iconDrawArrow },
    { name: "arrow_dashed", src: iconDrawDashArrow },
    { name: "block", src: iconDrawCutLine },
    { name: "block_dashed", src: iconDrawDashCutLine },
  ];
  const iTags = lineTypes.map(() => React.createRef());

  useEffect(() => {
    updatedObjectType && updateColorByType(updatedObjectType);
  }, []);

  useEffect(() => {
    setObjectColors(drawsObjsWithColor(selectedColor.tools));
  }, [selectedColor.tools]);
  const ItemList = () => {
    const isMobileView = getMobileView();
    const colSpan = isMobileView ? 1.5 : 12;
    return (
      <>
        <Col span={colSpan}>
          <TacticsBoardToolsIcon
            imgStyle="image_dim"
            classList="text-element"
            id={`${objectColors.text}_simple-text`}
            image={textOutlined}
            alt="Text Outlined"
          />
        </Col>
        <Col span={colSpan}>
          <TacticsBoardToolsIcon
            imgStyle="image_dim"
            classList="arrow-element"
            id={`simple_solid::${objectColors.simple_solid}`}
            image={simpleLine}
            alt="Solid Curved Line"
          />
        </Col>
        <Col span={colSpan}>
          <TacticsBoardToolsIcon
            imgStyle="image_dim"
            classList="arrow-element"
            id={`curvy_solid::${objectColors.curvy_solid}`}
            image={simpleCurve}
            alt="Dashed Curved Arrow"
          />
        </Col>
        <Col span={colSpan}>
          <TacticsBoardToolsIcon
            imgStyle="image_dim"
            classList="arrow-element"
            id={`curvyarrow_solid::${objectColors.curvyarrow_solid}`}
            image={simpleCurveArrow}
            alt="Dashed Curved Arrow"
          />
        </Col>
        <Col span={colSpan}>
          <TacticsBoardToolsIcon
            imgStyle="image_dim"
            classList="arrow-element"
            id={`arrow_solid::${objectColors.arrow_solid}`}
            image={simpleArrow}
            alt="Solid Curved Arrow"
          />
        </Col>
        <Col span={colSpan}>
          <TacticsBoardToolsIcon
            imgStyle="image_dim"
            classList="arrow-element"
            id={`simple_dashed::${objectColors.simple_dashed}`}
            image={dashedLine}
            alt="Solid Curved Arrow"
          />
        </Col>
        <Col span={colSpan}>
          <TacticsBoardToolsIcon
            imgStyle="image_dim"
            classList="arrow-element"
            id={`curvy_dashed::${objectColors.curvy_dashed}`}
            image={dashedCurve}
            alt="Solid Curved Line"
          />
        </Col>
        <Col span={colSpan}>
          <TacticsBoardToolsIcon
            imgStyle="image_dim"
            classList="arrow-element"
            id={`curvyarrow_dashed::${objectColors.curvyarrow_dashed}`}
            image={dashedCurveArrow}
            alt="Solid Curved Line"
          />
        </Col>
        <Col span={colSpan}>
          <TacticsBoardToolsIcon
            imgStyle="image_dim"
            classList="arrow-element"
            id={`arrow_dashed::${objectColors.arrow_dashed}`}
            image={dashedArrow}
            alt="Dashed Curved Arrow"
          />
        </Col>
        <Col span={colSpan}>
          <TacticsBoardToolsIcon
            imgStyle={`image_dim`}
            classList={`arrow-element`}
            id={`arrow_zigzag::${objectColors.arrow_zigzag}`}
            image={zigZag}
            alt="ZigZag Arrow"
          />
        </Col>
        {lineTypes.map((item, i) => (
          <Col id={item} span={colSpan}>
            <Button
              draggable="false"
              type={brush === `${item.name}` && "primary"}
              onClick={() =>
                updateBrush(`${item.name}`, objectColors[`draw_${item.name}`])
              }
              id={`${item.name}::${objectColors[`draw_${item.name}`]}`}
              className={`tacticsboard__feature_icon draw-element ${
                brush === item.name ? "color__primary" : ""
              } line-btn`}
              icon={
                <img
                  draggable="false"
                  className="tacticsboard__draw_img"
                  src={item.src}
                  alt="dashArrow"
                />
              }
            />
          </Col>
        ))}
      </>
    );
  };

  const getObjectType = () => {
    let obj = canvas.getActiveObject();
    if (!obj) return;
    let arrowInd = canvas._objects.findIndex(
      (f) => f.name === "arrow" && f.ref_id === obj.ref_id
    );
    let desType = obj.objecttype;
    if (obj.name === "text") desType = "text";
    else if (obj.name === "Line Arrow") desType = "arrow_zigzag";
    else if (
      obj.objecttype === "simple_line" &&
      !obj.strokeDashArray &&
      !obj.pa
    )
      desType = "simple_solid";
    else if (obj.objecttype === "simple_line" && obj.strokeDashArray)
      desType = "simple_dashed";
    else if (
      obj.objecttype === "arrow_line" &&
      !obj.strokeDashArray &&
      arrowInd > -1
    )
      desType = "arrow_solid";
    else if (obj.objecttype === "arrow_line" && obj.strokeDashArray)
      desType = "arrow_dashed";
    else if (obj.objecttype === "curve_line" && !obj.strokeDashArray && !obj.pa)
      desType = "curvy_solid";
    else if (obj.objecttype === "curve_line" && obj.strokeDashArray && !obj.pa)
      desType = "curvy_dashed";
    else if (obj.objecttype === "curve_line" && obj.pa && !obj.strokeDashArray)
      desType = "curvyarrow_solid";
    else if (obj.objecttype === "curve_line" && obj.strokeDashArray && obj.pa)
      desType = "curvyarrow_dashed";
    else if (obj.objecttype === "star")
      if (obj.shapeType === "outlined") desType = "star_outlined";
      else if (obj.type === "polygon") desType = "free_shape_fill";
      else desType = "star_solid";
    else if (obj.objecttype === "equipments" && obj.imageFileName) {
      const name = obj.imageFileName.split(":")[1].split("-")[0];
      desType = name;
      if (name === "Cones")
        desType = obj.imageFileName.split(":")[1].split("_")[0];
    }
    return desType;
  };

  const updateColorByType = (type) => {
    let actObj = canvas.getActiveObject();
    if (!actObj) return;
    if (actObj.type === "group" && actObj.name === "drawLine") {
      if (actObj._objects.length > 1)
        canvas.freeDrawingBrush.color = actObj._objects[1].stroke;
      else canvas.freeDrawingBrush.color = actObj._objects[0].stroke;
    }

    let actObjColor = actObj.stroke || actObj.fill;
    if (
      actObj.name === "p0" ||
      actObj.name === "p1" ||
      actObj.name === "p2" ||
      actObj.name === "pX" ||
      actObj.name === "pa" ||
      actObj.name === "square1" ||
      actObj.name === "square2"
    )
      return;
    if (actObj.name === "drawLine") actObjColor = canvas.freeDrawingBrush.color;
    if (actObj.name === "Line Arrow")
      actObjColor = getDesireColor(actObj.color);
    updateObjectColors(actObjColor, type);
  };

  const updateObjectColors = (selColor, type) => {
    let actObj = canvas.getActiveObject();
    if (!actObj) return;
    if (actObj) {
      if (actObj.name === "arrow_line" && type === "arrow_dashed")
        type = "arrow_line_dashed";
    }
    let destype = getObjMapType[type];
    if (!destype) destype = type;
    if (type === "ind_object") destype = getObjectType();
    let objColors = { ...objectColors };
    if (type === "free_shape") {
      let desTypeBorder = "free_shape_border";
      let desTypeFill = "free_shape_fill";
      objColors[desTypeBorder] = actObj.stroke;
      objColors[desTypeFill] = actObj.fill;
    } else {
      objColors[destype] = selColor;
    }
    setObjectColors(objColors);
  };

  const drawCanvas = () => {
    canvas.isDrawingMode = true;
    canvas.renderAll();
    dispatch(setDrawState(true));
    canvas.discardActiveObject();
  };

  const getDesireColor = (color, type = "name") => {
    if (typeof color === "string" && color[1] === "#") return colorName[color];
    return colorHex[color];
  };

  const updateBrush = (type, color) => {
    dispatch(clearClipBoard());
    drawCanvas();
    dispatch(setActiveTab(""));
    dispatch(setBrush(type));
    if (type === "simple" || type === "arrow" || type === "block") {
      canvas.freeDrawingBrush.strokeDashArray = 0;
    } else {
      canvas.freeDrawingBrush.strokeDashArray = [10, 5];
    }
    canvas.freeDrawingBrush.color = color;
  };
  const handleClickOutside = () => {
    dispatch(setColorPopup(false));
  };

  return (
    <div className="tacticsboard__feature_container  tacticsboard__feature_container_height">
      {getMobileView() ? (
        <ItemList props />
      ) : (
        <Row gutter={[7, 7]}>
          <ItemList props />
        </Row>
      )}
    </div>
  );
};

export default TacticsBoardToolsDraw;
