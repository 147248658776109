import {addAnnotationToCanvas} from "../addAnnotationToCanvas";
import {sendBackShapes} from "../shapes/shapeUtils";
import {copyToClipboard} from "../../actions";

export  const addZigzagLine = (props,canvas,canvasVar,dispatch,isDropped=false) => {
    const newAnnotate = addAnnotationToCanvas(canvas, [50, 50, -props.scaleProps.lineHeight, 50], 'arrow', props,false,canvasVar)
    newAnnotate.set('fadeFlag', 'no-fade-out')
    if(!isDropped){
        canvas.add(newAnnotate);
        canvas.setActiveObject(newAnnotate);
        canvas.sendToBack(newAnnotate);
        sendBackShapes(canvas);
        canvas.renderAll();
    }
    else{
        dispatch(copyToClipboard(newAnnotate));
        canvas.discardActiveObject();
        canvas.defaultCursor = 'crosshair';
        canvas.selection = false;
    }

}