import {fabric} from "fabric";
import {setPlayerNameDim, setTextDimensions} from "./utils";

export const handleReaddedText = (obj, isShadow , clonedObj, sprites ,shadowFrames,activeFrameVal,canvas, frames) => {
    if (obj.objecttype === 'sprite-image' || obj.name === 'player') sprites = true;
    if(!frames.length) return;
    let refFrame = frames[frames.length - 1];
    let refFrameInd = frames.findIndex(fd=>fd.data_num === activeFrameVal.data_num);
    if(refFrameInd > -1){
        refFrame = frames[refFrameInd];
    }
    let refObjs = JSON.parse(refFrame.json).objects;
    let refTxtInd = refObjs.findIndex(f=>f.name === 'custom_image_name' && f.ref_id === obj.ref_id);
    let hebrewCheck = /[\u0590-\u05FF]/;
    let shirtCircle, shirtNo, numGroup;
    let nameTextProps = obj.nameText;
    if(refTxtInd > -1) nameTextProps = {...refObjs[refTxtInd]}
    // let originalObjInd = canvas._objects.findIndex(f=>f.name === 'custom_image_name' && f.ref_id === obj.ref_id);
    let nextObjInd = shadowFrames[activeFrameVal.data_num].objects.findIndex(f => f.ref_id === obj.ref_id);
    if (nextObjInd > -1) nameTextProps.text = shadowFrames[activeFrameVal.data_num].objects[nextObjInd].nameText.text;
    const shirtNoProps = obj.shirtNo;
    let text = nameTextProps.text;
    if (!sprites && typeof (obj._objects[0].fill) === 'object') text = `${shirtNoProps.text} ${nameTextProps.text}`
    // if(originalObjInd>-1) canvas._objects[originalObjInd].text = text;
    var nameText = new fabric.IText(text, {
        textAlign: 'center', //"left", "center", "right" or "justify".
        fill: nameTextProps.fill,
        fillStyle: nameTextProps.fillStyle,
        direction: hebrewCheck.test(nameTextProps.text[0]) ? 'rtl' : 'ltr',
        fontSize: nameTextProps.fontSize,
        ref_id: isShadow ? `${obj.ref_id}__shadow-object` : obj.ref_id,
        originX: 'center',
        originY: 'top',
        hoverCursor:'text',
        selectable:(!text.length || isShadow)?false:true,
        hasControls: false,
        hasBg: nameTextProps?.hasBg || false,
        hasBorders: false,
        dirty: true,
        opacity: isShadow ? 0.5 : 1,
        name: isShadow ? `custom_image_name__shadow-object` : 'custom_image_name',
        fontFamily: 'sans-serif',
        fontWeight: nameTextProps.fontWeight
    });
    if (!sprites) {
        shirtCircle = new fabric.Circle({
            radius: shirtNoProps.radius,
            fill: 'white',
            originX: 'center',
            originY: 'center',
            ref_id: isShadow ? `${obj.ref_id}__shadow-object` : obj.ref_id,
            name: isShadow ? "shirtCircle__shadow-object" : 'shirtCircle',
            hasBorders: false,
            hasControls: false,
            evented: false,
            opacity: 0,
            // opacity: isShadow ? 0.5 : 1,
        });
        shirtNo = new fabric.IText(shirtNoProps.text, {
            textAlign: 'center', //"left", "center", "right" or "justify".
            fontSize: shirtNoProps.fontSize,
            fill: shirtNoProps.fill,
            selectionBackgroundColor: '',
            ref_id: isShadow ? `${obj.ref_id}__shadow-object` : obj.ref_id,
            hasControls: false,
            originX: 'center',
            originY: 'center',
            hasBorders: false,
            textLength: 3,
            opacity: 0,
            fontFamily: 'sans-serif',
            name: isShadow ? "custom_image_shirtno__shadow-object" : 'custom_image_shirtno',

        });
        numGroup = new fabric.Group('', {
            ref_id: isShadow ? `${obj.ref_id}__shadow-object` : obj.ref_id,
            width: shirtNoProps.width,
            height: shirtNoProps.height,
            // opacity: isShadow ? 0.5 : 1,
            left: shirtNoProps.left,
            top: shirtNoProps.top,
            scaleX: shirtNoProps.scaleX,
            scaleY: shirtNoProps.scaleY,
            name: isShadow ? "custom_image_shirtno__shadow-object" : 'custom_image_shirtno',
            originX: 'center',
            originY: 'center',
            hasControls: false,
            selectable:false,
            visibe:false,
            opacity: 0,
            selectionBackgroundColor: '',
            evented: false,
        });
        numGroup.add(shirtCircle);
        numGroup.add(shirtNo);
        numGroup.scale = shirtNoProps.scale;
        numGroup.opacity = 0;

        canvas.add(numGroup);
        canvas.bringForward(numGroup);
        numGroup.evented = false;
    }
    canvas.add(nameText);

    canvas.renderAll();
    if (isShadow) {
        nameText.text = clonedObj.nameText.text;
        canvas.renderAll();
        if (!sprites && typeof (clonedObj._objects[0].fill) === 'object') {
            nameText.set({ direction: hebrewCheck.test(clonedObj.nameText.text[0]) ? 'rtl' : 'ltr' })
            nameText.text = `${shirtNoProps.text} ${clonedObj.nameText.text}`;
        }
        hebrewCheck.test(clonedObj.nameText.text[0])
        nameText.name = 'shadow-object';
        nameText.selectable = false;
        nameText.evented = false;
        if (!sprites) {
            shirtNo.name = 'shadow-object';
            numGroup.name = 'shadow-object';
            shirtNo.evented = false;
            numGroup.evented = false;
            shirtNo.selectable = false;
            numGroup.selectable = false;
            numGroup._objects[1].set('text', clonedObj.shirtNo.text)
            // setTextDimensions(nameText, numGroup, clonedObj, canvas);
        }
        clonedObj.setTextPosition(nameText,canvas);
        // else setPlayerNameDim(nameText, clonedObj, canvas)



    } else sprites ? obj.setTextPosition(nameText,canvas) : obj.setTextPosition(nameText,canvas);
    nameText.set('dirty', true);
    // nameText.fillStyle = nameTextProps.fillStyle;
    // nameText.opacity = nameText.text.length ? 0.5 : 0;
    nameText.selectable = (!isShadow) ? true : false;
    nameText.evented = (!isShadow) ? true : false;

}