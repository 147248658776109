export const handleClearSelection = (stopEvents,startEvents,canvas)=>{
    stopEvents()
    for (var i = 0; i < canvas._objects.length; i++) {
        var obj = canvas._objects[i]
        obj.shadow = ""
        if (obj.name === 'arrow_line' && obj.square1) {
            obj.square1.opacity = 0
            obj.square2.opacity = 0
            obj.square2.set({visible:false});
            obj.square1.set({visible:false});
            // obj.sendToBack();
            obj.square1.selectable = false
            obj.square2.selectable = false
            obj.square1.hoverCursor = obj.square2.hoverCursor = "default"
        } else if (obj.name === 'line') {
            const middlePointActInd = canvas._objects.findIndex((o) => o.ref_id.includes(obj.ref_id) && o.name === "pX");
            if (middlePointActInd > -1) {
                canvas._objects[middlePointActInd].opacity = 0;
                canvas._objects[middlePointActInd].selectable = false;
            }
            if(obj.p0) {
                obj.p0.set({visible:false});
                obj.p1.set({visible:false});
                obj.p2.set({visible:false});
                // if(obj.pa) obj.pa.sendToBack();
                // obj.p0.opacity = 0
                // obj.p1.opacity = 0
                // obj.p2.opacity = 0
                // obj.p0.selectable = false
                // obj.p1.selectable = false
                // obj.p2.selectable = false
                // obj.p0.hoverCursor = obj.p1.hoverCursor = obj.p2.hoverCursor = "default";
            }
        } else if (obj.name === 'line-end-point_shadow-object') {
            obj.opacity = 0;
            obj.evented = false;
        }
    }

    canvas.renderAll();
    startEvents()
}