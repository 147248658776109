import React from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import './styles.scss';

const AppDrawerAvatarHeader = ({ intl, confirmOnClose, avatar, avatarType, title, titleId, subtitle, subtitleBold, onClickTitle, onCloseSideMenuDrawer }) => {

    const { formatMessage } = intl;

    const { direction } = useSelector(state => state.editorSettings);

    const onClickTheTitle = () => {
        if (onClickTitle) {
            onClickTitle();
        }
    }

    return (
        <>
            <div className={`app_drawer_avatar_header_container ${direction}`} >
                <div onClick={() => onClickTheTitle()} dir={direction} className='app_drawer_avatar_header_container color__white' >
                    <div dir={direction} className={`app_drawer_avatar_header_typography ${avatar && 'with-avatar'}`} >
                        <div className='app_drawer_avatar_header_title' >{titleId ? <FormattedMessage id={titleId} /> : title}</div>
                        <div className='app_drawer_avatar_header_subtitle' >{subtitleBold && <b dir={direction} >{subtitleBold}</b>}{subtitle}</div>
                    </div>
                </div>
                <Button onClick={() => onCloseSideMenuDrawer(confirmOnClose)} type="text" shape="circle" >
                    <CloseOutlined className='app_drawer_avatar_header_icon color__white' />
                </Button>
            </div>
        </>
    );
}

export default injectIntl(AppDrawerAvatarHeader);