import {calcArrowAngle} from "./calcArrowAngle";

export const moveEndHelper = (obj, is_set,canvas)=>{
    var p = obj,
        x1, y1, x2, y2;

    if (obj.pointType === 'arrow_end') {

        obj.line.set('x2', obj.get('left'));
        obj.line.set('y2', obj.get('top'));
    } else {

        obj.line.set('x1', obj.get('left'));
        obj.line.set('y1', obj.get('top'));
    }
    obj.line._setWidthHeight();
    x1 = obj.line.get('x1');
    y1 = obj.line.get('y1');
    x2 = obj.line.get('x2');
    y2 = obj.line.get('y2');

    var angle = calcArrowAngle(x1, y1, x2, y2);

    if (obj.pointType === 'arrow_end') {
        if (obj.arrow) {
            obj.arrow.set('angle', angle - 90);
        }
    } else {
        obj.set('angle', angle - 90);
    }

    obj.line.setCoords();
    if (is_set) {
        obj.square2.set('left', obj.get("left"));
        obj.square2.set('top', obj.get('top'));
        obj.square2.setCoords();
    } else {
        if (obj.arrow) {

            obj.arrow.set('left', obj.get("left"));
            obj.arrow.set('top', obj.get('top'));
        }
        obj.line._setWidthHeight();
        if (obj.arrow) {
            obj.arrow.set('angle', angle - 90);
        }

        if (obj.arrow) {
            obj.arrow.setCoords();
        }
    }

    canvas.renderAll();
}

export const moveEnd2Helper = (obj,canvas)=>{
    var p = obj,
        x1, y1, x2, y2;

    if (obj.pointType === 'arrow_end') {
        obj.line.set('x2', obj.get('left'));
        obj.line.set('y2', obj.get('top'));
    } else {
        obj.line.set('x1', obj.get('left'));
        obj.line.set('y1', obj.get('top'));
    }

    obj.line._setWidthHeight();

    x1 = obj.line.get('x1');
    y1 = obj.line.get('y1');
    x2 = obj.line.get('x2');
    y2 = obj.line.get('y2');

    var angle = calcArrowAngle(x1, y1, x2, y2);

    if (obj.pointType === 'arrow_end') {
        if (obj.arrow) {
            obj.arrow.set('angle', angle - 90);
        }
    } else {
        obj.set('angle', angle - 90);
    }
    obj.line.setCoords();

    if (obj.arrow) {
        obj.arrow.setCoords();
    }
    canvas.renderAll();
}