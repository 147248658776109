import {addObjectShadows} from "./addObjectShadows";

export const addClones =  async (props)=>{

    let {obj, shadowObj, d, resolve, isHide, updatePreviousShadows, canvas,svgPathElements,setSvgPathElementss,svgPathElementss,reAddImageTextObjs,propsArray,activeFrameVal,
        updateRemovedProps,makeShadowCurvePoint,scalePropsVal
    } = props;
    let { ref_id: refId, left, top, height, width, scaleX, scaleY, nameText, shirtNo } = shadowObj;
    // EXCEPTIONAL CASE WHEN OBJECT IS ADDED IN THE MIDDLE FRAMES
    left = updatePreviousShadows(obj.ref_id).left;
    top = updatePreviousShadows(obj.ref_id).top;


    //Add line
    const currentObj = canvas._objects.find((o) => o.ref_id === refId && !o.forAnimationPath);
    const x1 = left, y1 = top, x2 = currentObj.left, y2 = currentObj.top;
    const scaleProps = scalePropsVal
    addObjectShadows({
        x1, x2, y1, y2,
        left,
        top,
        height,
        width,
        scaleX: shadowObj.name === 'player_custom_image' || shadowObj.objecttype === 'sprite-image' || shadowObj.name === 'player' ? obj.scaleX : scaleX,
        scaleY: shadowObj.name === 'player_custom_image' || shadowObj.objecttype === 'sprite-image' || shadowObj.name === 'player' ? obj.scaleY : scaleY,
        is_dashed: false,
        ref_id: `${refId}__shadow-object`,
        color: obj.fill,
        scaleProps,
        nameText,
        shirtNo,
        d,
        canvas,svgPathElements,setSvgPathElementss,svgPathElementss,reAddImageTextObjs,propsArray,activeFrameVal,
        updateRemovedProps,makeShadowCurvePoint
    }, obj, resolve, isHide)

}