export const updateAllProperties = (currentObj, allFrames, setFrames, animationState)=>{
    if (!animationState) return
    let tempFrames = [...allFrames];
    for (let frameInd = 0; frameInd < tempFrames.length; frameInd++) {
        let parsedJson = JSON.parse(tempFrames[frameInd].json)
        let objs = parsedJson.objects;
        for (let jIndex = 0; jIndex < objs.length; jIndex++) {
            if ((currentObj.ref_id === objs[jIndex].ref_id) && (objs[jIndex].name === 'player' || objs[jIndex].name === 'player_custom_image' || objs[jIndex].objecttype === 'sprite-image')) {
                if (objs[jIndex].name === 'player' || objs[jIndex].name === 'player_custom_image') {
                    const { angle, scaleX, scaleY, opacity, startAngle } = currentObj;
                    const { bgrOpacity, highlightColor } = currentObj._objects[0];
                    const { text, fill } = currentObj._objects[1];
                    objs[jIndex].angle = angle ? angle : 0;
                    objs[jIndex].startAngleD = startAngle ? startAngle : 0;
                    objs[jIndex].scaleX = scaleX;
                    objs[jIndex].scaleY = scaleY;
                    objs[jIndex].opacity = opacity ? opacity : 1;

                    objs[jIndex].objects[0].bgrOpacity = bgrOpacity;
                    objs[jIndex].objects[0].highlightColor = highlightColor;
                    if (objs[jIndex].name === 'player_custom_image') {
                        if (currentObj._objects[0].fill.type !== 'pattern') {
                            objs[jIndex].objects[0].fill = currentObj._objects[0].fill;
                        }
                    }
                    else objs[jIndex].objects[0].fill = currentObj._objects[0].fill;
                    objs[jIndex].objects[1].text = text;
                    objs[jIndex].objects[1].fill = fill;

                    if (objs[jIndex].objects[2]) {
                        const { angle, direction, arrowFill } = currentObj._objects[2];
                        objs[jIndex].objects[2].angle = angle ? angle : 0;
                        objs[jIndex].objects[2].direction = direction;
                        objs[jIndex].objects[2].arrowFill = arrowFill;
                    }

                }

            }
        }
        parsedJson.objects = [...objs];
        tempFrames[frameInd].json = JSON.stringify(parsedJson);
    }
    setFrames(tempFrames)
}