import React from "react";
import {Alert, Button, Space} from "antd";


const DownloadAlert = ({downloadFile, declineDownload }) => {
    return <div style={{position: "fixed", left: "35%", top: "8%", zIndex: 10000, minWidth: "400px"}}>
        <Alert
            message="Download Ready"
            description={<div style={{marginTop:"10px"}}>
                <Space direction="vertical">
                    {/*<Paragraph>Download is Ready click view to see</Paragraph>*/}
                    <Space direction="horizontal" >
                        <Button size="small" type="primary" onClick={downloadFile}>
                            View
                        </Button>
                        {/*<Button size="small" danger ghost onClick={declineDownload}>*/}
                        {/*    Decline*/}
                        {/*</Button>*/}
                    </Space>
                </Space></div>}
            type="success"
            onClose={declineDownload}
            closable
        />
    </div>

}

export default DownloadAlert;