import {
    animateNonMoveables,
    animateShapes, defaultTime,
    fadeOutShapes,
    hideOpacity,
    setConnectLineDim,
    setPlayerNameDim,
    setTextDimensions, showOpacity
} from "./utils";
import {fabric} from "fabric";
import {isTri} from "./updateObjectsStates";
import {BLUE, BOLD, GREEN, RED, RESET} from "./fabric-overrides";
let count = 0, imgArr = [];

//Calculate the speed on object based on its pace and total path length
export const getObjectAnimationDuration = (canvas, duration, pathLength,videoPlayer=false,obj) => {
    const videoPlayerWidth = videoPlayer ? videoPlayer.width : 1920;
    const videoPlayerHeight = videoPlayer ? videoPlayer.height : 1080;
    const canvasDiagonal = Math.sqrt(canvas.getWidth() ** 2 + canvas.getHeight() ** 2);
    const videoPlayerDiagonal = Math.sqrt(videoPlayerWidth ** 2 + videoPlayerHeight ** 2);
    const screenFactor = 1920/canvas.getWidth();
    const normalSpeed = 150; //pixels per second for normal pace object
    const minSpeed = 75;
    var speed = normalSpeed;

    switch (duration) {

        case 4375://0.25
            speed = minSpeed+(normalSpeed*0.25);
            break;
        case 3750://0.5
            speed = minSpeed+normalSpeed*0.5;
            break;
        case 3125://0.75
            speed = minSpeed+normalSpeed*0.75;
            break;
        case 2700://Normal
            speed = minSpeed+normalSpeed;
            break;
        case 1875://1.25
            speed = minSpeed+normalSpeed*1.25;
            break;
        case 1250://1.5
            speed = minSpeed+normalSpeed*1.5;
            break;
        case 625://1.75
            speed = minSpeed+normalSpeed*1.75;
            break;
    }

    let pathRatio = pathLength/(obj.pCanvas?.width || canvas.getWidth());
    let absoluteLen = pathRatio*1920;
    if(!obj.pCanvas?.width) console.warn('cannot find parent canvas')
    return duration;
}
//This will be called once per object/per frmae
export const recursiveMovement = (move, obj, pathLen, duration, player, pathLength,canvas,hasAnimationStopped,textObj,shirtObj,resolve,videoPlayer) => {
    if(hasAnimationStopped) { if (hasAnimationStopped()) return;}
    var fadeOutflag = false;
    var endValueLeft =0,endValueTop = 0;
    var speedDuration = getObjectAnimationDuration(canvas,duration, pathLength,videoPlayer,player);
    /** WHEN PLAYER IS NOT MOVING, IT RETURNS EMPTY PATH POINTS ARRAY */
    if( !player.pathPoints.length){
        player.pathPoints.push({x:player.left,y:player.top})
    }
    endValueLeft = player.pathPoints[player.pathPoints.length-1].x;
    endValueTop = player.pathPoints[player.pathPoints.length-1].y;
    animateShapes(canvas,speedDuration,player);//Fadeinout for shapes
    if(!pathLength || pathLength === 1){
        resolve(player.name+'no-movement');
        return;
    }
    player.animate({ 'left': (endValueLeft), 'top': (endValueTop) }, {
        onChange: function(value, valueProgress, timeProgress, currentPoint, previousPoint,isMiddlePath){
            if(isMiddlePath && !fadeOutflag){
                fadeOutflag = true;
                fadeOutShapes(canvas,speedDuration,player);
            }
            if(previousPoint && currentPoint && ((player.name === 'player' && !player.isSvg) || player.name === "player_custom_image")){
                /** SETS THE PLAYER DIRECTION */
                setDirection(player,previousPoint,currentPoint);
            }
            /** ANIMATE PLAYER NAME AND CONNECTION LINES */
            animatePlayerNameAndLines(player,textObj,shirtObj,canvas);
            if(player.isLongestObject) canvas.requestRenderAll()
        },
        abort:() => !canvas.hasAnimationStarted,
        duration: speedDuration,
        onComplete: function (value, valueProgress, timeProgress,bufferArr) {
             player.set({isLongestObject:false});
            if(canvas.isVideoPlayer) imgArr = [...imgArr,...bufferArr]
            resolve(player.name);
        }
    });
}
const animatePlayerNameAndLines = (player,textObj,shirtObj,canvas)=>{
    player.setTextPosition(canvas._objects[textObj],canvas)
    for (let i = 0; i < canvas._objects.length; i++) {
        if (
            canvas._objects[i].name === "connectionLine" &&
            canvas._objects[i].ref_id.includes(player.ref_id)
        ) {
            setConnectLineDim(canvas, canvas._objects[i]);
        }
    }
}

const getObjProps = (frObjs,ob,canvas)=>{
    if(ob.name === 'player_custom_image' || ob.name === 'player' || ob.name === 'shape' || ob.name === 'free-shape' || (ob.name === 'image' && ob.is_animation === false)){
        let ind = frObjs.findIndex(f=>(f.name === 'player_custom_image' || f.name === 'player' || f.name === 'shape' || f.name === 'free-shape' || (f.name === 'image' && f.is_animation === false)) && f.ref_id === ob.ref_id)
        if(ind>-1){
            if(ob.name === 'player' || ob.name === 'player_custom_image'){
                ob._objects[0].showHighlight = frObjs[ind].objects[0].showHighlight;
                ob._objects[0].highlightColor  = frObjs[ind].objects[0].highlightColor;
                ob._objects[0].bgrOpacity  = frObjs[ind].objects[0].bgrOpacity;
                if(typeof ob._objects[0].fill !== 'object') ob._objects[0].fill  = frObjs[ind].objects[0].fill;
                ob.scaleX =  frObjs[ind].scaleX;
                ob.scaleY =  frObjs[ind].scaleY;
                if(frObjs[ind].objects[2]?.hasOwnProperty('direction') && ob._objects[2]?.direction) ob._objects[2].direction = frObjs[ind].objects[2]?.direction ;
            }
            ob.fadeFlag = frObjs[ind].fadeFlag

        }
    }
    else if(ob.objecttype === 'sprite-image'){
        let ind = frObjs.findIndex(f=>(f.name === 'image') && f.ref_id === ob.ref_id);
        ob.scaleX =  frObjs[ind].scaleX;
        ob.scaleY =  frObjs[ind].scaleY;
        if(ob.src !==frObjs[ind].src){
            ob.setSrc(frObjs[ind].src)
        }
    }
    let textObInd = canvas._objects.findIndex(f=>f.name === 'custom_image_name' && f.ref_id === ob.ref_id);
    let frTxtInd =   frObjs.findIndex(f=>f.name === 'custom_image_name' && f.ref_id === ob.ref_id);
    if(textObInd > -1 && frTxtInd > -1){
        canvas._objects[textObInd].fillStyle = frObjs[frTxtInd].fillStyle;
        canvas._objects[textObInd].set({dirty:true});
    }
    canvas.renderAll()

}
export const getStaticTime = (frObjs,ob,canvas)=>{
    let timeArr = [];
    let maxTime = 2500;
    frObjs.forEach(e=>{
        if(e.name === 'player' || e.name === 'player_custom_image' || (e.name === 'image' && e.is_animation === true)){
            timeArr.push(e.time)
        }
    })
    if(timeArr.length){
        maxTime = Math.max(...timeArr);
    }
    return maxTime

}
//Get the maximum duration of a single frame and add the flag to that object so that when recording video, we use it for dataurl
export const getMaxFrameDuration = (frameNo, shadowFrames, frames, canvas) =>{
    return new Promise(resolve => {
        let frObjs = JSON.parse(frames[frameNo].json).objects;
        let time = 0;
        let maxTime = -100;
        let longestObject;
        let promises = [];
        for(let i=0;i<canvas._objects.length;i++){
          promises[i] = new Promise(res=>{
              let ob = canvas._objects[i];
              if(ob.is_animation){
                  let shadowInd = shadowFrames[frameNo].shadowLines.findIndex(f=>f.ref_id === `${ob.ref_id}__shadow-object`);
                  let currObjInd = frObjs.findIndex(f=>f.ref_id === ob.ref_id && f.name === ob.name);
                  if(currObjInd > -1) {
                      time  = frObjs[currObjInd].time;

                  }
                  if(shadowInd>-1) {
                      const pathLen = document.getElementById(`line-svg:${frameNo}:${ob.ref_id}__shadow-object`);
                      if (pathLen) {
                          let pathLength = Math.floor(pathLen.getTotalLength());
                          let frameDuration = getObjectAnimationDuration(canvas, time, pathLength,false,ob);
                          if(frameDuration > maxTime && pathLength>1)
                          {
                              longestObject = ob;
                              maxTime = frameDuration;
                          }
                      }
                  }
                  res('resolved')
              }
              else{
                  res('resolved')
              }
          })

        }
        Promise.all(promises).then(val=>{
            if(longestObject)
            {
                longestObject.isLongestObject = true;
            }
            resolve('maxTime resolved');
            return maxTime;
        })

    })

}
const setLongestObj = (objs,fadeInFlag,fadeOutFlag,fadeInOutFlag,canvas)=>{
    if(fadeInFlag === -1 && fadeOutFlag === -1 && fadeInOutFlag === -1){
        let currObjInd = objs.findIndex(f=>f.visible);
        if(currObjInd>-1) objs[currObjInd].isLongestObject = true;
    }
    else if((fadeInOutFlag>-1 && fadeInFlag === -1 && fadeOutFlag === -1) || (fadeInOutFlag>-1 && fadeInFlag>-1 && fadeOutFlag>-1)){
        objs[fadeInOutFlag].isLongestObject = true;
    }
    else if((fadeInFlag>-1 || fadeOutFlag>-1) && fadeInOutFlag === -1){
        let currentObj = canvas._objects[fadeInFlag>-1?fadeInFlag:fadeOutFlag];
        currentObj.isLongestObject = true;
    }

}
export const waitForTime = async (seconds) =>{
    // Convert seconds to milliseconds
    const milliseconds = seconds * 1000;

    // Create a Promise that resolves after the given milliseconds
    await new Promise(resolve => setTimeout(resolve, milliseconds));
}

const setLayersOnPlayAnimation = (frames,frameNo, canvas)=>{
    let tempObjs = [];
    let frObjs = JSON.parse(frames[frameNo].json).objects;
    for(let i=0;i<canvas._objects.length;i++){
        let ob = canvas._objects[i];
        let currObjInd = frObjs.findIndex(f=>(f.ref_id === ob.ref_id && f.name!=="line-end-point_shadow-object" && f.name===ob.name));
        currObjInd > -1 ? tempObjs.push({ob, index:currObjInd, frameNo}) : canvas.sendToBack(ob);
    }
    if(tempObjs.length){
        tempObjs.forEach(item => {
            let {ob, index} = item;
            ob.moveTo(index)
        })
    }
}

export const startAnimation = async (frameNo = 1,animationState,canvas,frames,shadowFrames,hasAnimationStopped,stopCurvedAnimation,socket=false,id=null,videoPlayer = false)=>{
    if (animationState && frameNo === 0 && frames.length === 1 && !canvas._objects.length) {
        alert('No frames added');
        stopCurvedAnimation && stopCurvedAnimation();
        return;
    }

    if(frameNo>frames.length-1 || !canvas._objects.length){
        stopCurvedAnimation && stopCurvedAnimation(imgArr);
        let prcnt = canvas.isVideoPlayer? 25 : 95;
        socket && socket.emit(`send-message`, {msg:'Processing Video: '+prcnt + '% Done',roomId:id,number:prcnt});
        return;
    }
    if(frameNo === 0){
        count=count+1;
    }
    let promises = []
    let frTime = 0;
    if(!shadowFrames[frameNo].objects.length){
        await getStaticFrameObjs(frames,frameNo,canvas);
        let fadeInFlag = canvas.getObjects().findIndex(f=>f.hasOwnProperty('fadeFlag') && f.fadeFlag === 'fade-in-stay' && f.visible);
        let fadeOutFlag = canvas.getObjects().findIndex(f=>f.hasOwnProperty('fadeFlag') && f.fadeFlag === 'fade-out' && f.visible);
        let fadeInOutFlag = canvas.getObjects().findIndex(f=>f.hasOwnProperty('fadeFlag') && f.fadeFlag === 'fade-in-out' && f.visible);
        let promises = [];
        setLongestObj(canvas.getObjects(),fadeInFlag,fadeOutFlag,fadeInOutFlag,canvas);
        setLayersOnPlayAnimation(frames, frameNo, canvas);
        for(let i =0;i<canvas._objects.length;i++){
            promises[i] = new Promise(resolve => {
                let ob = canvas._objects[i];
                if(ob.visible){
                    if(ob.hasOwnProperty('fadeFlag') && ob.fadeFlag === 'fade-in-stay'){
                        ob.set({opacity:0});
                        canvas.renderAll();
                        ob.animate({'opacity':1},{
                            onChange:function () {
                                canvas.requestRenderAll()
                            },
                            duration:1000,
                            onComplete:function (value, valueProgress, timeProgress,bufferArr) {
                                ob.set({isLongestObject:false});
                                if(canvas.isVideoPlayer) imgArr = [...imgArr,...bufferArr]
                                resolve('resolve')

                            }
                        })
                    }
                    else if(ob.hasOwnProperty('fadeFlag') && ob.fadeFlag === 'fade-out'){
                        ob.set({opacity:1});
                        canvas.renderAll();
                        ob.animate({'opacity':0},{
                            onChange:function () {
                                canvas.requestRenderAll()
                            },
                            duration:1000,
                            onComplete:function (value, valueProgress, timeProgress,bufferArr) {
                                ob.set({isLongestObject:false});
                                if(canvas.isVideoPlayer) imgArr = [...imgArr,...bufferArr]
                                resolve('resolve')

                            }
                        })
                    }
                    else if(ob.hasOwnProperty('fadeFlag') && ob.fadeFlag === 'fade-in-out'){
                       ob.opacity = 0;
                       canvas.renderAll();
                        ob.animate({'opacity':1},{
                            onChange:function () {
                                canvas.requestRenderAll()
                            },
                            duration:1000,
                            onComplete:function (value, valueProgress, timeProgress,bufferArr) {
                                if(canvas.isVideoPlayer) imgArr = [...imgArr,...bufferArr]
                                setTimeout(()=>{
                                    ob.animate({'opacity':0},{
                                        onChange:function () {
                                            canvas.requestRenderAll()
                                        },
                                        duration:1000,
                                        onComplete:function (value, valueProgress, timeProgress,bufferArr) {
                                            ob.set({isLongestObject:false});
                                            if(canvas.isVideoPlayer) imgArr = [...imgArr,...bufferArr]
                                            resolve('resolve')
                                        }
                                    })
                                },1000)

                            }
                        })
                    }
                    else if((ob.fadeFlag === 'no-fade-out' || !ob.fadeFlag)){
                        ob.set({animationSteps:0});
                        canvas.renderAll();
                        ob.animate({'animationSteps':10},{
                            onChange:function () {
                                canvas.requestRenderAll()
                            },
                            duration:1000,
                            onComplete:function (value, valueProgress, timeProgress,bufferArr) {
                                ob.set({isLongestObject:false});
                                if(canvas.isVideoPlayer) imgArr = [...imgArr,...bufferArr]
                                resolve('resolve')

                            }
                        })
                    }
                }
                else{
                    resolve('resolve')
                }
            })
        }
        Promise.all(promises).then(async (val)=>{
            await waitForTime(frames[frameNo].delay || 1)
            await startAnimation(frameNo+1,animationState,canvas,frames,shadowFrames,hasAnimationStopped,stopCurvedAnimation,socket,id,videoPlayer)

        })

    }
    else{
        await  getMaxFrameDuration(frameNo, shadowFrames, frames, canvas);
        setLayersOnPlayAnimation(frames, frameNo, canvas);
        for(let i=0;i<canvas._objects.length;i++){
            promises[i]=new Promise((resolve,reject)=>{
                let ob = canvas._objects[i]
                let textObj;
                let staticTime;
                frTime = !shadowFrames[frameNo].objects.length  || frameNo === frames.length-1 ? frames[frameNo].delay : 0;
                let shirtObj;
                let time = defaultTime;
                let frObjs = JSON.parse(frames[frameNo].json).objects;
                let currObjInd = frObjs.findIndex(f=>(f.ref_id === ob.ref_id && f.name!=="line-end-point_shadow-object"));
                ob.visible = (currObjInd > -1);

                canvas.renderAll();
                if(frameNo !==0 && ob.visible === true){
                    getObjProps(frObjs,ob,canvas)
                    hideOpacity(canvas,i);
                    showOpacity(canvas,i);

                }
                staticTime = getStaticTime(frObjs,ob,canvas)

                if(!ob.is_animation) animateNonMoveables(canvas,staticTime,ob)
                if(ob.is_animation){
                    let shadowInd = shadowFrames[frameNo].shadowLines.findIndex(f=>f.ref_id === `${ob.ref_id}__shadow-object`);
                    let currObjInd = frObjs.findIndex(f=>f.ref_id === ob.ref_id && f.name === ob.name);
                    if(currObjInd > -1) {
                        time  = frObjs[currObjInd].time;

                    }
                    if(shadowInd>-1) {
                        const pathLen = document.getElementById(`line-svg:${frameNo}:${ob.ref_id}__shadow-object`);
                        if (pathLen) {
                            let pathLength = Math.ceil(pathLen.getTotalLength());
                            let pathPoints = [];
                            if(!pathLength) resolve(ob.name+'no-movement');
                            for(let i = 0; i<pathLength; i++)
                            {
                                pathPoints.push(pathLen.getPointAtLength(i));
                            }
                            ob.pathPoints = pathPoints;
                            let obj = shadowFrames[frameNo].shadowLines[shadowInd];
                            if (ob.name === "player_custom_image") {
                                textObj = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === ob.ref_id);
                                shirtObj = canvas._objects.findIndex(f => f.name === 'custom_image_shirtno' && f.ref_id === ob.ref_id);
                            }
                            else if (ob.objecttype === "sprite-image" || ob.name === 'player') {
                                textObj = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === ob.ref_id);
                            }
                            recursiveMovement(0, obj, pathLen, time, ob, pathLength,canvas,hasAnimationStopped,textObj,shirtObj,resolve,videoPlayer);
                        }
                        else resolve(ob.name + "no-movement")
                    }
                    else resolve(ob.name + "no-movement");
                }
                else resolve(ob.name + "error");
            })
        }
        Promise.all(promises).then(async (values) => {

            if(frameNo === 0){
                await waitForTime(frames[0].delay || 1)
            }
            else{
                let animateableObjs = JSON.parse(frames[frameNo].json).objects.filter(f=>f.is_animation);
                let noMovementObjs = values.filter(f=>f.includes('no-movement'));
                if(animateableObjs.length === noMovementObjs.length) await waitForTime(frames[frameNo].delay || 1)
            }
           if(frTime) await waitForTime(frTime)
                let prcnt = canvas.isVideoPlayer? 25 : 95;
                count = ((prcnt/frames.length)*frameNo);
                if(count <= 0) count = 1;
                socket && socket.emit(`send-message`, {msg:'Processing Video: '+ Math.round(count) + '% Done',roomId:id,number:Math.round(count)});
                const t1 = performance.now();
                await startAnimation(frameNo+1,animationState,canvas,frames,shadowFrames,hasAnimationStopped,stopCurvedAnimation,socket,id,videoPlayer)
        }).catch((error) => {
            console.log("error", error)
        })
    }
}
const getStaticFrameObjs = (frames,frameNo,canvas)=>{
    return new Promise(res=>{
        let frObjs = JSON.parse(frames[frameNo].json).objects;
        let promises = [];
        for(let i=0;i<canvas._objects.length;i++){
            let ob = canvas._objects[i];
            promises[i] = new Promise(resolve => {
                let currObjInd = frObjs.findIndex(f=>(f.ref_id === ob.ref_id && f.name!=="line-end-point_shadow-object"));
                if(currObjInd === -1) {
                    ob.visible = false
                    resolve('resolved')
                }
                else{
                    ob.visible = true
                    resolve('resolved')
                }
            })
        }
        Promise.all(promises).then(val=>{
            canvas.renderAll();
            res('resolved');
        })
    })
}
export const addAllObjs = (allFrames,canvas)=>{
    return new Promise(resolve => {
        let tempArr = []
        let finalArr = []
        let tempNames;
        for (let i = 0; i < allFrames.length; i++) {
            let canvasObjs = JSON.parse(allFrames[i].json).objects;
            tempArr.push(...canvasObjs);
        }
        tempNames = tempArr.filter(f=>f.name === 'custom_image_name' || f.name === 'pa' || f.name === 'arrow');
        tempArr = tempArr.filter(f=>f.name !== 'custom_image_name' && f.name !== 'custom_image_shirtno' &&  f.name !== 'pa' &&  f.name !== 'arrow');
        let filteredNames = [];
        let filteredObjs = [];
        tempNames.forEach((e,ind)=>{
            let filteredIndex = filteredNames.findIndex(f=>f.ref_id === e.ref_id);
            if(filteredIndex === -1) filteredNames.push(e)
        })
        tempArr.forEach((e,ind)=>{
            let filteredObjIndex = filteredObjs.findIndex(f=>f.ref_id === e.ref_id);
            if(filteredObjIndex === -1 || e.name === 'line') filteredObjs.push(e)
        })
        finalArr = [...filteredNames,...filteredObjs]
        if(finalArr.length){
            fabric.util.enlivenObjects(finalArr, function(objects) {
                objects.forEach(obj=>{
                    let objInd = JSON.parse(allFrames[0].json).objects.findIndex(f=>f.ref_id !== obj.ref_id);
                    if(objInd>-1) obj.visible = false
                })
                canvas.add(...objects);
                // canvas._objects.forEach(ob => ob.bringToFront());

                canvas.renderAll()
                resolve('resolved')
            })
        }
        canvas.renderAll()
    })
}
const setDirection = (player,pt,pt1)=>{
    var angle = Math.atan2(pt.y - pt1.y,  pt.x - pt1.x);
    var theta = Math.abs((angle *(180/Math.PI)))
    let quadrant = getQuadrant((pt.x - pt1.x),(pt.y - pt1.y));
    if(theta<0) theta = 360+theta;
    let polarityFactor = (quadrant === 3 || quadrant === 4) ? 1 : -1;
    if(isTri(player)){
        let dAngle = polarityFactor === 1? 270:90;
        player.set('angle',polarityFactor * (dAngle - theta))
    }
    else{
        player._objects[2]?.set("angle",polarityFactor * (180 - theta))
    }
}
const getQuadrant = (x,y) =>{
    if((Math.sign(x) === 1 || Math.sign(x) === 0) && (Math.sign(y) === 1 || Math.sign(y) === 0)){
        return 1
    }
    else if((Math.sign(x) === -1 || Math.sign(x) === -0) && (Math.sign(y) === 1 || Math.sign(y) === 0)){
        return 2
    }
    else if((Math.sign(x) === -1 || Math.sign(x) === -0) && (Math.sign(y) === -1 || Math.sign(y) === -0)){
        return 3
    }
    else if((Math.sign(x) === 1 || Math.sign(x) === 0) && (Math.sign(y) === -1 || Math.sign(y) === -0)){
        return 4
    }
}
