import React from 'react';
import {Switch, Tooltip} from "antd";
import {panelKeys} from "./index";
import {setPlayerShowNumbers} from "../../actions";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";


const TogglePlayerNumbers = () => {
    const activeKey = useSelector(state => state.activePanel);
    const {showPlayerNumbers} = useSelector(state => state.player);
    const dispatch = useDispatch();
    const togglePlayerNumbers = () => {
        let showNumbersObj = {...showPlayerNumbers};
        if (activeKey) {
            showNumbersObj = {...showNumbersObj, [panelKeys[activeKey]]: !showPlayerNumbers[panelKeys[activeKey]]};
            dispatch(setPlayerShowNumbers(showNumbersObj));
        }
    }
    return (
        <div className="switch_container">
            <span className="size_title">Numbers</span>
            {
                (activeKey === "7" || activeKey==="8") &&
                    <Switch className="toggle_number_switch" onChange={togglePlayerNumbers}
                            value={showPlayerNumbers[panelKeys[activeKey]]}
                            style={{
                                backgroundColor: showPlayerNumbers[panelKeys[activeKey]] ? '#5a5fe7' : '#393836',

                            }} checkedChildren="yes" unCheckedChildren="no" size="small"/>

            }
        </div>


    )
}
export default TogglePlayerNumbers;