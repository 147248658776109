export const handleCurveActivePoint = (obj,canvas,mpLeft,mpTop,setCenterCurvePoint,updateCurveArrow,startEvents,stopEvents,setMpVals)=>{
    let refId = obj.ref_id;
    stopEvents();
    if (obj.name === "pX") refId = refId.split("::")[0];
    const middlePointInd = canvas._objects.findIndex((o) => o.ref_id.includes(refId) && o.name === "p1");
    const middlePointActInd = canvas._objects.findIndex((o) => o.ref_id.includes(refId) && o.name === "pX");
    const curveLineInd = canvas._objects.findIndex((o) => o.ref_id.includes(refId) && (o.objecttype === "shadow_curve_line" || o.objecttype === "curve_line"));
    if (middlePointActInd > -1 && middlePointInd > -1 && curveLineInd > -1) {
        let middlePoint = canvas._objects[middlePointInd];
        let middlePointAct = canvas._objects[middlePointActInd];
        let curveLine = canvas._objects[curveLineInd];

        let tempX = middlePointAct.left - mpLeft;
        let tempY = middlePointAct.top - mpTop;
        setMpVals(middlePointAct)

        middlePoint.left = middlePoint.left + tempX;
        middlePoint.top = middlePoint.top + tempY;
        setCenterCurvePoint(middlePoint);
        let path = curveLine.path || curveLine.line.path;
        path[1][1] = middlePoint.left;
        path[1][2] = middlePoint.top;

        //for arrow line
        if (curveLine.pa) {
            curveLine.pa.opacity = 1
            curveLine.pa.selectable = false
            curveLine.pa.hoverCursor = "pointer"
        }
        if (curveLine.line2) {
            curveLine.line2.path[1][1] = middlePoint.left;
            curveLine.line2.path[1][2] = middlePoint.top;
        }
        if (curveLine.pa || curveLine.line?.pa) {
            updateCurveArrow(curveLine.line.pa, curveLine.path? curveLine : curveLine.line)
        }
    }
    startEvents();
}