import {fabric} from "fabric";
import {isTri} from "../updateObjectsStates";
import {directionAngle} from "../fabric-overrides";
let img = document.createElement('img');
img.src = directionAngle;
export  function renderIcon(icon, isEditCtr = false) {
    return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
        var size = this.cornerSize;
        // if (isEditCtr) size = fabricObject.getScaledWidth() * 0.8;
        if (isEditCtr) size = fabricObject.getScaledWidth() * 0.5;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        if(isTri(fabricObject)){
            ctx.drawImage(img, -size / 2, -size / 2, size + 2, size);
        }
        else{
            ctx.drawImage(icon, -size / 2, -size / 2, size + 2, size);
        }
        ctx.restore();
    }
}