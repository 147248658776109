import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from 'antd';
import {useSelector} from 'react-redux';
import TacticsBoardToolsIcon from './icon';
import {tacticsBoardToolsNumber, tacticsBoardToolsPlayerTriangle} from '../../utils/utils';
import './tools.scss';
import ArrowUpOutlined from "@ant-design/icons/lib/icons/ArrowUpOutlined";
import ArrowLeftOutlined from "@ant-design/icons/lib/icons/ArrowLeftOutlined";
import ArrowDownOutlined from "@ant-design/icons/lib/icons/ArrowDownOutlined";
import ArrowRightOutlined from "@ant-design/icons/lib/icons/ArrowRightOutlined";
import {getMobileView} from "../helpers";
import TacticsBoardToolsIconMobile from "../../MobileComponents/TacticsBoardToolsIconMobile";
import PlayerSide from "./PlayerSide";
let canvasWrapper, pitch;
const TacticsBoardToolsPlayersTriangles = (props) => {
    const canvas = useSelector(state => state.canvas);
    const {playerSizes, team} = useSelector(state => state.player);
    const players = ['','C', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const playerSizesDel = [
        {size: 'small', span: 12, fontSize: 50, radius: 30},
        {size: 'medium', span: 12, fontSize: 90, radius: 62},
        {size: 'large', span: 12, fontSize: 130, radius: 92}
    ];

    const [sizee, setSizee] = useState({size: 'small', span: 12, fontSize: 50, radius: 30})
    const [isVertical, setVertical] = useState(false);
    const selectedColor = useSelector(state => state.color);
    useEffect(() => {
        let newSize = playerSizesDel.find(f => f.size === playerSizes.playerNo);
        if (newSize) setSizee({...newSize});
    }, [playerSizes.playerNo])
    useEffect(()=>{
        canvasWrapper = canvas?.wrapperEl.classList[1];
        if(canvasWrapper){
            pitch = canvasWrapper.slice(-2);
            setVertical(!['p7','p4','p8'].includes(pitch));
        }
    },[canvas]);
    const ItemList = ()=>{
        const isMobileView = getMobileView();
        const colSpan = isMobileView?4:12
        return(
            <>
                {
                    players.map((number) => {
                        return (
                            <Col key={number} span={colSpan}>
                                {
                                    getMobileView()?
                                        <TacticsBoardToolsIconMobile
                                            type='triangle'
                                            dataType="player_object"
                                            alt={`Triangle ${number}`}
                                            id={"player-" + number + "-" + sizee.size}
                                            style={{cursor: 'pointer', objectFit: 'contain'}}
                                            classList={`player-element player-element-triangle ${team} svg__number_icon-` + 'large'}
                                            image={tacticsBoardToolsPlayerTriangle(number, selectedColor.playerNo, sizee.radius, sizee.fontSize)}
                                        />:
                                        <TacticsBoardToolsIcon
                                            type='triangle'
                                            dataType="player_object"
                                            alt={`Triangle ${number}`}
                                            id={"player-" + number + "-" + sizee.size}
                                            style={{cursor: 'pointer', objectFit: 'contain'}}
                                            classList={`player-element player-element-triangle ${team} svg__number_icon-` + sizee.size}
                                            image={tacticsBoardToolsPlayerTriangle(number, selectedColor.playerNo, sizee.radius, sizee.fontSize)}
                                        />
                                }

                            </Col>
                        )
                    })
                }
            </>
        )
    }



    return (
        <div className="tacticsboard__feature_container tacticsboard__mobile_container" onClick={props.props}>
            {getMobileView() ?
                <ItemList/>:
                <Row className="tacticsboard__feature_icons tacticsboard__feature_container-height" gutter={[7, 7]}>
                    <ItemList/>
                </Row>
            }
            {!getMobileView() && <PlayerSide/>}
        </div>
    )
}

export default TacticsBoardToolsPlayersTriangles;