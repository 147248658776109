import React, {useEffect, useState} from 'react';
import {Col, Row} from 'antd';
import {useSelector} from 'react-redux';
import TacticsBoardToolsIcon from './icon';
import './tools.scss';
import {tacticsBoardToolsNumber, tacticsBoardToolsShirts} from '../../utils/utils'
import {getMobileView} from "../helpers";
import TacticsBoardToolsIconMobile from "../../MobileComponents/TacticsBoardToolsIconMobile";
import PlayerSide from "./PlayerSide";
let canvasWrapper, pitch;
const TacticsBoardPlayerShirts = (props) => {
    const canvas = useSelector(state => state.canvas);
    const {playerSizes, team} = useSelector(state => state.player);
    const players = ['','C', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const playerSizesDel = [
        {size: 'small', span: 12, fontSize: 50, radius: 30},
        {size: 'medium', span: 12, fontSize: 90, radius: 62},
        {size: 'large', span: 12, fontSize: 130, radius: 92}
    ];
    const [isVertical, setVertical] = useState(false);
    const [sizee, setSizee] = useState({size: 'small', span: 12, fontSize: 50, radius: 30})
    const selectedColor = useSelector(state => state.color);
    useEffect(()=>{
        canvasWrapper = canvas?.wrapperEl.classList[1];
        if(canvasWrapper){
            pitch = canvasWrapper.slice(-2);
            setVertical(!['p7','p4','p8'].includes(pitch));
        }
    },[canvas])
    useEffect(() => {
        let newSize = playerSizesDel.find(f => f.size === playerSizes.playerShirts);
        if (newSize) setSizee({...newSize});
    }, [playerSizes.playerShirts])

    const ItemList = ()=>{
        const isMobileView = getMobileView();
        const colSpan = isMobileView?4:12
        return(
            <>
                {
                    players.map((number) => {
                        return (
                            <Col key={number} span={colSpan}>
                                {
                                    getMobileView()?
                                        <TacticsBoardToolsIconMobile
                                            classList={"player-element player-element-shirt svg__number_icon-" + 'large'+` ${team} `+'playerNo'}
                                            dataType="player_object"
                                            id={"player-" + number + "-" + sizee.size}
                                            style={{cursor: 'pointer', objectFit: 'contain'}}
                                            image={tacticsBoardToolsShirts(number, selectedColor.playerNo, true)}
                                            alt={`Number ${number}`}/>:
                                        <TacticsBoardToolsIcon
                                            classList={"player-element player-element-shirt svg__number_icon-" + sizee.size+` ${team} `}
                                            dataType="player_object"
                                            id={"player-" + number + "-" + sizee.size}
                                            style={{cursor: 'pointer', objectFit: 'contain'}}
                                            image={tacticsBoardToolsShirts(number, selectedColor.playerNo, true)}
                                            alt={`Number ${number}`}/>
                                }

                            </Col>
                        )
                    })
                }
            </>
        )
    }


    return (
        <div className="tacticsboard__feature_container tacticsboard__mobile_container" onClick={props.props}>
            {getMobileView() ?
                <ItemList/>:
                <Row className="tacticsboard__feature_icons tacticsboard__feature_container-height" gutter={[7, 7]}>
                    <ItemList/>
                </Row>
            }
        </div>
    )
}

export default TacticsBoardPlayerShirts;