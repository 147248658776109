import {setColorPopup} from "../actions";
import {useDispatch} from "react-redux";

export const useDeleteObject = () => {
    const dispatch = useDispatch();
    const deleteObjectHelper = (props) => {
        let {
            canvas, deleteShadowObjByName, returnAllObjects, shadowFrames, svgPathElements, deletedObjects,
            updateUndoRedoState, updateFrameObjects, updateArrowLinesArray, activeFrameVal, updateDeletedObjs,
            reRenderCanvas
        } = props;

        dispatch(setColorPopup(false))
        let obj = canvas.getActiveObject();
        if (!obj) return;
        if (obj.isEditing) return;
        let currentDeletedPath, nextDeletedPath, deletedObj;
        const objectsToDelete = obj.type === "activeSelection" ? obj._objects : [obj];
        for (let i = 0; i < objectsToDelete.length; i++) {
            const obj = objectsToDelete[i];
            deleteShadowObjByName(obj);
            if (obj.name === "drawLine") {
                const endP = canvas._objects.filter(
                    (f) =>
                        f.name === "line-end-point_shadow-object" && f.ref_id === obj.ref_id
                );
                const endArr = canvas._objects.findIndex(
                    (f) =>
                        (f.name === "drawElementArrow" || f.name === "drawElementBlock") &&
                        f.ref_id === obj.ref_id
                );
                if (endArr > -1) {
                    canvas.remove(canvas._objects[endArr]);
                }
                canvas.remove(endP[0]);
                canvas.remove(obj);
            } else if (obj.name === "arrow_line") {
                canvas.remove(obj.arrow);
                canvas.remove(obj.square1);
                canvas.remove(obj.square2);
                canvas.remove(obj);
            } else if (obj.name === "square1") {
                canvas.remove(obj.arrow);
                canvas.remove(obj.line);
                canvas.remove(obj.square2);
                canvas.remove(obj);
            } else if (obj.name === "square2") {
                canvas.remove(obj.arrow);
                canvas.remove(obj.square1);
                canvas.remove(obj.line);
                canvas.remove(obj);
            } else if (
                obj.name === "p0" ||
                obj.name === "p1" ||
                obj.name === "pX" ||
                obj.name === "p2" ||
                obj.name === "line"
            ) {
                const objects = returnAllObjects(obj.ref_id);
                if (objects.length > 0) {
                    for (let j = 0; j < objects.length; j++) {
                        canvas.remove(objects[j]);
                    }
                }
            } else if (obj.name.includes("custom_image")) {
                const objects = returnAllObjects(obj.ref_id);
                if (objects.length > 0) {
                    for (let j = 0; j < objects.length; j++) {
                        canvas.remove(objects[j]);
                    }
                }
                if (obj.name === "player_custom_image" && shadowFrames.length) {
                    const tempFrameObj = {...shadowFrames[activeFrameVal.data_num]};
                    const svgInd = svgPathElements.findIndex(
                        (el) =>
                            el.id ===
                            `line-svg:${activeFrameVal.data_num}:${obj.ref_id}__shadow-object`
                    );
                    const svgIndNext = svgPathElements.findIndex(
                        (el) =>
                            el.id ===
                            `line-svg:${activeFrameVal.data_num + 1}:${
                                obj.ref_id
                            }__shadow-object`
                    );
                    if (svgInd > -1) {
                        currentDeletedPath = svgPathElements[svgInd];
                        if (svgIndNext > -1) {
                            nextDeletedPath = svgPathElements[svgIndNext];
                        }
                    } else {
                        if (svgIndNext > -1) {
                            nextDeletedPath = svgPathElements[svgIndNext];
                        }
                    }
                    if (tempFrameObj.objects.length) {
                        const ind = tempFrameObj.objects.findIndex(
                            (o) => o.ref_id === obj.ref_id
                        );
                        if (ind > -1) {
                            deletedObj = tempFrameObj.objects[ind];
                        }
                    }

                    updateFrameObjects("delete", obj);
                    deletedObjects.push({
                        frame: activeFrameVal.data_num,
                        ObjRefId: obj.ref_id,
                        currentDeletedPath,
                        nextDeletedPath,
                        deletedObj,
                    });
                    updateDeletedObjs({deletedObjects});
                }
            } else {
                const tempFrameObj = {...shadowFrames[activeFrameVal.data_num]};
                const objects = returnAllObjects(obj.ref_id);
                if (objects.length > 0) {
                    for (let j = 0; j < objects.length; j++) {
                        canvas.remove(objects[j]);
                    }
                }
                if (shadowFrames.length) {
                    const svgInd = svgPathElements.findIndex(
                        (el) =>
                            el.id ===
                            `line-svg:${activeFrameVal.data_num}:${obj.ref_id}__shadow-object`
                    );
                    const svgIndNext = svgPathElements.findIndex(
                        (el) =>
                            el.id ===
                            `line-svg:${activeFrameVal.data_num + 1}:${
                                obj.ref_id
                            }__shadow-object`
                    );
                    if (svgInd > -1) {
                        currentDeletedPath = svgPathElements[svgInd];
                        if (svgIndNext > -1) {
                            nextDeletedPath = svgPathElements[svgIndNext];
                        }
                    } else {
                        if (svgIndNext > -1) {
                            nextDeletedPath = svgPathElements[svgIndNext];
                        }
                    }
                    if (tempFrameObj.objects.length) {
                        const ind = tempFrameObj.objects.findIndex(
                            (o) => o.ref_id === obj.ref_id
                        );
                        if (ind > -1) {
                            deletedObj = tempFrameObj.objects[ind];
                        }
                    }
                }
                deleteShadowObjByName(obj);
                canvas.remove(obj);
                if (obj.is_animation) updateFrameObjects("delete", obj);
                deletedObjects.push({
                    frame: activeFrameVal.data_num,
                    ObjRefId: obj.ref_id,
                    currentDeletedPath,
                    nextDeletedPath,
                    deletedObj,
                });
                updateDeletedObjs({deletedObjects});
            }
        }
        updateUndoRedoState(
            obj,
            obj.type === "activeSelection" ? "selection-deleted" : "deleted"
        );
        canvas.discardActiveObject();
        updateArrowLinesArray();
        reRenderCanvas();

    }
    return {deleteObjectHelper}
}