export const updateShadowLine = (obj,canvas) => {
    let refId = obj.ref_id;
    if (obj.name.startsWith('custom_image_')) {
        let imgInd = canvas._objects.findIndex(f => (f.name === 'player_custom_image' || f.objecttype === 'sprite-image' || f.name === 'player') && f.ref_id === refId);
        if (imgInd > -1) {
            obj = canvas._objects[imgInd];
        }
    }
    if (obj?.name === "pX") return;
    if (!refId.includes("__shadow-object")) refId = `${refId}__shadow-object`;
    const objInd = canvas._objects.findIndex((o) => o.ref_id?.includes(refId) && o.name === "shadow-line");
    if (objInd > -1) {
        canvas._objects[objInd].path[1][3] = obj.left;
        canvas._objects[objInd].path[1][4] = obj.top;
        canvas.renderAll();
    } else return;
}