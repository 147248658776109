import React, {useEffect, useState} from 'react';
import {Col, Row} from "antd";
import '../../../attacking-players.scss';
import {clearActiveTab, clearClipBoard, setActiveTab} from "../../../actions";
import {useDispatch, useSelector} from "react-redux";
import {getMobileView} from "../../helpers";
import DirectionsTab from "../Directions";

const TacticsBoardGoalkeepers = (props) => {
    const [sizee, setSizee] = useState({size: 'small', span: 12, fontSize: 8, sm: `goali-xs`})
    const symbols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];
    const iTags = symbols.map(() => React.createRef());
    const activeTab = useSelector((state)=>state.activeTab).tab;
    const canvas = useSelector(state => state.canvas);
    const {playerSizes, spriteIndex} = useSelector(state => state.player);
    const dispatch = useDispatch();
    useEffect(() => {
        let newSize = playerSizesDel.find(f => f.size === playerSizes.goalKeepers);
        if (newSize) setSizee({...newSize});
    }, [playerSizes.goalKeepers])
    const playerSizesDel = [
        {size: 'small', span: 12, fontSize: 8, sm: `goali-xs`},
        {size: 'medium', span: 12, fontSize: 8, sm: getMobileView()?`goali-xs`:'goali-s'},
        {size: 'large', span: 12, fontSize: 8, sm:  getMobileView()?`goali-xs`:`goali`}
    ];
    const setTab = (id,e)=>{
        if(activeTab === id){
            e.stopPropagation();
            canvas.selection = true;
            canvas.defaultCursor = 'default';
            dispatch(clearActiveTab());
            dispatch(clearClipBoard());
            return;
        }
        dispatch(setActiveTab(id));
    }
    const ItemsList = ()=> {
        const isMobileView = getMobileView();
        const colSpan = isMobileView?4:sizee.span;
        return(
            symbols.map((symbol, i) => {
                    let ratioFactor = 0;
                    if (symbol === 'D' || symbol === 'N')
                        ratioFactor = 0.88
                    else if (symbol === 'Q')
                        ratioFactor = 0.80
                    else if (symbol === 'C')
                        ratioFactor = 0.77
                    else if (symbol === 'G')
                        ratioFactor = 1.20
                    else if (symbol === 'E')
                        ratioFactor = 1.26
                    else if (symbol === 'K')
                        ratioFactor = 1.11
                    else if (symbol === 'J')
                        ratioFactor = 0.66
                    else if (symbol === 'A')
                        ratioFactor = 1.1

                    return (
                        <Col span={colSpan} >
                            <div key={i} className={`${getMobileView()?'tacticsboard__feature_icon-sprite':'tacticsboard__feature_icon'}
                                            ${activeTab === `${sizee.sm}-${symbol}000${spriteIndex}`?'prim':''}`} onClick={(e)=>{
                                setTab(`${sizee.sm}-${symbol}000${spriteIndex}`,e);
                                iTags[i].current.click();
                            }}>
                                <i  ref={iTags[i]}
                                    className={`sprites-element ${sizee.sm} ${sizee.sm}-${symbol}000${spriteIndex}`}
                                    ratiofactor={ratioFactor}
                                    id={`Goali_${symbol}000${spriteIndex}_${playerSizes.goalKeepers}`}>
                                </i>
                            </div>
                        </Col>
                    )
                })
        )
    }


    return (
        <div className="tacticsboard_sprite_main">
            {!getMobileView() && <DirectionsTab/>}
            <div className="sprites-container goalKeeper_container tacticsboard__mobile_container" >
                <div className="players-sprites" onClick={props.props}>
                    {getMobileView()?<Row>
                            <div className='sprites-mobile'>
                                <ItemsList/>
                            </div>
                        </Row>:
                        <Row gutter={[7, 7]}>
                            <ItemsList/>
                        </Row>}
                </div>
            </div>
        </div>
    )
}

export default TacticsBoardGoalkeepers;