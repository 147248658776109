window.getDrawing = async (url)=>{
    const result = await fetch(url);
    const canvasJSONData = await result.text();
    let canvasObj = Buffer.from(canvasJSONData, 'base64');

    canvasObj = canvasObj.toString('utf-8');
    const canvasParsed =JSON.parse(canvasObj);

    const {canvasJson:canvasDJson, containerDimensionsSave:canvasDimensions, animationData:canvasAnimationData, canvasBackground:canvasBackgroundClass} = canvasParsed;
    localStorage.setItem("animationData", canvasAnimationData)
    localStorage.setItem("canvas", canvasDJson);
    localStorage.setItem("background", canvasBackgroundClass);
    localStorage.setItem("containerDimensions", canvasDimensions);
    window.location.reload()
}