import {setPlayerNameDim, setTextDimensions} from "./utils";

const handleMovemoent = (ob,svgInd,shadowObjInd,pXInd,p1Ind,shadowLineInd,svgPathElements,updateShadowLine,shadowLineUpdate,canvas)=>{
    let actObj = svgPathElements[svgInd]
    let d = actObj?.d.split(' ');
    ob.left = canvas._objects[shadowObjInd].left;
    ob.top = canvas._objects[shadowObjInd].top;
    if (ob.name === "player_custom_image") {
        const textObj = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === ob.ref_id);
        const shirtObj = canvas._objects.findIndex(f => f.name === 'custom_image_shirtno' && f.ref_id === ob.ref_id);
        setTextDimensions(canvas._objects[textObj], canvas._objects[shirtObj], ob, canvas);
    } else if (ob.objecttype === "sprite-image" || ob.name === 'player') {
        const textObj = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === ob.ref_id);
        setPlayerNameDim(canvas._objects[textObj], ob, canvas)
    }
    ob.setCoords();
    updateShadowLine(ob,canvas);
    shadowLineUpdate(ob);
    canvas.renderAll();
    canvas._objects[p1Ind].left = canvas._objects[shadowObjInd].left;
    canvas._objects[p1Ind].top = canvas._objects[shadowObjInd].top;
    canvas._objects[p1Ind].evented = false;
    canvas._objects[pXInd].left = canvas._objects[shadowObjInd].left;
    canvas._objects[pXInd].top = canvas._objects[shadowObjInd].top;
    canvas._objects[shadowLineInd].path[1][1] = canvas._objects[shadowObjInd].left
    canvas._objects[shadowLineInd].path[1][2] = canvas._objects[shadowObjInd].top

    d[1] = canvas._objects[shadowLineInd].path[0][1];
    d[2] = canvas._objects[shadowLineInd].path[0][2];
    d[4] = canvas._objects[shadowLineInd].path[1][1];
    d[5] = canvas._objects[shadowLineInd].path[1][2];
    d[6] = canvas._objects[shadowLineInd].path[1][3];
    d[7] = canvas._objects[shadowLineInd].path[1][4];
    d = d.join(' ');
    return d;
}
export const cancelMovement = (svgPathElements,updateShadowLine,shadowLineUpdate,updateSvgs,animationState,activeFrameVal,canvas)=>{
    let ob = canvas.getActiveObject();
    if (animationState && ob.is_animation && activeFrameVal.data_num > 0) {
        const shadowLineInd = canvas._objects.findIndex((o) => o.ref_id === `${ob.ref_id}__shadow-object` && o.name === "shadow-line");
        const shadowObjInd = canvas._objects.findIndex((o) => o.ref_id === `${ob.ref_id}__shadow-object` && o.name === "shadow-object");
        const p1Ind = canvas._objects.findIndex((o) => o.ref_id === `${ob.ref_id}__shadow-object` && o.name === "p1");
        const pXInd = canvas._objects.findIndex((o) => o.ref_id === `${ob.ref_id}__shadow-object::active` && o.name === "pX");
        let svgInd = svgPathElements.findIndex((el) => el.id === `line-svg:${activeFrameVal.data_num}:${ob.ref_id}__shadow-object`)
        if (shadowLineInd === -1 || svgInd === -1) return;
        let d = handleMovemoent(ob,svgInd,shadowObjInd,pXInd,p1Ind,shadowLineInd,svgPathElements,updateShadowLine,shadowLineUpdate,canvas);
        updateSvgs(d,pXInd,svgInd,ob,shadowObjInd);
    }
}