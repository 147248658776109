import React from 'react';
import { useSelector } from 'react-redux';
import TacticsBoardLeftSide from './leftside';
import TacticsBoardRightSide from './rightside';
import './header.scss';
import { Layout } from 'antd';
import {moveLine} from "../../utils/lines/moveLine";

const TacticsBoardHeader = ({ isMobileView, presentationMode, recordingStatus, frControlVisibility, updateAllProps, cancelMove, downloadingProgress, isDownloadingVideo, reinitFreeShape, background, deleteCanvasObject, onShowPreviousDrawings, exportVideo, updateUndoStates, moveEnd2, moveLine, returnAllObjects, onDoneDrawing , onCloseDrawing, undoEvent, redoEvent, modifiedObject, saveCanvas, movingObject, mouseUp, reinitpath, onFailTacticsBoard, activeAnimationBoard, activeBar, activeFrame,                     setActiveFrame, updateRemovedProps,frames,setFrames, isAnimationOpen
                            }) => {

    return (
        <>
            {
                (window.innerWidth > 992) &&
                <TacticsBoardLeftSide
                    isMobileView={isMobileView}
                    updateUndoStates={updateUndoStates}
                    frControlVisibility={frControlVisibility}
                    moveEnd2={moveEnd2}
                    activeBar={activeBar}
                    moveLine={moveLine}
                    returnAllObjects={returnAllObjects}
                    onCloseDrawing={onCloseDrawing}
                    undoEvent={undoEvent}
                    redoEvent={redoEvent}
                    modifiedObject={modifiedObject}
                    deleteCanvasObject={deleteCanvasObject}
                    movingObject={movingObject}
                    mouseUp={mouseUp}
                    exportVideo={exportVideo}
                    reinitFreeShape={reinitFreeShape}
                    reinitpath={reinitpath}
                    cancelMove={cancelMove}
                    activeFrame={activeFrame}
                    updateAllProps={updateAllProps}
                    setActiveFrame={setActiveFrame}
                    updateRemovedProps={updateRemovedProps}
                    frames={frames}
                    setFrames={setFrames}
                    isAnimationOpen={isAnimationOpen}
                />
            }
            <TacticsBoardRightSide
                isMobileView={isMobileView}
                frames={frames}
                deleteCanvasObject={deleteCanvasObject}
                onCloseDrawing={onCloseDrawing}
                onDoneDrawing={onDoneDrawing}
                modifiedObject={modifiedObject}
                saveCanvas={saveCanvas}
                activeBar={activeBar}
                presentationMode={presentationMode}
                onFailTacticsBoard={onFailTacticsBoard}
                recordingStatus={recordingStatus}
                isDownloadingVideo={isDownloadingVideo}
                exportVideo={exportVideo}
                downloadingProgress={downloadingProgress}
                activeAnimationBoard={activeAnimationBoard}
                background={background}
                onShowPreviousDrawings={onShowPreviousDrawings}
                reinitFreeShape={reinitFreeShape} updateUndoStates={updateUndoStates}
                moveEnd2={moveEnd2}
                movingObject={movingObject} reinitpath={reinitpath}
                mouseUp={mouseUp}
            />
        </>
    )
}

export default TacticsBoardHeader;
