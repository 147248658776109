import React, {useEffect, useState, useRef} from 'react';
import "./layerStyles.scss"
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useDispatch, useSelector} from "react-redux";
import {
    createCurvedLineSvg, createSineWaveWithArrow,
    createStraightLineSvg,
    tacticsBoardToolsNumber,
    tacticsBoardToolsPlayerTriangle,
    tacticsBoardToolsShirts
} from "../../utils/utils";
import {SvgIcon} from "@mui/material";
import {DragIcon, ExitLayerIcon} from "../../assets/icons";
import {Button} from "antd";
import {toggleLayer} from "../../actions";

const Layers = ({frames}) => {
    const canvas = useSelector(state => state.canvas);
    const objectStates = useSelector(state => state.canvasObjectStates);
    const {layerOrder} = useSelector(state => state.player);
    const [objList, setObjList] = useState([]);
    const [activeLayer, setActiveLayer] = useState('');
    const boxRefs = useRef({});
    const dispatch = useDispatch();

    useEffect(()=>{
       if(canvas) setObjectsList();
    },[layerOrder,frames, objectStates])

    useEffect(()=>{
        let activeObj = canvas?.getActiveObject();
        setActiveLayer(activeObj?.ref_id || '');
    },[objectStates])

    useEffect(()=>{
        const scrollToThumbnail = (id) => {
            const thumbnailElement = boxRefs.current[id];
            if (thumbnailElement) {
                thumbnailElement.scrollIntoView({
                    behavior: 'smooth', // Smooth scrolling
                    block: 'nearest',   // Scroll as little as possible to bring the element into view
                });
            }
        };
        scrollToThumbnail(activeLayer);
    },[activeLayer])



    const setObjectsList = ()=>{
        let propsToFilterOut = ['p1','p2','square1','square2','custom_image_name','connectionLine', 'custom_image_shirtno','p0','pX','pa','shadow-line','shadow-object','arrow', 'line-end-point_shadow-object'];
        let objs = canvas._objects.filter(fl=>!propsToFilterOut.includes(fl.name));
        objs = objs.map(mp => getSpecialProps(mp));
        objs.reverse();
        (objs.length) ?  setObjList([...objs]) : setObjList([]);
    }
    const createSvgImage = (obj)=>{
        if(!obj.isSvg && !obj.polygon) return tacticsBoardToolsNumber(obj._objects[1].text, obj._objects[0].fill, true, true);
        else if(obj.isSvg) return tacticsBoardToolsShirts(obj._objects[11].text, obj._objects[2].fill, true);
        else if(obj.polygon) return tacticsBoardToolsPlayerTriangle(obj._objects[1].text, obj._objects[0].fill)
    }
    const getLineSvg = (obj) => {
        if (obj.name === 'arrow_line') {
            return createStraightLineSvg('#000000', 2, !!obj.arrow, !!obj.strokeDashArray);
        }
        if (obj.name === 'line') {
            return createCurvedLineSvg('#000000', 2, !!obj.pa, !!obj.strokeDashArray);
        }
        return null;
    }
    const setActiveLayerObject = (id, name)=>{
        setActiveLayer(id);
        let objInd = canvas?._objects.findIndex((item)=>item.ref_id === id.toString() && item.name === name);
        if(objInd > -1 ){
            canvas.discardActiveObject();
            canvas.setActiveObject(canvas._objects[objInd]);
            canvas.requestRenderAll();
        }
    }

    const getSpecialProps = (obj)=>{
        let imgSrc = '';
        let {name, ref_id} = obj;
        let index = canvas._objects.findIndex(ob=>ob.ref_id === ref_id && ob.name === name);
        if(obj.type === 'image'){
            imgSrc = obj.getSrc();
        }
        else if(obj.type === 'group' && obj.name === 'player'){
            imgSrc = createSvgImage(obj);
        }
        else if(obj.type === 'group' && obj.name === 'player_custom_image'){
            let fill = obj._objects[0].fill;
            if(typeof fill === 'object') imgSrc  = obj.customImage;
            else imgSrc = createSvgImage(obj);
        }
        else if(['arrow_line','line'].includes(obj.name))  imgSrc = getLineSvg(obj)
        else if(['Line Arrow'].includes(obj.name))  imgSrc = createSineWaveWithArrow();
        else imgSrc = obj.toDataURL();


        return {
            ind:index,
            ref_id:obj.ref_id,
            name:obj.name,
            imgSrc,
            roundedCorners:(obj.name === 'player_custom_image' && typeof obj._objects[0].fill === 'object')
        }


    }
    const handleOnDragEnd = (result) => {
        const {destination, source} = result;
        if(objList && destination && source){
            let sourceObj = objList[source.index];
            let destinationObj = objList[destination.index];
            let objIndex = canvas?._objects.findIndex(item=>item.name === sourceObj.name && item.ref_id === sourceObj.ref_id);
            if(objIndex > -1){
                canvas._objects[objIndex].moveTo(destinationObj.ind);
                source.index > destination.index ? (canvas._objects[objIndex].arrow || canvas._objects[objIndex].pa)?.sendToBack() : (canvas._objects[objIndex].arrow || canvas._objects[objIndex].pa)?.bringToFront();
            }

            canvas.renderAll();
            setObjectsList();
        }
    }
    return (
         <DragDropContext onDragEnd={handleOnDragEnd}>
            <div className='drop-container'>
            <Droppable droppableId="boxes">
                {(provided) =>
                    <ul ref={provided.innerRef} {...provided.droppableProps}>
                        {
                            objList.map(({ref_id:id, imgSrc, ind, name, roundedCorners}, index) =>
                                <Draggable key={id} draggableId={id.toString()} index={index} payload={ind}>
                                    {
                                        (provided) =>
                                            <li key={id} draggableId={id.toString()}  index={index} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}  >
                                                <div className={`box-container ${activeLayer === id ? "activeLayer":""}`} onMouseDown={()=>setActiveLayerObject(id, name)}>
                                                    <DragIcon fill={activeLayer === id?'#5a5fe7':'#eeece9'}/>
                                                    <div
                                                        ref={(el)=> (boxRefs.current[id]= el)}
                                                        ind={ind}
                                                        obj={"hello"}
                                                        className={`box ${roundedCorners ? 'rounded-corners':''}`}
                                                        style={{backgroundImage: `url(${imgSrc})`}}
                                                    />
                                                </div>
                                            </li>

                                    }
                                </Draggable>
                            )}
                        {provided.placeholder}
                    </ul>
                }

            </Droppable>
                <Button onClick={()=>dispatch(toggleLayer())}   className={`layer-close-icon`} icon={<SvgIcon   className=''><ExitLayerIcon fill={'#000000'} /></SvgIcon>} />
            </div>
        </DragDropContext>
    )
}
export default Layers;