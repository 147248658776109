import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Popover, Tooltip, Modal, Switch} from 'antd';
import { SvgIcon } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import './rightside.scss';
import {useDispatch, useSelector} from 'react-redux';
import {
    CloseIcon,
    DownloadAllIcon,
    DownloadIcon,
    DownloadImageIcon,
    DrawerDelete,
    DuplicateIconEditor,
    DuplicateIconRight,
    HelpIcon, LayersIconEditor,
    MinusSolidIcon,
    MobileSettingsIcon,
    MonitorIcon,
    PlusCircleIcon,
    PlusSolidIcon,
    PreviousDrawingIcon,
    SaveIcon,
    Settings,
    SizeIconEditor,
    SizeIconEditorMobile,
    TextIconEditor,
    TextIconEditorRight,
    ThreeDotsIcon,
    UserMinus,
    UserPlus,
    VideoSolidIcon
} from '../../assets/icons';
import * as html2canvas from 'html2canvas';
import { Loading } from '../../loadinga';
import {setIsModalOpen, setModalLeft, setModalTop, setTextField, toggleLayer} from "../../actions";
import DirectionsTab from "../tools/Directions";
import {activeSelectionConnectionLine} from "../../utils/utils";
import {duplicateObject} from "../../utils/duplicate";
import {moveLine} from "../../utils/lines/moveLine";
import PlusCircleOutlined from "@ant-design/icons/lib/icons/PlusCircleOutlined";
import MinusCircleOutlined from "@ant-design/icons/lib/icons/MinusCircleOutlined";
import {getMobileView} from "../helpers";
import {VideoGen} from "../../utils/videoPlayerDatals";

const TacticsBoardRightSide = ({ isMobileView, frames, presentationMode,modifiedObject, onCloseDrawing, deleteCanvasObject, onDoneDrawing, isDownloadingVideo, downloadingProgress, background, recordingStatus, onShowPreviousDrawings, saveCanvas, activeAnimationBoard, activeBar, exportVideo, onFailTacticsBoard,reinitFreeShape, updateUndoStates,moveEnd2, movingObject,reinitpath,mouseUp }) => {

    const canvas = useSelector(state => state.canvas)
    const animationState = useSelector(state => state.animation)
    const objectStates = useSelector(state => state.canvasObjectStates)
    const dispatch = useDispatch();
    const [showLoading, setShowLoading] = useState(false);
    const setConnect = useSelector(state => state.connectObject);
    const {isModalOpen} = useSelector(state => state.player);
    const [oldVid, setOldVid] = useState(false);

    // const { direction } = useSelector((state) => state.settings);
    const  direction = 'ltr';

    useEffect(() => {
        if(isModalOpen && isMobileView) showModal(false,true);
    }, [objectStates])

    const downloadCanvasImg = () => {
        setShowLoading(true);
        canvas.discardActiveObject();
        let imageGen = new VideoGen(canvas);
        imageGen.generateImageStatic(true).then(img=>{
            const link = document.createElement('a');
            link.download = 'tacticsboard.png';
            link.href = img;
            setShowLoading(false);
            document.body.appendChild(link);
            setShowLoading(false);
            link.click();
            document.body.removeChild(link);
            setShowLoading(false);
        }).catch(e=>{
            if (onFailTacticsBoard) {
                console.log('error')
            }
        });

        // html2canvas(document.querySelector(".canvas-container"), {
        //     useCORS: true,
        //     allowTaint: true,
        // }).then(function (canvasEl) {
        //     const dataURL = canvasEl.toDataURL({
        //         width: 1920,
        //         height: 1080,
        //         format: 'jpg',
        //         quality: 5,
        //         multiplier: 5
        //     });
        //     const link = document.createElement('a');
        //     link.download = 'tacticsboard.png';
        //     link.href = dataURL;
        //     setShowLoading(false);
        //     document.body.appendChild(link);
        //     setShowLoading(false);
        //     link.click();
        //     document.body.removeChild(link);
        //     setShowLoading(false);
        // })
        //     .catch(function (err) {
        //         console.log(err);
        //     });
    }
    const downloadCanvas = () => {
        setShowLoading(true);
        if (exportVideo) {
            exportVideo(oldVid);
            setShowLoading(false)
            return;
        }
        canvas.discardActiveObject();
        let imageGen = new VideoGen(canvas);
        imageGen.generateImageStatic().then(img=>{
            const link = document.createElement('a');
            link.download = 'tacticsboard.png';
            link.href = img;
            setShowLoading(false);
            document.body.appendChild(link);
            setShowLoading(false);
            link.click();
            document.body.removeChild(link);
            setShowLoading(false);
        }).catch(e=>{
            if (onFailTacticsBoard) {
                console.log('error')
            }
        });


        // html2canvas(document.querySelector(".canvas-container"), {
        //     useCORS: true,
        //     allowTaint: true,
        // }).then(function (canvasEl) {
        //     const dataURL = canvasEl.toDataURL({
        //         width: 1920,
        //         height: 1080,
        //         format: 'jpg',
        //         quality: 5,
        //         multiplier: 5
        //     });
        //     const link = document.createElement('a');
        //     link.download = 'tacticsboard.png';
        //     link.href = dataURL;
        //     setShowLoading(false);
        //     document.body.appendChild(link);
        //     setShowLoading(false);
        //     link.click();
        //     document.body.removeChild(link);
        //     setShowLoading(false);
        // })
        //     .catch(function (err) {
        //         console.log(err);
        //     });
    }

    // onDone - Raphael will use the prop drilled function
    const onDone = async () => {
        if (onDoneDrawing) {
            setShowLoading(true);
            canvas.discardActiveObject();

            /** Old code to generate canvas image*/
            let imageGen = new VideoGen(canvas, {frames});
            imageGen.generateImageStatic(!!exportVideo).then(img=>{
                setShowLoading(false);
                // console.log("Canvas ratio: ", canvas.width/canvas.height);
                console.log('img',img)
                onDoneDrawing(img);
            }).catch(e=>{
                if (onFailTacticsBoard) {
                    onFailTacticsBoard();
                    setShowLoading(false);
                }
            });


            /** Old code to generate canvas image*/

            // html2canvas(document.querySelector(".canvas-container"), {
            //     useCORS: true,
            //     allowTaint: true,
            // }).then(function (canvasEl) {
            //     const dataURL = canvasEl.toDataURL({
            //         width: 1920,
            //         height: 1080,
            //         format: 'jpg',
            //         quality: 5,
            //         multiplier: 5
            //     });
            //     setShowLoading(false);
            //
            //     onDoneDrawing(dataURL);
            // })
            //     .catch(function (err) {
            //         if (onFailTacticsBoard) {
            //             onFailTacticsBoard();
            //         }
            //     });
        } else {
            saveCanvas();
        }
    }


    const selectPreviousDrawings = () => {
        if (onShowPreviousDrawings) {
            onShowPreviousDrawings(background);
        }
    }
    const onClose = () => {
        if (onCloseDrawing) {
            onCloseDrawing();
        }
    }
    const percentageIncrease = (num) => {
        return parseFloat((5 / 100) * num)
    }
    const setLineDimension = () => {
        if (setConnect) {
            activeSelectionConnectionLine(canvas)
        } else {
            return
        }
    }

    const setTextDimensionOnSize = (obj) => {
        if (obj.name === "player" || obj.objecttype === "sprite-image" || obj.name === 'player_custom_image') {
            let imgIndex = canvas._objects.findIndex(f=>f.name === 'custom_image_name' && f.ref_id === obj.ref_id);
            if(imgIndex>-1) obj.setTextPosition(canvas._objects[imgIndex],canvas);
        }
    }
    const updateSize = (state) => {
        const obj = canvas.getActiveObject();
        if (obj) {
            if (obj.objecttype !== "arrow_line" && obj.objecttype !== "simple_line" && obj.objecttype !== "curve_line") {
                let sizeX
                let sizeY
                switch (state) {
                    case "increase":
                        sizeX = obj.scaleX + percentageIncrease(obj.scaleX)
                        sizeY = obj.scaleY + percentageIncrease(obj.scaleY)
                        setTextDimensionOnSize(obj)
                        setLineDimension()
                        break;
                    case "decrease":
                        sizeX = obj.scaleX - percentageIncrease(obj.scaleX)
                        sizeY = obj.scaleY - percentageIncrease(obj.scaleY)
                        setTextDimensionOnSize(obj)
                        setLineDimension()
                        break;
                    default:
                        if (obj.name === "drawLine" || obj.name === "shape") {
                            if (obj.objecttype === "star") {
                                obj.scaleToHeight(obj.initialSize);
                                canvas.renderAll()
                                modifiedObject({ target: obj })
                            } else {
                                sizeX = 1
                                sizeY = 1
                            }
                        } else if (obj.name === "player" || obj.name === "image") {
                            obj.scaleToHeight(obj.initialSize);
                            canvas.renderAll()
                            modifiedObject({ target: obj })
                        }
                        break;
                }

                if (sizeX > 0 && sizeY > 0) {
                    obj.set("scaleX", sizeX)
                    obj.set("scaleY", sizeY)
                    canvas.renderAll()
                    modifiedObject({ target: obj })
                }
            }
        }
    }

    const showModal = (txt=false) => {
        let obj = canvas.getActiveObject();
        if (!obj) return
        let objLeft = obj.left*canvas.getZoom();
        let objTop = obj.top*canvas.getZoom();
        if (isMobileView) {
            if (objectStates.line) {
                dispatch(setModalLeft(objLeft + 'px'))
                dispatch(setModalTop(objTop > 170 ? objTop - 150 + 'px' : objTop + obj.height + 20 + 'px'))
            } else if (objectStates.shape) {
                dispatch(setModalLeft(((objLeft - 100) > 0 ? objLeft - 100 : 100) + 'px'))
                dispatch(setModalTop(objTop > 170 ? ((objTop - (obj.height) - 150 > 0 ? objTop - (obj.height) - 150 : 150)) + 'px' : ((objTop + obj.getScaledHeight() + 50) > 0 ? objTop + obj.getScaledHeight() + 50 : 50) + 'px'))
            } else {
                dispatch(setModalLeft(objLeft - 100 + 'px'))
                dispatch(setModalTop(objTop > 170 ? objTop - 180 + 'px' : objTop + 80 + 'px'))
            }
        }
        dispatch(setTextField(txt));
        dispatch(setIsModalOpen(!isModalOpen));
    };
    const SizePopup = ()=> {
        return(
            <div className={'dwn-container'}>
                <Tooltip overlayClassName="tacticsboard_tooltip" title={'increase'}>
                    <Button  onClick={() => updateSize("increase")}   icon={<PlusCircleOutlined fill={isMobileView ? '#595959' : '#fff'} />} />
                </Tooltip>
                <Tooltip overlayClassName="tacticsboard_tooltip" title={'decrease'}>
                    <Button  onClick={() => updateSize("decrease")}  icon={<MinusCircleOutlined fill={isMobileView ? '#595959' : '#fff'} />} />
                </Tooltip>
            </div>
        )
    }
    const duplicateObjectHelper = ()=>{
        const props = {
            canvas, reinitFreeShape, updateUndoStates,moveLine,moveEnd2, movingObject,reinitpath,mouseUp
        }
        duplicateObject(props);
    }
    const changeMod = ()=>{
        setOldVid(!oldVid);
    }
    const DownloadOptions = () => {
        return (
            <div className={'dwn-container'}>
                {exportVideo &&
                <Tooltip overlayClassName="tacticsboard_tooltip" title={'Download Image'}>
                    <Button onClick={downloadCanvasImg} className={`rgt_small_button`} icon={<SvgIcon><DownloadImageIcon fill={isMobileView ? '#595959' : '#fff'}   /></SvgIcon>} />
                </Tooltip>
                }
                <Tooltip overlayClassName="tacticsboard_tooltip" title={'Download'}>
                    <Button onClick={downloadCanvas} disabled={recordingStatus !== "stop"} className={`rgt_small_button`} icon={<SvgIcon className='tacticsBoard_rgt_icon'><DownloadIcon fill={isMobileView ? '#595959' : '#fff'}   /></SvgIcon>} />
                </Tooltip>
            </div>
        );
    }
    const MoreOptions = ()=>{
        return(
            <div className={'dwn-container'}>
                <Tooltip overlayClassName="tacticsboard_tooltip" title={'Download Image'}>
                    <Button onClick={duplicateObjectHelper} className={`rgt_small_button`} icon={<SvgIcon><DuplicateIconEditor   fill={isMobileView ? '#595959' : '#fff'} /></SvgIcon>} />
                </Tooltip>
                {( objectStates.player || objectStates.playerImage || objectStates.sprite) && <Tooltip overlayClassName="tacticsboard_tooltip" title={'Download'}>
                    <Button onClick={() => showModal(true)} disabled={recordingStatus !== "stop"}
                            className={`rgt_small_button`}
                            icon={<SvgIcon className='tacticsBoard_rgt_icon'><TextIconEditor
                                fill={isMobileView ? '#595959' : '#fff'}  /></SvgIcon>}/>
                </Tooltip>}
            </div>
        )
    }
    const TopSection = ()=>{
        return(
            <>
                {(objectStates.objectActive && isMobileView) && <Tooltip overlayClassName="tacticsboard_tooltip" title={'Object Settings'}>
                    <Button  onClick={() => showModal()} className={`rgt_small_button`}  icon={<SvgIcon><MobileSettingsIcon fill={'#595959'}  /></SvgIcon>} >
                        <span style={{fontSize:'6px',marginTop:'0px'}}><FormattedMessage id="general.objectSetting" /></span>
                    </Button>
                </Tooltip>}
                {(objectStates.objectActive && isMobileView) &&
                <Tooltip overlayClassName="tacticsboard_tooltip" title={<FormattedMessage id="general.delete" />}>
                    <Button onClick={deleteCanvasObject} size='default' className={`rgt_small_button`}
                            icon={<SvgIcon><DrawerDelete fill={"#595959"}  /></SvgIcon>} >
                        <span style={{fontSize:'6px',marginTop:'0px'}}><FormattedMessage id="general.delete" /></span>
                    </Button>
                </Tooltip>
                }
                {(objectStates.objectActive && isMobileView) &&
                <Popover content={<SizePopup/>} className="tacticsboard_tooltip" trigger="click">
                    <Button className={`rgt_small_button`}
                            icon={<SizeIconEditorMobile
                                fill={'#000000'}/>}>
                        <span style={{fontSize:'6px',marginTop:'0px'}}><FormattedMessage id="general.resize" /></span>

                    </Button>
                </Popover>
                }
                <>

                    {(objectStates.objectActive && isMobileView) &&
                    <>
                        <Tooltip overlayClassName="tacticsboard_tooltip" title={'Download Image'}>
                            <Button onClick={duplicateObjectHelper} className={`rgt_small_button`} icon={<SvgIcon><DuplicateIconRight   fill={isMobileView ? '#595959' : '#fff'} /></SvgIcon>} >
                                <span style={{fontSize:'6px',marginTop:'0px'}}><FormattedMessage id="general.duplicate" /></span>
                            </Button>
                        </Tooltip>
                        {( objectStates.player || objectStates.playerImage || objectStates.sprite) && <Tooltip overlayClassName="tacticsboard_tooltip" title={'Download'}>
                            <Button onClick={() => showModal(true)} disabled={recordingStatus !== "stop"}
                                    className={`rgt_small_button`}
                                    icon={<SvgIcon className='tacticsBoard_rgt_icon'><TextIconEditorRight
                                        fill={isMobileView ? '#595959' : '#fff'}  /></SvgIcon>}>
                                <span style={{fontSize:'6px',marginTop:'0px'}}><FormattedMessage id="general.text" /></span>

                            </Button>
                        </Tooltip>}
                    </>
                    }

                </>
            </>

        )
    }
    const BottomSection = ()=>{
        return(
            <>
                {!getMobileView() &&
                    <Tooltip overlayClassName="tacticsboard_tooltip" title={'Layers'}>
                        <Button onClick={()=>{dispatch(toggleLayer())}} className={`rgt_small_button`} icon={<SvgIcon><LayersIconEditor fill={isMobileView ? '#595959' : '#fff'} size={45} vb={'0 0 30 30'} /></SvgIcon>} />
                    </Tooltip>
                }
                {exportVideo &&
                <Tooltip overlayClassName="tacticsboard_tooltip" title={'Download Image'}>
                    <Button onClick={downloadCanvasImg} className={`rgt_small_button`} icon={<SvgIcon><DownloadImageIcon fill={isMobileView ? '#595959' : '#fff'}  /></SvgIcon>} />
                </Tooltip>
                }
                <Tooltip overlayClassName="tacticsboard_tooltip" title={'Download'}>
                    <Button onClick={()=>downloadCanvas()} disabled={recordingStatus !== "stop"} className={`rgt_small_button`} icon={<SvgIcon className='tacticsBoard_rgt_icon'><DownloadIcon fill={isMobileView ? '#595959' : '#fff'}   /></SvgIcon>} />
                </Tooltip>
                 <Tooltip overlayClassName="tacticsboard_tooltip" title={'Animation'} >
                    <Button onClick={activeAnimationBoard} disabled={recordingStatus !== "stop"} className={`rgt_small_button`} icon={<SvgIcon className='tacticsBoard_rgt_icon'><VideoSolidIcon fill={isMobileView ? '#595959' : '#fff'}   /></SvgIcon>} />
                </Tooltip>

                <>
                    {
                        !isMobileView && <Button onClick={() => selectPreviousDrawings()} className="tacticsBoard_rgt_btn" type="default"><span className='button_inner_text_dark'><FormattedMessage id='general.previousDrawing'/></span></Button>
                    }
                </>

                <Tooltip overlayClassName="tacticsboard_tooltip" title={'Save'}>
                    <Button onClick={onDone} className="tacticsBoard_rgt_btn disp_none" disabled={recordingStatus !== "stop"} type="default" icon={<SaveIcon   fill={isMobileView ? '#595959' : 'blue'} />}><span className='button_inner_text'><FormattedMessage id='general.save'/></span></Button>
                    <Button onClick={onDone} disabled={recordingStatus !== "stop"} className={`rgt_small_button disp_res_none`}>
                        <span style={{ color: 'blue',fontSize:getMobileView()?'10px':'16px'}}><FormattedMessage id='general.save'/></span>
                    </Button>
                </Tooltip>
                <Tooltip overlayClassName="tacticsboard_tooltip" title={'close'} >
                    <Button onClick={onClose} disabled={recordingStatus !== "stop"} className={`rgt_small_button disp_none`} icon={<SvgIcon   className='tacticsBoard_rgt_icon'><CloseIcon fill={isMobileView ? '#595959' : '#fff'} /></SvgIcon>} />
                    <Button onClick={onClose} disabled={recordingStatus !== "stop"} className={`rgt_small_button disp_res_none`}>
                        <span style={{ color: 'red', fontSize:getMobileView()?'10px':'16px' }}><FormattedMessage id='general.exit'/></span>
                    </Button>
                </Tooltip>
            </>
        )
    }
    return (
        <>
            {(showLoading || (recordingStatus !== "stop" && isDownloadingVideo)) && <Loading downloadingProgress={downloadingProgress} />}
            <div className={"tacticsboard__header_rgt_container"} >
                {
                    isMobileView ?<div className='top-section'>
                        <TopSection/>
                    </div>:<TopSection/>
                }
                {
                    isMobileView ?<div className='btm-section'>
                        <BottomSection/>
                    </div>:<BottomSection/>
                }
            </div>
        </>
    )
}

export default TacticsBoardRightSide;