import {fabric} from "fabric";
import {colorHex, defaultTime, setPlayerNameDim} from "./utils";
import {setLayers} from "./layring";
import {copyToClipboard, setLoader} from "../actions";
import {v4 as uuidv4} from "uuid";

export const getFabricImageFromURL = (path, callback, errorCallback) => {
    const image = new Image();
    let url = path;
    image.crossOrigin = 'Anonymous';
    image.onload = () => {
        const fabricImage = new fabric.Image(image,{});
        callback(fabricImage);
    };

    if (path.includes('?')) {
        url += `&temp-cache-${uuidv4().substring(0, 8)}`;
    } else {
        url += `?temp-cache-${uuidv4().substring(0, 8)}`;
    }

    fetch(
        path.includes('base64') ? path : url,
        {
            method: 'GET',
            mode: 'cors',
            referrerPolicy: 'strict-origin-when-cross-origin',
            headers: {
                Origin: window.location.origin,
            },
        },
    ).then((res) => {
        image.src = res.url;
    }).catch((error) => {
        console.error(error);
    });
};

export const handleImages = (type, src, left, top, scaleProps,customProps,isAnimate,formation,fn,objColor,canvas,setObjectPadding,isMobileView,animationState,activeFrameVal,updateUndoRedoState,isDropped,dispatch)=>{
    const uuid = require("uuid");
    const id = uuid.v4();
    getFabricImageFromURL(src,(imgInstance)=>{
        imgInstance.set({
            crossOrigin: "Anonymous",
            ref_id: id,
            left: left,
            top: top,
            originX: 'center',
            originY: 'center',
            name: "image",
            objecttype: type,
            opacity: 1,
            is_animation: isAnimate,
            formation: formation.action,
            team:formation.team,
            parentFrame: animationState ? activeFrameVal.data_num : null,
            time: defaultTime
        })
        let scaleVal = scaleProps.height / 1.5
        if (type) {
            switch (type) {
                case "ball":
                    isMobileView ? scaleVal = ((scaleProps.height) * 0.9) : scaleVal = ((scaleProps.height / 2) * 0.9); // Added factor to ball
                    setObjectPadding(imgInstance, 8, 2)
                    break;
                case "goal":
                    isMobileView ? scaleVal = scaleProps.height / .2 : scaleVal = scaleProps.height / .5
                    imgInstance.set('fadeFlag', 'no-fade-out')
                    break;
                case "player":
                    isMobileView ? scaleVal = scaleProps.height / 1.5 : scaleVal = scaleProps.height / 2
                    setObjectPadding(imgInstance, 5, 0)
                    break;
                case "opponent":
                    isMobileView ? scaleVal = scaleProps.height / .5 : scaleVal = scaleProps.height
                    setObjectPadding(imgInstance, 5, 2)
                    imgInstance.set('fadeFlag', 'no-fade-out')
                    break;
                case "pole":
                    isMobileView ? scaleVal = scaleProps.height / .5 : scaleVal = scaleProps.height
                    imgInstance.set('fadeFlag', 'no-fade-out')
                    break;
                case "flag":
                    isMobileView ? scaleVal = scaleProps.height / .5 : scaleVal = scaleProps.height
                    setObjectPadding(imgInstance, 5, 2)
                    imgInstance.set('fadeFlag', 'no-fade-out')
                    break;
                case "cone":
                    isMobileView ? scaleVal = scaleProps.height : scaleVal = scaleProps.height / 1.5
                    setObjectPadding(imgInstance, 5, 2)
                    imgInstance.set('fadeFlag', 'no-fade-out')
                    break;
                case "obstacle":
                    isMobileView ? scaleVal = scaleProps.height : scaleVal = scaleProps.height / 1.5
                    setObjectPadding(imgInstance, 5, 2)
                    imgInstance.set('fadeFlag', 'no-fade-out')
                    break;
                case "hoop":
                    isMobileView ? scaleVal = scaleProps.height : scaleVal = scaleProps.height / 1.5
                    setObjectPadding(imgInstance, 5, 2)
                    imgInstance.set('fadeFlag', 'no-fade-out')
                    break;
                case "dot":
                    isMobileView ? scaleVal = scaleProps.height : scaleVal = scaleProps.height / 1.5
                    setObjectPadding(imgInstance, 5, 2)
                    imgInstance.set('fadeFlag', 'no-fade-out')
                    break;
                case "cross":
                    isMobileView ? scaleVal = scaleProps.height : scaleVal = scaleProps.height / 1.5
                    setObjectPadding(imgInstance, 5, 2)
                    imgInstance.set('fadeFlag', 'no-fade-out')
                    break;
                case "equipments":
                    isMobileView ? scaleVal = scaleProps.height / 1.2 : scaleVal = scaleProps.height / 1.5
                    imgInstance.set('fadeFlag', 'no-fade-out')
                    break
                default:
                    isMobileView ? scaleVal = scaleProps.height / 1.2 : scaleVal = scaleProps.height / 2
                    break;
            }
        }
        if (customProps?.length && customProps[0] !== '0') {
            imgInstance.scaleToHeight(scaleVal * customProps[0]);
        } else
            imgInstance.scaleToHeight(scaleVal);
        if (customProps?.length && customProps[1]) imgInstance.imageFileName = customProps[1];
        else if (formation.action) imgInstance.imageFileName = formation.imageFileName
        imgInstance.initialSize = scaleVal;
        if (objColor === 'Goali') objColor = 'LightBlue'
        let actColor = colorHex[objColor]

        let textColor = '#fff';
        if (actColor === "#fff" || actColor === "#F7CF00") {
            textColor = '#00000'
        }

        var nameText = new fabric.IText('', {
            textAlign: 'center', //"left", "center", "right" or "justify".
            fill: textColor,
            hasBg: false,
            fillStyle: actColor === '#fff' ? '#ffffff95' : actColor + 95,
            fontSize: fn,
            fontWeight: 'bold',
            ref_id: id,
            hoverCursor:'text',
            originX: 'center',
            originY: 'top',
            hasControls: false,
            hasBorders: false,
            name: 'custom_image_name',
            fontFamily: 'sans-serif',
            evented: true,
            formation: formation
        });
        if (type === 'sprite-image') {
            if(!isDropped) canvas.add(nameText);
            imgInstance.set({nameInstance:nameText})
            imgInstance.set({position:'top'});
            imgInstance.set('nameText', {
                fillStyle: nameText.fillStyle,
                text: nameText.text,
                fontSize: nameText.fontSize,
                fontWeight: nameText.fontWeight,
                fill: nameText.fill,
                textLength: nameText.textLength
            });
            imgInstance.setTextPosition(nameText,canvas)
        }
        if(!isDropped){
            canvas.add(imgInstance);
            formation.resolve && formation.resolve()
        }
        else{
            dispatch(copyToClipboard(imgInstance));
            canvas.discardActiveObject();
            canvas.defaultCursor = 'crosshair';
            canvas.selection = false;
        }
        if (formation.index == 10) {
            setTimeout(() => {
                updateUndoRedoState(imgInstance, 'added')
            }, 100)

        }
        canvas.discardActiveObject();
        dispatch(setLoader(false))
        if (!formation.action && !isDropped) canvas.setActiveObject(imgInstance);
    })



}