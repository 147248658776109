import React, {useEffect, useState} from 'react';
import '../components/tools/tools.scss';
import {clearActiveTab, clearClipBoard, setActiveTab} from "../actions";
import {useDispatch, useSelector} from "react-redux";

const TacticsBoardToolsIconMobile = ({ image, alt, hscale, classList, id, dataType ,customStyle={}, imgStyle}) => {
    const activeTab = useSelector((state)=>state.activeTab).tab;
    const canvas = useSelector(state => state.canvas);
    const dispatch = useDispatch()

    const setTab = (cTab,e)=>{
        if(activeTab === id){
            e.stopPropagation();
            canvas.selection = true;
            canvas.defaultCursor = 'default';
            dispatch(clearActiveTab());
            dispatch(clearClipBoard());
            return;
        }
        dispatch(setActiveTab(cTab));
    }

    return (
        <div datatype={dataType} style={customStyle.div} onClick={(e)=>setTab(id,e)}  className={classList + " tacticsboard__feature_icon-p" + `${(activeTab === id) ?' prim':''}`+' tacticsboard__feature_icon'} id={id}>
            <img hscale={hscale} style={customStyle.img} className={imgStyle + " custom-drawer-images"} src={image} alt={alt} />
        </div>
    )
}

export default TacticsBoardToolsIconMobile;