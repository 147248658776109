export     const updateCanvasResize = (obj,setObjectPadding) => {
    switch (obj.name) {
        case "image":
            switch (obj.objecttype) {
                case "ball":
                    setObjectPadding(obj, 8, 2)
                    break;
                case "player":
                    setObjectPadding(obj, 5, 0)
                    break;
                case "opponent":
                case "pole":
                case "flag":
                case "cone":
                case "obstacle":
                case "hoop":
                case "dot":
                case "cross":
                case "square1":
                case "square2":
                case "p0":
                case "p1":
                case "p2":
                    setObjectPadding(obj, 5, 2)
                    break;
            }
            break;
        case "drawLine":
            setObjectPadding(obj, 10, 0)
            break;
        case "arrow_line":
        case "curve_line":
        case "simple_line":
            setObjectPadding(obj, 20, 10)
            break;
    }
}