import {fabric} from "fabric";
import * as html2canvas from "html2canvas";
import player from "../reducers/player";
import {copyToClipboard} from "../actions";
import {getFabricImageFromURL} from "./addImage";

let defaultColor = 'White';

export const defaultTime = 1500;

export var BASE_URL_ASSETS = "https://easycoach.s3.eu-central-1.amazonaws.com/animation";

export const rotateIcon = "https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/icons/rotateIcon.png";
export const colorName = {
    '#003E60': 'DarkBlue',
    '#35ACDD': 'LightBlue',
    '#F7CF00': 'Yellow',
    '#C40E12': 'Red',
    '#fff': 'White',
    '#23803C': 'Green',
    '#000000': 'Black'
}
export const colorHex = {
    DarkBlue: '#003E60',
    LightBlue: '#35ACDD',
    Yellow: '#F7CF00',
    Red: '#C40E12',
    White: '#fff',
    Green: '#23803C',
    Black: '#000000'
}

export const tacticsBoardToolsNumber = (number, color = '#000000',isNumber=false, layer=false) => {
    let playerColor;
    if (color === '#fff' || color === '#F7CF00') {
        playerColor = 'black'
    } else {
        playerColor = 'white'
    }
    const fontSize = (isNumber && !layer) ? '70px' : '45px';
    let strokeWidth = layer ? '6' : '12';
    let radius = layer?42:62;

    const svgString = `<svg width='128' height='128' viewBox='0 0 128 128' xmlns='http://www.w3.org/2000/svg' version='1.1' >
        <circle cx='64' cy='64' r='${radius}' fill='${color}' stroke='rgba(255, 255, 255, 0.9)' stroke-width='${strokeWidth}'/>
        <text text-anchor='middle' x='50%' y='50%' dy='.35em' text-align="center" line-height="24px" font-weight="400" font-family='Bahnschrift' font-size='${fontSize}' fill='${playerColor}'>${number}</text>
                        </svg>`;
    return `data:image/svg+xml;base64,${btoa(svgString)}`;
}

const getLeft = (number)=>{
    if(number.length === 2) return "10.9797"
    else if(number.length > 2) return "8.9797"
    else return "12.9797"
}
export const getColorScheme = (color) => {
    switch (color) {
        case '#003E60':
        return {
            shirt_back:'#022336',
            arm_strips:'#ffffff',
            arm_strips_bottom:'#022336',
            bottom_strips:'#022336',
            colStrip1:'#010849',
            colStrip2:'#010849',
            colStrip3:'#010849',
            colStrip4:'#010849',
        }
        case '#35ACDD':
            return {
                shirt_back:'#33749c',
                arm_strips:'#ffffff',
                arm_strips_bottom:'#022336',
                bottom_strips:'#ffffff',
                colStrip1:'#33749c',
                colStrip2:'#33749c',
                colStrip3:'#33749c',
                colStrip4:'#33749c',
            }
        case '#F7CF00':
            return {
                shirt_back:'#776103',
                arm_strips:'#ffffff',
                arm_strips_bottom:'#E5C60F',
                bottom_strips:'#F2B906',
                colStrip1:'#817008',
                colStrip2:'#DCBE0E',
                colStrip3:'#BBA10C',
                colStrip4:'#DCBE0E',
            }
        case '#C40E12':
            return {
                shirt_back:'#AE0000',
                arm_strips:'#ffffff',
                arm_strips_bottom:'#A30606',
                bottom_strips:'#BD0101',
                colStrip1:'#490101',
                colStrip2:'#C30000',
                colStrip3:'#8F0505',
                colStrip4:'#C30000',
            }
        case '#fff':
            return {
                shirt_back:'#D0D0D0',
                arm_strips:'#ffffff',
                arm_strips_bottom:'#232323',
                bottom_strips:'#242424',
                colStrip1:'#404040',
                colStrip2:'#BEBEBE',
                colStrip3:'#989898',
                colStrip4:'#BEBEBE',
            }
        case '#23803C':
            return {
                shirt_back:'#185c2a',
                arm_strips:'#ffffff',
                arm_strips_bottom:'#38A306',
                bottom_strips:'#3CBD01',
                colStrip1:'#174901',
                colStrip2:'#3EC300',
                colStrip3:'#318F05',
                colStrip4:'#3EC300',
            }
        case '#000000':
            return {
                shirt_back:'#3D3D3D',
                arm_strips:'#ffffff',
                arm_strips_bottom:'#1C1C1C',
                bottom_strips:'#FFFFFF',
                colStrip1:'#656565',
                colStrip2:'#E6E6E6',
                colStrip3:'#ACACAC',
                colStrip4:'#E6E6E6',
            }
        default:
            return
    }
}

export const tacticsBoardToolsShirts = (number, color = '#000000',isNumber=false) => {
    let playerColor;
    const {shirt_back,arm_strips,arm_strips_bottom, bottom_strips, colStrip1, colStrip2, colStrip3, colStrip4} = getColorScheme(color);
    if (color === '#fff' || color === '#F7CF00') {
        playerColor = 'black'
    } else {
        playerColor = 'white'
    }
    const fontSize = '12px';

    const svgString = `
    <svg width="32" height="37" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.64488 13.3251C7.60361 13.449 7.47153 13.515 7.35597 13.4737L3.64134 12.2768C3.51752 12.2355 3.45148 12.1034 3.49275 11.9879L3.64959 11.4761L7.80172 12.8133L7.64488 13.3251Z" id="arm_strips_bottom" fill='${arm_strips_bottom}'/>
<path d="M8.38163 28.9517L8.25781 29.8184C8.25781 29.8184 10.9406 30.908 15.9677 30.908C20.9949 30.908 23.7354 29.8184 23.7354 29.8184L23.6116 28.9517H8.38163Z" id="bottom_strips" fill="${bottom_strips}"/>
<g filter="url(#filter0_d_17556_48142)">
<path d="M27.4095 7.82766C26.939 5.44225 25.354 4.55878 25.354 4.55878L19.4024 2.13189L18.5934 1.73567L16.0262 1.5293L13.4094 1.74392L12.6005 2.14015L6.64881 4.56704C6.64881 4.56704 5.07216 5.4505 4.59338 7.83591L3.65234 11.4845L7.80448 12.8218L8.28325 11.2534C8.28325 11.2534 10.537 16.1567 8.39056 28.9598C8.39056 28.9598 10.9991 30.1948 15.9767 30.1948C20.9543 30.1948 23.6205 28.9598 23.6205 28.9598C21.4741 16.1567 23.7279 11.2534 23.7279 11.2534L24.2066 12.8218L28.3588 11.4845L27.4095 7.82766Z" fill='${color}'/>
</g>
<path d="M19.3996 3.00689L18.8382 1.73567L16.0234 1.5293L13.159 1.73567L12.5977 3.00689L15.1814 5.68968H16.8158L19.3996 3.00689Z" id="shirt_back" fill="${shirt_back}"/>
<path d="M24.3522 13.3251C24.3934 13.449 24.5255 13.515 24.6411 13.4737L28.3557 12.2768C28.4795 12.2355 28.5456 12.1034 28.5043 11.9879L28.3474 11.4761L24.1953 12.8133L24.3522 13.3251Z"  id="arm_strips_bottom" fill='${arm_strips_bottom}'/>
<path d="M19.1056 2.00785C19.1056 2.00785 17.8839 1.85101 16.0265 1.85101C14.1692 1.85101 12.9062 2.00785 12.9062 2.00785L13.4015 1.45478C13.4015 1.45478 14.293 1.31445 16.0265 1.31445C17.7683 1.31445 18.6185 1.45478 18.6185 1.45478L19.1056 2.00785Z" id=" colStrip1" fill="${colStrip1}"/>
<path d="M18.6154 1.44678C18.6154 1.44678 19.9279 2.48687 19.2428 3.56824C18.7113 4.64961 16.3536 6.38311 16.3536 6.38311L16.0235 5.53287C16.0152 5.53287 19.7711 3.3206 18.6154 1.44678Z" id=" colStrip2" fill="${colStrip2}"/>
<path d="M13.3913 1.44678C13.3913 1.44678 12.0706 2.48687 12.7557 3.56824C13.2873 4.64961 15.6449 6.38311 15.6449 6.38311L16.0163 5.53287C16.0163 5.53287 12.2357 3.3206 13.3913 1.44678Z" id=" colStrip3" fill="${colStrip3}"/>
<path d="M7.79327 12.851L3.64453 11.4942L3.81861 10.8286L5.88427 11.4942L8.00421 12.1598L7.79327 12.851Z" class="arm_strips" fill="${arm_strips}"/>
<path d="M24.2067 12.851L28.3555 11.4942L28.1814 10.8286L26.1157 11.4942L23.9958 12.1598L24.2067 12.851Z" class="arm_strips" fill="${arm_strips}"/>
<path d="M15.854 6.74974C15.8076 6.78339 15.7425 6.77232 15.7098 6.72519L15.2635 6.08221L16.0273 5.53223L16.2524 5.95868L16.4446 6.32198L15.854 6.74974Z" id=" colStrip4" fill="${colStrip4}" />
 <text text-anchor='middle' x='50%' y='40%' dy='.35em' text-align="center" line-height="24px" font-weight="400" font-family='Bahnschrift' font-size='${fontSize}' fill='${playerColor}'>${number}</text>
<defs>
<filter id="filter0_d_17556_48142" x="1.09234" y="1.5293" width="29.827" height="35.2255" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="1.28"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_17556_48142"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_17556_48142" result="shape"/>
</filter>
</defs>
</svg>
    `
    return `data:image/svg+xml;base64,${btoa(svgString)}`;
}

export const returnObjectOfObject = (array, ref_id, name) => {
    for (var i = 0; i < array._objects.length; i++) {
        var obj = array._objects[i]
        if (obj.ref_id === ref_id && obj.name === name) {
            return obj
        }
    }
}
export const hexToRgbA = (hex,alpha)=>{
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        let tempHexArr = hex.split('').filter(o=> o!=='#');
        hex = tempHexArr.join('');
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }

        var r = parseInt(hex.substring(0,2), 16),
            g = parseInt(hex.substring(2,4), 16),
            b = parseInt(hex.substring(4,6), 16);

        /* Backward compatibility for whole number based opacity values. */

        return 'rgba('+r+','+g+','+b+','+alpha+')';
    }
    else{
        hex = rgba2hex(hex,alpha);
        let tempHexArr = hex.split('').filter(o=> o!=='#');
        hex = tempHexArr.join('');
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }

        var r = parseInt(hex.substring(0,2), 16),
            g = parseInt(hex.substring(2,4), 16),
            b = parseInt(hex.substring(4,6), 16);

        /* Backward compatibility for whole number based opacity values. */


        return 'rgba('+r+','+g+','+b+','+alpha+')';

    }
}
export const rgba2hex = (rgba) => {
    rgba = rgba.match(
        /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
    );
    let newVal = rgba && rgba.length === 4
        ? "#" +
        ("0" + parseInt(rgba[1], 10).toString(16)).slice(-2) +
        ("0" + parseInt(rgba[2], 10).toString(16)).slice(-2) +
        ("0" + parseInt(rgba[3], 10).toString(16)).slice(-2)
        : "";
    return newVal
}

export const shadow = new fabric.Shadow({
    color: 'orange',
    blur: 6,
    offsetX: 0,
    offsetY: 0,
    affectStroke: true,
});

export const drawsObjsWithColor = (defaultColor = 'white')=> {
    return {
        simple_solid: defaultColor,
        curvy_solid: defaultColor,
        curvyarrow_solid: defaultColor,
        arrow_solid: defaultColor,
        simple_dashed: defaultColor,
        curvy_dashed: defaultColor,
        curvyarrow_dashed: defaultColor,
        arrow_dashed: defaultColor,
        arrow_zigzag: defaultColor,
        draw_simple: defaultColor,
        draw_simple_dashed: defaultColor,
        draw_arrow: defaultColor,
        draw_arrow_dashed: defaultColor,
        draw_block: defaultColor,
        draw_block_dashed: defaultColor,
        square_outlined: defaultColor,
        square_solid_fill: '#35ACDD',
        square_solid_border: '#C40E12',
        ellipse_outlined: defaultColor,
        ellipse_solid: defaultColor,
        ellipse_solid_fill: '#35ACDD',
        ellipse_solid_border: '#C40E12',
        star_outlined: defaultColor,
        star_solid: defaultColor,
        oval_outlined: defaultColor,
        oval_solid: defaultColor,
        oval_solid_fill: '#35ACDD',
        oval_solid_border: '#C40E12',
        free_shape_fill: '#35ACDD',
        free_shape_border: '#C40E12',
        text: defaultColor
    }
}

export const equipmentsObjsColor = (defaultColor = 'Red') => {
    return{
        SG: defaultColor,
        Stand: defaultColor,
        AngleStand: defaultColor,
        DummyStand: defaultColor,
        'Cones-T': defaultColor,
        'Cones-Flat': defaultColor,
        'Cones-Long': defaultColor,
        'Cones-TopL': defaultColor,
    }
}
export const getFormations = {
    'p1': {
        'goali': 'B0000', 'player': 'A0000', 'f1': {
            'gk': {left: 0.51, top: 0.16},
            'pl1': {left: 0.69, top: 0.34},
            'pl2': {left: 0.51, top: 0.34},
            'pl3': {left: 0.32, top: 0.34},
            'pl4': {left: 0.23, top: 0.57},
            'pl5': {left: 0.37, top: 0.57},
            'pl6': {left: 0.51, top: 0.57},
            'pl7': {left: 0.65, top: 0.57},
            'pl8': {left: 0.79, top: 0.57},
            'pl9': {left: 0.59, top: 0.80},
            'pl10': {left: 0.42, top: 0.80}
        },
        'f2': {
            'gk': {left: 0.51, top: 0.16},
            'pl1': {left: 0.69, top: 0.34},
            'pl2': {left: 0.56, top: 0.34},
            'pl3': {left: 0.44, top: 0.34},
            'pl4': {left: 0.32, top: 0.34},
            'pl5': {left: 0.67, top: 0.57},
            'pl6': {left: 0.51, top: 0.57},
            'pl7': {left: 0.34, top: 0.57},
            'pl8': {left: 0.32, top: 0.80},
            'pl9': {left: 0.51, top: 0.80},
            'pl10': {left: 0.69, top: 0.80}
        },
        'f3': {
            'gk': {left: 0.51, top: 0.16},
            'pl1': {left: 0.69, top: 0.34},
            'pl2': {left: 0.56, top: 0.34},
            'pl3': {left: 0.44, top: 0.34},
            'pl4': {left: 0.32, top: 0.34},
            'pl5': {left: 0.69, top: 0.57},
            'pl6': {left: 0.56, top: 0.57},
            'pl7': {left: 0.44, top: 0.57},
            'pl8': {left: 0.32, top: 0.57},
            'pl9': {left: 0.59, top: 0.80},
            'pl10': {left: 0.42, top: 0.80}
        },
        'f4': {
            'gk': {left: 0.51, top: 0.16},
            'pl1': {left: 0.69, top: 0.34},
            'pl2': {left: 0.56, top: 0.34},
            'pl3': {left: 0.44, top: 0.34},
            'pl4': {left: 0.32, top: 0.34},
            'pl5': {left: 0.23, top: 0.57},
            'pl6': {left: 0.37, top: 0.57},
            'pl7': {left: 0.51, top: 0.57},
            'pl8': {left: 0.65, top: 0.57},
            'pl9': {left: 0.79, top: 0.57},
            'pl10': {left: 0.51, top: 0.80}
        },
        'f5': {
            'gk': {left: 0.51, top: 0.16},
            "pl1": {
                "left": 0.69,
                "top": 0.34
            },
            "pl2": {
                "left": 0.5,
                "top": 0.34
            },
            "pl3": {
                "left": 0.31,
                "top": 0.34
            },
            "pl4": {
                "left": 0.5,
                "top": 0.49
            },
            "pl5": {
                "left": 0.7,
                "top": 0.65
            },
            "pl6": {
                "left": 0.5,
                "top": 0.65
            },
            "pl7": {
                "left": 0.32,
                "top": 0.65
            },
            "pl8": {
                "left": 0.5,
                "top": 0.82
            }
        },
        'f6': {
            'gk': {left: 0.51, top: 0.16},

            "pl1": {
                "left": 0.7,
                "top": 0.47
            },
            "pl2": {
                "left": 0.51,
                "top": 0.42
            },
            "pl3": {
                "left": 0.28,
                "top": 0.47
            },
            "pl4": {
                "left": 0.73,
                "top": 0.64
            },
            "pl5": {
                "left": 0.51,
                "top": 0.57
            },
            "pl6": {
                "left": 0.25,
                "top": 0.63
            },
            "pl7": {
                "left": 0.6,
                "top": 0.77
            },
            "pl8": {
                "left": 0.4,
                "top": 0.76
            }
        },
        'f7': {
            'gk': {left: 0.51, top: 0.16},
            "pl1": {
                "left": 0.6,
                "top": 0.35
            },
            "pl2": {
                "left": 0.41,
                "top": 0.35
            },
            "pl3": {
                "left": 0.78,
                "top": 0.51
            },
            "pl4": {
                "left": 0.5,
                "top": 0.51
            },
            "pl5": {
                "left": 0.26,
                "top": 0.5
            },
            "pl6": {
                "left": 0.61,
                "top": 0.66
            },
            "pl7": {
                "left": 0.39,
                "top": 0.65
            },
            "pl8": {
                "left": 0.5,
                "top": 0.81
            }
        },
        'f8': {
            'gk': {left: 0.51, top: 0.16},
            "pl1": {
                "left": 0.69,
                "top": 0.34
            },
            "pl2": {
                "left": 0.5,
                "top": 0.34
            },
            "pl3": {
                "left": 0.31,
                "top": 0.34
            },
            "pl4": {
                "left": 0.63,
                "top": 0.5
            },
            "pl5": {
                "left": 0.38,
                "top": 0.5
            },
            "pl6": {
                "left": 0.7,
                "top": 0.66
            },
            "pl7": {
                "left": 0.32,
                "top": 0.66
            },
            "pl8": {
                "left": 0.5,
                "top": 0.82
            }
        },
    },
    'p2': {
        'goali': 'B0000', 'player': 'A0000', 'f1': {
            'gk': {left: 0.50, top: 0.09},
            'pl1': {left: 0.61, top: 0.22},
            'pl2': {left: 0.50, top: 0.22},
            'pl3': {left: 0.39, top: 0.22},
            'pl4': {left: 0.30, top: 0.36},
            'pl5': {left: 0.40, top: 0.36},
            'pl6': {left: 0.50, top: 0.36},
            'pl7': {left: 0.60, top: 0.36},
            'pl8': {left: 0.70, top: 0.36},
            'pl9': {left: 0.55, top: 0.45},
            'pl10': {left: 0.45, top: 0.45}
        },
        'f2': {
            'gk': {left: 0.50, top: 0.09},
            'pl1': {left: 0.61, top: 0.22},
            'pl2': {left: 0.54, top: 0.22},
            'pl3': {left: 0.46, top: 0.22},
            'pl4': {left: 0.39, top: 0.22},
            'pl5': {left: 0.41, top: 0.35},
            'pl6': {left: 0.50, top: 0.35},
            'pl7': {left: 0.59, top: 0.35},
            'pl8': {left: 0.39, top: 0.45},
            'pl9': {left: 0.50, top: 0.45},
            'pl10': {left: 0.61, top: 0.45}
        },
        'f3': {
            'gk': {left: 0.50, top: 0.09},
            'pl1': {left: 0.61, top: 0.22},
            'pl2': {left: 0.54, top: 0.22},
            'pl3': {left: 0.46, top: 0.22},
            'pl4': {left: 0.39, top: 0.22},
            'pl5': {left: 0.61, top: 0.35},
            'pl6': {left: 0.54, top: 0.35},
            'pl7': {left: 0.46, top: 0.35},
            'pl8': {left: 0.39, top: 0.35},
            'pl9': {left: 0.55, top: 0.45},
            'pl10': {left: 0.45, top: 0.45}
        },
        'f4': {
            'gk': {left: 0.50, top: 0.09},
            'pl1': {left: 0.61, top: 0.22},
            'pl2': {left: 0.54, top: 0.22},
            'pl3': {left: 0.46, top: 0.22},
            'pl4': {left: 0.39, top: 0.22},
            'pl5': {left: 0.30, top: 0.36},
            'pl6': {left: 0.40, top: 0.36},
            'pl7': {left: 0.50, top: 0.36},
            'pl8': {left: 0.60, top: 0.36},
            'pl9': {left: 0.70, top: 0.36},
            'pl10': {left: 0.50, top: 0.45}
        },
        'f5': {
            'gk': {left: 0.50, top: 0.09},
            "pl1": {
                "left": 0.61,
                "top": 0.21
            },
            "pl2": {
                "left": 0.5,
                "top": 0.21
            },
            "pl3": {
                "left": 0.39,
                "top": 0.21
            },
            "pl4": {
                "left": 0.5,
                "top": 0.3
            },
            "pl5": {
                "left": 0.61,
                "top": 0.4
            },
            "pl6": {
                "left": 0.5,
                "top": 0.4
            },
            "pl7": {
                "left": 0.4,
                "top": 0.4
            },
            "pl8": {
                "left": 0.5,
                "top": 0.48
            }
        },
        'f6': {
            'gk': {left: 0.50, top: 0.09},
            "pl1": {
                "left": 0.62,
                "top": 0.28
            },
            "pl2": {
                "left": 0.5,
                "top": 0.25
            },
            "pl3": {
                "left": 0.36,
                "top": 0.28
            },
            "pl4": {
                "left": 0.63,
                "top": 0.37
            },
            "pl5": {
                "left": 0.5,
                "top": 0.34
            },
            "pl6": {
                "left": 0.34,
                "top": 0.37
            },
            "pl7": {
                "left": 0.57,
                "top": 0.46
            },
            "pl8": {
                "left": 0.43,
                "top": 0.46
            }
        },
        'f7': {
            'gk': {left: 0.50, top: 0.09},
            "pl1": {
                "left": 0.58,
                "top": 0.22
            },
            "pl2": {
                "left": 0.43,
                "top": 0.22
            },
            "pl3": {
                "left": 0.67,
                "top": 0.3
            },
            "pl4": {
                "left": 0.51,
                "top": 0.3
            },
            "pl5": {
                "left": 0.35,
                "top": 0.3
            },
            "pl6": {
                "left": 0.58,
                "top": 0.38
            },
            "pl7": {
                "left": 0.43,
                "top": 0.38
            },
            "pl8": {
                "left": 0.5,
                "top": 0.46
            }
        },
        'f8': {
            'gk': {left: 0.50, top: 0.09},
            "pl1": {
                "left": 0.61,
                "top": 0.21
            },
            "pl2": {
                "left": 0.5,
                "top": 0.21
            },
            "pl3": {
                "left": 0.39,
                "top": 0.21
            },
            "pl4": {
                "left": 0.57,
                "top": 0.31
            },
            "pl5": {
                "left": 0.44,
                "top": 0.31
            },
            "pl6": {
                "left": 0.61,
                "top": 0.39
            },
            "pl7": {
                "left": 0.39,
                "top": 0.39
            },
            "pl8": {
                "left": 0.5,
                "top": 0.48
            }
        },
    },
    'p3': {
        'goali': 'B0004', 'player': 'A0004', 'f1':
            {
                'gk': {left: 0.50 , top: 0.80 },
                'pl1': {left: 0.62, top: 0.62},
                'pl2': {left: 0.50, top: 0.62},
                'pl3': {left: 0.38, top: 0.62},
                'pl4': {left: 0.23, top: 0.46},
                'pl5': {left: 0.37, top: 0.46},
                'pl6': {left: 0.51, top: 0.46},
                'pl7': {left: 0.65, top: 0.46},
                'pl8': {left: 0.79, top: 0.46},
                'pl9': {left: 0.60, top: 0.35},
                'pl10': {left: 0.40, top: 0.35}
            },
        'f2': {
            'gk': {left: 0.50 , top: 0.80 },
            'pl1': {left: 0.29, top: 0.62},
            'pl2': {left: 0.43, top: 0.62},
            'pl3': {left: 0.57, top: 0.62},
            'pl4': {left: 0.71, top: 0.62},
            'pl5': {left: 0.36, top: 0.46},
            'pl6': {left: 0.50, top: 0.46},
            'pl7': {left: 0.64, top: 0.46},
            'pl8': {left: 0.33, top: 0.35},
            'pl9': {left: 0.50, top: 0.35},
            'pl10': {left: 0.68, top: 0.35}
        },
        'f3': {
            'gk': {left: 0.50 , top: 0.80 },
            'pl1': {left: 0.29, top: 0.62},
            'pl2': {left: 0.43, top: 0.62},
            'pl3': {left: 0.57, top: 0.62},
            'pl4': {left: 0.71, top: 0.62},
            'pl5': {left: 0.29, top: 0.46},
            'pl6': {left: 0.43, top: 0.46},
            'pl7': {left: 0.57, top: 0.46},
            'pl8': {left: 0.71, top: 0.46},
            'pl9': {left: 0.60, top: 0.35},
            'pl10': {left: 0.40, top: 0.35}
        },
        'f4': {
            'gk': {left: 0.50 , top: 0.80 },
            'pl1': {left: 0.29, top: 0.62},
            'pl2': {left: 0.43, top: 0.62},
            'pl3': {left: 0.57, top: 0.62},
            'pl4': {left: 0.71, top: 0.62},
            'pl5': {left: 0.23, top: 0.46},
            'pl6': {left: 0.37, top: 0.46},
            'pl7': {left: 0.51, top: 0.46},
            'pl8': {left: 0.65, top: 0.46},
            'pl9': {left: 0.79, top: 0.46},
            'pl10': {left: 0.50 , top: 0.35}
        },
        'f5': {
            'gk': {left: 0.50 , top: 0.80 },
            "pl1": {
                "left": 0.5,
                "top": 0.63
            },
            "pl2": {
                "left": 0.3,
                "top": 0.46
            },
            "pl3": {
                "left": 0.71,
                "top": 0.62
            },
            "pl4": {
                "left": 0.29,
                "top": 0.62
            },
            "pl5": {
                "left": 0.7,
                "top": 0.46
            },
            "pl6": {
                "left": 0.5,
                "top": 0.36
            },
            "pl7": {
                "left": 0.5,
                "top": 0.46
            },
            "pl8": {
                "left": 0.5,
                "top": 0.54
            }
        },
        'f6': {
            'gk': {left: 0.50 , top: 0.80 },
            "pl1": {
                "left": 0.73,
                "top": 0.49
            },
            "pl2": {
                "left": 0.71,
                "top": 0.59
            },
            "pl3": {
                "left": 0.4,
                "top": 0.41
            },
            "pl4": {
                "left": 0.61,
                "top": 0.41
            },
            "pl5": {
                "left": 0.26,
                "top": 0.48
            },
            "pl6": {
                "left": 0.29,
                "top": 0.57
            },
            "pl7": {
                "left": 0.5,
                "top": 0.6
            },
            "pl8": {
                "left": 0.5,
                "top": 0.51
            }
        },
        'f7': {
            'gk': {left: 0.50 , top: 0.80 },
            "pl1": {
                "left": 0.77,
                "top": 0.56
            },
            "pl2": {
                "left": 0.5,
                "top": 0.36
            },
            "pl3": {
                "left": 0.37,
                "top": 0.63
            },
            "pl4": {
                "left": 0.64,
                "top": 0.63
            },
            "pl5": {
                "left": 0.22,
                "top": 0.55
            },
            "pl6": {
                "left": 0.64,
                "top": 0.46
            },
            "pl7": {
                "left": 0.37,
                "top": 0.45
            },
            "pl8": {
                "left": 0.5,
                "top": 0.55
            }
        },
        'f8': {
            'gk': {left: 0.50 , top: 0.80 },
            "pl1": {
                "left": 0.71,
                "top": 0.62
            },
            "pl2": {
                "left": 0.7,
                "top": 0.46
            },
            "pl3": {
                "left": 0.29,
                "top": 0.62
            },
            "pl4": {
                "left": 0.5,
                "top": 0.36
            },
            "pl5": {
                "left": 0.5,
                "top": 0.63
            },
            "pl6": {
                "left": 0.4,
                "top": 0.55
            },
            "pl7": {
                "left": 0.3,
                "top": 0.46
            },
            "pl8": {
                "left": 0.62,
                "top": 0.55
            }
        },
    },
    'p3R': {
        'goali': 'B0000', 'player': 'A0000', 'f1':
            {
                'gk': {left: 0.50 , top: 0.06 },
                'pl3': {left: 0.62, top: 0.13},
                'pl2': {left: 0.50, top: 0.13},
                'pl1': {left: 0.38, top: 0.13},
                'pl8': {left: 0.23, top: 0.20},
                'pl7': {left: 0.37, top: 0.20},
                'pl6': {left: 0.51, top: 0.20},
                'pl5': {left: 0.65, top: 0.20},
                'pl4': {left: 0.79, top: 0.20},
                'pl9': {left: 0.40, top: 0.29},
                'pl10': {left: 0.60, top: 0.29},
            },
        'f2': {
            'gk': {left: 0.50 , top: 0.06 },
            'pl4': {left: 0.29, top: 0.13},
            'pl3': {left: 0.43, top: 0.13},
            'pl2': {left: 0.57, top: 0.13},
            'pl1': {left: 0.71, top: 0.13},
            'pl7': {left: 0.36, top: 0.20},
            'pl6': {left: 0.50, top: 0.20},
            'pl5': {left: 0.64, top: 0.20},
            'pl10': {left: 0.33, top: 0.29},
            'pl9': {left: 0.50, top: 0.29},
            'pl8': {left: 0.68, top: 0.29}
        },
        'f3': {
            'gk': {left: 0.50 , top: 0.06 },
            'pl4': {left: 0.29, top: 0.13},
            'pl3': {left: 0.43, top: 0.13},
            'pl2': {left: 0.57, top: 0.13},
            'pl1': {left: 0.71, top: 0.13},
            'pl8': {left: 0.29, top: 0.20},
            'pl7': {left: 0.43, top: 0.20},
            'pl6': {left: 0.57, top: 0.20},
            'pl5': {left: 0.71, top: 0.20},
            'pl10': {left: 0.60, top: 0.29},
            'pl9': {left: 0.40, top: 0.29}
        },
        'f4': {
            'gk': {left: 0.50 , top: 0.06 },
            'pl4': {left: 0.29, top: 0.13},
            'pl3': {left: 0.43, top: 0.13},
            'pl2': {left: 0.57, top: 0.13},
            'pl1': {left: 0.71, top: 0.13},
            'pl9': {left: 0.23, top: 0.20},
            'pl8': {left: 0.37, top: 0.20},
            'pl7': {left: 0.51, top: 0.20},
            'pl6': {left: 0.65, top: 0.20},
            'pl5': {left: 0.79, top: 0.20},
            'pl10': {left: 0.50 , top: 0.29}
        },
        'f5': {
            'gk': {left: 0.50 , top: 0.06 },
            "pl1": {
                "left": 0.63,
                "top": 0.12
            },
            "pl2": {
                "left": 0.5,
                "top": 0.12
            },
            "pl3": {
                "left": 0.65,
                "top": 0.24
            },
            "pl4": {
                "left": 0.5,
                "top": 0.31
            },
            "pl5": {
                "left": 0.37,
                "top": 0.12
            },
            "pl6": {
                "left": 0.5,
                "top": 0.18
            },
            "pl7": {
                "left": 0.5,
                "top": 0.24
            },
            "pl8": {
                "left": 0.36,
                "top": 0.24
            }
        },
        'f6': {
            'gk': {left: 0.50 , top: 0.06 },
            "pl1": {
                "left": 0.64,
                "top": 0.16
            },
            "pl2": {
                "left": 0.5,
                "top": 0.14
            },
            "pl3": {
                "left": 0.42,
                "top": 0.3
            },
            "pl4": {
                "left": 0.36,
                "top": 0.16
            },
            "pl5": {
                "left": 0.6,
                "top": 0.3
            },
            "pl6": {
                "left": 0.5,
                "top": 0.22
            },
            "pl7": {
                "left": 0.32,
                "top": 0.23
            },
            "pl8": {
                "left": 0.68,
                "top": 0.24
            }
        },
        'f7': {
            'gk': {left: 0.50 , top: 0.06 },
            "pl1": {
                "left": 0.42,
                "top": 0.23
            },
            "pl2": {
                "left": 0.5,
                "top": 0.31
            },
            "pl3": {
                "left": 0.42,
                "top": 0.12
            },
            "pl4": {
                "left": 0.31,
                "top": 0.16
            },
            "pl5": {
                "left": 0.7,
                "top": 0.16
            },
            "pl6": {
                "left": 0.58,
                "top": 0.23
            },
            "pl7": {
                "left": 0.58,
                "top": 0.13
            },
            "pl8": {
                "left": 0.5,
                "top": 0.16
            }
        },
        'f8': {
            'gk': {left: 0.50 , top: 0.06 },
            "pl1": {
                "left": 0.5,
                "top": 0.31
            },
            "pl2": {
                "left": 0.43,
                "top": 0.19
            },
            "pl3": {
                "left": 0.5,
                "top": 0.12
            },
            "pl4": {
                "left": 0.63,
                "top": 0.12
            },
            "pl5": {
                "left": 0.37,
                "top": 0.12
            },
            "pl6": {
                "left": 0.64,
                "top": 0.25
            },
            "pl7": {
                "left": 0.57,
                "top": 0.19
            },
            "pl8": {
                "left": 0.37,
                "top": 0.24
            }
        },
    },
    'p4': {
        'goali': 'B0002', 'player': 'A0002', 'f1': {
            'gk': {left: 0.23, top: 0.34},
            'pl1': {left: 0.40, top: 0.57},
            'pl2': {left: 0.41, top: 0.37},
            'pl3': {left: 0.42, top: 0.17},
            'pl4': {left: 0.58, top: 0.12},
            'pl5': {left: 0.58, top: 0.26},
            'pl6': {left: 0.59, top: 0.40},
            'pl7': {left: 0.60, top: 0.54},
            'pl8': {left: 0.60, top: 0.68},
            'pl9': {left: 0.82, top: 0.44},
            'pl10': {left: 0.78, top: 0.25}
        },
        'f2': {
            'gk': {left: 0.23, top: 0.34},
            'pl1': {left: 0.40, top: 0.57},
            'pl2': {left: 0.40, top: 0.43},
            'pl3': {left: 0.41, top: 0.30},
            'pl4': {left: 0.42, top: 0.17},
            'pl5': {left: 0.58, top: 0.50},
            'pl6': {left: 0.58, top: 0.35},
            'pl7': {left: 0.58, top: 0.20},
            'pl8': {left: 0.84, top: 0.57},
            'pl9': {left: 0.80, top: 0.37},
            'pl10': {left: 0.76, top: 0.17}
        },
        'f3': {
            'gk': {left: 0.23, top: 0.34},
            'pl1': {left: 0.40, top: 0.57},
            'pl2': {left: 0.40, top: 0.43},
            'pl3': {left: 0.41, top: 0.30},
            'pl4': {left: 0.42, top: 0.17},
            'pl5': {left: 0.59, top: 0.57},
            'pl6': {left: 0.59, top: 0.43},
            'pl7': {left: 0.58, top: 0.30},
            'pl8': {left: 0.58, top: 0.17},
            'pl9': {left: 0.82, top: 0.44},
            'pl10': {left: 0.78, top: 0.25}
        },
        'f4': {
            'gk': {left: 0.23, top: 0.34},
            'pl1': {left: 0.40, top: 0.57},
            'pl2': {left: 0.40, top: 0.43},
            'pl3': {left: 0.41, top: 0.30},
            'pl4': {left: 0.42, top: 0.17},
            'pl5': {left: 0.58, top: 0.12},
            'pl6': {left: 0.58, top: 0.26},
            'pl7': {left: 0.59, top: 0.40},
            'pl8': {left: 0.60, top: 0.54},
            'pl9': {left: 0.60, top: 0.68},
            'pl10': {left: 0.80, top: 0.36}
        },
        'f5': {
            'gk': {left: 0.23, top: 0.34},
            "pl1": {
                "left": 0.63,
                "top": 0.55
            },
            "pl2": {
                "left": 0.6,
                "top": 0.17
            },
            "pl3": {
                "left": 0.61,
                "top": 0.32
            },
            "pl4": {
                "left": 0.41,
                "top": 0.17
            },
            "pl5": {
                "left": 0.78,
                "top": 0.32
            },
            "pl6": {
                "left": 0.38,
                "top": 0.55
            },
            "pl7": {
                "left": 0.39,
                "top": 0.32
            },
            "pl8": {
                "left": 0.51,
                "top": 0.32
            }
        },
        'f6': {
            'gk': {left: 0.23, top: 0.34},
            "pl1": {
                "left": 0.7,
                "top": 0.4
            },
            "pl2": {
                "left": 0.53,
                "top": 0.32
            },
            "pl3": {
                "left": 0.69,
                "top": 0.25
            },
            "pl4": {
                "left": 0.57,
                "top": 0.14
            },
            "pl5": {
                "left": 0.43,
                "top": 0.32
            },
            "pl6": {
                "left": 0.47,
                "top": 0.18
            },
            "pl7": {
                "left": 0.47,
                "top": 0.51
            },
            "pl8": {
                "left": 0.58,
                "top": 0.55
            }
        },
        'f7': {
            'gk': {left: 0.23, top: 0.34},
            "pl1": {
                "left": 0.64,
                "top": 0.45
            },
            "pl2": {
                "left": 0.51,
                "top": 0.1
            },
            "pl3": {
                "left": 0.4,
                "top": 0.21
            },
            "pl4": {
                "left": 0.78,
                "top": 0.32
            },
            "pl5": {
                "left": 0.52,
                "top": 0.63
            },
            "pl6": {
                "left": 0.51,
                "top": 0.32
            },
            "pl7": {
                "left": 0.38,
                "top": 0.44
            },
            "pl8": {
                "left": 0.63,
                "top": 0.21
            }
        },
        'f8': {
            'gk': {left: 0.23, top: 0.34},
            "pl1": {
                "left": 0.38,
                "top": 0.55
            },
            "pl2": {
                "left": 0.39,
                "top": 0.32
            },
            "pl3": {
                "left": 0.41,
                "top": 0.17
            },
            "pl4": {
                "left": 0.52,
                "top": 0.24
            },
            "pl5": {
                "left": 0.62,
                "top": 0.18
            },
            "pl6": {
                "left": 0.79,
                "top": 0.32
            },
            "pl7": {
                "left": 0.52,
                "top": 0.43
            },
            "pl8": {
                "left": 0.65,
                "top": 0.54
            }
        },
    },
    'p5': {
        'goali': 'B0001', 'player': 'A0001', 'f1': {
            'gk': {left: 0.45, top: 0.18},
            'pl1': {left: 0.37, top: 0.36},
            'pl2': {left: 0.56, top: 0.26},
            'pl3': {left: 0.69, top: 0.16},
            'pl4': {left: 0.42, top: 0.59},
            'pl5': {left: 0.53, top: 0.50},
            'pl6': {left: 0.66, top: 0.41},
            'pl7': {left: 0.77, top: 0.33},
            'pl8': {left: 0.88, top: 0.24},
            'pl9': {left: 0.77, top: 0.57},
            'pl10': {left: 0.92, top: 0.42}
        },
        'f2': {
            'gk': {left: 0.45, top: 0.18},
            'pl1': {left: 0.37, top: 0.36},
            'pl2': {left: 0.49 , top: 0.29},
            'pl3': {left: 0.60, top: 0.22},
            'pl4': {left: 0.69, top: 0.16},
            'pl5': {left: 0.59, top: 0.49},
            'pl6': {left: 0.69, top: 0.41},
            'pl7': {left: 0.79, top: 0.33},
            'pl8': {left: 0.98, top: 0.42},
            'pl9': {left: 0.86, top: 0.53},
            'pl10': {left: 0.69, top: 0.69}
        },
        'f3': {
            'gk': {left: 0.45, top:0.18},
            'pl1': {left: 0.37, top: 0.36},
            'pl2': {left: 0.49 , top: 0.29},
            'pl3': {left: 0.60, top: 0.22},
            'pl4': {left: 0.69, top: 0.16},
            'pl5': {left: 0.52, top: 0.51},
            'pl6': {left: 0.64, top: 0.43},
            'pl7': {left: 0.75, top: 0.34},
            'pl8': {left: 0.84, top: 0.27},
            'pl9': {left: 0.78, top: 0.58},
            'pl10': {left: 0.93, top: 0.44}
        },
        'f4': {
            'gk': {left: 0.44, top: 0.18},
            'pl1': {left: 0.37, top: 0.36},
            'pl2': {left: 0.49 , top: 0.29},
            'pl3': {left: 0.60, top: 0.22},
            'pl4': {left: 0.69, top: 0.16},
            'pl5': {left: 0.42, top: 0.59},
            'pl6': {left: 0.53, top: 0.50},
            'pl7': {left: 0.66, top: 0.41},
            'pl8': {left: 0.77, top: 0.33},
            'pl9': {left: 0.88, top: 0.24},
            'pl10': {left: 0.86, top: 0.52}
        },
        'f5': {
            'gk': {left: 0.44, top: 0.18},
            "pl1": {
                "left": 0.37,
                "top": 0.36
            },
            "pl2": {
                "left": 0.52,
                "top": 0.53
            },
            "pl3": {
                "left": 0.53,
                "top": 0.24
            },
            "pl4": {
                "left": 0.68,
                "top": 0.17
            },
            "pl5": {
                "left": 0.7,
                "top": 0.43
            },
            "pl6": {
                "left": 0.6,
                "top": 0.31
            },
            "pl7": {
                "left": 0.81,
                "top": 0.3
            },
            "pl8": {
                "left": 0.82,
                "top": 0.53
            }
        },
        'f6': {
            'gk': {left: 0.44, top: 0.18},
            "pl1": {
                "left": 0.47,
                "top": 0.41
            },
            "pl2": {
                "left": 0.58,
                "top": 0.29
            },
            "pl3": {
                "left": 0.5,
                "top": 0.54
            },
            "pl4": {
                "left": 0.74,
                "top": 0.53
            },
            "pl5": {
                "left": 0.88,
                "top": 0.42
            },
            "pl6": {
                "left": 0.65,
                "top": 0.37
            },
            "pl7": {
                "left": 0.83,
                "top": 0.3
            },
            "pl8": {
                "left": 0.73,
                "top": 0.24
            }
        },
        'f7': {
            'gk': {left: 0.44, top: 0.18},
            "pl1": {
                "left": 0.83,
                "top": 0.17
            },
            "pl2": {
                "left": 0.81,
                "top": 0.37
            },
            "pl3": {
                "left": 0.46,
                "top": 0.31
            },
            "pl4": {
                "left": 0.62,
                "top": 0.2
            },
            "pl5": {
                "left": 0.6,
                "top": 0.35
            },
            "pl6": {
                "left": 0.82,
                "top": 0.53
            },
            "pl7": {
                "left": 0.62,
                "top": 0.52
            },
            "pl8": {
                "left": 0.39,
                "top": 0.49
            }
        },
        'f8': {
            'gk': {left: 0.44, top: 0.18},
            "pl1": {
                "left": 0.37,
                "top": 0.36
            },
            "pl2": {
                "left": 0.53,
                "top": 0.24
            },
            "pl3": {
                "left": 0.68,
                "top": 0.17
            },
            "pl4": {
                "left": 0.7,
                "top": 0.29
            },
            "pl5": {
                "left": 0.87,
                "top": 0.52
            },
            "pl6": {
                "left": 0.52,
                "top": 0.41
            },
            "pl7": {
                "left": 0.88,
                "top": 0.32
            },
            "pl8": {
                "left": 0.54,
                "top": 0.58
            }
        },
    },
    'p6': {
        'goali': 'B0004', 'player': 'A0004', 'f1':
            {
                'gk': {left: 0.50 , top: 0.80 },
                'pl1': {left: 0.71, top: 0.61},
                'pl2': {left: 0.50, top: 0.61},
                'pl3': {left: 0.29, top: 0.61},
                'pl4': {left: 0.23, top: 0.42},
                'pl5': {left: 0.37, top: 0.42},
                'pl6': {left: 0.51, top: 0.42},
                'pl7': {left: 0.65, top: 0.42},
                'pl8': {left: 0.79, top: 0.42},
                'pl9': {left: 0.57, top: 0.25},
                'pl10': {left: 0.43, top: 0.25}
            },
        'f2': {
            'gk': {left: 0.50 , top: 0.80 },
            'pl1': {left: 0.29, top: 0.61},
            'pl2': {left: 0.43, top: 0.61},
            'pl3': {left: 0.57, top: 0.61},
            'pl4': {left: 0.71, top: 0.61},
            'pl5': {left: 0.36, top: 0.42},
            'pl6': {left: 0.50, top: 0.42},
            'pl7': {left: 0.64, top: 0.42},
            'pl8': {left: 0.33, top: 0.25},
            'pl9': {left: 0.50, top: 0.25},
            'pl10': {left: 0.68, top: 0.25}
        },
        'f3': {
            'gk': {left: 0.50 , top: 0.80 },
            'pl1': {left: 0.29, top: 0.61},
            'pl2': {left: 0.43, top: 0.61},
            'pl3': {left: 0.57, top: 0.61},
            'pl4': {left: 0.71, top: 0.61},
            'pl5': {left: 0.29, top: 0.42},
            'pl6': {left: 0.43, top: 0.42},
            'pl7': {left: 0.57, top: 0.42},
            'pl8': {left: 0.71, top: 0.42},
            'pl9': {left: 0.57, top: 0.25},
            'pl10': {left: 0.43, top: 0.25}
        },
        'f4': {
            'gk': {left: 0.50 , top: 0.80 },
            'pl1': {left: 0.29, top: 0.61},
            'pl2': {left: 0.43, top: 0.61},
            'pl3': {left: 0.57, top: 0.61},
            'pl4': {left: 0.71, top: 0.61},
            'pl5': {left: 0.23, top: 0.42},
            'pl6': {left: 0.37, top: 0.42},
            'pl7': {left: 0.51, top: 0.42},
            'pl8': {left: 0.65, top: 0.42},
            'pl9': {left: 0.79, top: 0.42},
            'pl10': {left: 0.50 , top: 0.25}
        },
        'f5': {
            'gk': {left: 0.50 , top: 0.80 },
            "pl1": {
                "left": 0.71,
                "top": 0.61
            },
            "pl2": {
                "left": 0.31,
                "top": 0.39
            },
            "pl3": {
                "left": 0.49,
                "top": 0.39
            },
            "pl4": {
                "left": 0.29,
                "top": 0.61
            },
            "pl5": {
                "left": 0.7,
                "top": 0.4
            },
            "pl6": {
                "left": 0.5,
                "top": 0.61
            },
            "pl7": {
                "left": 0.49,
                "top": 0.25
            },
            "pl8": {
                "left": 0.5,
                "top": 0.5
            },
        },
        'f6': {
            'gk': {left: 0.50 , top: 0.80 },
            "pl1": {
                "left": 0.6,
                "top": 0.3
            },
            "pl2": {
                "left": 0.75,
                "top": 0.43
            },
            "pl3": {
                "left": 0.71,
                "top": 0.53
            },
            "pl4": {
                "left": 0.31,
                "top": 0.52
            },
            "pl5": {
                "left": 0.25,
                "top": 0.43
            },
            "pl6": {
                "left": 0.4,
                "top": 0.3
            },
            "pl7": {
                "left": 0.5,
                "top": 0.45
            },
            "pl8": {
                "left": 0.5,
                "top": 0.56
            }
        },
        'f7': {
            'gk': {left: 0.50 , top: 0.80 },
            "pl1": {
                "left": 0.24,
                "top": 0.47
            },
            "pl2": {
                "left": 0.77,
                "top": 0.47
            },
            "pl3": {
                "left": 0.38,
                "top": 0.61
            },
            "pl4": {
                "left": 0.62,
                "top": 0.61
            },
            "pl5": {
                "left": 0.37,
                "top": 0.37
            },
            "pl6": {
                "left": 0.5,
                "top": 0.25
            },
            "pl7": {
                "left": 0.62,
                "top": 0.37
            },
            "pl8": {
                "left": 0.5,
                "top": 0.48
            }
        },
        'f8': {
            'gk': {left: 0.50 , top: 0.80 },
            "pl1": {
                "left": 0.29,
                "top": 0.61
            },
            "pl2": {
                "left": 0.5,
                "top": 0.61
            },
            "pl3": {
                "left": 0.71,
                "top": 0.61
            },
            "pl4": {
                "left": 0.49,
                "top": 0.25
            },
            "pl5": {
                "left": 0.3,
                "top": 0.38
            },
            "pl6": {
                "left": 0.7,
                "top": 0.38
            },
            "pl7": {
                "left": 0.38,
                "top": 0.5
            },
            "pl8": {
                "left": 0.62,
                "top": 0.5
            }
        },
    },
    'p7': {
        'goali': 'B0000', 'player': 'A0000', 'f1': {
            'gk': {left: 0.08, top: 0.50},
            'pl1': {left: 0.21, top: 0.33},
            'pl2': {left: 0.21, top: 0.50},
            'pl3': {left: 0.21, top: 0.67},
            'pl4': {left: 0.33, top: 0.20},
            'pl5': {left: 0.35, top: 0.40},
            'pl6': {left: 0.40, top: 0.50},
            'pl7': {left: 0.35, top: 0.60},
            'pl8': {left: 0.33, top: 0.80},
            'pl9': {left: 0.47, top: 0.33},
            'pl10': {left: 0.47, top: 0.67}
        },
        'f2': {
            'gk': {left: 0.08, top: 0.50},
            'pl1': {left: 0.20, top: 0.20},
            'pl2': {left: 0.20, top: 0.40},
            'pl3': {left: 0.20, top: 0.60},
            'pl4': {left: 0.20, top: 0.80},
            'pl5': {left: 0.35, top: 0.33},
            'pl6': {left: 0.33, top: 0.50},
            'pl7': {left: 0.35, top: 0.67},
            'pl8': {left: 0.47, top: 0.33},
            'pl9': {left: 0.47, top: 0.50},
            'pl10': {left: 0.47, top: 0.67}
        },
        'f3': {
            'gk': {left: 0.08, top: 0.50},
            'pl1': {left: 0.20, top: 0.20},
            'pl2': {left: 0.20, top: 0.40},
            'pl3': {left: 0.20, top: 0.60},
            'pl4': {left: 0.20, top: 0.80},
            'pl5': {left: 0.35, top: 0.20},
            'pl6': {left: 0.35, top: 0.40},
            'pl7': {left: 0.35, top: 0.60},
            'pl8': {left: 0.35, top: 0.80},
            'pl9': {left: 0.47, top: 0.33},
            'pl10': {left: 0.47, top: 0.67}
        },
        'f4': {
            'gk': {left: 0.08, top: 0.50},
            'pl1': {left: 0.20, top: 0.20},
            'pl2': {left: 0.20, top: 0.40},
            'pl3': {left: 0.20, top: 0.60},
            'pl4': {left: 0.20, top: 0.80},
            'pl5': {left: 0.35, top: 0.14},
            'pl6': {left: 0.35, top: 0.33},
            'pl7': {left: 0.35, top: 0.50},
            'pl8': {left: 0.35, top: 0.67},
            'pl9': {left: 0.35, top: 0.86},
            'pl10': {left: 0.47, top: 0.50}
        },
        'f5': {
            'gk': {left: 0.08, top: 0.50},
            'pl1': {left: 0.22, top: 0.29},
            'pl2': {left: 0.22, top: 0.50},
            'pl3': {left: 0.22, top: 0.70},
            'pl4': {left: 0.30, top: 0.50},
            'pl5': {left: 0.38, top: 0.29},
            'pl6': {left: 0.38, top: 0.50},
            'pl7': {left: 0.38, top: 0.70},
            'pl8': {left: 0.47, top: 0.50},
            'pl9': {left: 0.35, top: 0.86},
            'pl10': {left: 0.47, top: 0.50}
        },
        'f6': {
            'gk': {left: 0.08, top: 0.50},
            "pl1": {
                "left": 0.27,
                "top": 0.3
            },
            "pl2": {
                "left": 0.25,
                "top": 0.50
            },
            "pl3": {
                "left": 0.27,
                "top": 0.68
            },
            "pl4": {
                "left": 0.37,
                "top": 0.29
            },
            "pl5": {
                "left": 0.34,
                "top": 0.50
            },
            "pl6": {
                "left": 0.37,
                "top": 0.7
            },
            "pl7": {
                "left": 0.47,
                "top": 0.38
            },
            "pl8": {
                "left": 0.46,
                "top": 0.62
            }
        },
        'f7': {
            'gk': {left: 0.08, top: 0.50},
            "pl1": {
                "left": 0.21,
                "top": 0.37
            },
            "pl2": {
                "left": 0.21,
                "top": 0.6
            },
            "pl3": {
                "left": 0.32,
                "top": 0.22
            },
            "pl4": {
                "left": 0.32,
                "top": 0.5
            },
            "pl5": {
                "left": 0.33,
                "top": 0.77
            },
            "pl6": {
                "left": 0.4,
                "top": 0.37
            },
            "pl7": {
                "left": 0.39,
                "top": 0.61
            },
            "pl8": {
                "left": 0.47,
                "top": 0.5
            }
        },
        'f8': {
            'gk': {left: 0.08, top: 0.50},
            "pl1": {
                "left": 0.22,
                "top": 0.29
            },
            "pl2": {
                "left": 0.22,
                "top": 0.5
            },
            "pl3": {
                "left": 0.22,
                "top": 0.7
            },
            "pl4": {
                "left": 0.31,
                "top": 0.4
            },
            "pl5": {
                "left": 0.31,
                "top": 0.61
            },
            "pl6": {
                "left": 0.39,
                "top": 0.29
            },
            "pl7": {
                "left": 0.38,
                "top": 0.7
            },
            "pl8": {
                "left": 0.47,
                "top": 0.5
            }
        },
    },
    'p8': {
        'goali': 'B0000', 'player': 'A0000', 'f1': {
            'gk': {left: 0.08, top: 0.50},
            'pl1': {left: 0.21, top: 0.33},
            'pl2': {left: 0.21, top: 0.50},
            'pl3': {left: 0.21, top: 0.67},
            'pl4': {left: 0.33, top: 0.20},
            'pl5': {left: 0.35, top: 0.40},
            'pl6': {left: 0.40, top: 0.50},
            'pl7': {left: 0.35, top: 0.60},
            'pl8': {left: 0.33, top: 0.80},
            'pl9': {left: 0.45, top: 0.33},
            'pl10': {left: 0.45, top: 0.67}
        },
        'f2': {
            'gk': {left: 0.08, top: 0.50},
            'pl1': {left: 0.20, top: 0.20},
            'pl2': {left: 0.20, top: 0.40},
            'pl3': {left: 0.20, top: 0.60},
            'pl4': {left: 0.20, top: 0.80},
            'pl5': {left: 0.35, top: 0.33},
            'pl6': {left: 0.33, top: 0.50},
            'pl7': {left: 0.35, top: 0.67},
            'pl8': {left: 0.45, top: 0.33},
            'pl9': {left: 0.45, top: 0.50},
            'pl10': {left: 0.45, top: 0.67}
        },
        'f3': {
            'gk': {left: 0.08, top: 0.50},
            'pl1': {left: 0.20, top: 0.20},
            'pl2': {left: 0.20, top: 0.40},
            'pl3': {left: 0.20, top: 0.60},
            'pl4': {left: 0.20, top: 0.80},
            'pl5': {left: 0.35, top: 0.20},
            'pl6': {left: 0.35, top: 0.40},
            'pl7': {left: 0.35, top: 0.60},
            'pl8': {left: 0.35, top: 0.80},
            'pl9': {left: 0.45, top: 0.33},
            'pl10': {left: 0.45, top: 0.67}
        },
        'f4': {
            'gk': {left: 0.08, top: 0.50},
            'pl1': {left: 0.20, top: 0.20},
            'pl2': {left: 0.20, top: 0.40},
            'pl3': {left: 0.20, top: 0.60},
            'pl4': {left: 0.20, top: 0.80},
            'pl5': {left: 0.35, top: 0.14},
            'pl6': {left: 0.35, top: 0.33},
            'pl7': {left: 0.35, top: 0.50},
            'pl8': {left: 0.35, top: 0.67},
            'pl9': {left: 0.35, top: 0.86},
            'pl10': {left: 0.45, top: 0.50}
        },
        'f5': {
            'gk': {left: 0.08, top: 0.50},
            "pl1": {
                "left": 0.2,
                "top": 0.31
            },
            "pl2": {
                "left": 0.2,
                "top": 0.5
            },
            "pl3": {
                "left": 0.2,
                "top": 0.69
            },
            "pl4": {
                "left": 0.3,
                "top": 0.5
            },
            "pl5": {
                "left": 0.4,
                "top": 0.3
            },
            "pl6": {
                "left": 0.39,
                "top": 0.5
            },
            "pl7": {
                "left": 0.39,
                "top": 0.7
            },
            "pl8": {
                "left": 0.47,
                "top": 0.50
            }
        },
        'f6': {
            'gk': {left: 0.08, top: 0.50},
            "pl1": {
                "left": 0.25,
                "top": 0.34
            },
            "pl2": {
                "left": 0.2,
                "top": 0.50
            },
            "pl3": {
                "left": 0.23,
                "top": 0.67
            },
            "pl4": {
                "left": 0.36,
                "top": 0.33
            },
            "pl5": {
                "left": 0.31,
                "top": 0.50
            },
            "pl6": {
                "left": 0.36,
                "top": 0.67
            },
            "pl7": {
                "left": 0.47,
                "top": 0.4
            },
            "pl8": {
                "left": 0.47,
                "top": 0.6
            }
        },
        'f7': {
            'gk': {left: 0.08, top: 0.50},
            "pl1": {
                "left": 0.2,
                "top": 0.37
            },
            "pl2": {
                "left": 0.19,
                "top": 0.62
            },
            "pl3": {
                "left": 0.29,
                "top": 0.24
            },
            "pl4": {
                "left": 0.3,
                "top": 0.5
            },
            "pl5": {
                "left": 0.29,
                "top": 0.74
            },
            "pl6": {
                "left": 0.38,
                "top": 0.37
            },
            "pl7": {
                "left": 0.37,
                "top": 0.65
            },
            "pl8": {
                "left": 0.47,
                "top": 0.5
            }
        },
        'f8': {
            'gk': {left: 0.08, top: 0.50},
            "pl1": {
                "left": 0.2,
                "top": 0.31
            },
            "pl2": {
                "left": 0.2,
                "top": 0.5
            },
            "pl3": {
                "left": 0.2,
                "top": 0.69
            },
            "pl4": {
                "left": 0.3,
                "top": 0.41
            },
            "pl5": {
                "left": 0.3,
                "top": 0.61
            },
            "pl6": {
                "left": 0.39,
                "top": 0.31
            },
            "pl7": {
                "left": 0.39,
                "top": 0.68
            },
            "pl8": {
                "left": 0.47,
                "top": 0.5
            }
        },
    },
    'p9': {
        'goali': 'B0000', 'player': 'A0000', 'f1': {
            'gk0': {left: 0.25, top: 0.09},
            'pl1': {left: 0.36, top: 0.22},
            'pl2': {left: 0.25, top: 0.22},
            'pl3': {left: 0.14, top: 0.22},
            'pl4': {left: 0.05, top: 0.36},
            'pl5': {left: 0.15, top: 0.36},
            'pl6': {left: 0.25, top: 0.36},
            'pl7': {left: 0.35, top: 0.36},
            'pl8': {left: 0.45, top: 0.36},
            'pl9': {left: 0.30, top: 0.45},
            'pl10': {left: 0.20, top: 0.45},

            'gk1': {left: 0.75, top: 0.09},
            'pl11': {left: 0.86, top: 0.22},
            'pl12': {left: 0.75, top: 0.22},
            'pl13': {left: 0.66, top: 0.22},
            'pl14': {left: 0.55, top: 0.36},
            'pl15': {left: 0.65, top: 0.36},
            'pl16': {left: 0.75, top: 0.36},
            'pl17': {left: 0.85, top: 0.36},
            'pl18': {left: 0.95, top: 0.36},
            'pl19': {left: 0.80, top: 0.45},
            'pl20': {left: 0.70, top: 0.45}
        },
        'f2': {
            'gk0': {left: 0.75, top: 0.09},
            'pl1': {left: 0.86, top: 0.22},
            'pl2': {left: 0.79, top: 0.22},
            'pl3': {left: 0.71, top: 0.22},
            'pl4': {left: 0.64, top: 0.22},
            'pl5': {left: 0.66, top: 0.35},
            'pl6': {left: 0.75, top: 0.35},
            'pl7': {left: 0.84, top: 0.35},
            'pl8': {left: 0.64, top: 0.45},
            'pl9': {left: 0.75, top: 0.45},
            'pl10': {left: 0.86, top: 0.45},

            'gk1': {left: 0.25, top: 0.09},
            'pl11': {left: 0.36, top: 0.22},
            'pl12': {left: 0.29, top: 0.22},
            'pl13': {left: 0.21, top: 0.22},
            'pl14': {left: 0.14, top: 0.22},
            'pl15': {left: 0.16, top: 0.35},
            'pl16': {left: 0.25, top: 0.35},
            'pl17': {left: 0.34, top: 0.35},
            'pl18': {left: 0.14, top: 0.45},
            'pl19': {left: 0.25, top: 0.45},
            'pl20': {left: 0.36, top: 0.45}
        },
        'f3': {
            'gk0': {left: 0.25, top: 0.09},
            'pl1': {left: 0.36, top: 0.22},
            'pl2': {left: 0.29, top: 0.22},
            'pl3': {left: 0.21, top: 0.22},
            'pl4': {left: 0.14, top: 0.22},
            'pl5': {left: 0.36, top: 0.35},
            'pl6': {left: 0.29, top: 0.35},
            'pl7': {left: 0.21, top: 0.35},
            'pl8': {left: 0.14, top: 0.35},
            'pl9': {left: 0.30, top: 0.45},
            'pl10': {left: 0.20, top: 0.45},

            'gk1': {left: 0.75, top: 0.09},
            'pl11': {left: 0.86, top: 0.22},
            'pl12': {left: 0.79, top: 0.22},
            'pl13': {left: 0.71, top: 0.22},
            'pl14': {left: 0.64, top: 0.22},
            'pl15': {left: 0.86, top: 0.35},
            'pl16': {left: 0.79, top: 0.35},
            'pl17': {left: 0.71, top: 0.35},
            'pl18': {left: 0.64, top: 0.35},
            'pl19': {left: 0.80, top: 0.45},
            'pl20': {left: 0.70, top: 0.45}
        },
        'f4': {
            'gk0': {left: 0.75, top: 0.09},
            'pl1': {left: 0.86, top: 0.22},
            'pl2': {left: 0.79, top: 0.22},
            'pl3': {left: 0.71, top: 0.22},
            'pl4': {left: 0.64, top: 0.22},
            'pl5': {left: 0.55, top: 0.36},
            'pl6': {left: 0.65, top: 0.36},
            'pl7': {left: 0.75, top: 0.36},
            'pl8': {left: 0.85, top: 0.36},
            'pl9': {left: 0.95, top: 0.36},
            'pl10': {left: 0.75, top: 0.45},

            'gk1': {left: 0.25, top: 0.09},
            'pl11': {left: 0.36, top: 0.22},
            'pl12': {left: 0.29, top: 0.22},
            'pl13': {left: 0.21, top: 0.22},
            'pl14': {left: 0.14, top: 0.22},
            'pl15': {left: 0.05, top: 0.36},
            'pl16': {left: 0.15, top: 0.36},
            'pl17': {left: 0.25, top: 0.36},
            'pl18': {left: 0.35, top: 0.36},
            'pl19': {left: 0.45, top: 0.36},
            'pl20': {left: 0.25, top: 0.45}
        },
        'f5': {
            'gk0': {left: 0.75, top: 0.09},
            "pl1": {
                "left": 0.85,
                "top": 0.21
            },
            "pl2": {
                "left": 0.75,
                "top": 0.21
            },
            "pl3": {
                "left": 0.65,
                "top": 0.21
            },
            "pl4": {
                "left": 0.75,
                "top": 0.29
            },
            "pl5": {
                "left": 0.85,
                "top": 0.37
            },
            "pl6": {
                "left": 0.75,
                "top": 0.37
            },
            "pl7": {
                "left": 0.65,
                "top": 0.37
            },
            "pl8": {
                "left": 0.75,
                "top": 0.45
            },
            'gk1': {left: 0.25, top: 0.09},
            "pl11": {
                "left": 0.35,
                "top": 0.21
            },
            "pl12": {
                "left": 0.25,
                "top": 0.21
            },
            "pl13": {
                "left": 0.15,
                "top": 0.21
            },
            "pl14": {
                "left": 0.25,
                "top": 0.29
            },
            "pl15": {
                "left": 0.35,
                "top": 0.38
            },
            "pl16": {
                "left": 0.25,
                "top": 0.37
            },
            "pl17": {
                "left": 0.16,
                "top": 0.37
            },
            "pl18": {
                "left": 0.25,
                "top": 0.45
            }
        },
        'f6': {
            'gk0': {left: 0.75, top: 0.09},
            "pl1": {
                "left": 0.41,
                "top": 0.28
            },
            "pl2": {
                "left": 0.25,
                "top": 0.24
            },
            "pl3": {
                "left": 0.11,
                "top": 0.28
            },
            "pl4": {
                "left": 0.43,
                "top": 0.37
            },
            "pl5": {
                "left": 0.25,
                "top": 0.35
            },
            "pl6": {
                "left": 0.07,
                "top": 0.36
            },
            "pl7": {
                "left": 0.33,
                "top": 0.47
            },
            "pl8": {
                "left": 0.17,
                "top": 0.46
            },
            'gk1': {left: 0.25, top: 0.09},
            "pl11": {
                "left": 0.89,
                "top": 0.29
            },
            "pl12": {
                "left": 0.75,
                "top": 0.25
            },
            "pl13": {
                "left": 0.6,
                "top": 0.29
            },
            "pl14": {
                "left": 0.92,
                "top": 0.39
            },
            "pl15": {
                "left": 0.75,
                "top": 0.35
            },
            "pl16": {
                "left": 0.57,
                "top": 0.37
            },
            "pl17": {
                "left": 0.83,
                "top": 0.47
            },
            "pl18": {
                "left": 0.67,
                "top": 0.47
            },

        },
        'f7': {
            'gk0': {left: 0.75, top: 0.09},
            "pl1": {
                "left": 0.82,
                "top": 0.21
            },
            "pl2": {
                "left": 0.67,
                "top": 0.22
            },
            "pl3": {
                "left": 0.92,
                "top": 0.29
            },
            "pl4": {
                "left": 0.75,
                "top": 0.29
            },
            "pl5": {
                "left": 0.59,
                "top": 0.3
            },
            "pl6": {
                "left": 0.84,
                "top": 0.38
            },
            "pl7": {
                "left": 0.67,
                "top": 0.38
            },
            "pl8": {
                "left": 0.75,
                "top": 0.45
            },
            'gk1': {left: 0.25, top: 0.09},
            "pl11": {
                "left": 0.32,
                "top": 0.22
            },
            "pl12": {
                "left": 0.18,
                "top": 0.22
            },
            "pl13": {
                "left": 0.42,
                "top": 0.29
            },
            "pl14": {
                "left": 0.25,
                "top": 0.29
            },
            "pl15": {
                "left": 0.09,
                "top": 0.29
            },
            "pl16": {
                "left": 0.33,
                "top": 0.37
            },
            "pl17": {
                "left": 0.19,
                "top": 0.37
            },
            "pl18": {
                "left": 0.25,
                "top": 0.46
            }
        },
        'f8': {
            'gk0': {left: 0.75, top: 0.09},
            "pl1": {
                "left": 0.35,
                "top": 0.21
            },
            "pl2": {
                "left": 0.25,
                "top": 0.21
            },
            "pl3": {
                "left": 0.15,
                "top": 0.21
            },
            "pl4": {
                "left": 0.31,
                "top": 0.32
            },
            "pl5": {
                "left": 0.2,
                "top": 0.32
            },
            "pl6": {
                "left": 0.35,
                "top": 0.41
            },
            "pl7": {
                "left": 0.15,
                "top": 0.41
            },
            "pl8": {
                "left": 0.25,
                "top": 0.45
            },
            'gk1': {left: 0.25, top: 0.09},
            "pl11": {
                "left": 0.85,
                "top": 0.21
            },
            "pl12": {
                "left": 0.75,
                "top": 0.21
            },
            "pl13": {
                "left": 0.65,
                "top": 0.21
            },
            "pl14": {
                "left": 0.82,
                "top": 0.32
            },
            "pl15": {
                "left": 0.7,
                "top": 0.32
            },
            "pl16": {
                "left": 0.85,
                "top": 0.41
            },
            "pl17": {
                "left": 0.65,
                "top": 0.41
            },
            "pl18": {
                "left": 0.75,
                "top": 0.45
            },
        },
    },

}

export const getEquivalentNum = {
    'f1': {
        1: 2,
        2: 5,
        3: 3,
        4: 11,
        5: 6,
        6: 4,
        7: 8,
        8: 7,
        9: 9,
        10: 10,
    },
    'f2': {
        1: 3,
        2: 5,
        3: 4,
        4: 2,
        5: 10,
        6: 6,
        7: 8,
        8: 11,
        9: 9,
        10: 7,
    },
    'f3': {
        1: 3,
        2: 5,
        3: 4,
        4: 2,
        5: 11,
        6: 8,
        7: 6,
        8: 7,
        9: 9,
        10: 10,
    },
    'f4': {
        1: 2,
        2: 3,
        3: 4,
        4: 5,
        5: 6,
        6: 9,
        7: 8,
        8: 10,
        9: 7,
        10: 11,
    },
    'f5': {
        1: 'LD',
        2: 'CD',
        3: 'RD',
        4: 'DM',
        5: 'LW',
        6: 'AM',
        7: 'RW',
        8: 'CF',
    },
    'f6':{
        1:"LD",
        2:"CD",
        3:"RD",
        4:"LM",
        5:"CM",
        6:"RM",
        7:"LS",
        8:"RS",
    },
    'f7': {
        1: 'LB',
        2: 'RB',
        3: 'LM',
        4: 'DM',
        5: 'RM',
        6: 'LAM',
        7: 'RAM',
        8: 'CF',
    },
    'f8': {
        1: 'LB',
        2: 'CB',
        3: 'RB',
        4: 'LCM',
        5: 'RCM',
        6: 'LW',
        7: 'RW',
        8: 'CF',
        9: 9,
        10: 10,
    }
}

export const setPlayerDim = (name,player,canvas)=>{
    let objHeight = player.getScaledHeight();
    player.set('top',((name.top + name.getScaledHeight()+ (name.getScaledHeight()/2.5) + (objHeight/2.5) )))
    player.set('left',name.left)
    player.setCoords();
    canvas.renderAll();
}
export const setPlayerNameDim = (name,player,canvas)=>{
    if(!name) return;
    let objHeight = player.getScaledHeight();
    name.set('top',(player.top - name.getScaledHeight()- name.getScaledHeight()/2.5 - (objHeight * 0.5)))
    name.set('left',player.left)
    name.setCoords();
    canvas.renderAll()
}
export const setImageDimensions = (nameText, shirtNo, imgInstance, actObj,canvas) => {
    switch (actObj.name) {
        case 'custom_image_name':
            imgInstance.left = actObj.left;
            imgInstance.top = (nameText.top + nameText.getScaledHeight()+ nameText.getScaledHeight()/2 + imgInstance.getScaledHeight() / 2);
            shirtNo.left = (imgInstance.left) - (imgInstance.getScaledWidth() / 3) - (shirtNo.getScaledWidth() / 16);
            shirtNo.top = (imgInstance.top - 3) - ((imgInstance.getScaledHeight() / 2) - shirtNo.getScaledHeight() / 1.5);
            canvas.renderAll();
            break;
        case 'custom_image_shirtno':
            imgInstance.left = actObj.left + ((imgInstance.getScaledWidth() / 3 + actObj.getScaledWidth() / 16));
            imgInstance.top = actObj.top + ((imgInstance.getScaledHeight() / 2) - actObj.getScaledHeight() / 1.5);
            nameText.top = (imgInstance.top + imgInstance.getScaledHeight() / 2) + nameText.getScaledHeight() / 2;
            nameText.left = imgInstance.left;
            canvas.renderAll();
            break;
        default:
    }
}
export const setTextDimensions = (nameText, shirtNo, imgInstance,canvas) => {
    console.log('text dimensions called')
    if (!nameText) return;
    const imgScaledHeight = imgInstance.getScaledHeight();
    const imgScaledWidth = imgInstance.getScaledWidth();
    nameText.top = (imgInstance.top - imgScaledHeight/2) - nameText.getScaledHeight()*1.5;
    nameText.left = imgInstance.left;
    // if (nameText.width <= imgScaledWidth) nameText.set("width", imgScaledWidth);
    // else nameText.set("width", nameText.width);
    nameText.setCoords();
    if(shirtNo){
        if (shirtNo.width <= (imgScaledWidth * 0.4)) shirtNo.set("width", imgScaledWidth * 0.4);
        else shirtNo.set("width", shirtNo.width);
        shirtNo.left = (imgInstance.left) - (imgScaledWidth / 3) - (shirtNo.getScaledWidth() / 16);
        shirtNo.top = (imgInstance.top - (imgScaledHeight / 2) - 3) + (shirtNo.getScaledHeight() / 1.5);
        shirtNo.setCoords();
        shirtNo.evented = false
    }
    imgInstance.setCoords()
}
export const setImage = (obj,ur,canvas,isDropped=false,dispatch)=> {
    getFabricImageFromURL(ur,function (img) {
        if(img._element) {
            img.dirty = true;
            obj.dirty = true;
            var patternSourceCanvas = new fabric.StaticCanvas();
            const maxWidth = 40;
            //if it is a horizontal image, we set the height to 40, otherwise, we set the width to 40
            if(img.width > img.height)
                img.scaleToHeight(maxWidth);
            else
                img.scaleToWidth(maxWidth);
            let offsetX = 0;
            let offsetY = 0;
            patternSourceCanvas.setDimensions({
                width: img.getScaledWidth(),
                height: img.getScaledHeight(),
            });
            //Width of Pattern image is larger then our object, so we need to set offset
            if(patternSourceCanvas.width > maxWidth)
            {
                offsetX = (maxWidth-patternSourceCanvas.width)/2;
            }
            if(patternSourceCanvas.height > maxWidth)
            {
                offsetY = (maxWidth-patternSourceCanvas.height)/2;
            }
            patternSourceCanvas.add(img);
            patternSourceCanvas.renderAll();
            var pattern = new fabric.Pattern({
                source: patternSourceCanvas.toCanvasElement(),
                repeat: 'no-repeat',
                offsetX : offsetX,
                offsetY : offsetY,
            });
            canvas.requestRenderAll();
            obj._objects[0].set('fill', pattern)
            obj._objects[1].set('text', '');
            let nameText = canvas._objects.findIndex(f=>f.name === 'custom_image_name' && f.ref_id === obj.ref_id);
            if(nameText > -1) canvas._objects[nameText].text = `${obj.shirtNo.text} ${obj.nameText.text}`;
            if(!isDropped) canvas.add(obj)
            else{
                dispatch(copyToClipboard(obj));
                canvas.discardActiveObject();
                canvas.defaultCursor = 'crosshair';
                canvas.selection = false;
            }
        }
    })

}
export const animateShapes = (canvas, duration = 1000,player,movement=false)=> {
    if(player.fadeFlag && (player.fadeFlag === 'fade-in-out' || player.fadeFlag === 'fade-in-stay' || player.fadeFlag === 'no-fade-out')) {
        player._objects[0].animate('bgrOpacity', '1', {
            duration: duration / 3
        })
    }
    if(movement) {
        if(player.fadeFlag && (player.fadeFlag === 'fade-in-out' ||  player.fadeFlag === 'fade-out')) {
            setTimeout(() => {
                player._objects[0].animate('bgrOpacity', '0', {
                    onChange: function () {
                        player._objects[0].set('dirty', true)
                        canvas.renderAll.bind(canvas)
                    },
                    duration: duration / 1.5
                })

            }, duration / 6)

        }
    }
        // }
        // else {
        //     shapes[i].animate('opacity', '1', {
        //         onChange: canvas.renderAll.bind(canvas),
        //         duration: duration/3
        //     })
        // }
    // }
}
export const fadeOutShapes = (canvas, duration = 1000,player)=>{
    if(player.fadeFlag && (player.fadeFlag === 'fade-in-out' ||  player.fadeFlag === 'fade-out')){
        player._objects[0].animate('bgrOpacity','0',{
            duration:duration/6
        })
    }
    // let shapes = canvas._objects.filter(f => f.fadeFlag === 'fade-in-out' ||  f.fadeFlag === 'fade-out');
    //     for(let i=0; i<shapes.length; i++){
    //         if(shapes[i].name === 'player' || shapes[i].name === 'player_custom_image'){
    //             shapes[i]._objects[0].animate('bgrOpacity','0',{
    //                 onChange:function(){
    //                     shapes[i]._objects[0].set('dirty',true)
    //                     canvas.renderAll.bind(canvas)},
    //                 duration:duration/6
    //             })
    //         }
    //         else{
    //             shapes[i].animate('opacity','0',{
    //                 onChange:canvas.renderAll.bind(canvas),
    //                 duration:duration/6
    //             })
    //         }
    //
    //     }

}
export const hideOpacity = (canvas,i)=>{
    if(canvas._objects[i].fadeFlag){
    if(canvas._objects[i].fadeFlag === 'fade-in-stay' || canvas._objects[i].fadeFlag === 'fade-in-out' ) {
        if ((canvas._objects[i].name === 'player' || canvas._objects[i].name === 'player_custom_image')&& canvas._objects[i].bgrOpacity !== 0) {
            canvas._objects[i]._objects[0].bgrOpacity = 0;
            canvas._objects[i]._objects[0].set('dirty', true);
        }
        else if(canvas._objects[i].opacity !== 0) {
            canvas._objects[i].opacity = 0;
        }
        canvas.renderAll()
    }
    }
}
export const showOpacity = (canvas,i)=>{
    if(canvas._objects[i].fadeFlag){
    if(canvas._objects[i].fadeFlag === 'no-fade-out' || canvas._objects[i].fadeFlag === 'fade-out') {
        if ((canvas._objects[i].name === 'player' || canvas._objects[i].name === 'player_custom_image') && canvas._objects[i].bgrOpacity === 0) {
            canvas._objects[i]._objects[0].bgrOpacity = 1;
            canvas._objects[i]._objects[0].set('dirty', true);
        } else if(canvas._objects[i].opacity === 0) {
            canvas._objects[i].opacity = 1;
        }
    }
    }
}
export const animateNonMoveables = (canvas,time,ob)=>{
    if(ob.fadeFlag && (ob.fadeFlag === 'fade-in-out' || ob.fadeFlag === 'fade-in-stay')) {
            ob.opacity = 0;
            canvas.renderAll();

            ob.animate('opacity', '1', {
                onChange: canvas.renderAll.bind(canvas),
                duration: time/4,
                onComplete:function () {
                    setTimeout(()=>{
                        if(ob.fadeFlag && (ob.fadeFlag === 'fade-in-out' ||  ob.fadeFlag === 'fade-out')) {
                            ob.animate('opacity', '0', {
                                onChange: canvas.renderAll.bind(canvas),
                                duration: time / 6
                            })
                        }
                    },time/6)
                }
            })
    }
    else if(ob.fadeFlag && (ob.fadeFlag === 'fade-out')){

        ob.animate('opacity', '0', {
            onChange: canvas.renderAll.bind(canvas),
            duration: time / 4,
        })
    }
}
export const setConnectLineDim = (canvas,connectLine)=>{
    let ids = connectLine.ref_id.split('_')
    let objInd1 = canvas._objects.findIndex(f => (f.name === 'player' || f.name === 'player_custom_image' || f.objecttype === 'sprite-image') && f.ref_id === ids[0]);
    let objInd2 = canvas._objects.findIndex(f => (f.name === 'player' || f.name === 'player_custom_image' || f.objecttype === 'sprite-image') && f.ref_id === ids[1]);

    let from = canvas._objects[objInd1].calcTransformMatrix();
    let to = canvas._objects[objInd2].calcTransformMatrix();

    connectLine.set({ 'x1': from[4], 'y1': from[5], 'x2': to[4], 'y2': to[5] });

}
export const createSineWaveWithArrow = (color = '#ffffff', strokeWidth = 2, arrow = true, isDashed = false) => {
    const arrowDef = `
        <defs>
            <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="10" refY="3.5" orient="auto">
                <polygon points="0 0, 10 3.5, 0 7" fill="${color}" />
            </marker>
        </defs>
    `;

    const markerEnd = arrow ? 'marker-end="url(#arrowhead)"' : '';
    const dashArray = isDashed ? 'stroke-dasharray="5,5"' : '';

    // Adjusted sine wave path for smaller amplitude and higher frequency
    const wavePath = `
        M 10 100
        C 30 80, 50 120, 70 100
        C 90 80, 110 120, 130 100
        C 150 80, 170 120, 190 100
        C 210 80, 230 120, 250 100
    `;

    const svgString = `
        <svg width="260" height="200" xmlns="http://www.w3.org/2000/svg" version="1.1">
            ${arrow ? arrowDef : ''}
            <path d="${wavePath}" stroke="${color}" stroke-width="${strokeWidth}" fill="none" ${markerEnd} ${dashArray} />
        </svg>
    `;

    return `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgString)))}`;
}


export const generateVideoImage = (canvas, callback)=>{
    canvas?.discardActiveObject();
    html2canvas(document.querySelector(".canvas-container"), {
        useCORS: true,
        allowTaint: true,
    }).then(function (canvasEl) {
        const dataURL = canvasEl.toDataURL({
            width: 1920,
            height: 1080,
            format: 'png',
            quality: 1,
            multiplier: 1
        });
        callback(dataURL);
    })
}

export const activeSelectionConnectionLine = (canvas) => {
    let obj = canvas.getActiveObjects().filter((f)=>f.name === 'player' || f.name === 'player_custom_image' || f.objecttype === 'sprite-image')
    let connectLineInd = canvas._objects.filter(f => f.name === 'connectionLine');
    for (let i = 0; i < connectLineInd.length; i++) {
        for (let j = 0; j < obj.length; j++) {
            if(connectLineInd[i].ref_id.includes(obj[j].ref_id)){
                setConnectLineDim(canvas,connectLineInd[i])
            }
        }
    }
}
export const createCurvedLineSvg = (color = '#000000', strokeWidth = 2, arrow = false, isDashed = false) => {
    if(!color.length) color = '#ffffff'
    const arrowDef = `
        <defs>
            <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="10" refY="3.5" orient="auto">
                <polygon points="0 0, 10 3.5, 0 7" fill="${color}" />
            </marker>
        </defs>
    `;

    const markerEnd = arrow ? 'marker-end="url(#arrowhead)"' : '';
    const dashArray = isDashed ? 'stroke-dasharray="5,5"' : '';

    const svgString = `
        <svg width="200" height="200" xmlns="http://www.w3.org/2000/svg" version="1.1">
            ${arrow ? arrowDef : ''}
            <path d="M 20 180 Q 100 10, 180 180" stroke="${color}" stroke-width="${strokeWidth}" fill="none" ${markerEnd} ${dashArray} />
        </svg>
    `;

    return `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgString)))}`;
}
export const createStraightLineSvg = (color = '#000000', strokeWidth = 2, arrow = true, isDashed = false) => {
    if(!color.length) color = '#ffffff'
    const arrowDef = `
        <defs>
            <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="10" refY="3.5" orient="auto">
                <polygon points="0 0, 10 3.5, 0 7" fill="${color}" />
            </marker>
        </defs>
    `;

    const markerEnd = arrow ? 'marker-end="url(#arrowhead)"' : '';
    const dashArray = isDashed ? 'stroke-dasharray="5,5"' : '';

    const svgString = `
        <svg width="200" height="200" xmlns="http://www.w3.org/2000/svg" version="1.1">
            ${arrow ? arrowDef : ''}
            <line x1="20" y1="180" x2="180" y2="20" stroke="${color}" stroke-width="${strokeWidth}" ${markerEnd} ${dashArray} />
        </svg>
    `;

    return `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgString)))}`;
}


export const tacticsBoardCustomImg = (name, color = '#000000') => {
    let playerColor;
    let fullName;
    let nameArr = name;
    let imgFirstName = nameArr.length > 1 ? nameArr[0][0] : '';
    let imgLastName = nameArr.length > 1 ? nameArr[nameArr.length - 1][0] : '';
    fullName = nameArr.length > 1 ? `${imgFirstName}${imgLastName}` : `${imgFirstName}`;
    if (color === '#fff' || color === '#F7CF00') {
        playerColor = 'black'
    } else {
        playerColor = 'white'
    }
    const svgString = `<svg width='128' height='128' viewBox='0 0 128 128' xmlns='http://www.w3.org/2000/svg' version='1.1' >
                            <circle cx='64' cy='64' r='59' fill='${color}' stroke='rgba(255, 255, 255, 0.9)' stroke-width='8'/>
                            <text text-anchor='middle' x='50%' y='50%' dy='.35em' text-align="center" line-height="24px" font-weight="400" font-family='Bahnschrift' font-size='70px' fill='${playerColor}'>${fullName}</text>
                        </svg>`;
    return `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgString)))}`;
}

export const tacticsBoardToolsPlayerTriangle = (number, color = '#000000') => {
    let playerColor;
    if (color === '#fff' || color === '#F7CF00') {
        playerColor = 'black'
    } else {
        playerColor = 'white'
    }

    const svgString = `
    <svg width='240' height='240' viewBox='0 0 128 128' xmlns='http://www.w3.org/2000/svg' version='1.1' >
        <polygon fill='${color}' points="62 27, 112 112, 12 112" stroke='rgba(255, 255, 255, 0.9)' stroke-width='8' />
        <text text-anchor='middle' x="60" y="84" dy='.35em' text-align="center" line-height="24px" font-weight="400" font-family='Bahnschrift' font-size='40px' fill='${playerColor}'>${number}</text>
    </svg>
    `;
    return `data:image/svg+xml;base64,${btoa(svgString)}`;
}
// Function to check if a string contains Arabic or Hebrew characters
export function checkHebrew(input) {
    var arabicHebrewRegex = /[\u0600-\u06FF\u0750-\u077F\u0590-\u05FF]/;
    if (arabicHebrewRegex.test(input)) {
        return true;
    } else {
        return false;
    }
}

export const getLongestObject = (canvas)=>{
    let objs = canvas._objects.filter(f=>f.is_animation);
    if(objs.length){
        objs = objs.sort((firstObj, lastObj)=>(lastObj.time - firstObj.time));
        return objs[0];
    }
}

export const isObjectMoving = (obj, frameNo)=>{
    const pathLen = document.getElementById(`line-svg:${frameNo}:${obj.ref_id}__shadow-object`);
    if (pathLen) {
        let pathLength = Math.ceil(pathLen.getTotalLength());
        return !(pathLength <= 1)
    }
    else return false;
}
export const detectMovementInFrame = (canvas, frameNo) =>{
    let objs = canvas._objects.filter(f=>f.is_animation && isObjectMoving(f, frameNo));
    return !!objs.length
}
