import {fabric} from "fabric";
import {isTri} from "../updateObjectsStates";

export const canvasSetBorders = (canvas,isMobile) => {
    if (!canvas) return;
    let controls = {
        tl: false, //top-left
        mt: false, // middle-top
        tr: false, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: false, // bottom-left
        mb: false, //middle-bottom
        br: false, //bottom-right
        mtr: false, // rotate icon
        editctr: false, // edit text
        leftAction: false, //left Action
        rightAction: false, //Right Action
        flipAction:false,
        loaderAction: false,
        rotateAction: false,//angle rotate action

    }
    const activeObject = canvas.getActiveObject();
    if (!activeObject) return;
    if (window.innerWidth < 1100 || isMobile()) {
        fabric.Object.prototype.setControlsVisibility(controls)
    } else {
        if (activeObject.type === 'rect') {
            fabric.Object.prototype.setControlsVisibility({ ...controls, mr: true, mb: true, br: true, mtr: true })
        } else {
            fabric.Object.prototype.setControlsVisibility({ ...controls, br: true, mtr: true })
        }
        if (activeObject.name === 'player_custom_image' || activeObject.name === 'player') {
            fabric.Object.prototype.setControlsVisibility({ ...controls, editctr: false, br: false, loaderAction: false, rotateAction: activeObject._objects[2]?.direction, mtr:isTri(activeObject)})
        }
    }
    if (activeObject.type === 'activeSelection') {
        activeObject.setControlsVisibility({ ...controls, editctr: false, leftAction: activeObject.multiSelectAction(), rightAction: activeObject.multiSelectAction() })
    }
    if (activeObject.name === 'shape') {
        activeObject.selectionBackgroundColor = ''
        activeObject.hasBorders = true
    }
    if (activeObject.type === 'activeSelection') {
        activeObject._objects.forEach(o => o.set('borderScaleFactor', 1));
        activeObject.set('borderScaleFactor', 1)
    } else activeObject.set('borderScaleFactor', 5)
    if (activeObject.objecttype === 'sprite-image') {
        fabric.Object.prototype.setControlsVisibility({ ...controls, editctr: false })
    }
}