import React from "react";
import './styles.scss';

const PleaseRotate = () => {
    return (
        <div className="pleaseRotate">
            <div className="pleaseRotate-inner">
                <div className="phone">
                </div>
                <div className="message">
                    Please rotate your device!
                </div>
            </div>
        </div>
    )
}
export default PleaseRotate