import {fabric} from "fabric";

export const makeArrow = (left, top, line, props, angle = -45, customProps = false) => {
    var arrow = new fabric.Triangle({
        left: left,
        top: top,
        originX: 'center',
        originY: 'center',
        hasBorders: false,
        hasControls: false,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        selectable: true,
        angle,
        width: props.pa.width || (props.scaleProps.height / 2) - 4,
        height: props.pa.height || (props.scaleProps.height / 2) - 4,
        fill: props.color,
        name: "curvearrow",
        opacity:customProps ? props.opacity  : 1,
    });
    return arrow
}