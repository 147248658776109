import {setColorPopup, setLoader} from "../actions";
import {useDispatch} from "react-redux";
import {waitForTime} from "../utils/animations";

export const useAddFrame = ()=>{
    const dispatch = useDispatch();
    const appendFrame = async (props)=>{
        let {setActiveFrameTime,state,
            canvas,
            canvas2,
            frames,allFrames,shadowFrames,updateFrameObjects,stopEvents,convertToJson,
            deleteShadowObjects,setFrames,setActiveFrame,activeFrameVal,buildCanvas,frameObjects,
            addShadowObjects,reInitArrowLines,sendBackShapes,updateRemovedProps, resolve} = props;
            dispatch(setColorPopup(false));

        setActiveFrameTime(3000)
        // Discard active object.
         if (canvas.getActiveObject()) canvas.discardActiveObject();
         canvas.renderAll();
         ///
         canvas2.clear()
         let newFrame = {
             name: `frame${Math.random()}`,
             data_num: frames.length,
             json: convertToJson(canvas2),
             undoStates: [{
                 target: "init",
                 name: "init",
                 ref_id: "init",
                 json: frames.length > 0 ? convertToJson(canvas) : convertToJson(canvas2),
                 action: "init",
             }],
             activeStateIndex: 0,
             time: 2500,
             delay:1,
         }
         const cFrames = [...frames];
         cFrames.push(newFrame);
         setFrames(cFrames);
         allFrames = cFrames;
         updateRemovedProps(false,false,false,{allFrames})

         // for shadow

         let frameObjs = [...frameObjects];
         frameObjs.push({
             frame: newFrame.name,
             objects: [],
             arrowLines: [],
             shadowLines: []
         })
         shadowFrames = frameObjs;
         updateRemovedProps(false,false,{shadowFrames});
         updateFrameObjects("add-all");
         ///end for shadow
         if (state) {
             stopEvents()
             await deleteShadowObjects();
             cFrames[activeFrameVal.data_num].json = convertToJson(canvas);
             setFrames(cFrames)
             allFrames = cFrames;
             setActiveFrame(newFrame);
             activeFrameVal = newFrame;
             updateRemovedProps({allFrames,activeFrameVal})
             await buildCanvas(canvas, newFrame.json, true, cFrames, activeFrameVal);
             await addShadowObjects(activeFrameVal);
             reInitArrowLines();
             setTimeout(() => {
                 sendBackShapes(canvas);
                 canvas.renderAll();
                 resolve();
             }, 100)


         } else {
             setActiveFrame(newFrame);
             activeFrameVal = newFrame;
             updateRemovedProps({allFrames,activeFrameVal});
             canvas.renderAll();
             resolve();
         }

    }
    return {appendFrame}
}