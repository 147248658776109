import {fabric} from "fabric";
import {changePlayerAction, flipObj} from "../actionUtils";
import {renderIcon} from "./renderIcon";
import {BASE_URL_ASSETS, rotateIcon} from "../utils";
import {animatedLoader, leftAction, rightAction} from "./controlIcons";

export const handleUpdateControls = (canvas)=>{

    var loaderImg = document.createElement('img');
    loaderImg.src = animatedLoader;
    fabric.Object.prototype.controls.loaderAction = new fabric.Control({
        x: 0,
        y: 0.5,
        offsetY: 20,
        offsetX: 3,
        cursorStyle: 'pointer',
        actionName: 'left',
        mouseUpHandler: () => changePlayerAction('left', canvas),
        render: renderIcon(loaderImg),
        cornerSize: 20,
        withConnection: false
    });

    var leftImg = document.createElement('img');
    leftImg.src = leftAction;
    fabric.Object.prototype.controls.leftAction = new fabric.Control({
        x: 0,
        y: 0.5,
        offsetY: 20,
        offsetX: 12,
        cursorStyle: 'pointer',
        actionName: 'left',
        mouseUpHandler: () => changePlayerAction('left', canvas),
        render: renderIcon(leftImg),
        cornerSize: 20,
        withConnection: false
    });
    var rightImg = document.createElement('img');
    rightImg.src = rightAction;
    fabric.Object.prototype.controls.rightAction = new fabric.Control({
        x: 0,
        y: 0.5,
        offsetY: 20,
        offsetX: -12,
        cursorStyle: 'pointer',
        actionName: 'right',
        mouseUpHandler: () => changePlayerAction('right', canvas),
        render: renderIcon(rightImg),
        cornerSize: 20,
        withConnection: false
    });
    var flipImg = document.createElement('img');
    flipImg.src =`${BASE_URL_ASSETS}/assets/icons/flip.png`;

    fabric.Object.prototype.controls.flipAction = new fabric.Control({
        x: 0.2,
        y: 1,
        offsetY: 20,
        offsetX: -12,
        cursorStyle: 'pointer',
        actionName: 'flip',
        mouseUpHandler: () => flipObj(canvas),
        render: renderIcon(flipImg),
        cornerSize: 20,
        withConnection: false
    });


    var img = document.createElement('img');
    img.src = rotateIcon;
    fabric.Object.prototype.controls.mtr = new fabric.Control({
        x: 0,
        y: -0.6,
        offsetY: -20,
        cursorStyle: 'pointer',
        actionHandler: fabric.controlsUtils.rotationWithSnapping,
        actionName: 'rotate',
        render: renderIcon(img),
        cornerSize: 26,
        withConnection: false
    });
    var editImg = document.createElement('img');
    editImg.src = rightAction;
    fabric.Object.prototype.controls.editctr = new fabric.Control({
        x: -0.5,
        y: 0.4,
        cursorStyle: 'pointer',
        actionName: 'textBox',
        render: renderIcon(editImg, 'editCtr'),
        cornerSize: 40,
        withConnection: false,
    })
}