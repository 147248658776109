export const downloadFile = async ({ fileUrl = "", fileName, callback }) => {
    const extension = fileUrl.split('.')?.pop();
    try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        // a.target = '_blank'
        a.download = `${fileName || Math.random()}.${extension}`;
        a.click();
        callback && callback()
    } catch (e) {
        console.log(e.message);
        callback && callback()
    }
}
export const downloadImage = async ({ fileUrl = "", extKey, fileName }) => {
    const extension = extKey.split('.')?.pop();
    try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${fileName || Math.random()}.${extension}`;
        a.click();
    } catch (e) {
        let a = document.createElement('a');
        a.href = fileUrl;
        a.click();
        console.log(e.message);
    }
}
export const downloadLink = async ({ fileUrl = "", fileName }) => {
    const extension = fileUrl.split('.')?.pop();
    try {
        let a = document.createElement('a');
        a.href = fileUrl;
        a.download = `${fileName || Math.random()}.${extension}`;
        // a.target = "_blank";
        a.click();
    } catch (e) {
        console.log(e.message);
    }
}

export const getFileFromBase64 = async (dataUrl, type = 'image/png') => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], `${Math.random()}.png`, { type });
}

export const getMobileView = ()=>{
        return window.innerWidth < 992
}