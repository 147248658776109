import React, {useEffect, useRef} from 'react';
import { Button } from 'antd';

import {useDispatch, useSelector} from 'react-redux';
import { FormattedMessage } from 'react-intl';
import './frames.scss';
import {setLoader} from "../../actions";
import {waitForTime} from "../../utils/animations";
const TacticsBoardAnimationsFrames = ({ addMoreFrame, removeFrame, updateFrame, activeFrame, frames }) => {
    const { direction } = useSelector(state => state.editorSettings);
    const frameRef = useRef(null);
    const loader = useSelector(state => state.loader);
    const dispatch = useDispatch();


    useEffect(() => {
        if (frameRef.current) {
            frameRef.current.scrollLeft = frameRef.current.scrollWidth;
        }
    }, [frames]);
    const addNewFrame = async ()=>{
        dispatch(setLoader(true));
        await waitForTime(0);
        await addMoreFrame(true);
        dispatch(setLoader(false));
    }
    return (
        <>
        <div dir={direction} className='tacticsboard__animations_frames' ref={frameRef}>
            {
                frames.map((frame, i) => {
                    return (
                        <div  onClick={() => updateFrame(frame.name === activeFrame.name ? "" : frame)} key={i} className={`tacticsboard__animations_frame ${activeFrame?.data_num === frame?.data_num && 'primary__background'}  ${loader && 'disabled'}}`} >
                            {activeFrame?.data_num === frame?.data_num && <div onClick={() => removeFrame(frame)} className='tacticsboard__animations_frame_remove background__red' ><span>x</span></div>}
                            {activeFrame?.data_num === frame?.data_num ? `${i + 1}/${frames.length}` : i + 1}
                        </div>
                    )
                })
            }
        </div>
    <Button dir={direction} onClick={() => addNewFrame()} type='primary' size='small' shape='round' className='tacticsboard__animations_frames_add' ><FormattedMessage id="general.addFrame" /></Button>
</>
)
}

export default TacticsBoardAnimationsFrames;