import React, {useEffect} from "react";
import { InputNumber } from "antd";
import {DelayIcon} from "../../assets/icons";
import {useSelector} from "react-redux";
import {getLongestObject} from "../../utils/utils";
const DelayAnimationMenu = ({activeFrame,  setActiveFrame, updateRemovedProps,frames,setFrames})=>{

    const {defaultDelay} = useSelector(state => state.player);
    const canvas = useSelector(state => state.canvas);
    const updateAllObjectsTime = (frameDelay)=>{
        let objs = canvas.getObjects();
        for(let obj of objs){
            obj.time = frameDelay;
        }
    }
   useEffect(()=>{
       if(canvas && typeof activeFrame !== ('string')) {
           let longestObj = getLongestObject(canvas);
           const frameDelaytime = longestObj?.time / 1000 ;
           setAnimationDelay(frameDelaytime || 1.5, true)
       }
   },[defaultDelay])


    const setAnimationDelay = (delay, reRendered=false)=>{
        if(!reRendered) updateAllObjectsTime(delay*1000);
        let newActiveFrame = {...activeFrame, delay}
        setActiveFrame(newActiveFrame)
        let newFrames= frames?.map((frame)=>{
            if(frame?.data_num === activeFrame?.data_num){
                return newActiveFrame
            }
            else{
                return frame
            }
        });
        setFrames(newFrames);
        updateRemovedProps({allFrames: newFrames, activeFrameVal: newActiveFrame})
    }

    return(
        <div  className='time-container'>

                    <InputNumber className={'speed-bar'} min={0.1} max={10} defaultValue={1} value={activeFrame?.delay || 1}
                                 step = {0.5}
                                 onChange={(e)=>setAnimationDelay(e)}

                    />

            <DelayIcon fill={'#000000'}/>
        </div>
    )
}
export default DelayAnimationMenu;