/**
 * Changes the sizes of objects on canvas according to the screen size
 * @param containerDimensions
 * @returns {{strokeWidth: number, lineSelectorHeight: number, width: number, lineHeight: number, fontWeight: number, height: number}}
 */
import {getMobileView} from "../../components/helpers";

export  const getObjectDimensions = (containerDimensions) => {
    let fontWeight = 500;
    let percentFactor = getMobileView()?10:5;
    let perctangeWidth = (5 / 100) * containerDimensions.width
    let perctangeHeight = (5 / 100) * containerDimensions.height

    if (containerDimensions.width < 700) {
        fontWeight = 400
    }
    if (containerDimensions.width < 500) {
        fontWeight = 300
    }
    return {
        height: perctangeHeight,
        width: perctangeWidth,
        strokeWidth: perctangeWidth / 14,
        fontWeight: fontWeight,
        lineHeight: perctangeHeight * 2,
        lineSelectorHeight: perctangeHeight * .75,
    }
}