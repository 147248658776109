import {fabric} from "fabric";
import {sendBackShapes} from "../../shapes/shapeUtils";
import {transformedPoint} from "./transformedPoint";

export const initializePath = ({e, callback, canvas, reAddActivePoint, returnObject, stopEvents, setLineToCircle, setObjectPadding, startEvents, isDropped})=>{
    if (e.target) {
        if (e.target.objecttype === 'curve_line') {
            let refId = e.target.ref_id;
            if (e.target.name === "pX") refId = e.target.ref_id.split("::")[0];
            let is_dashed = false;
            var p0 = returnObject(refId, "p0")
            var p1 = returnObject(refId, "p1")
            var p2 = returnObject(refId, "p2")
            var pa = returnObject(refId, "pa")
            var line = returnObject(refId, "line");
            let lineIndex = canvas._objects.indexOf(line);
            const strokeWidthVal = line?.strokeWidth || 16
            if (line.strokeDashArray) {
                is_dashed = true
            }
            p0.setCoords();
            p1.setCoords();
            p2.setCoords();
            reAddActivePoint();
            canvas.remove(line);
            var path = line.path;
            if (e.target.name == 'line') {
                var transformedPoints = transformedPoint(line);
                path = [
                    ['M', transformedPoints[0].x, transformedPoints[0].y],
                    ['Q', transformedPoints[2].x, transformedPoints[2].y, transformedPoints[1].x, transformedPoints[1].y]
                ];
            };
            line = new fabric.Path(isDropped?line.path:path, {
                perPixelTargetFind: true,
                fill: '',
                stroke: line.stroke,
                strokeWidth: strokeWidthVal,
                objectCaching: false,
                name: "line",
                objecttype: "curve_line",
                ref_id: refId,
                widthFactor: line.widthFactor,
                fadeFlag: line.fadeFlag,
                opacity: line.newOpacity,
                newOpacity: line.newOpacity
            });
            line.hasBorders = line.hasControls = false;
            if (is_dashed) {
                line.set({ strokeDashArray: [5, 5] })
            }
            stopEvents();
            canvas.add(line);
            if (pa) {
                line.objecttype = p0.objecttype = p1.objecttype = p2.objecttype = pa.objecttype = "curve_line"
                line.p0 = p1.p0 = p2.p0 = pa.p0 = p0;
                line.p1 = p0.p1 = p2.p1 = pa.p1 = p1;
                line.p2 = p1.p2 = p0.p2 = pa.p2 = p2;
                line.pa = p0.pa = p1.pa = p2.pa = pa;
                p0.line = p1.line = p2.line = pa.line = line;
                canvas.sendBackwards(pa);
            } else {
                line.objecttype = p0.objecttype = p1.objecttype = p2.objecttype = "curve_line"
                line.p0 = p1.p0 = p2.p0 = p0;
                line.p1 = p0.p1 = p2.p1 = p1;
                line.p2 = p1.p2 = p0.p2 = p2;
                p0.line = p1.line = p2.line = line;
            }
            setLineToCircle(p1, null, line, null)
            setLineToCircle(p0, line, p1, null);
            setLineToCircle(p2, null, p1, line);
            setObjectPadding(line, 20, 10)
            if(lineIndex)  line.moveTo(lineIndex)
            startEvents();
            if (e.state) {
                const pointInd = canvas._objects.findIndex((o) => o.name === "pX" && o.ref_id.includes(refId));
                if (pointInd > -1) {
                    canvas._objects[pointInd].opacity = 0;
                    canvas._objects[pointInd].evented = false;
                }
                p0.opacity = p1.opacity = p2.opacity = 0
                p0.selectable = p1.selectable = p2.selectable = false
                canvas.renderAll()
            } else {
                canvas.setActiveObject(line)
            }
            // canvas.sendBackwards(line);
            // sendBackShapes(canvas);
            if (typeof callback === "function") {
                callback(line);
            }

        }
    }
}