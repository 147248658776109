const colorsState = (state = true, action)=>{
    if(action.type === "SET_COLORS_STATE"){
        state = action.state
    }
    if(action.type === "RESET_CANVAS"){
        state = true;
    }

    return state;
}

export default colorsState;
