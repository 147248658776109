export const getContainerHeight = (props)=>{
    let {
        el,isAnimationOpen,isOld,bPanel
    } = props;
    let windowHeight = '';
    if (isAnimationOpen) {
        windowHeight = window.innerHeight * 0.9;
    } else
        windowHeight = window.innerHeight - 50;
    const windowWidth = window.innerWidth

    let width = el.clientWidth
    let height
    if (isOld) {
        width = width > 950 ? 950 : width
        height = width * .65
        width = windowWidth < 1250 && windowWidth > 992 ? width - 50 : width
    } else if (window.innerWidth < 992) {
        let ratio = 1.40
        height = window.innerHeight - (bPanel?55:0);
        width = height * ratio
    } else {
        height = width * .72

        if (height > windowHeight) {
            for (var i = height; i > windowHeight; i - 50) {
                width = width - 50
                height = width * .72
                i = height
            }
        }
    }

    return {
        width: width,
        height: height,
    }
}