import {fabric} from "fabric";
import {setTextDimensions} from "./utils";

export const handleTextSelection = (dObj,updated,stopEvents,startEvents,getSurName,canvas)=>{
    let nameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === dObj.ref_id);
    let shirtInd = canvas._objects.findIndex(f => f.name === 'custom_image_shirtno' && f.ref_id === dObj.ref_id);
    let imgInd = canvas._objects.findIndex(f => f.name === 'player_custom_image' && f.ref_id === dObj.ref_id);
    let spritePlayerInd = canvas._objects.findIndex(f => (f.name === 'image' || f.name === 'player') && f.ref_id === dObj.ref_id);
    let shirtItems = canvas._objects.filter(f => (f.ref_id === dObj.ref_id) && (f.name === 'shirtCircle' || f.name === 'custom_image_shirtno'));
    stopEvents();
    // let newName = getSurName(canvas._objects[nameInd].text);
    canvas._objects[nameInd].text = canvas._objects[nameInd].text;
    canvas.renderAll();
    startEvents()

    if (nameInd > -1 && shirtInd > -1 && imgInd > -1) {
        canvas._objects[imgInd].evented = true;
        canvas._objects[imgInd].setTextPosition(canvas._objects[nameInd],canvas);
        if (!updated) {
            canvas.discardActiveObject();
            canvas.setActiveObject(canvas._objects[imgInd]);
            canvas.renderAll()
        }
    }
    else if(spritePlayerInd >-1 && nameInd > -1){
        if (!updated) {
            canvas.discardActiveObject();
            canvas.setActiveObject(canvas._objects[spritePlayerInd]);
            canvas.renderAll()
        }
    }
};