import {setAnimationStates} from "../actions";
import {fabric} from "fabric";
import {useDispatch} from "react-redux";

export const useStopAnimation = ()=>{
    const dispatch = useDispatch();
    const handleStopAnimation = (props)=>{
        let {canvas,reCreateAnimationProps, setFirstFrameActive,hasAnimationStarted,animationPlaying,isAnimating,setFrControlVisibility,
            setDownloadingStatus,hasAnimationStopped,setAnimationStatus, setPlayStatus} = props;
        canvas.hasAnimationStarted = false;
        setFrControlVisibility(true);
        setDownloadingStatus('');
        setAnimationStatus(true);
        setPlayStatus(false);
        dispatch(setAnimationStates({
            play: false,
            pause: false,
        }))
        canvas.backgroundImage = null;
        hasAnimationStarted = false;
        isAnimating = false;
        reCreateAnimationProps(false,false,{animationPlaying,hasAnimationStarted,isAnimating})
        fabric.window.hasAnimationStarted = false;
        // setFirstFrameActive()
        return
    }
    return {handleStopAnimation}
}