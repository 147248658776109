import {colorHex, colorName, getEquivalentNum, getFormations} from "./utils";
import {fabric} from "fabric";
import {addCustomPlayerImage} from "./addCustomPlayerImage";
import {addLine} from "./lines/addLine";
import {addArrow} from "./lines/addArrow";
import {addZigzagLine} from "./lines/zigZagLine";
import {toRgba} from "./toRgba";
import {moveLine} from "./lines/moveLine";
import {copyToClipboard, setLoader} from "../actions";

const defaultFormation = {
    action: false,
    index: 0,
    imageFileName: ''
}

const removeFormations = (canvas, team) => {
    return new Promise(resolve => {
        let promises = [];
        let objs = canvas._objects;
        for (let ind = 0; ind < objs.length; ind++) {
            promises[ind] = new Promise((res, rej) => {
                let obj = objs[ind];
                if (obj.formation && (obj.team === team)) {
                    canvas.remove(obj)
                }
                res('resolved')
            })
        }
        Promise.all(promises).then(val => {
            resolve('resolved')
        })
    })
}


const addCharFormation = (props)=>{
    let {pitch, pitchVal,cWidth, cHeight, objColor1, canvas, dispatch, team, scaleProps, formationFn, formationSizeVal,
        target, addImage, addPlayer, uuid, isMobileView, formationSize, id} = props;
    switch (pitch) {
        case 'p1':
        case 'p2':
        case 'p3':
        case 'p4':
        case 'p5':
        case 'p6':
        case 'p7':
        case 'p8':
            let goalieAction = getFormations[team === 'left' ? pitch + 'R' : pitch]?.goali || getFormations[pitch]?.goali;
            let playerAction = getFormations[team === 'left' ? pitch + 'R' : pitch]?.player || getFormations[pitch]?.player;
            let gLeft = getFormations[pitch][target.id]['gk'].left;
            let gkArr = getFormations[team === 'left' ? pitch + 'R' : pitch] || getFormations[pitch];
            let gTop = gkArr[target.id][`gk`].top;
            if(pitch !== 'p3' && team === 'right'){
                if(pitch !== 'p3' && team === 'right'){
                    gTop = 1-gTop;
                    gLeft = 1-gLeft;
                }
            }
            let colName = colorName[`${objColor1}`];
            let promises = [];

            // height: isMobileView ? scaleProps.height * 2.70 : scaleProps.height * 4.0
            let attackingSrc = `https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/Attacking/Goali/${goalieAction}.png`
            addImage('sprite-image', attackingSrc, gLeft * cWidth, gTop * cHeight, {
                ...scaleProps, height: scaleProps.height * formationSizeVal
            }, [], true, {
                action: true,
                index: 0,
                imageFileName: `Players:Goali_${goalieAction}_medium`,
                team: team
            }, formationFn)

            for (let i = 1; i <= 10; i++) {
                if (['f5', 'f6', 'f7', 'f8'].includes(target.id) && i > 8) continue;
                promises [i] = new Promise(resolve => {
                    let pArr = getFormations[team === 'left' ? pitch + 'R' : pitch] || getFormations[pitch];
                    let pTop = pArr[target.id][`pl${i}`].top;
                    let pLeft = pArr[target.id][`pl${i}`].left;
                    if(pitch !== 'p3' && team === 'right'){
                        pTop = 1-pTop;
                        pLeft = 1-pLeft;
                    }
                    let attackingSrc = `https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/Attacking/${colName}/${playerAction}.png`
                    addImage('sprite-image', attackingSrc, pLeft * cWidth, pTop * cHeight, {
                            ...scaleProps, height: scaleProps.height * formationSizeVal
                        }
                        , [], true, {
                            action: true,
                            index: i,
                            imageFileName: `Players:${colName}_${playerAction}_medium`,
                            team: team,
                            resolve
                        }, formationFn, colName)

                })
            }
            Promise.all(promises).then(res => {
                dispatch(setLoader(false));
                canvas.discardActiveObject()
            })
            break;
        case 'p9':
            let goalieActionD = getFormations[team === 'left' ? pitch + 'R' : pitch]?.goali || getFormations[pitch]?.goali;
            let playerActionD = getFormations[team === 'left' ? pitch + 'R' : pitch]?.player || getFormations[pitch]?.player;
            setTimeout(() => {
                let colName = colorName[`${objColor1}`];
                let plrPromises = [];
                for (let i = 0; i < 2; i++) {
                    let gLeft = getFormations[pitch][target.id][`gk${i}`].left;
                    let gTop = getFormations[pitch][target.id][`gk${i}`].top;
                    if (team === 'right') {
                        gLeft = 1 - gLeft;
                        gTop = 1 - gTop;
                    }
                    let attackingSrc = `https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/Attacking/Goali/${goalieAction}.png`
                    addImage('sprite-image', attackingSrc, gLeft * cWidth, gTop * cHeight, {
                        ...scaleProps, height: scaleProps.height * formationSizeVal
                    }, [], true, {
                        action: true,
                        index: 0,
                        imageFileName: `Players:Goali_${goalieActionD}_medium`,
                        team: team
                    }, formationFn)
                }
                for (let i = 1; i <= 20; i++) {
                    console.log(pitch,target.id,`pl${i}`)
                    if (['f5', 'f6', 'f7', 'f8'].includes(target.id) && [9, 10, 19, 20].includes(i)) continue;
                    let pTop =getFormations[pitch][target.id][`pl${i}`].top;
                    let pLeft = getFormations[pitch][target.id][`pl${i}`].left;
                    if (team === 'right') {
                        pLeft = 1 - pLeft;
                        pTop = 1 - pTop;
                    }
                    plrPromises[i] = new Promise(resolve => {
                        let attackingSrc = `https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/Attacking/${colName}/${playerAction}.png`
                        addImage('sprite-image', attackingSrc, pLeft * cWidth, pTop * cHeight, {
                                ...scaleProps, height: scaleProps.height * formationSizeVal
                            }
                            , [], true, {
                                action: true,
                                index: i,
                                imageFileName: `Players:${colName}_${playerActionD}_medium`,
                                team: team,
                                resolve
                            }, formationFn, colName)
                    });

                }
                Promise.all(plrPromises).then(val=>{
                    dispatch(setLoader(false));
                    canvas.discardActiveObject()
                })

            }, 0)
            break;
    }
}
const addCircleFormation = (props,isShirt=false, showNumbers)=>{
    let {pitch, pitchVal,cWidth, cHeight, objColor1, canvas, dispatch, team, scaleProps, formationFn, formationSizeVal,
        target, addImage, addPlayer, uuid, isMobileView, formationSize,  id} = props;
    switch (pitch) {
        case 'p1':
        case 'p2':
        case 'p3':
        case 'p4':
        case 'p5':
        case 'p6':
        case 'p7':
        case 'p8':
            setTimeout(() => {
                let nLeft = getFormations[pitch][target.id][`gk`].left;
                let nTop = getFormations[pitch][target.id][`gk`].top;
                let playerPromises = [];
                if(['p3'].includes(pitch)){
                    let gkArr = getFormations[team === 'left' ? pitch + 'R' : pitch] || getFormations[pitch];
                    nLeft = getFormations[pitch][target.id]['gk'].left;
                    nTop = gkArr[target.id][`gk`].top;
                }
                if (team === 'right' && pitch !=='p3') {
                    nLeft = 1 - nLeft;
                    nTop = 1 - nTop;
                }
                addPlayer({
                    isSvg:isShirt,
                    left: nLeft * cWidth,
                    top: nTop * cHeight,
                    text: showNumbers? (['f5','f6','f7','f8'].includes(target.id)?'GK':'1'): "",
                    ref_id: id,
                    color: objColor1,
                    team: team,
                    fontSize: 20
                }, scaleProps, isMobileView ? formationSize : formationSize, {action: true, index: 0})
                for (let i = 1; i <= 10; i++) {
                    if(['f5','f6','f7', 'f8'].includes(target.id) && i>8) continue;
                    playerPromises[i] = new Promise(res => {
                        let idd = uuid.v4();
                        let nLeft = getFormations[pitch][target.id][`pl${i}`].left;
                        let nTop = getFormations[pitch][target.id][`pl${i}`].top;
                        if(['p3'].includes(pitch)){
                            let pArr = getFormations[team === 'left' ? pitch + 'R' : pitch] || getFormations[pitch];
                            nTop = pArr[target.id][`pl${i}`].top;
                            nLeft = pArr[target.id][`pl${i}`].left;
                        }


                        if (team === 'right' && pitch !== 'p3') {
                            nLeft = 1 - nLeft;
                            nTop = 1 - nTop;
                        }
                        let num = getEquivalentNum[target.id][i];
                        addPlayer({
                            isSvg:isShirt,
                            left: nLeft * cWidth,
                            top: nTop * cHeight,
                            text: showNumbers ?`${num}`:"",
                            ref_id: idd,
                            color: objColor1,
                            team: team,
                            fontSize: 20,
                            res
                        }, scaleProps, isMobileView ? formationSize : formationSize, {action: true, index: i})
                    })
                }
                Promise.all(playerPromises).then(val => {
                    console.log('all players added')
                    canvas.discardActiveObject()
                    dispatch(setLoader(false));

                })
            }, 0)
            break;
        case 'p9':
            setTimeout(() => {
                let plrPromises = []
                for (let i = 0; i < 2; i++) {
                    let nLeft1 = getFormations[pitch][target.id][`gk${i}`].left;
                    let nTop1 = getFormations[pitch][target.id][`gk${i}`].top;
                    if (team === 'right') {
                        nLeft1 = 1 - nLeft1;
                        nTop1 = 1 - nTop1;
                    }
                    addPlayer({
                        isSvg:isShirt,
                        left: nLeft1 * cWidth,
                        top: nTop1 * cHeight,
                        text: showNumbers? (['f5','f6','f7','f8'].includes(target.id)?'GK':'1'):"",
                        ref_id: id,
                        color: objColor1,
                        team: team,
                        fontSize: 20
                    }, scaleProps, isMobileView ? formationSize : formationSize, {action: true, index: 0})
                }
                for (let i = 1; i <= 20; i++) {
                    if(['f5','f6','f7','f8'].includes(target.id) && [9,10,19,20].includes(i)) continue;
                    plrPromises[i] = new Promise(res => {
                        let idd = uuid.v4();
                        let num = getEquivalentNum[target.id][i > 10 ? i - 10 : i];
                        let nLeft = getFormations[pitch][target.id][`pl${i}`].left;
                        let nTop = getFormations[pitch][target.id][`pl${i}`].top;
                        if (team === 'right') {
                            nLeft = 1 - nLeft;
                            nTop = 1 - nTop;
                        }
                        addPlayer({
                            isSvg:isShirt,
                            left: nLeft * cWidth,
                            top: nTop * cHeight,
                            text: showNumbers?`${num}`:"",
                            ref_id: idd,
                            color: objColor1,
                            team: team,
                            res,
                            fontSize: 20
                        }, scaleProps, isMobileView ? formationSize : formationSize, {action: true, index: 0})
                    })
                }
                Promise.all(plrPromises).then(val => {
                    canvas.discardActiveObject()
                    dispatch(setLoader(false));
                })
            }, 0)
            break;
    }
}

export const handleToolsClick = async (props) => {
    const {e, type, event, canvas, addImage, setLoader1, drawQuadratic, drawQuadraticArrow, disableDrawer, scalePropsVal, startEvents, stopEvents, addPlayer, addFreeShape, setActiveObject, showMobileTools, getSurName, actColor, isMobileView, setObjectPadding, updateUndoStates, moveEnd2, canvasVar, dispatch} = props;
     e.stopPropagation();
    const uuid = require("uuid");
    const id = uuid.v4();
    if (!e.target) return;
    let target = e.target;
    if (e.target.classList.contains("custom-drawer-images") || e.target.classList.contains("player-custom-image")) {
        target = e.target.parentElement
    }
    const scaleProps = scalePropsVal
    let objColor = 'White';
    let borderColor = 'White';

    if (target.classList.contains("tacticsboard__feature_icon")) {
        disableDrawer()
    }

    let left, top

    if (type === 'drag') {
        left = canvas.getPointer(event.e).x
        top = canvas.getPointer(event.e).y
    } else {
        if (canvas.vptCoords.br) {
            left = canvas.vptCoords.br.x / 2
            top = canvas.vptCoords.br.y / 2
        } else {
            left = canvas.width / 2
            top = canvas.height / 2
        }
    }

    switch (true) {
        case target.classList.contains("formation-element"):
            dispatch(setLoader(true));
            let team = target.classList[1];
            let formationSize = target.classList[2];
            let  formationType = target.classList[3];
            let showNumbers = target.classList[4] === "true";
            console.log('formation type',formationType);
            let formationSizeVal, formationFn;
            switch (formationSize) {
                case 'small':
                    formationSizeVal = 2.70
                    formationFn = 18;
                    break;
                case 'medium':
                    formationSizeVal = 3.2
                    formationFn = 18;
                    break;
                case 'large':
                    formationSizeVal = 4.0
                    formationFn = 18
                    break;
            }
            stopEvents();
            await removeFormations(canvas, team);
            startEvents();
            let pitchVal = canvas.wrapperEl.classList[1];
            let pitch = pitchVal.slice(-2);
            let cWidth = canvas.width / canvas.getZoom();
            let cHeight = canvas.height / canvas.getZoom();
            let objColor1 = actColor.playerFormation;
            const props = {pitch, pitchVal,cWidth, cHeight, objColor1, canvas, dispatch, team, scaleProps, formationFn, formationSizeVal,
                target, addImage, addPlayer, uuid, isMobileView, formationSize,id
            };
            if(formationType === 'shirt'){
                addCircleFormation(props,true, showNumbers)
            }
            else if(formationType === 'circle'){
                addCircleFormation(props, false, showNumbers)
            }
            else{
                addCharFormation(props)
            }

            break;
        case target.classList.contains("shape-element"):
            var shape = null;
            const targetId = target.id.split('::')[0]
            objColor = target.id.split('::')[1];
            let borderType = target.id.split('::')[3];
            if (target.id.split.length > 1) borderColor = target.id.split('::')[2];

            if (typeof objColor === 'string' && objColor[0] !== '#') objColor = colorHex[objColor]
            switch (targetId) {
                case "square_solid":
                    shape = new fabric.Rect({
                        name: "shape",
                        width: scaleProps.width,
                        height: scaleProps.width,
                        originX: 'center',
                        originY: 'center',
                        left: left,
                        top: top,
                        shapeType: "solid",
                        objecttype: targetId,
                        strokeUniform: true,
                        ref_id: id,
                        is_animation: false,
                        strokeWidth: 2.5,
                        strokeDashArray: borderType === 'dotted' ? [3, 2] : null
                    });
                    shape.stroke = borderColor;
                    shape.fill = toRgba(objColor, 0.4);
                    shape.bgOpacity = 0.4;
                    break;
                case "oval_solid":
                    shape = new fabric.Ellipse({
                        rx: scaleProps.width / 1.2,
                        ry: scaleProps.height / 1.2,
                        left: left,
                        top: top,
                        fill: objColor,
                        shapeType: "solid",
                        objecttype: targetId,
                        strokeUniform: true,
                        ref_id: id,
                        originX: 'center',
                        originY: 'center',
                        name: "shape",
                        is_animation: false,
                        strokeWidth: 2.5,
                        strokeDashArray: borderType === 'dotted' ? [3, 2] : null
                    });
                    shape.stroke = borderColor;
                    shape.fill = toRgba(objColor, 0.4);
                    shape.bgOpacity = 0.4;
                    break;
                case "star_outlined":
                    shape = addFreeShape();
                    shape.strokeWidth = 0.5;
                    shape.stroke = borderColor;
                    shape.fill = toRgba(objColor, 0.4);
                    shape.bgOpacity = 0.4;
                    shape.strokeDashArray = borderType === 'dotted' ? [2, 1] : null
                    break;
                case "ellipse_solid":
                    shape = new fabric.Circle({
                        name: "shape",
                        radius: scaleProps.height,
                        left: left,
                        top: top,
                        originX: 'center',
                        originY: 'center',
                        fill: objColor,
                        shapeType: "solid",
                        objecttype: targetId,
                        strokeUniform: true,
                        ref_id: id,
                        is_animation: false,
                        strokeWidth: 2.5,
                        strokeDashArray: borderType === 'dotted' ? [3, 2] : null
                    });
                    shape.stroke = borderColor;
                    shape.fill = toRgba(objColor, 0.4);
                    shape.bgOpacity = 0.4;
                    break;
            }
            shape.set('fadeFlag', 'no-fade-out');
            dispatch(copyToClipboard(shape));
            canvas.discardActiveObject();
            canvas.defaultCursor = 'crosshair';
            canvas.selection = false;
            break;
        case target.classList.contains("text-element"):
            objColor = target.id.split('_')[0];

            const text = new fabric.IText("", {
                left: left,
                top: top,
                fontSize: scaleProps.height / 2,
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
                ref_id: id,
                fill: objColor,
                name: "text",
                originX: 'center',
                originY: 'center',
                editable: true,
                is_animation: false,
                bgOpacity: 0.6,
                initialSize: scaleProps.height / 2
            });
            dispatch(copyToClipboard(text));
            canvas.discardActiveObject();
            canvas.defaultCursor = 'crosshair';
            canvas.selection = false;
            break;
        case target.classList.contains("arrow-element"):
            const targetId1 = target.id.split('::')[0]
            objColor = target.id.split('::')[1];

            switch (targetId1) {
                case "arrow_zigzag":
                    addZigzagLine({
                        left: left,
                        top: top,
                        is_zigzag: true,
                        is_dashed: false,
                        ref_id: id,
                        color: objColor,
                        scaleProps
                    }, canvas, canvasVar, dispatch, true)
                    break;
                case "simple_solid":
                    addLine({
                        left: left,
                        top: top,
                        is_dashed: false,
                        ref_id: id,
                        color: objColor,
                        scaleProps,
                        dispatch
                    }, false, setObjectPadding, moveLine, updateUndoStates, setActiveObject, canvas, true)
                    break;
                case "simple_dashed":
                    addLine({
                        left: left,
                        top: top,
                        is_dashed: true,
                        ref_id: id,
                        color: objColor,
                        scaleProps,
                        dispatch
                    }, false, setObjectPadding, moveLine, updateUndoStates, setActiveObject, canvas, true)
                    break;
                case "arrow_solid":
                    addArrow({
                        left: left,
                        top: top,
                        is_dashed: false,
                        ref_id: id,
                        color: objColor,
                        scaleProps
                    }, false, {
                        setObjectPadding,
                        moveLine,
                        moveEnd2,
                        canvas,
                        setActiveObject,
                        updateUndoStates,
                        dispatch
                    }, true)
                    break;
                case "arrow_dashed":
                    addArrow({
                        left: left,
                        top: top,
                        is_dashed: true,
                        ref_id: id,
                        color: objColor,
                        scaleProps
                    }, false, {
                        setObjectPadding,
                        moveLine,
                        moveEnd2,
                        canvas,
                        setActiveObject,
                        updateUndoStates,
                        dispatch
                    }, true)
                    break;
                case "curvy_solid":
                    dispatch(copyToClipboard({
                        left: left,
                        top: top,
                        is_dashed: false,
                        ref_id: id,
                        color: objColor,
                        scaleProps,
                        objecttype: 'curve_line'
                    }));
                    canvas.discardActiveObject();
                    canvas.defaultCursor = 'crosshair';
                    canvas.selection = false;
                    break;
                case "curvy_dashed":
                    dispatch(copyToClipboard({
                        left: left,
                        top: top,
                        is_dashed: true,
                        ref_id: id,
                        color: objColor,
                        scaleProps,
                        isArrow: true,
                        objecttype: 'curve_line'
                    }));
                    canvas.discardActiveObject();
                    canvas.defaultCursor = 'crosshair';
                    canvas.selection = false;
                    break;
                case "curvyarrow_solid":
                    dispatch(copyToClipboard({
                        left: left,
                        top: top,
                        is_dashed: false,
                        ref_id: id,
                        color: objColor,
                        scaleProps,
                        isArrow: true,
                        objecttype: 'curve_line'
                    }));
                    canvas.discardActiveObject();
                    canvas.defaultCursor = 'crosshair';
                    canvas.selection = false;
                    break;
                case "curvyarrow_dashed":
                    dispatch(copyToClipboard({
                        left: left,
                        top: top,
                        is_dashed: true,
                        ref_id: id,
                        color: objColor,
                        scaleProps,
                        isArrow: true,
                        objecttype: 'curve_line'
                    }));
                    canvas.discardActiveObject();
                    canvas.defaultCursor = 'crosshair';
                    canvas.selection = false;
                    break;
            }
            break;
        case target.classList.contains("icon-element"):
            const src = target.querySelector(".custom-drawer-images").getAttribute("src");
            const type = target.getAttribute("dataType");
            if (type === 'player' || type === 'ball')
                addImage(type, src, left, top, scaleProps, [], true, defaultFormation, 0, 'LightBlue', true)
            else
                addImage(type, src, left, top, scaleProps, [], false, defaultFormation, 0, 'LightBlue', true)
            break;
        case target.classList.contains("Equipments-element"):
            dispatch(setLoader(true));
            const ids = target.getAttribute("id");
            const elementss = ids.split("-");
            const folderName = elementss[0];
            const fileName = elementss[1];
            setTimeout(()=>{
                let s3Src = `https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/${folderName}/${fileName}.png`;
                addImage('equipments', s3Src, left, top, {
                    ...scaleProps,
                    height: scaleProps.height * 1.5
                }, [target.getAttribute('ratiofactor'), `Equipments:${target.id}`], false, defaultFormation, 0, 'LightBlue', true);
            },0)
            break;
        case target.classList.contains("Gate-element"):
            dispatch(setLoader(true));
            const idd = target.getAttribute("id")
            setTimeout(()=>{
                let gateSrc = `https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/Gate/${idd}.png`;
                // import(`../assets/images/Gate/${idd}.png`).then(srcSprite => {
                addImage('equipments', gateSrc, left, top, {
                    ...scaleProps,
                    height: scaleProps.height * 3.5
                }, [target.getAttribute('ratiofactor'), `Gate:${idd}`], false, defaultFormation, 0, 'LightBlue', true);
                // });
            },0)
            break;
        case target.classList.contains("player-element"):
            const dir = target.classList[2];
            const isPlayer = (target.classList[3] === 'playerNo');
            const fontSize = isPlayer ? 20 : 15;
            const num = target.id.split("-")[1];
            const sizeFactor = target.id.split("-")[2];
            objColor = actColor.playerNo;
            if (target.classList.contains("player-element-triangle")) {
                addPlayer({
                    polygon: 'triangle',
                    left: left,
                    top: top,
                    text: num,
                    ref_id: id,
                    color: objColor,
                    team: dir,
                    isSingle: true
                }, scaleProps, sizeFactor, {action: false, index: 0}, null, true)
            }
            else if (target.classList.contains("player-element-shirt")) {
                addPlayer({
                    isSvg:true,
                    left: left,
                    top: top,
                    text: num,
                    ref_id: id,
                    color: objColor,
                    team: dir,
                    isSingle: true,
                    fontSize
                }, scaleProps, sizeFactor, {action: false, index: 0}, null, true)
            }

            else {
                addPlayer({
                    left: left,
                    top: top,
                    text: num,
                    ref_id: id,
                    color: objColor,
                    team: dir,
                    isSingle: true,
                    fontSize
                }, scaleProps, sizeFactor, {action: false, index: 0}, null, true)
            }
            break;
        case target.classList.contains("player-custom-image"):
            if (!target?.hasAttribute("data-playercustom")) return
            const name = target.id;
            const imgSrc = target.getAttribute('datasrc');
            objColor = actColor.playerSquad;
            let squadTeam = target.classList[2];
            let squadShowNumbers = target.classList[3] === "true";
            addCustomPlayerImage(canvas, imgSrc, {
                left: left,
                top: top,
                text: name,
                ref_id: id,
                color: objColor,
                team: squadTeam,
                showPlayerNumbers: squadShowNumbers
            }, {
                ...scaleProps,
                height: scaleProps.height * 2.70
            }, setActiveObject, getSurName, isMobileView, true, dispatch)
            break;
        case target.classList.contains("sprites-element"):
            const targetID = target.id.split("_");
            const ratioFactor = target.getAttribute('ratiofactor');
            const sFactor = targetID[2];
            let sizeVal, fn;
            switch (sFactor) {
                case 'small':
                    sizeVal = 2.70
                    fn = 14;
                    break;
                case 'medium':
                    sizeVal = 3.2
                    fn = 14;
                    break;
                case 'large':
                    sizeVal = 4.0
                    fn = 14
                    break;
            }
            objColor = targetID[0];
            const imageName = targetID[1];
            dispatch(setLoader(true));
            setTimeout(()=>{
                let attackingSrc = `https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/Attacking/${objColor}/${imageName}.png`
                // import(`../assets/Attacking/${objColor}/${imageName}.png`).then(srcSprite => {
                addImage('sprite-image', attackingSrc, left, top, {
                    ...scaleProps,
                    height: scaleProps.height * sizeVal
                }, [ratioFactor, `Players:${target.id}`], true, false, fn, objColor, true)
                // dispatch(setLoader(false));
                // });
            },0)
            break;

    }
    showMobileTools(false);
};
