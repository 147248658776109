const draw = (state = false, action)=>{
    if(action.type === "SET_DRAW_STATE"){
        state = action.state
    }
    if(action.type === "RESET_CANVAS"){
        state = false;
    }

    return state;
}

export default draw;