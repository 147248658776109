const animation = (state = false, action)=>{
    if(action.type === "SET_ANIMATION"){
        state = action.state
    }
    if(action.type === "RESET_CANVAS"){
        state = false;
    }

    return state;
}

export default animation;