import React, {useState, useEffect, useRef} from 'react';
import './colors.scss';
import { useSelector, useDispatch } from 'react-redux';
import { setColor, setTextBackCol } from '../../actions';
import {ArrowPlayer, CrossIcon} from "../../assets/icons";
import {Button} from "antd";
import Draggable from "react-draggable";

let COLORS = [] ;
const panelColors = {
    '1':'players',
    '4':'tools',
    '5':'shapes',
    '6':'equipments',
    '7':'playerFormation',
    '8':'playerSquad',
    '9':'playerSquadNumbers',
    '10':'playerNo',
    '11':'playerNo',
    '12':'playerPositions',
};
const TacticsBoardToolsColors = ({setOpenColorPopup, modifiedObject, isBackgroundColor = false, textElement = false ,panelKey='' }) => {

    const leftDir = document.body.dir === 'ltr' || document.body.dir === '';

    const dispatch = useDispatch();

    const activeColor = useSelector(state => state.color)
    const canvas = useSelector(state => state.canvas)
    const colorsState = useSelector(state => state.colorsState)
    const textBackCol = useSelector(state => state.textBackColor);

    const [textColor, setTextColor] = useState("");
    const [objectColor, setObjectColor] = useState("");
    const [spritePitches, setSpritePitches] = useState(false)

    if(panelKey === "1" || panelKey === "6" || (panelKey === "8" && spritePitches)){
        COLORS = ["#003E60", "#35ACDD", "#F7CF00", "#C40E12", "#fff", "#23803C"]
    }else{
        COLORS = ["#003E60", "#35ACDD", "#F7CF00", "#C40E12", "#fff", "#23803C", "#000000"]
    }

    const updateColor = (color) => {
        let colObj = {...activeColor};
        if (panelKey) {
            if (panelKey === '1') colObj = {...colObj, players: color};
            if (panelKey === '4') colObj = {...colObj, tools: color};
            if (panelKey === '5') colObj = {...colObj, shapes: color};
            if (panelKey === '6') colObj = {...colObj, equipments: color};
            if (panelKey === '7') colObj = {...colObj, playerFormation: color};
            if (panelKey === '8') colObj = {...colObj, playerSquad: color};
            if (panelKey === '9') colObj = {...colObj, playerSquad: color,  playerSquadNumbers: color};
            if (panelKey === '10') colObj = {...colObj, playerNo: color};
            if (panelKey === '11') colObj = {...colObj, playerNo: color};
            if (panelKey === '12') colObj = {...colObj, playerPositions:color , playerNo: color};
            if (panelKey === '13') colObj = {...colObj, playerShirts:color , playerNo: color};
            dispatch(setColor(colObj));
        }
    }
    const updateTextColor = (color) => {
        const object = canvas.getActiveObject();
        if (object) {
            if (isBackgroundColor)
                object.set({ backgroundColor: color })
            else {
                object.set({ fill: color })
            }
            dispatch(setTextBackCol(color));
            canvas.renderAll()
            modifiedObject({ target: object, state: true })
        }
    }

    useEffect(() => {
        if (textColor) {
            updateTextColor(textColor);
        }
    }, [textColor]);

    useEffect(() => {
        if (objectColor) {
            updateColor(objectColor);
        }
    }, [objectColor]);
    useEffect(() => {
        let pitchVal = canvas?.wrapperEl.classList[1];
        let pitch = pitchVal && pitchVal.slice(-2);
        switch (pitch) {
            case 'p3':
            case 'p4':
            case 'p5':
            case 'p6':
                setSpritePitches(true)
                break;
            case 'p1':
            case 'p2':
            case 'p7':
            case 'p8':
            case 'p9':
                setSpritePitches(false)
                break;
        }
    }, []);
    return (
        <div className={`tacticsboard__colors_container color_popup ${colorsState ? '' : 'd__none'} ${!leftDir ? 'tacticsboard__colors_container-r':''}`}>
            <div className='tacticsBoard_colors_body'>
                {COLORS.map(color => <div key={color} data-color={color} onClick={() => updateColor(color)}
                                          className={`tacticsboard__colors_color ${activeColor[panelColors[panelKey]] === color ? 'blue__border_heavy' : 'newGray__border_light'}`}
                                          style={{backgroundColor: color}}/>)}
            </div>
        </div>
    )
}

export default TacticsBoardToolsColors;