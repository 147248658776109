import {shadow} from "../utils";

export  const setSelectionBackground = (object,canvas) => {
    const bgLessObjNames = ['drawLine', 'line', 'arrow_line', 'Line Arrow', 'zigzag_arrow_line', 'p0', 'p1', 'pX', 'p2', 'square1', 'square2', 'shadow-line']
    if (object.type === 'activeSelection') {
        for (let i = 0; i < object._objects.length; i++) {
            if (bgLessObjNames.includes(object._objects[i].name)) {
                object._objects[i].set({selectionBackgroundColor: ''})
                object._objects[i].set({shadow: shadow})
            }
        }
        canvas.renderAll();
        return;
    } else {
        if (bgLessObjNames.includes(object.name) || object.objecttype === 'shadow_curve_line' || object.objecttype === 'shadow_curve_point') {
            object.set({selectionBackgroundColor: ''})
            canvas.renderAll();
        } else {
            if (object.name === 'player' || object.objecttype === 'sprite-image') {
                object.set({shadow: ""})
                canvas.renderAll();
            }
        }
    }
}