const settings = (state = { state: false, text: "0" }, action) => {
    if (action.type === "SET_SETTINGS_STATE") {
        state = {
            direction: action.action.direction
        }
    }
    if (action.type === "RESET_CANVAS") {
        state = { state: false, text: "0" };
    }
    return {
        ...state,
        direction: "ltr",
    };
}

export default settings;