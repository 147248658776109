import {fabric} from "fabric";

export const transformedPoint = (target) => {
    const points = [];
    const path = target.path;
    points.push(new fabric.Point(path[0][1], path[0][2]));
    points.push(new fabric.Point(path[1][3], path[1][4]));
    points.push(new fabric.Point(path[1][1], path[1][2]));
    const matrix = target.calcTransformMatrix();
    const xArray = [path[0][1], path[1][3], path[1][1]];
    const yArray = [path[0][2], path[1][4], path[1][2]];
    const minX = Math.min(...xArray);
    const maxX = Math.max(...xArray);
    const maxY = Math.max(...yArray);
    const minY = Math.min(...yArray);
    return points
        .map(p => {
                let pointX, pointY;
                if (path[1][1] >= path[0][1]) {
                    pointX = p.x - minX - (target.width / 2);
                } else {
                    pointX = p.x - maxX + (target.width / 2);
                }
                if (path[1][2] >= path[0][2]) {
                    pointY = p.y - minY - (target.height / 2);
                } else {
                    pointY = p.y - maxY + (target.height / 2);
                }
                return new fabric.Point(pointX, pointY)
            }
        ).map(p => fabric.util.transformPoint(p, matrix));
}