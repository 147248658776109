import React from 'react';
import {FormattedMessage} from "react-intl";
import {Button} from "antd";
import {SvgIcon} from "@mui/material";
import {
    CirclePlayerIcon, PlayersIcon,

} from "../../assets/icons";
import {useDispatch, useSelector} from "react-redux";
import {getMobileView} from "../helpers";
import {setCircleImg} from "../../actions";

const SquadType = () => {
    const {circleImg} = useSelector(state => state.player);
    const dispatch = useDispatch();

    return (
        <div className={`size_container`}>
            <span> <FormattedMessage id="Squad Type"/></span>
            <div className="expandable_sizes size-buttons">
                <Button onClick={() => dispatch(setCircleImg(true))} icon={<SvgIcon><PlayersIcon
                    fill={circleImg ? 'blue' : getMobileView() ? '#000000' : 'white'}/></SvgIcon>}/>
                <Button onClick={() => dispatch(setCircleImg(false))} icon={<SvgIcon><CirclePlayerIcon
                    fill={!circleImg ? 'blue' : getMobileView() ? '#000000' : 'white'}/></SvgIcon>}/>
            </div>
        </div>
    )
}
export default SquadType;