export const setFreeDrawControls = (object,canvas,prevArX,prevArY,prevX,prevY,updateDrawControls) => {
    let endPInd = canvas._objects.findIndex(f => f.name === 'line-end-point_shadow-object' && f.ref_id === object.ref_id);
    if (endPInd > -1) {
        canvas._objects[endPInd].opacity = 0.5;
        canvas._objects[endPInd].evented = true;
        let type = canvas._objects[endPInd].brushType;
        let otherEndPs = canvas._objects.filter(f => f.name === 'line-end-point_shadow-object' && f.ref_id !== object.ref_id);
        for (let i = 0; i < otherEndPs?.length; i++) {
            otherEndPs[i].opacity = 0;
            otherEndPs[i].evented = false;
        }
        let prevXInd = prevX.findIndex(g => g.ref_id === object.ref_id);
        let prevYInd = prevY.findIndex(g => g.ref_id === object.ref_id);
        if (prevXInd > -1) {
            prevX[prevXInd].x = object.left;
            prevY[prevYInd].y = object.top;
        } else {
            prevX.push({ ref_id: object.ref_id, x: object.left });
            prevY.push({ ref_id: object.ref_id, y: object.top });
        }
        prevArX = object.left;
        prevArY = object.top;
        updateDrawControls({prevX,prevY,prevArX,prevArY});

        if (type === "simple" || type === "arrow" || type === "block") {
            canvas.freeDrawingBrush.strokeDashArray = 0;
        } else {
            canvas.freeDrawingBrush.strokeDashArray = [10, 5]
        }
    }
}

export  const addContinuePoint = (objs, o,canvas, continueDraw, prevArX, prevArY, prevX, prevY, updateDrawControls) => {
    if (o.name === 'drawLine') {
        let endPInd = canvas._objects.findIndex(f => f.name === 'line-end-point_shadow-object' && f.ref_id === o.ref_id);
        let selectedP = objs.findIndex(i => i.name === 'line-end-point_shadow-object' && i.ref_id === o.ref_id);

        if (endPInd > -1 && selectedP == -1) {
            let px = prevX.find(f => f.ref_id === o.ref_id);
            let py = prevY.find(f => f.ref_id === o.ref_id);
            const leftDiff = px.x - o.left;
            const topDiff = py.y - o.top;
            canvas._objects[endPInd].left = canvas._objects[endPInd].left - leftDiff;
            canvas._objects[endPInd].top = canvas._objects[endPInd].top - topDiff;

            let newEndPoint = continueDraw(1, 2, canvas._objects[endPInd].left, canvas._objects[endPInd].top, canvas._objects[endPInd].brushType, 'none', o.ref_id);
            canvas.remove(canvas._objects[endPInd]);
            canvas.add(newEndPoint);
            canvas.renderAll();
            let prevXInd = prevX.findIndex(g => g.ref_id === o.ref_id);
            let prevYInd = prevY.findIndex(g => g.ref_id === o.ref_id);
            if (prevXInd > -1) {
                prevX[prevXInd].x = o.left;
                prevY[prevYInd].y = o.top;
                updateDrawControls({prevArX, prevArY, prevX, prevY})
            }
        }
    }
}