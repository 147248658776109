const easyTacticsBoard = (state={customPlayerImagesJson :JSON.stringify([
        {
            "name": " שלומי דנה",
            "imageUrl": "https://images.unsplash.com/photo-1587691592099-24045742c181?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1473&q=80",
            "shirtNo": "0"
        },
        {
            "name": " Osama",
            "imageUrl": "https://encrypted-tbn2.gstatic.com/licensed-image?q=tbn:ANd9GcTOH7v9X1Y0sB1fCgK2Hj9Sz9gGVZ8XiaB6mMADnAxhDt6vx7igOJJASEwU6JqJTLlb2-_l84Ge5gk1LjU",
            "shirtNo": "10"
        },
        {
            "name": " Ali",
            "imageUrl": "https://encrypted-tbn2.gstatic.com/licensed-image?q=tbn:ANd9GcQlj3rCfLHry58AtJ8ZyBEAFPtChMddDSUSjt7C7nV3Nhsni9RIx5b0-n7LxfgerrPS6b-P-u3BOM3abuY",
            "shirtNo": "7"
        },
        {
            "name": " Alex",
            "imageUrl": "",
            "shirtNo": "6"
        }
    ])},action)=>{

    if(action.type === "SET_CUSTOM_JSON"){
        state.customPlayerImagesJson = action.state
    }
    return state
}

export default easyTacticsBoard;
