import {moveLine} from "./moveLine";

export const reinitLineObject = (obj,canvas,returnObject,setLineToCircle) => {
    const id = obj.ref_id
    switch (obj.objecttype) {
        case "simple_line":
            var square1 = returnObject(id, "square1")
            var square2 = returnObject(id, "square2")
            var line = returnObject(id, "arrow_line")

            switch (obj.name) {
                case "square1":
                    obj.opacity = 0
                    obj.square2 = square2
                    obj.line = line
                    break;
                case "square2":
                    obj.opacity = 0
                    obj.square1 = square1
                    obj.line = line
                    break;
                case "arrow_line":
                    obj.square1 = square1
                    obj.square2 = square2
                    break;
            }
            moveLine(line)
            break;
        case "arrow_line":
            var square1 = returnObject(id, "square1")
            var square2 = returnObject(id, "square2")
            var line = returnObject(id, "arrow_line")
            var arrow = returnObject(obj.ref_id, "arrow")

            switch (obj.name) {
                case "square1":
                    obj.opacity = 0
                    obj.arrow = arrow
                    obj.square2 = square2
                    obj.line = line
                    break;
                case "square2":
                    obj.opacity = 0
                    obj.arrow = arrow
                    obj.square1 = square1
                    obj.line = line
                    break;
                case "arrow_line":
                    obj.arrow = arrow
                    obj.square1 = square1
                    obj.square2 = square2
                    break;
            }
            moveLine(line);
            break;
        case "curve_line":
            switch (obj.name) {
                case "line":
                    var p0 = returnObject(obj.ref_id, "p0");
                    var p1 = returnObject(obj.ref_id, "p1");
                    var p2 = returnObject(obj.ref_id, "p2");
                    var pa = returnObject(obj.ref_id, "pa");

                    const middlePointActInd = canvas._objects.findIndex((o) => o.ref_id.includes(obj.ref_id) && o.name === "pX");
                    if (!p0) {
                        canvas.remove(p1);
                        canvas.remove(canvas._objects[middlePointActInd]);
                        canvas.remove(obj);
                        if (pa) {
                            canvas.remove(pa)
                        }
                        canvas.renderAll();
                        return;
                    }
                    if (pa) {
                        obj.p0 = p1.p0 = p2.p0 = pa.p0 = p0;
                        obj.p1 = p0.p1 = p2.p1 = pa.p1 = p1;
                        obj.p2 = p1.p2 = p0.p2 = pa.p2 = p2;
                        obj.pa = p0.pa = p1.pa = p2.pa = pa;
                        p0.line = p1.line = p2.line = pa.line = obj;
                    } else {
                        obj.p0 = p1.p0 = p2.p0 = p0;
                        obj.p1 = p0.p1 = p2.p1 = p1;
                        obj.p2 = p1.p2 = p0.p2 = p2;
                        p0.line = p1.line = p2.line = obj;
                    }

                    setLineToCircle(p1, null, obj, null)
                    setLineToCircle(p0, obj, p1, null);
                    setLineToCircle(p2, null, p1, obj);
                    p0.opacity = p1.opacity = p2.opacity = 0
                    p0.selectable = p1.selectable = p2.selectable = false
                    if (middlePointActInd > -1) {
                        canvas._objects[middlePointActInd].opacity = 0;
                        canvas._objects[middlePointActInd].selectable = false;
                    }
                    if (pa) {
                        pa.selectable = false
                    }
                    canvas.renderAll()
                    break;
            }
            break;
    }
}