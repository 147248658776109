import React from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';

const AppDrawerSimpleFooter = ({ footerLabelId, footerLabel, footerActionLabel, footerActionLabelId, onFooterAction, footerActions }) => {

    const { direction } = useSelector(state => state.editorSettings);

    return (
        <div className={`app-drawer-simple-footer ${direction}`} >
            <div className='app-drawer-simple-footer-label color__black' >{footerLabelId ? <FormattedMessage id={footerLabelId} /> : footerLabel}</div>
            <div>
                {
                    footerActions || <Button shape="round" type="primary" onClick={onFooterAction} >{footerActionLabelId ? <FormattedMessage id={footerActionLabelId} /> : footerActionLabel}</Button>
                }
            </div>
        </div>
    );
}

export default AppDrawerSimpleFooter;