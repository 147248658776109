import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .card-header {
    a {
      color: #0464b3 !important;
    }
  }

  .ant-collapse {
    border: none !important;
  }
  .ant-collapse:hover {
    border: none !important;
  }
  .ant-collapse-item {
    border: none !important;
  }
  .ant-collapse-item:hover {
    border: none !important;
  }
  .ant-collapse-header {
    border: none !important;
  }
  .ant-collapse-header:hover {
    border: none !important;
  }

  .avatar {
    color: #000000 !important;
    background-color: #ffffff !important;
  }

  .avatar_primary {
    display: flex !important;
    color: #ffffff !important;
    align-items: center !important;
    justify-content: center !important; 
    background-color: #0464b3 !important;
  }

  .ant-btn {
    &:hover {
      color: #0464b3 !important;
      border-color: #0464b3 !important;
    }
  }

  .ant-btn-ghost {
    color: #0464b3 !important;
    border-color: #0464b3 !important;
    &:hover, &:focus {
      color: #0464b3 !important;
      border-color: #0464b3 !important;
    }
  }

  .ant-btn-danger {
    color: #ffffff !important;
    border-color: transparent !important;
    &:hover {
      color: #ffffff !important;
      border-color: transparent !important;
    }
  }

  .ant-btn-text {
    &:hover {
      border-color: transparent !important;
      color: #0464b3 !important;
    }
  }

  .ant-btn-icon-only {
    &:focus, &:hover {
      border-color: transparent !important;
    }
  }

  .ant-btn-primary {
    color: #ffffff !important;
    border-color: #0464b3 !important;
    background-color: #0464b3 !important;
    &:focus, &:hover {
      color: #ffffff !important;
      border-color: #0464b3 !important;
      background-color: #0464b3 !important;
    }
  }

  .ant-modal-content, .ant-modal-header {
    // border-radius: 16px !important;
  }

  .ant-modal-header {
    top: 0px !important;
    z-index: 500 !important;
    position: sticky;
  }

  ::selection {
    color: #ffffff !important;
    background-color: #0464b3 !important;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: #0464b3 !important;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #0464b3 !important;
  }
  .ant-radio-inner:after {
    background-color: #0464b3 !important;
  }

  .ant-checkbox-input:focus, .ant-checkbox-inner {
    border-color: #0464b3 !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0464b3 !important;
    border-color: #0464b3 !important;
  }
  .ant-checkbox-checked::after {
    border: 1px solid #0464b3 !important;
  }

  .primary__border {
    color: #0464b3 !important;
    border: 1px solid #0464b3 !important;
  }

  .primary__background {
    color: #ffffff !important;
    background-color: #0464b3 !important;
  }
  
  .modal__confirm_ltr .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body {
    text-align: left !important;
  }
  .modal__confirm_rtl .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body {
    text-align: right !important;
  }

  .modal__confirm_ltr .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .anticon {
    float: left !important;
    margin-left: 0px !important;
    margin-right: 16px !important;
  }
  .modal__confirm_rtl .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .anticon {
    float: right !important;
    margin-right: 0px !important;
    margin-left: 16px !important;
  }

  .modal__confirm_ltr .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns {
    float: right !important;
    direction: ltr !important;
  }
  .modal__confirm_rtl .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns {
    float: left !important;
    direction: rtl !important;
    > button {
      margin-left: 0px !important;
      margin-right: 8px !important;
    }
  }

  .ant-collapse-content-box, .ant-collapse-header {
    padding: 2px 0px !important;
  }

  .primary__text {
    cursor: pointer !important;
    &:hover {
      text-decoration: underline !important;
      color: #0464b3 !important;
    }
  }
  .primary__subtext {
    color: #0464b3 !important;
  }
  .primary__link {
    color: #000000 !important;
    cursor: pointer !important;
    &:hover {
      text-decoration: underline !important;
      color: #0464b3 !important;
    }
  }

  .primary__border_heavy {
    border: 2px solid #0464b3 !important;
  }

  .primary__border_marked {
    cursor: pointer !important;
    border: 8px solid #fff !important;
  }
  .primary__border_mark:hover {
    cursor: pointer !important;
    border: 8px solid #fff !important;
  }

  .shade__background {
    background-color: '#cde8fe !important;
    &:hover {
      background-color: '#cde8fe !important;
    }
  }
  .shade__background.shade__background_on_hover {
    background-color: '#cde8fe !important;
    &:hover {
      background-color: '#cde8fe !important;
    }
  }
  .ant-tooltip-inner {
    font-size: 13px !important;
    border-radius: 8px !important;
  }

  .white__color {
    color: #ffffff !important;
  }
  .black__color {
    color: #000000 !important;
  }
  .primary__color {
    color: #0464b3 !important;
  }

  .circular__primary_button {
    color: #0464b3 !important;
    border: 1px solid #0464b3 !important;
    &:hover, &:focus {
      color: #0464b3 !important;
      border: 1px solid #0464b3 !important;
    }
  }

  .primary__icon {
    fill: #0464b3 !important;
    color: #0464b3 !important;
  }

  .primary__on_hover:hover {
    background-color: #0464b3 !important;
  }

  .data__table {

    .ant-input:focus {
        border-color: #0464b3 !important;
    }

    .ant-input {
      font-size: 13px !important;
      font-weight: 300 !important;
    }

    .ant-input-affix-wrapper {
      width: 44px !important;
      height: 32px !important;
      padding: 0px 4px !important;
    }  

    .match__report_table_auto {
      .ant-input:active {
        border-color: transparent !important;
      }
      .ant-input:focus {
        border-color: transparent !important;
      }
    }

    .ant-select-focused
    .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-open .ant-select-selector {
        border-color: #0464b3 !important;
    }

    /*Do something on focus, in this case show dashed border*/
    [type="checkbox"].primary__checkbox:focus + label:before {
        border: 1px dashed #0464b3 !important;
    }

    /*Do something on hover, in this case change the image color*/
    [type="checkbox"].primary__checkbox + label:before {
        color: #0464b3 !important;
    }

    /*Do something on hover, in this case change the image color*/
    [type="checkbox"].primary__checkbox:hover + label:before {
        color: #0464b3 !important;
    }
    
  }

  .app-simple-drawer {
    .ant-drawer-header {
        flex: 0 0 52px !important;
        color: #5A5FE7 !important;
        background-color: #5A5FE7 !important;
        padding: 16px 144px;
        @media (max-width: 992px) {
            height: 48px !important;
        }
    }
    .ant-drawer-body {
        flex: 1 1 auto !important;
    }
  }

  .primary__border_marked {
    border: 8px solid #0464b3 !important;
    background-color: #0464b3 !important;
    &:hover {
      border: 8px solid #0464b3 !important;
      background-color: #0464b3 !important;
    }
  }


  .ant-spin-dot-item {
    background-color: #0464b3 !important;
  }

  .ant-radio-button-wrapper {
    border: none !important;
    border-bottom: 1px solid #bdbdbd !important;
    &:hover {
      color: #0464b3 !important;
    }
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    font-weight: 500 !important;
    background-color: transparent !important;
    color: #0464b3 !important;
    border-bottom: 2px solid #0464b3 !important;
  }
  .ant-radio-button.ant-radio-button-checked {
    border-color: #0464b3 !important;
  }

  // SHADES

  .shade__background_on_hover {
    &:hover {
      background-color: #515153 !important;
    }
  }

  .pale__shade_background {
    background-color: #f5f5f5 !important;
    &:hover {
      background-color: #f5f5f5 !important;
    }
  }

  .ant-carousel {
    .slick-next {
      right: 10px !important;
      z-index: 1000 !important;
      color: #0464b3 !important;
      &::before {
        content: "";
      }
    }
    .slick-prev {
      left: 10px !important;
      z-index: 1000 !important;
      color: #0464b3 !important;
      &::before {
        content: "";
      }
    }
  }

`;

export default GlobalStyle;