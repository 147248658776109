import React, {useEffect, useState} from "react";
import TacticsBoard from "./index";
import {useIntl} from 'react-intl';

const FootBallApp = ()=>{
    const [jsonData, setJsonData] = useState({});
    const intl = useIntl();

    const fetchJson = async ()=>{
        const result = await fetch('http://localhost:5001/libraries/test.text');
        const canvasJSONData = await result.text();
        let canvasObj = Buffer.from(canvasJSONData, 'base64');

        canvasObj = canvasObj.toString('utf-8');
        const canvasParsed =JSON.parse(canvasObj);
        setJsonData(canvasParsed);

    }

    useEffect(()=>{
        fetchJson().then();
    },[])

    return(
        jsonData && <TacticsBoard             onClose={false}
                                              canvasJson={jsonData.canvasJson}
                                              isAnimation={true}
                                              animationsData={jsonData.animationData}
                                              presentationMode={false}
                                              canvasBackground={jsonData.canvasBackground}
                                              customImagesJson={JSON.stringify([])}
                                              onFailTacticsBoard={false}
                                              addCustomSquad={() => {
                                                  // setShowSelectPlayers(true);
                                              }}
                                              intl={intl}
                                              canvasContainerDimensionsSave={jsonData.containerDimensionsSave}
                                              isOld={false}
                                              onShowPreviousDrawings={false}
                                              onDone={false}
                                              showSquad={false} />
    )

}
export default FootBallApp;

