const animationStates = (state = {play: false, pause: false}, action)=>{
    if(action.type === "SET_ANIMATION_STATES"){
        state = {play: action.action.play, pause: action.action.pause}
    }
    if(action.type === "RESET_CANVAS"){
        state = {play: false, pause: false};
    }

    return state;
}

export default animationStates;