import {setColorPopup} from "../../actions";
import {updatePaths} from "../../utils/undo-redo-events/undo-redo";
import {updateAllCustomPlayerImageDimensions} from "../../utils/addCustomPlayerImage";
import {sendBackShapes} from "../../utils/shapes/shapeUtils";
import {useDispatch} from "react-redux";
import {addAnnotationToCanvas} from "../../utils/addAnnotationToCanvas";

export const useUndo = ()=>{
    const dispatch = useDispatch();
    const undoHelper = (props)=>{
         let {
             setActiveBar,animationState,activeFrameVal,animation_state,isAnimationOpen,deletedObjects,svgPathElements,shadowFrames,setFrameObjects,addShadowObjects
             ,reinitAllEvents,canvas,activeStateIndex,undoStates,frames,stopEvents,reArrangeShadow,reinitCanvas,addedTextObjs,reinitFreeShape,
             reInitArrowLines,startEvents,drawLineStates,showShadowObjects,updateRemovedProps,allFrames,updateDeletedObjs,updateActiveIndex
         } = props;
        dispatch(setColorPopup(false))
        let undoVal, stateIndex;
        let shadowObjs
        setActiveBar(false);
        if (animationState) {
            undoVal = activeFrameVal.undoStates
            stateIndex = activeFrameVal.activeStateIndex

        } else {
            undoVal = undoStates
            stateIndex = activeStateIndex
        }
        let count = 0;
        if (animation_state) {
            for (let i = 0; i < canvas._objects.length; i++) {
                if (!canvas._objects[i].ref_id?.includes("shadow-object") && (canvas._objects[i]?.name !== 'line' && canvas._objects[i].name !== 'p0' && canvas._objects[i].name !== 'pX' && canvas._objects[i].name !== 'p1')) count += 1;
            }
        }

        if (undoVal[stateIndex] && isAnimationOpen) {
            if (activeFrameVal.data_num > 0) {
                let prInd = frames[activeFrameVal.data_num - 1].undoStates.findIndex(f => f.ref_id === undoVal[stateIndex].ref_id && undoVal[stateIndex].action === 'added');
                if (prInd > -1) return
            }
            if (undoVal[stateIndex].action === 'deleted') {

                let tempOb = undoVal[stateIndex].target
                let delInd = deletedObjects.findIndex(f => parseInt(f.deletedObj?.left) === parseInt(tempOb.left) && parseInt(f.deletedObj?.top) === parseInt(tempOb.top) && f.ObjRefId === tempOb.ref_id);
                if (activeFrameVal.data_num > 0 && activeFrameVal.data_num < frames.length) {
                    if (delInd > -1) {
                        const { currentDeletedPath, nextDeletedPath, deletedObj } = deletedObjects[delInd];
                        svgPathElements.push(currentDeletedPath)
                        svgPathElements.push(nextDeletedPath)
                        shadowFrames[activeFrameVal.data_num].objects.push(deletedObj)
                        setFrameObjects([...shadowFrames]);
                        deletedObjects.splice(delInd, 1);
                        updateRemovedProps(false,false,{shadowFrames});
                        updateDeletedObjs({deletedObjects})
                    }
                } else if (activeFrameVal.data_num == 0) {
                    if (delInd > -1) {
                        const { nextDeletedPath, deletedObj } = deletedObjects[delInd];
                        svgPathElements.push(nextDeletedPath)
                        shadowFrames[activeFrameVal.data_num].objects.push(deletedObj)
                        setFrameObjects([...shadowFrames]);
                        deletedObjects.splice(delInd, 1);
                        updateRemovedProps(false,false,{shadowFrames});
                        updateDeletedObjs({deletedObjects});
                    }
                }
            };
        }
        if (undoVal.length > 0 && undoVal[stateIndex]) {
            stopEvents();
            let pop_num = 1;
            if(undoVal[0]?.target !== 'init'){
                let initState = {
                    "target": "init",
                    "name": "init",
                    "ref_id": "init",
                    "json": "{\"version\":\"4.6.0\",\"objects\":[],\"hoverCursor\":\"move\",\"perPixelTargetFind\":false}",
                    "action": "init"
                }
                undoVal.unshift(initState)
            }

            if (stateIndex > 0) {
                stateIndex = stateIndex - pop_num
            }
            if (undoVal[stateIndex]) {
                let json
                if (undoVal[stateIndex].action === "array") {
                    json = undoVal[stateIndex].data[undoVal[stateIndex].data.length - 1].json
                } else {
                    json = undoVal[stateIndex].json;
                }
                //Shadows
                if (animation_state && activeFrameVal.data_num > 0) {
                    if ((undoVal[stateIndex].name === 'pX' || undoVal[stateIndex].name === 'p1' ||
                        undoVal[stateIndex].name === 'p2' || undoVal[stateIndex].name === 'p0') && undoVal[stateIndex].action === 'added') {
                        let newValInd = undoVal.findIndex(f => f.name === 'line' && f.ref_id === undoVal[stateIndex].ref_id && undoVal[stateIndex].action === 'added');
                        if (undoVal[newValInd].json) json = undoVal[newValInd].json
                    }
                    if (undoVal[stateIndex].shadowObjects) shadowObjs = [...undoVal[stateIndex].shadowObjects];

                    canvas.loadFromJSON(json, async function () {
                        canvas.renderAll();
                        if (undoVal[stateIndex].shadowObjects) {
                            await addShadowObjects(activeFrameVal, null, true);
                                updatePaths(false, shadowObjs, canvas, showShadowObjects, reArrangeShadow, reinitCanvas, addedTextObjs, reinitFreeShape, reInitArrowLines, startEvents);
                                updateAllCustomPlayerImageDimensions(canvas);

                        } else {
                            sendBackShapes(canvas);
                            updateAllCustomPlayerImageDimensions(canvas);
                            reinitAllEvents('undo')
                        }

                    });
                } else {
                    canvas.loadFromJSON(json, function () {
                        // setTimeout(() => {
                            sendBackShapes(canvas);
                            updateAllCustomPlayerImageDimensions(canvas);
                            reinitZigZag(canvas);
                            canvas.renderAll();
                            reinitAllEvents('undo');
                        // }, 200)
                    })
                }
                if (undoVal[stateIndex].name === 'line-end-point_shadow-object' && undoVal[stateIndex].action === 'added') {
                    let addedObj = drawLineStates.find(f => undoVal[stateIndex].ref_id);
                    let ind = canvas._objects.findIndex(f => f.name === 'line-end-point_shadow-object' && f.ref_id === addedObj.ref_id);
                    let endps = canvas._objects.filter(f => f.name === 'line-end-point_shadow-object');


                    for (let j = 0; j < endps.length; j++) {
                        for (let i = 0; i < drawLineStates.length; i++) {
                            if (endps[j].ref_id === drawLineStates[i].ref_id) {
                                endps[j].set({ brushType: drawLineStates[i].brushType });
                            }
                        }
                    }
                    if (ind > -1) {
                        canvas._objects[ind].brushType = addedObj.brushType;
                    }
                }

                let endPs = canvas._objects.filter(f => f.name === 'line-end-point_shadow-object');
                if (endPs.length) {
                    endPs.forEach(e => {
                        e.opacity = 0;
                        e.evented = false;
                    });
                    canvas.renderAll();
                }
            }
        }

        if (animationState) {
            activeFrameVal.activeStateIndex = stateIndex;
            updateRemovedProps({allFrames,activeFrameVal})
        } else {
            activeStateIndex = stateIndex;
            updateActiveIndex(activeStateIndex);
        }
    }
    return {undoHelper}
}
export const reinitZigZag = (canvas)=>{
    let objs = canvas._objects;
    for(let i=0; i<objs.length; i++){
        let obj = objs[i];
        if(obj.name === 'Line Arrow'){
            const {x1,y1,x2,y2} = obj;
            canvas.remove(obj);
            const annotate = addAnnotationToCanvas(canvas,[x1,y1,x2,y2],'arrow',obj,true,canvas)
            canvas.add(annotate);
        }
    }
}