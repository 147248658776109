import {activeSelectionConnectionLine, setConnectLineDim, setImageDimensions, setPlayerDim} from "../../utils/utils";
import {hideShadowLines} from "../../utils/HandleShadowObjs/hideShadowLines";
import {updateShadowLine} from "../../utils/HandleShadowObjs/updateShadowLine";

export const useObjectMoving = ()=>{
    const movingHelper =  async (props)=>{
        let {
            e,canvas,updateArrowObject,setCurveActivePoint,setCenterCurvePoint,animation_state,animationState,
            allFrames,frames
        } = props;
        e.target.opacity = .5;
        if (e.target.name === 'drawLine') {
            let endPInd = canvas._objects.findIndex(f => f.name === 'line-end-point_shadow-object' && f.ref_id === e.target.ref_id);
            if (endPInd > -1) {
                canvas._objects[endPInd].opacity = 0;
            }
        }

        if (e.target.type === 'activeSelection') {
            let objs = e.target._objects;
            for(let i=0; i<objs.length; i++){
                let obj = objs[i];
                if(obj.name === 'line-end-point_shadow-object' ){
                    obj.opacity = 0
                }
                if(animationState){
                    hideShadowLines(obj,canvas)
                }
                updateArrowObject(obj,'moving');
                if (obj.name == 'arrow_line' && obj.arrow) {
                            obj.arrow.opacity = 0;
                }
            }
        } else {
            if (e.target.name.includes('custom_image') || e.target.objecttype === 'sprite-image' || e.target.name === "player") {
                let activeObject = e.target;
                let nameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === activeObject.ref_id);
                let shirtInd = canvas._objects.findIndex(f => f.name === 'custom_image_shirtno' && f.ref_id === activeObject.ref_id);
                let imgInd = canvas._objects.findIndex(f => (f.name === 'player_custom_image' || f.objecttype === 'sprite-image' || f.name === "player") && f.ref_id === activeObject.ref_id);
                for (let i = 0; i < canvas._objects.length; i++) {
                    if (canvas._objects[i].name === 'connectionLine' && canvas._objects[i].ref_id.includes(activeObject.ref_id)) {
                        setConnectLineDim(canvas, canvas._objects[i])
                    }
                }
                if (activeObject.name === 'player_custom_image') {
                    if (nameInd > -1 && shirtInd > -1) {
                        activeObject.setTextPosition(canvas._objects[nameInd], canvas)
                        // setTextDimensions(canvas._objects[nameInd], canvas._objects[shirtInd], activeObject, canvas);
                    }
                } else if (activeObject.objecttype === 'sprite-image' || activeObject.name === "player") {
                    if (nameInd > -1 && imgInd > -1) {
                        canvas._objects[imgInd].setTextPosition(canvas._objects[nameInd], canvas)
                        // setPlayerNameDim(canvas._objects[nameInd], canvas._objects[imgInd], canvas);
                    }
                } else {
                    if (nameInd > -1 && shirtInd > -1 && imgInd > -1) {
                        setImageDimensions(canvas._objects[nameInd], canvas._objects[shirtInd], canvas._objects[imgInd], activeObject, canvas)
                    } else if (nameInd > -1 && imgInd > -1) {
                        setPlayerDim(canvas._objects[nameInd], canvas._objects[imgInd], canvas)
                    }
                }
            }
            const refId = e.target.ref_id;
            const pointInd = canvas._objects.findIndex((o) => o.name === "pX" && o.ref_id.includes(refId));
            if (pointInd > -1) {
                canvas._objects[pointInd].bringToFront();
            }
            if (animationState && (allFrames.length > 0 || frames.length > 0))
                updateShadowLine(e.target,canvas);
            if (e.target.name !== "player" && e.target.name !== "pX" && e.target.objecttype !== "sprite-image")
                updateArrowObject(e.target, "moving")
            if (e.target.name === "pX") {
                setCurveActivePoint(e.target)
            } else {
                const middlePointInd = canvas._objects.findIndex((o) => o.ref_id.includes(e.target.ref_id) && o.name === "p1");
                if (middlePointInd > -1) setCenterCurvePoint(canvas._objects[middlePointInd], false);
            }
        }
    }
    return {movingHelper}
}