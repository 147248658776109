import {fabric} from "fabric";

const setArrowAlignment = (x2, y2, tempVal) => {
    if (tempVal == -1.57) {
        // 90 degrees
        x2 = x2 - 2
    } else if (-1.57 < tempVal && tempVal < 0) {
        // between 0 and 90 degrees
        x2 = x2 - 1.75;
        y2 = y2 - 2;
    } else if (tempVal < -1.57) {
        // between 90 and 180 degrees
        y2 = y2 + 2;
        x2 = x2 - 1.75;
    } else if (tempVal <= 3.14 && tempVal > 1.57) {
        // between 180 and 270 degrees
        x2 = x2 + 1.75;
        y2 = y2 + 2;
    } else if (tempVal == 1.57) {
        // 360 degrees
        x2 = x2 + 2;
    } else {
        x2 = x2 + 2;
        y2 = y2 - 2;
    }

    return {
        x2, y2
    }
}
export const createArrow = (x1, y1, x2, y2, type, color, id,scalePropsVal)=>{
    const scaleProps = scalePropsVal
    let obj
    var angle = Math.atan2(y2 - y1, x2 - x1);
    let tempVal = Number.parseFloat(angle).toFixed(2);

    if (type === "arrow" || type === "arrow_dashed") {
        x2 = setArrowAlignment(x2, y2, tempVal).x2;
        y2 = setArrowAlignment(x2, y2, tempVal).y2;
        obj = new fabric.Triangle({
            left: x2,
            top: y2,
            hasBorders: false,
            hasControls: false,
            lockScalingX: true,
            lockScalingY: true,
            lockRotation: true,
            lockMovementX: true,
            lockMovementY: true,
            selectable: true,
            evented: false,
            originX: 'center',
            ref_id: id,
            originY: 'center',
            angle: (angle * (180 / (3.142))) - 30,
            width: (scaleProps.height / 2) - 1,
            height: (scaleProps.height / 2) - 1,
            fill: color,
            name: "drawElementArrow",
            is_animation: false,
            dirty: true,
            selectionBackgroundColor: 'transparent',
        });

    } else if (type === "block" || type === "block_dashed") {
        obj = new fabric.Rect({
            left: x2,
            top: y2,
            height: 2,
            width: (scaleProps.width / 2) - 1,
            stroke: color,
            fill: color,
            strokeWidth: 1,
            originX: 'center',
            originY: 'center',
            ref_id: id,
            angle: (angle * (180 / (3.142))) + 90,
            hasBorders: false,
            hasControls: false,
            lockMovementX: true,
            lockMovementY: true,
            lockScalingX: true,
            lockScalingY: true,
            lockRotation: true,
            name: "drawElementBlock",
            selectable: true,
            evented: false,
            selectionBackgroundColor: 'transparent',
        });
    }
    return obj;
}